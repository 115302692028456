import React, { useContext, useEffect, useState } from "react";
import "../styles/newSTOverlay.scss";
import { db, terminiRef } from "../utils/firebase";
import { AppContext } from "../functions/AppProvider";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { Termin } from "../utils/types";
import { DatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import dayjs from "dayjs";
import useKeyPress from "react-use-keypress";
import { eachDayOfInterval } from "date-fns";

interface Props {
  setIsPRNVD: any;
  nizDanasnjihTermina: Array<Termin>;
  sviTermini: Array<Termin>;
  instruktorID: string;
}
interface Day {
  date: Date;
}
export function PRNVDOverlay(props: Props) {
  const { setIsPRNVD, nizDanasnjihTermina, sviTermini, instruktorID } = props;
  const [prviDan, setPrviDan] = useState<string>("");
  const [poslednjiDan, setPoslednjiDan] = useState<string>("");
  const [daLiPostoji, setDaLiPostoji] = useState<boolean>(false);
  const [radnaSubota, setRadnaSubota] = useState<string>("da");
  const { currentUserData, setAlert, setIsAlert, setLoading } =
    useContext(AppContext);

  useKeyPress("Escape", () => {
    setIsPRNVD(false);
  });
  useKeyPress("Enter", () => {
    primeniRaspored();
  });

  function primeniRaspored() {
    setLoading(true);
    if (prviDan === "" || poslednjiDan === "") {
      setAlert("Unesite datume");
      setIsAlert(true);
      setLoading(false);
    }
    const daysInMonth: Day[] = eachDayOfInterval({
      start: new Date(prviDan),
      end: new Date(poslednjiDan),
    }).map((date) => ({ date }));

    let daLiP: boolean = false;

    for (let i = 0; i < daysInMonth.length; i++) {
      const godina = daysInMonth[i].date.getFullYear();
      const mesec = daysInMonth[i].date.getMonth() + 1;
      const dan = daysInMonth[i].date.getDate();
      const datum: string = `${godina}/${mesec}/${dan}`;
      for (let j = 0; j < sviTermini.length; j++) {
        if (sviTermini[j].kojiDatum === datum) {
          setDaLiPostoji(true);
          daLiP = true;
        }
      }
    }
    if (daLiPostoji === true || daLiP === true) {
      alert(
        "Svi odabrani dani moraju biti potpuno prazni. Obrišite termine za odabrane dane ili odaberite druge dane"
      );
      setIsPRNVD(false);
      setLoading(false);
      return;
    } else if (daLiPostoji === false) {
      for (let i = 0; i < daysInMonth.length; i++) {
        const godina = daysInMonth[i].date.getFullYear();
        const mesec = daysInMonth[i].date.getMonth() + 1;
        const dan = daysInMonth[i].date.getDate();
        const danUNedelji = daysInMonth[i].date.getDay();
        const datum: string = `${godina}/${mesec}/${dan}`;
        if (radnaSubota === "da" && danUNedelji !== 0) {
          for (let j = 0; j < nizDanasnjihTermina.length; j++) {
            const [satiPocetka, minutiPocetka] = nizDanasnjihTermina[
              j
            ].vremePocetka
              .split(":")
              .map(Number);
            const [satiKraja, minutiKraja] = nizDanasnjihTermina[j].vremeKraja
              .split(":")
              .map(Number);
            const fullPocetak: string = new Date(
              godina,
              mesec - 1,
              dan,
              satiPocetka,
              minutiPocetka
            ).toString();
            const fullKraj: string = new Date(
              godina,
              mesec - 1,
              dan,
              satiKraja,
              minutiKraja
            ).toString();
            const noviTerminData: Termin = {
              beleskaInstruktora: nizDanasnjihTermina[j].beleskaInstruktora,
              fullDateEnd: fullPocetak,
              fullDateStart: fullKraj,
              kojiDatum: datum,
              status: "Slobodan",
              studentID: "",
              studentIme: "",
              tipTermina: nizDanasnjihTermina[j].tipTermina,
              vremeKraja: nizDanasnjihTermina[j].vremeKraja,
              vremePocetka: nizDanasnjihTermina[j].vremePocetka,
            };
            console.log("NOVI TERMIN DATA", noviTerminData);

            updateDoc(doc(terminiRef, instruktorID), {
              termini: arrayUnion(noviTerminData),
            });
          }
        } else if (
          radnaSubota === "ne" &&
          danUNedelji !== 0 &&
          danUNedelji !== 6
        ) {
          for (let j = 0; j < nizDanasnjihTermina.length; j++) {
            const [satiPocetka, minutiPocetka] = nizDanasnjihTermina[
              j
            ].vremePocetka
              .split(":")
              .map(Number);
            const [satiKraja, minutiKraja] = nizDanasnjihTermina[j].vremeKraja
              .split(":")
              .map(Number);
            const fullPocetak: string = new Date(
              godina,
              mesec - 1,
              dan,
              satiPocetka,
              minutiPocetka
            ).toString();
            const fullKraj: string = new Date(
              godina,
              mesec - 1,
              dan,
              satiKraja,
              minutiKraja
            ).toString();
            const noviTerminData: Termin = {
              beleskaInstruktora: nizDanasnjihTermina[j].beleskaInstruktora,
              fullDateEnd: fullPocetak,
              fullDateStart: fullKraj,
              kojiDatum: datum,
              status: "Slobodan",
              studentID: "",
              studentIme: "",
              tipTermina: nizDanasnjihTermina[j].tipTermina,
              vremeKraja: nizDanasnjihTermina[j].vremeKraja,
              vremePocetka: nizDanasnjihTermina[j].vremePocetka,
            };
            console.log("NOVI TERMIN DATA", noviTerminData);

            updateDoc(doc(terminiRef, instruktorID), {
              termini: arrayUnion(noviTerminData),
            });
          }
        }
      }

      setAlert("Uspešno dodati termini");
      setIsAlert(true);
      setLoading(false);
      setIsPRNVD(false);
    }
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="newSTOverlay">
        <div className="newSTBox">
          <div className="newSTBoxHeader">
            <h2>Izaberite dane</h2>
          </div>
          <FormControl>
            <p>Uključene radne subote?</p>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="da"
              className="izborDatumaMUI"
              sx={{
                marginBottom: "0",
              }}
              row
              name="radio-buttons-group"
              onChange={(e) => {
                setRadnaSubota(e.target.value);
              }}
            >
              <FormControlLabel value="da" control={<Radio />} label="Da" />
              <FormControlLabel value="ne" control={<Radio />} label="Ne" />
            </RadioGroup>
          </FormControl>
          <label htmlFor="tipTermina">Prvi dan</label>
          <DatePicker
            label="Izaberite datum"
            className="izborDatumaMUI"
            format="DD/MM/YYYY"
            disablePast
            closeOnSelect
            onChange={(e: any) => {
              console.log(e.$d);
              setPrviDan(`${e.$d}`);
            }}
          />

          <label htmlFor="vremePocetka">Poslednji dan</label>
          <DatePicker
            label="Izaberite datum"
            className="izborDatumaMUI"
            format="DD/MM/YYYY"
            disablePast
            closeOnSelect
            value={prviDan !== "" ? dayjs(prviDan) : null}
            onChange={(e: any) => {
              console.log(e.$d);
              setPoslednjiDan(`${e.$d}`);
            }}
          />
          <div className="buttonOptions">
            <button onClick={() => primeniRaspored()}>Sačuvaj</button>
            <button onClick={() => setIsPRNVD(false)}>Odustani</button>
          </div>
          <button
            className="x"
            onClick={() => {
              setIsPRNVD(false);
            }}
          >
            X
          </button>
        </div>
      </div>
    </LocalizationProvider>
  );
}
