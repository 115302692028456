import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../functions/AppProvider";
import "../styles/home.scss";
import { skoleRef } from "../utils/firebase";
import { BiHelpCircle, BiLogOutCircle } from "react-icons/bi";
import { GiBookCover, GiTeacher } from "react-icons/gi";
import { MdAttachMoney, MdEmojiPeople, MdQuiz } from "react-icons/md";
import { BsFillCalendarDateFill, BsPeopleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { AiFillCar, AiFillEdit, AiFillFilePpt } from "react-icons/ai";
import DarkProfile from "../DarkProfile.png";
import { UserData } from "../utils/types";
import { doc, getDoc } from "firebase/firestore";

interface Props {}

export function HomeSA(props: Props) {
  const [nizStudenata, setNizStudenata] = useState<Array<UserData>>([]);
  const [nizInstruktora, setNizInstruktora] = useState<Array<UserData>>([]);
  const navigate = useNavigate();
  const date = new Date();
  const mesec = date.getMonth() + 1;
  const { currentUser, currentUserData, logOut, setLoading, loading } =
    useContext(AppContext);

  useEffect(() => {
    setLoading(true);
    getDoc(doc(skoleRef, currentUserData?.autoskolaId)).then((skola) => {
      console.log("SKOLA", skola?.data()?.studenti);
      setNizStudenata(skola?.data()?.studenti);
      setNizInstruktora(skola?.data()?.instruktori);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  }, [currentUserData]);

  const [isP, setP] = useState<boolean>(false);
  return (
    <>
      <div className="welcome">
        <h2>
          Dobro došli, {currentUserData && currentUserData?.ime.split(" ")[0]}
        </h2>
        <h2>
          {date.getDate()}.{mesec}.{date.getFullYear()}
        </h2>
      </div>
      <div className="optionsShortcuts">
        <div
          className="shortcut"
          onClick={() => {
            navigate(`/studenti/${currentUserData?.autoskolaId}`);
          }}
        >
          <div className="ikonicaWrap">
            <BsPeopleFill color="white" size={50} />
          </div>
          <h3>Kandidati</h3>
        </div>
        <div
          className="shortcut"
          onClick={() => {
            navigate(`/zahtevi`);
          }}
        >
          <div className="ikonicaWrap">
            <MdEmojiPeople color="white" size={50} />
          </div>
          <h3>Zahtevi</h3>
        </div>

        <div
          className="shortcut"
          onClick={() => {
            navigate(`/instruktori/${currentUserData?.autoskolaId}`);
          }}
        >
          <div className="ikonicaWrap">
            <AiFillCar color="white" size={50} />
          </div>
          <h3>Instruktori</h3>
        </div>

        <div
          className="shortcut"
          onClick={() => {
            navigate(`/predavaci/${currentUserData?.autoskolaId}`);
          }}
        >
          <div className="ikonicaWrap">
            <GiTeacher color="white" size={50} />
          </div>
          <h3>Predavači</h3>
        </div>
        <div
          className="shortcut"
          onClick={() => {
            navigate(`/predavanja/${currentUserData?.autoskolaId}`);
          }}
        >
          <div className="ikonicaWrap">
            <GiBookCover color="white" size={50} />
          </div>
          <h3>Predavanja</h3>
        </div>
        <div
          className="shortcut"
          onClick={() => {
            navigate(`/lekcije/${currentUserData?.autoskolaId}`);
          }}
        >
          <div className="ikonicaWrap">
            <GiTeacher color="white" size={50} />
          </div>
          <h3>Lekcije</h3>
        </div>

        <div
          className="shortcut"
          onClick={() => {
            navigate(`/prezentacije/${currentUserData?.autoskolaId}`);
          }}
        >
          <div className="ikonicaWrap">
            <AiFillFilePpt color="white" size={50} />
          </div>
          <h3>Prezentacije</h3>
        </div>

        <div
          className="shortcut"
          onClick={() => {
            navigate(`/testovi/${currentUserData?.autoskolaId}`);
          }}
        >
          <div className="ikonicaWrap">
            <MdQuiz color="white" size={50} />
          </div>
          <h3>Testovi</h3>
        </div>
        <div className="shortcut">
          <div className="ikonicaWrap">
            <BiHelpCircle color="white" size={50} />
          </div>
          <h3>Pomoć</h3>
        </div>

        <div
          className="shortcut"
          onClick={() => {
            logOut();
            navigate("/login");
          }}
        >
          <div className="ikonicaWrap">
            <BiLogOutCircle color="white" size={60} />
          </div>
          <h3>Izlogujte se</h3>
        </div>
      </div>

      {currentUserData?.subscribed === false && (
        <div className="optionsShortcuts">
          <h1>
            Notifikacije nisu aktivirane. Kako biste ih aktivirali, pošaljite
            poruku na WhatsApp-u sa sadržajem "Prihvatam" na broj 065 840 54 80
          </h1>
        </div>
      )}

      <div className="opcijeKontejner">
        <div className="opcije Sredina">
          <div className="opcijeSredinaHeader">
            <p></p>
            <h3>Kandidati</h3>
            <p></p>
          </div>
          <div className="opcijeSredinaBody">
            {nizStudenata.map((student) => {
              return (
                <div className="korisnik">
                  <div className="korisnikInfo">
                    <img src={student.profilnaURL} alt={student.ime} />
                    <h3>{student.ime}</h3>
                  </div>
                  <div className="opcijeKorisnik">
                    <div className="okvirOpcije">
                      <MdAttachMoney color="white" size={30} />
                    </div>
                    <div className="okvirOpcije">
                      <AiFillEdit color="white" size={30} />
                    </div>
                  </div>
                </div>
              );
            })}
            {nizStudenata.length === 0 && (
              <h3>Kandidati nisu dodati ili nisu aktivirali profile.</h3>
            )}
          </div>
        </div>
        <div className="opcije Desno">
          <div className="opcijeDesnoHeader">
            <h3>Instruktori</h3>
          </div>
          <div className="opcijeDesnoBody">
            {nizInstruktora.map((instruktor) => {
              return (
                <div className="korisnik">
                  <div className="korisnikInfo">
                    <img src={instruktor.profilnaURL} alt={instruktor.ime} />
                    <h3>{instruktor.ime.split(" ")[0]}</h3>
                  </div>
                  <div className="opcijeKorisnik">
                    <div className="okvirOpcije">
                      <BsFillCalendarDateFill color="white" size={20} />
                    </div>
                  </div>
                </div>
              );
            })}
            {nizInstruktora.length === 0 && (
              <h3>Instruktori nisu dodati ili nisu aktivirali profile.</h3>
            )}
          </div>
        </div>
        <div className="opcije Levo">
          <div className="opcijeLevoDiv">
            <div className="opcijeLevoHeader">
              <div className="opcijeLevoHeaderImage">
                <img
                  src={
                    currentUserData?.profilnaURL !== undefined &&
                    currentUserData.profilnaURL !== null &&
                    currentUserData?.profilnaURL.length < 1
                      ? DarkProfile
                      : currentUserData?.profilnaURL
                  }
                  alt={currentUserData?.ime}
                />
              </div>
              <div className="opcijeLevoHeaderTekst">
                <h3>{currentUserData?.ime}</h3>
              </div>
            </div>
            <div className="opcijeLevoBody">
              <div className="stavka">
                <h4>Email</h4>
                <p>{currentUserData?.email}</p>
              </div>
              <div className="stavka">
                <h4>Auto skola</h4>
                <p>{currentUserData?.autoskolaId}</p>
              </div>
              <div className="stavka">
                <h4>Uloga</h4>
                <p>Administrator</p>
              </div>
              <div className="stavka">
                <h4>ID</h4>
                <p>{currentUserData?.id}</p>
              </div>
            </div>
          </div>
          <button
            onClick={() => {
              navigate(`/profile/${currentUserData?.id}`);
            }}
          >
            Profil
          </button>
        </div>
      </div>

      {/* <div className="optionsContainer">
        <div
          className="option"
          onClick={() => {
            logOut();
            navigate("/");
          }}
        >
          <BiLogOutCircle color="white" size={100} />
          <h3>Izlogujte se</h3>
        </div>
        <div className="option" onClick={() => setIsNewST(true)}>
          <GiTeacher color="white" size={100} />
          <h3>Dodajte novog predavača</h3>
        </div>
        <div className="option" onClick={() => setIsNewSI(true)}>
          <MdOutlineDriveEta color="white" size={100} />
          <h3>Dodajte novog instruktora</h3>
        </div>
        <div className="option" onClick={() => setIsNewSS(true)}>
          <BsFillPersonPlusFill color="white" size={100} />
          <h3>Dodajte novog učenika</h3>
        </div>
        <div className="option" onClick={() => navigate(`/random`)}>
          <BsPeopleFill color="white" size={100} />
          <h3>Random ljudi </h3>
        </div>
      </div> */}
      {/* {isNewST && <NewSTOverlay setIsNewST={setIsNewST} />} */}
      {/* {isNewSS && <NewSSOverlay setIsNewSS={setIsNewSS} />} */}
      {/* {isNewSI && <NewSIOverlay setIsNewSI={setIsNewSI} />} */}
    </>
  );
}
