import React, { useContext } from "react";
import "../styles/navigation.scss";
import { AiOutlineHome } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../functions/AppProvider";
import { BsFillCalendarDateFill } from "react-icons/bs";
import { IoCalendarOutline } from "react-icons/io5";
interface Props {}

export function Navigation(props: Props) {
  const navigate = useNavigate();
  const { currentUserData } = useContext(AppContext);
  const location = useLocation();
  console.log("PATH", location.pathname, location.pathname === "/");
  if (location.pathname !== "/")
    return (
      <div className="navigation">
        <div
          className="iconBox"
          onClick={() => navigate(`/profile/${currentUserData?.id}`)}
        >
          <CgProfile color="#fff" size={50} />
          <p>Profil</p>
        </div>
        <div className="iconBox" onClick={() => navigate("/home")}>
          <AiOutlineHome color="#fff" size={50} />
          <p>Početna</p>
        </div>
        {currentUserData?.uloga === "schoolinstructor" && (
          <div
            className="iconBox"
            onClick={() => {
              if (currentUserData.uloga === "schoolinstructor") {
                navigate(`/kalendar/${currentUserData.id}`);
              } else if (currentUserData.uloga === "student") {
                navigate(`/kalendar/${currentUserData.koJeInstruktorID}`);
              }
            }}
          >
            <IoCalendarOutline color="white" size={50} />
            <p>Kalendar</p>
          </div>
        )}
        {currentUserData?.uloga === "student" &&
          currentUserData.polozioTeoriju === true &&
          currentUserData.koJeInstruktorID !== "" &&
          currentUserData.koJeInstruktorID !== null &&
          currentUserData.koJeInstruktorID !== undefined && (
            <div
              className="iconBox"
              onClick={() => {
                if (currentUserData.uloga === "schoolinstructor") {
                  navigate(`/kalendar/${currentUserData.id}`);
                } else if (currentUserData.uloga === "student") {
                  navigate(`/kalendar/${currentUserData.koJeInstruktorID}`);
                }
              }}
            >
              <IoCalendarOutline color="white" size={50} />
              <p>Kalendar</p>
            </div>
          )}
      </div>
    );
  else return <></>;
}
