import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import "../styles/home.scss";
import { AppContext } from "../functions/AppProvider";
import axios from "axios";
import {
  AiFillCloseCircle,
  AiFillSave,
  AiOutlineAppstoreAdd,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { BiArrowBack, BiArrowToRight, BiSave } from "react-icons/bi";
import { IoTextOutline } from "react-icons/io5";

import SpinAndWin from "react-spin-game";
import "react-spin-game/dist/index.css";
import { NewUploadOverlay } from "../components/NewUploadOverlay";
import { Test, PitanjeTesta } from "../utils/types";
import uuid from "react-uuid";
import { db, testoviRef, skoleRef } from "../utils/firebase";
import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { TestDescription } from "../components/TestDescription";
import _ from "lodash";
import { MdArrowBack, MdOutlineSpellcheck } from "react-icons/md";
import { TestResultOverlay } from "../components/TestResultOverlay";
import { IconContext } from "react-icons/lib";

interface Props {}

export function TestResenja(props: Props) {
  const { currentUser, currentUserData, setLoading } = useContext(AppContext);
  const { testID, odgovori } = useParams();
  const [slikaZaPitanje, setSlikuZaPitanje] = useState<string>("");
  const [test, setTest] = useState<Test>();
  const praznoPitanje: PitanjeTesta = {
    pitanje: "",
    a: "",
    b: "",
    c: "",
    d: "",
    tacanOdgovor: "",
    slika: "",
  };
  const [nizPitanja, setNizPitanja] = useState<Array<PitanjeTesta>>([
    praznoPitanje,
  ]);
  const [nizOdgovora, setNizOdgovora] = useState<Array<string>>([]);
  const [indikator, setIndikator] = useState<number>(0);
  const navigate = useNavigate();

  function dohvatiTest() {
    getDoc(doc(testoviRef, testID)).then((Test) => {
      const pitanja = Test?.data()?.nizPitanja;
      setNizPitanja(pitanja);
      setTest(Test?.data() as Test);
    });
  }
  useEffect(() => {
    dohvatiTest();
  }, []);

  useEffect(() => {
    if (odgovori !== undefined && odgovori !== null) {
      setNizOdgovora(odgovori.split("-"));
    }
  }, [odgovori]);

  useEffect(() => {
    if (slikaZaPitanje !== "") {
      let newArr = [...nizPitanja];
      newArr[indikator].slika = slikaZaPitanje;
      setNizPitanja(newArr);
    }
  }, [slikaZaPitanje]);
  function prethodnoPitanje() {
    setIndikator(indikator - 1);
  }
  function sledecePitanje() {
    setIndikator(indikator + 1);
  }

  return (
    <>
      <div className="welcome">
        <div className="welcomeTitle">
          <IconContext.Provider value={{ className: "backIkonica" }}>
            <div className="ikonicaOmotac" onClick={() => navigate(-1)}>
              <MdArrowBack />
            </div>
          </IconContext.Provider>
          <h2>Resenja testa - {test?.ime} </h2>
        </div>
      </div>
      <div className="optionsShortcuts">
        <div
          className="shortcut"
          onClick={() => {
            navigate(`/testovi/${currentUserData?.autoskolaId}`);
          }}
        >
          <div className="ikonicaWrap">
            <AiFillCloseCircle color="white" size={50} />
          </div>
          <h3>Zatvori test</h3>
        </div>
        {indikator !== 0 && (
          <div
            className="shortcut"
            onClick={() => {
              prethodnoPitanje();
            }}
          >
            <div className="ikonicaWrap">
              <AiOutlineArrowLeft color="white" size={50} />
            </div>
            <h3>Prethodno pitanje</h3>
          </div>
        )}
        {indikator + 1 < nizPitanja.length && (
          <div
            className="shortcut"
            onClick={() => {
              sledecePitanje();
            }}
          >
            <div className="ikonicaWrap">
              <AiOutlineArrowRight color="white" size={50} />
            </div>
            <h3>Sledeće pitanje</h3>
          </div>
        )}
      </div>

      <div className="list">
        <div className="listPieceVertical">
          <h2>
            Pitanje {indikator + 1} - Tačno{" "}
            {nizPitanja[indikator]?.tacanOdgovor.toUpperCase()}- Vaš odgovor -{" "}
            {nizOdgovora[indikator]?.toUpperCase()}
          </h2>
          {/* <p>Tačan odgovor - {nizPitanja[indikator].tacanOdgovor}</p>
          <p>Vaš odgovor - {nizOdgovora[indikator]} </p> */}
          <div className="listPieceRow">
            <div className="listPieceRowQuestions">
              <textarea
                name="pitanje"
                id="pitanje"
                placeholder="Unesite pitanje"
                disabled
                value={
                  nizPitanja[indikator].pitanje !== "" &&
                  nizPitanja[indikator].pitanje !== null &&
                  nizPitanja[indikator].pitanje !== undefined
                    ? nizPitanja[indikator].pitanje
                    : ""
                }
                onChange={(e) => {
                  let newArr = [...nizPitanja];
                  newArr[indikator].pitanje = e.target.value;
                  setNizPitanja(newArr);
                }}
              />
              <label htmlFor="a">A</label>
              <textarea
                placeholder="Odgovor A"
                disabled
                className={
                  nizPitanja[indikator].tacanOdgovor === "a"
                    ? "correct"
                    : nizOdgovora[indikator] === "a"
                    ? "incorrect"
                    : ""
                }
                value={
                  nizPitanja[indikator].a !== "" &&
                  nizPitanja[indikator].a !== null &&
                  nizPitanja[indikator].a !== undefined
                    ? nizPitanja[indikator].a
                    : ""
                }
                onChange={(e) => {
                  let newArr = [...nizPitanja];
                  newArr[indikator].a = e.target.value;
                  setNizPitanja(newArr);
                }}
              />
              <label htmlFor="b">B</label>
              <textarea
                placeholder="Odgovor B"
                className={
                  nizPitanja[indikator].tacanOdgovor === "b"
                    ? "correct"
                    : nizOdgovora[indikator] === "b"
                    ? "incorrect"
                    : ""
                }
                disabled
                value={
                  nizPitanja[indikator].b !== "" &&
                  nizPitanja[indikator].b !== null &&
                  nizPitanja[indikator].b !== undefined
                    ? nizPitanja[indikator].b
                    : ""
                }
                onChange={(e) => {
                  let newArr = [...nizPitanja];
                  newArr[indikator].b = e.target.value;
                  setNizPitanja(newArr);
                }}
              />
              <label htmlFor="c">C</label>
              <textarea
                placeholder="Odgovor C"
                className={
                  nizPitanja[indikator].tacanOdgovor === "c"
                    ? "correct"
                    : nizOdgovora[indikator] === "c"
                    ? "incorrect"
                    : ""
                }
                disabled
                value={
                  nizPitanja[indikator].c !== "" &&
                  nizPitanja[indikator].c !== null &&
                  nizPitanja[indikator].c !== undefined
                    ? nizPitanja[indikator].c
                    : ""
                }
                onChange={(e) => {
                  let newArr = [...nizPitanja];
                  newArr[indikator].c = e.target.value;
                  setNizPitanja(newArr);
                }}
              />
              <label htmlFor="d">D</label>
              <textarea
                placeholder="Odgovor D"
                disabled
                className={
                  nizPitanja[indikator].tacanOdgovor === "d"
                    ? "correct"
                    : nizOdgovora[indikator] === "d"
                    ? "incorrect"
                    : ""
                }
                value={
                  nizPitanja[indikator].d !== "" &&
                  nizPitanja[indikator].d !== null &&
                  nizPitanja[indikator].d !== undefined
                    ? nizPitanja[indikator].d
                    : ""
                }
                onChange={(e) => {
                  let newArr = [...nizPitanja];
                  newArr[indikator].d = e.target.value;
                  setNizPitanja(newArr);
                }}
              />
            </div>
            <div className="listPieceRowImage">
              {nizPitanja[indikator].slika !== "" &&
                nizPitanja[indikator].slika !== null &&
                nizPitanja[indikator].slika !== undefined && (
                  <img src={nizPitanja[indikator].slika} alt="" />
                )}

              <h2>Vaš odgovor</h2>
              <div className="listPieceRowImageButtons">
                <button
                  className={
                    nizPitanja[indikator].tacanOdgovor === "a"
                      ? "correct"
                      : nizOdgovora[indikator] === "a"
                      ? "incorrect"
                      : ""
                  }
                  disabled
                  onClick={() => {
                    let newArr = [...nizOdgovora];
                    newArr[indikator] = "a";
                    setNizOdgovora(newArr);
                  }}
                >
                  A
                </button>
                <button
                  className={
                    nizPitanja[indikator].tacanOdgovor === "b"
                      ? "correct"
                      : nizOdgovora[indikator] === "b"
                      ? "incorrect"
                      : ""
                  }
                  disabled
                  onClick={() => {
                    let newArr = [...nizOdgovora];
                    newArr[indikator] = "b";
                    setNizOdgovora(newArr);
                  }}
                >
                  B
                </button>
                <button
                  className={
                    nizPitanja[indikator].tacanOdgovor === "c"
                      ? "correct"
                      : nizOdgovora[indikator] === "c"
                      ? "incorrect"
                      : ""
                  }
                  disabled
                  onClick={() => {
                    let newArr = [...nizOdgovora];
                    newArr[indikator] = "c";
                    setNizOdgovora(newArr);
                  }}
                >
                  C
                </button>
                <button
                  className={
                    nizPitanja[indikator].tacanOdgovor === "d"
                      ? "correct"
                      : nizOdgovora[indikator] === "d"
                      ? "incorrect"
                      : ""
                  }
                  disabled
                  onClick={() => {
                    let newArr = [...nizOdgovora];
                    newArr[indikator] = "d";
                    setNizOdgovora(newArr);
                  }}
                >
                  D
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
