import React, { useContext, useEffect, useState } from "react";
import "../styles/home.scss";
import { AiFillCloseCircle, AiFillEye } from "react-icons/ai";
import { BiPlay } from "react-icons/bi";
import {
  Test,
  InteraktivniKviz,
  InteraktivniOdgovor,
  RezultatInteraktivnog,
} from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import {
  arrayUnion,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { testoviRef, skoleRef, interaktivniRef } from "../utils/firebase";
import uuid from "react-uuid";
import _ from "lodash";
import { useNavigate, useParams } from "react-router";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { PieChart } from "@mui/x-charts";
import { IconContext } from "react-icons/lib";
import { MdArrowBack } from "react-icons/md";

interface Props {}

export function InteraktivniKvizAdmin(props: Props) {
  const { currentUserData, setLoading, setIsAlert, setAlert } =
    useContext(AppContext);
  const { testID } = useParams();
  const [isCreated, setIsCreated] = useState<boolean>(false);
  const [interaktivniKviz, setInteraktivniKviz] = useState<InteraktivniKviz>();
  const [nizInteraktivnih, setNizInteraktivnih] = useState<
    Array<InteraktivniKviz>
  >([]);
  const [jedinstveniBroj, setJedinstveniBroj] = useState<string>(uuid());
  const date = new Date();
  const navigate = useNavigate();

  useEffect(() => {
    kreirajInteraktivniKviz();
  }, [currentUserData]);

  async function kreirajInteraktivniKviz() {
    if (
      currentUserData === null ||
      currentUserData === undefined ||
      isCreated === true ||
      currentUserData.autoskolaId === ""
    )
      return;
    await getDoc(doc(testoviRef, testID)).then((testData) => {
      const ikData: InteraktivniKviz = {
        id: jedinstveniBroj,
        nizStudenata: [],
        testID: testID as string,
        test: testData.data() as Test,
        status: "waiting",
        trenutnoPitanje: 0,
        kreirao: currentUserData.ime,
        kreiraoID: currentUserData.id,
        a: 0,
        b: 0,
        c: 0,
        d: 0,
        nizOdgovora: [],
        ukupnoOdgovora: 0,
        ukupnoNeodgovorenih: 0,
        nizRezultata: [],
        datum: new Date().toString(),
      };

      setDoc(doc(interaktivniRef, jedinstveniBroj), ikData).then(async () => {
        await updateDoc(doc(skoleRef, currentUserData.autoskolaId), {
          interaktivniUToku: arrayUnion(ikData),
        }).then(() => {
          setIsCreated(true);
          slusajPromene();
        });
      });
    });
  }

  function slusajPromene() {
    const unsubscribe = onSnapshot(
      doc(interaktivniRef, jedinstveniBroj),
      (snap) => {
        if (!snap.exists) {
          alert("Greska u programu.");
          return;
        }
        const trenutniPodaci = snap.data();
        if (trenutniPodaci === undefined) return;
        setInteraktivniKviz(trenutniPodaci as InteraktivniKviz);
      }
    );
    return () => unsubscribe();
  }

  function odustani() {
    if (
      currentUserData === null ||
      currentUserData === undefined ||
      interaktivniKviz === undefined ||
      interaktivniKviz === null
    )
      return;
    setLoading(true);
    updateDoc(doc(interaktivniRef, interaktivniKviz.id), {
      status: "quit",
    }).then(() => {
      getDoc(doc(skoleRef, currentUserData.autoskolaId))
        .then((skola) => {
          const nizI = skola?.data()
            ?.interaktivniUToku as Array<InteraktivniKviz>;
          setNizInteraktivnih(nizI);
        })
        .then(() => {
          const filtriraniNiz = _.filter(nizInteraktivnih, function (o) {
            if (o.id !== interaktivniKviz.id) return o;
          });
          updateDoc(doc(skoleRef, currentUserData.autoskolaId), {
            interaktivniUToku: filtriraniNiz,
          }).then(() => {
            deleteDoc(doc(interaktivniRef, interaktivniKviz.id)).then(() => {
              setLoading(false);
              navigate(`/testovi/${currentUserData.autoskolaId}`);
            });
          });
        });
    });
  }

  function startujKviz() {
    if (
      currentUserData === null ||
      currentUserData === undefined ||
      interaktivniKviz === undefined ||
      interaktivniKviz === null
    )
      return;
    updateDoc(doc(interaktivniRef, interaktivniKviz.id), {
      status: "prestart",
    }).then(() => {
      const element = document.getElementById("kviz");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    });
  }

  function sracunajRezultate() {
    if (
      currentUserData === null ||
      currentUserData === undefined ||
      interaktivniKviz === null ||
      interaktivniKviz === undefined
    ) {
      return;
    }
    for (let i = 0; i < interaktivniKviz.nizStudenata.length; i++) {
      let ukupnaOcena = 0;
      const nizOdgovoraTogStudenta = _.filter(
        interaktivniKviz.nizOdgovora,
        function (o) {
          if (o.idStudenta === interaktivniKviz.nizStudenata[i].id) return o;
        }
      );
      for (let j = 0; j < nizOdgovoraTogStudenta.length; j++) {
        const nizOdgovoraTwo: Array<InteraktivniOdgovor> =
          nizOdgovoraTogStudenta as Array<InteraktivniOdgovor>;
        ukupnaOcena = ukupnaOcena + nizOdgovoraTwo[j].ocena;
      }
      const rezultatData: RezultatInteraktivnog = {
        idStudenta: interaktivniKviz.nizStudenata[i].id,
        imeStudenta: interaktivniKviz.nizStudenata[i].ime,
        ukupanRezultat: ukupnaOcena,
      };
      updateDoc(doc(interaktivniRef, interaktivniKviz.id), {
        nizRezultata: arrayUnion(rezultatData),
      });
    }
  }
  return (
    <>
      <div className="welcome">
        <div className="welcomeTitle">
          <IconContext.Provider value={{ className: "backIkonica" }}>
            <div
              className="ikonicaOmotac"
              onClick={() => {
                odustani();
              }}
            >
              <MdArrowBack />
            </div>
          </IconContext.Provider>
          <h2>
            Interaktivni kviz - {interaktivniKviz?.id.slice(0, 5)} -
            {interaktivniKviz?.status === "waiting" && ` Čekaonica`}
          </h2>
        </div>
      </div>

      {/* O P T I O N S  S H O R T C U T S */}
      {interaktivniKviz?.status === "waiting" && (
        <div className="optionsShortcuts">
          <div className="shortcut" onClick={() => startujKviz()}>
            <div className="ikonicaWrap">
              <BiPlay color="white" size={50} />
            </div>
            <h3>Start</h3>
          </div>
          <div
            className="shortcut"
            onClick={() => {
              window.open(`https://voznja.rs/test/${interaktivniKviz.testID}`);
            }}
          >
            <div className="ikonicaWrap">
              <AiFillEye color="white" size={50} />
            </div>
            <h3>Pogledaj test</h3>
          </div>
          <div
            className="shortcut"
            onClick={() => {
              odustani();
            }}
          >
            <div className="ikonicaWrap">
              <AiFillCloseCircle color="white" size={50} />
            </div>
            <h3>Odustani</h3>
          </div>
        </div>
      )}
      {interaktivniKviz?.status === "prestart" && (
        <div className="optionsShortcuts">
          <div
            className="shortcut"
            onClick={() => {
              if (
                currentUserData === null ||
                currentUserData === undefined ||
                interaktivniKviz === undefined ||
                interaktivniKviz === null
              )
                return;
              updateDoc(doc(interaktivniRef, interaktivniKviz.id), {
                status: "started",
              });
            }}
          >
            <div className="ikonicaWrap">
              <BiPlay color="white" size={50} />
            </div>
            <h3>Dalje</h3>
          </div>

          <div
            className="shortcut"
            onClick={() => {
              odustani();
            }}
          >
            <div className="ikonicaWrap">
              <AiFillCloseCircle color="white" size={50} />
            </div>
            <h3>Odustani</h3>
          </div>
        </div>
      )}
      {interaktivniKviz?.status === "started" && (
        <div className="optionsShortcuts">
          <div
            className="shortcut"
            onClick={() => {
              if (
                currentUserData === null ||
                currentUserData === undefined ||
                interaktivniKviz === undefined ||
                interaktivniKviz === null
              )
                return;
              updateDoc(doc(interaktivniRef, interaktivniKviz.id), {
                status: "inbetween",
              });
            }}
          >
            <div className="ikonicaWrap">
              <BiPlay color="white" size={50} />
            </div>
            <h3>Rezultati</h3>
          </div>

          <div
            className="shortcut"
            onClick={() => {
              odustani();
            }}
          >
            <div className="ikonicaWrap">
              <AiFillCloseCircle color="white" size={50} />
            </div>
            <h3>Odustani</h3>
          </div>
        </div>
      )}

      {interaktivniKviz?.status === "inbetween" && (
        <div className="optionsShortcuts">
          {interaktivniKviz.trenutnoPitanje + 1 <
            interaktivniKviz.test.nizPitanja.length && (
            <div
              className="shortcut"
              onClick={() => {
                if (
                  currentUserData === null ||
                  currentUserData === undefined ||
                  interaktivniKviz === undefined ||
                  interaktivniKviz === null
                )
                  return;
                updateDoc(doc(interaktivniRef, interaktivniKviz.id), {
                  status: "prestart",
                  a: 0,
                  b: 0,
                  c: 0,
                  d: 0,
                  ukupnoNeodgovorenih: 0,
                  ukupnoOdgovora: 0,
                  trenutnoPitanje: interaktivniKviz.trenutnoPitanje + 1,
                });
              }}
            >
              <div className="ikonicaWrap">
                <BiPlay color="white" size={50} />
              </div>
              <h3>Dalje</h3>
            </div>
          )}
          {interaktivniKviz.trenutnoPitanje + 1 ===
            interaktivniKviz.test.nizPitanja.length && (
            <div
              className="shortcut"
              onClick={() => {
                if (
                  currentUserData === null ||
                  currentUserData === undefined ||
                  interaktivniKviz === undefined ||
                  interaktivniKviz === null
                )
                  return;
                sracunajRezultate();
                updateDoc(doc(interaktivniRef, interaktivniKviz.id), {
                  status: "results",
                  a: 0,
                  b: 0,
                  c: 0,
                  d: 0,
                  ukupnoNeodgovorenih: 0,
                  ukupnoOdgovora: 0,
                  trenutnoPitanje: interaktivniKviz.trenutnoPitanje + 1,
                });
              }}
            >
              <div className="ikonicaWrap">
                <BiPlay color="white" size={50} />
              </div>
              <h3>Rezultati</h3>
            </div>
          )}

          <div
            className="shortcut"
            onClick={() => {
              odustani();
            }}
          >
            <div className="ikonicaWrap">
              <AiFillCloseCircle color="white" size={50} />
            </div>
            <h3>Odustani</h3>
          </div>
        </div>
      )}

      {interaktivniKviz?.status === "results" && (
        <div className="optionsShortcuts">
          <div
            className="shortcut"
            onClick={() => {
              odustani();
            }}
          >
            <div className="ikonicaWrap">
              <AiFillCloseCircle color="white" size={50} />
            </div>
            <h3>Završi kviz</h3>
          </div>
        </div>
      )}
      {/* O P T I O N S  S H O R T C U T S */}

      {/* L I S T */}
      {interaktivniKviz?.status === "waiting" && (
        <div className="welcome">
          <h2>
            Priključeni kandidati
            {interaktivniKviz !== null &&
              interaktivniKviz !== undefined &&
              `(${interaktivniKviz.nizStudenata.length})`}
            {(interaktivniKviz === null || interaktivniKviz === undefined) &&
              `(0)`}
          </h2>
        </div>
      )}
      {interaktivniKviz?.status === "waiting" && (
        <div className="list">
          {interaktivniKviz?.nizStudenata !== null &&
            interaktivniKviz?.nizStudenata !== undefined &&
            interaktivniKviz.nizStudenata.map((student) => {
              return (
                <div className="listPiece">
                  <div className="pieceInfo">
                    <div className="pieceInfoText">
                      <h3>{student.ime}</h3>
                      <p>Grupa: {student.grupa}</p>
                    </div>
                  </div>
                  <div className="pieceOptions">
                    <div className="pieceOption">
                      <div className="pieceOptionIcon">
                        <AiFillCloseCircle color="white" size={40} />
                      </div>
                      <p>Izbaci</p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {interaktivniKviz?.status === "prestart" && (
        <div className="list" id="kviz">
          <div className="listPieceVertical">
            <h2>Pitanje {interaktivniKviz.trenutnoPitanje + 1}</h2>
            <div className="listPieceRow">
              <div className="listPieceRowQuestions">
                <textarea
                  name="pitanje"
                  id="pitanje"
                  placeholder="Unesite pitanje"
                  disabled
                  value={
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].pitanje !== "" &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].pitanje !== null &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].pitanje !== undefined
                      ? interaktivniKviz.test.nizPitanja[
                          interaktivniKviz.trenutnoPitanje
                        ].pitanje
                      : ""
                  }
                />
                <label htmlFor="a">A</label>
                <textarea
                  placeholder="Odgovor A"
                  disabled
                  value={
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].a !== "" &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].a !== null &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].a !== undefined
                      ? interaktivniKviz.test.nizPitanja[
                          interaktivniKviz.trenutnoPitanje
                        ].a
                      : ""
                  }
                />
                <label htmlFor="b">B</label>
                <textarea
                  placeholder="Odgovor B"
                  disabled
                  value={
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].b !== "" &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].b !== null &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].b !== undefined
                      ? interaktivniKviz.test.nizPitanja[
                          interaktivniKviz.trenutnoPitanje
                        ].b
                      : ""
                  }
                />
                <label htmlFor="c">C</label>
                <textarea
                  placeholder="Odgovor C"
                  disabled
                  value={
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].c !== "" &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].c !== null &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].c !== undefined
                      ? interaktivniKviz.test.nizPitanja[
                          interaktivniKviz.trenutnoPitanje
                        ].c
                      : ""
                  }
                />
                <label htmlFor="d">D</label>
                <textarea
                  placeholder="Odgovor D"
                  disabled
                  value={
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].d !== "" &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].d !== null &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].d !== undefined
                      ? interaktivniKviz.test.nizPitanja[
                          interaktivniKviz.trenutnoPitanje
                        ].d
                      : ""
                  }
                />
              </div>
              <div className="listPieceRowImage">
                {interaktivniKviz.test.nizPitanja[
                  interaktivniKviz.trenutnoPitanje
                ].slika !== "" &&
                  interaktivniKviz.test.nizPitanja[
                    interaktivniKviz.trenutnoPitanje
                  ].slika !== null &&
                  interaktivniKviz.test.nizPitanja[
                    interaktivniKviz.trenutnoPitanje
                  ].slika !== undefined && (
                    <img
                      src={
                        interaktivniKviz.test.nizPitanja[
                          interaktivniKviz.trenutnoPitanje
                        ].slika
                      }
                      alt=""
                    />
                  )}
                <h2>
                  Ukupno odgovora: {interaktivniKviz?.ukupnoOdgovora}/
                  {interaktivniKviz?.nizStudenata?.length}
                </h2>
              </div>
            </div>
          </div>
          <div className="timer">
            <CountdownCircleTimer
              isPlaying
              duration={15}
              colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
              colorsTime={[7, 5, 2, 0]}
              size={150}
              onComplete={() => {
                updateDoc(doc(interaktivniRef, interaktivniKviz.id), {
                  status: "started",
                });
                return {
                  // shouldRepeat: true,
                };
              }}
            >
              {({ remainingTime }) => {
                console.log(remainingTime);
                return remainingTime;
              }}
            </CountdownCircleTimer>
          </div>
        </div>
      )}
      {interaktivniKviz?.status === "started" && (
        <div className="list" id="kviz">
          <div className="listPieceVertical">
            <h2>Pitanje {interaktivniKviz.trenutnoPitanje + 1}</h2>
            <div className="listPieceRow">
              <div className="listPieceRowQuestions">
                <textarea
                  name="pitanje"
                  id="pitanje"
                  placeholder="Unesite pitanje"
                  disabled
                  value={
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].pitanje !== "" &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].pitanje !== null &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].pitanje !== undefined
                      ? interaktivniKviz.test.nizPitanja[
                          interaktivniKviz.trenutnoPitanje
                        ].pitanje
                      : ""
                  }
                />
                <label htmlFor="a">A</label>
                <textarea
                  placeholder="Odgovor A"
                  disabled
                  value={
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].a !== "" &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].a !== null &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].a !== undefined
                      ? interaktivniKviz.test.nizPitanja[
                          interaktivniKviz.trenutnoPitanje
                        ].a
                      : ""
                  }
                />
                <label htmlFor="b">B</label>
                <textarea
                  placeholder="Odgovor B"
                  disabled
                  value={
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].b !== "" &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].b !== null &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].b !== undefined
                      ? interaktivniKviz.test.nizPitanja[
                          interaktivniKviz.trenutnoPitanje
                        ].b
                      : ""
                  }
                />
                <label htmlFor="c">C</label>
                <textarea
                  placeholder="Odgovor C"
                  disabled
                  value={
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].c !== "" &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].c !== null &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].c !== undefined
                      ? interaktivniKviz.test.nizPitanja[
                          interaktivniKviz.trenutnoPitanje
                        ].c
                      : ""
                  }
                />
                <label htmlFor="d">D</label>
                <textarea
                  placeholder="Odgovor D"
                  disabled
                  value={
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].d !== "" &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].d !== null &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].d !== undefined
                      ? interaktivniKviz.test.nizPitanja[
                          interaktivniKviz.trenutnoPitanje
                        ].d
                      : ""
                  }
                />
              </div>
              <div className="listPieceRowImage">
                {interaktivniKviz.test.nizPitanja[
                  interaktivniKviz.trenutnoPitanje
                ].slika !== "" &&
                  interaktivniKviz.test.nizPitanja[
                    interaktivniKviz.trenutnoPitanje
                  ].slika !== null &&
                  interaktivniKviz.test.nizPitanja[
                    interaktivniKviz.trenutnoPitanje
                  ].slika !== undefined && (
                    <img
                      src={
                        interaktivniKviz.test.nizPitanja[
                          interaktivniKviz.trenutnoPitanje
                        ].slika
                      }
                      alt=""
                    />
                  )}
                <h2>
                  Ukupno odgovora:{" "}
                  {
                    _.filter(interaktivniKviz?.nizOdgovora, function (o) {
                      if (o.rbPitanja === interaktivniKviz.trenutnoPitanje)
                        return o;
                    }).length
                  }
                  /{interaktivniKviz?.nizStudenata?.length}
                </h2>
              </div>
            </div>
          </div>
          <div className="timer">
            <CountdownCircleTimer
              isPlaying
              duration={15}
              colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
              colorsTime={[7, 5, 2, 0]}
              size={150}
              onComplete={() => {
                updateDoc(doc(interaktivniRef, interaktivniKviz.id), {
                  status: "inbetween",
                });
                return {
                  // shouldRepeat: true,
                };
              }}
            >
              {({ remainingTime }) => {
                console.log(remainingTime);
                return remainingTime;
              }}
            </CountdownCircleTimer>
          </div>
        </div>
      )}
      {interaktivniKviz?.status === "inbetween" && (
        <div className="list">
          <div className="listPieceVertical">
            <h2>Rezultati za pitanje {interaktivniKviz.trenutnoPitanje + 1}</h2>
            <div className="listPieceRow">
              <div className="listPieceRowQuestions">
                <textarea
                  name="pitanje"
                  id="pitanje"
                  placeholder="Unesite pitanje"
                  disabled
                  value={
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].pitanje !== "" &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].pitanje !== null &&
                    interaktivniKviz.test.nizPitanja[
                      interaktivniKviz.trenutnoPitanje
                    ].pitanje !== undefined
                      ? interaktivniKviz.test.nizPitanja[
                          interaktivniKviz.trenutnoPitanje
                        ].pitanje
                      : ""
                  }
                />
                <label htmlFor="a">
                  A -
                  {(
                    (interaktivniKviz.a / interaktivniKviz.ukupnoOdgovora) *
                    100
                  ).toFixed(2)}
                  %
                </label>
                <label htmlFor="b">
                  B -
                  {(
                    (interaktivniKviz.b / interaktivniKviz.ukupnoOdgovora) *
                    100
                  ).toFixed(2)}
                  %
                </label>
                <label htmlFor="c">
                  C -
                  {(
                    (interaktivniKviz.c / interaktivniKviz.ukupnoOdgovora) *
                    100
                  ).toFixed(2)}
                  %
                </label>
                <label htmlFor="d">
                  D -
                  {(
                    (interaktivniKviz.d / interaktivniKviz.ukupnoOdgovora) *
                    100
                  ).toFixed(2)}
                  %
                </label>
                <PieChart
                  series={[
                    {
                      data: [
                        {
                          id: 0,
                          value: interaktivniKviz.a,
                          label: "Odgovor A",
                        },
                        {
                          id: 1,
                          value: interaktivniKviz.b,
                          label: "Odgovor B",
                        },
                        {
                          id: 2,
                          value: interaktivniKviz.c,
                          label: "Odgovor C",
                        },
                        {
                          id: 3,
                          value: interaktivniKviz.d,
                          label: "Odgovor D",
                        },
                      ],
                    },
                  ]}
                  width={400}
                  height={200}
                />
              </div>
              <div
                className="listPieceRowImage"
                style={{
                  justifyContent: "flex-start",
                  alignSelf: "stretch",
                }}
              >
                {_.orderBy(
                  _.filter(interaktivniKviz.nizOdgovora, function (o) {
                    if (o.rbPitanja === interaktivniKviz.trenutnoPitanje)
                      return o;
                  }),
                  ["ocena"],
                  ["desc"]
                ).map((ocenjenOdg) => {
                  if (
                    typeof ocenjenOdg === "number" ||
                    typeof ocenjenOdg === "undefined"
                  )
                    return;
                  else {
                    const ocenjenOdgovor: InteraktivniOdgovor =
                      ocenjenOdg as InteraktivniOdgovor;
                    return (
                      <div className="ocenjenOdgovor">
                        <h2>{ocenjenOdgovor.imeStudenta}</h2>
                        <p>{ocenjenOdgovor.ocena} poena</p>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      {interaktivniKviz?.status === "results" && (
        <div className="list">
          {_.orderBy(
            interaktivniKviz.nizRezultata,
            ["ukupanRezultat"],
            ["desc"]
          ).map((rezultat) => {
            return (
              <div className="listPiece">
                <div className="pieceInfo">
                  <div className="pieceInfoText">
                    <h3>{rezultat.imeStudenta}</h3>
                  </div>
                </div>
                <div className="pieceOptions">
                  <h3>{rezultat.ukupanRezultat}</h3>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {/* L I S T */}
    </>
  );
}
