import React, { useContext, useEffect, useState } from "react";
import "../styles/home.scss";
import { BiHelpCircle, BiLogOutCircle } from "react-icons/bi";
import { useNavigate } from "react-router";
import { AppContext } from "../functions/AppProvider";
import { GiTeacher } from "react-icons/gi";
import { BsClockHistory } from "react-icons/bs";
import { AiFillCar, AiFillEye, AiFillFilePpt } from "react-icons/ai";
import { MdQuiz } from "react-icons/md";
import { CheckForInteractive } from "../components/CheckForInteractive";
import { Lekcija, Predavanje, Termin } from "../utils/types";
import { doc, getDoc } from "firebase/firestore";
import { skoleRef } from "../utils/firebase";
import _ from "lodash";
interface Props {}

export function HomeSS(props: Props) {
  const navigate = useNavigate();
  const { currentUserData, logOut, setLoading } = useContext(AppContext);
  const [isCFI, setCFI] = useState<boolean>(false);
  const [sledeceVoznje, setSledeceVoznje] = useState<Array<Termin>>([]);
  const [nizLekcija, setNizLekcija] = useState<Array<Lekcija>>([]);
  const [nizSledecihPredavanja, setNizSledecihPredavanja] = useState<
    Array<Predavanje>
  >([]);
  const date = new Date();
  const mesec = date.getMonth() + 1;

  useEffect(() => {
    if (currentUserData !== null && currentUserData !== undefined) {
      dohvatiLekcije();
    }
  }, [currentUserData]);

  function dohvatiLekcije() {
    getDoc(doc(skoleRef, currentUserData?.autoskolaId)).then((skola) => {
      if (skola === undefined || skola === null) return;
      if (skola.data() === undefined || skola.data() === null) return;
      setNizLekcija(skola?.data()?.lekcije as Array<Lekcija>);
    });
  }

  useEffect(() => {
    if (currentUserData?.polozioTeoriju === true) {
      if (
        currentUserData?.nizZakazanihTermina === undefined ||
        currentUserData.nizZakazanihTermina === null ||
        currentUserData.nizZakazanihTermina.length === 0
      )
        return;

      const nextVoznjaSortirano = currentUserData.nizZakazanihTermina.sort(
        (a, b) => {
          const dateA = new Date(a.fullDateStart as string);
          const dateB = new Date(b.fullDateStart as string);

          if (dateA < dateB) return -1;
          if (dateA > dateB) return 1;
          return 0;
        }
      );
      const filtrirano = _.filter(nextVoznjaSortirano, function (o) {
        console.log(o);
        const [godina, mesec, dan] = o?.kojiDatum.split("/").map(Number);
        const [sati, minuti] = o?.vremePocetka.split(":").map(Number);
        console.log(
          new Date(godina, mesec - 1, dan, sati, minuti) > new Date(),
          new Date(godina, mesec - 1, dan, sati, minuti),
          new Date()
        );
        if (new Date(godina, mesec - 1, dan, sati, minuti) > new Date())
          return o;
      });
      setSledeceVoznje(filtrirano as Array<Termin>);
    }
    if (currentUserData?.polozioTeoriju === false) {
      getDoc(doc(skoleRef, currentUserData.autoskolaId)).then((skola) => {
        const predavanja = skola.data()?.predavanja as Array<Predavanje>;
        const sortirano = predavanja.sort((a, b) => {
          const dateA = parseDate(a.datum, a.vreme);
          const dateB = parseDate(b.datum, b.vreme);

          if (dateA < dateB) return -1;
          if (dateA > dateB) return 1;
          return 0;
        });

        const filtrirano = _.filter(sortirano, function (o) {
          const danasnjiDatum = new Date();

          if (
            parseDate(o.datum, o.vreme) > danasnjiDatum &&
            o.grupa.id === currentUserData.grupaID
          )
            return o;
        });

        setNizSledecihPredavanja(filtrirano as Array<Predavanje>);
      });
    }
  }, [currentUserData]);
  function parseDate(dateString: string, vreme: string) {
    const [year, month, day] = dateString.split("-").map(Number);
    const [hours, minutes] = vreme.split(":").map(Number);
    return new Date(year, month - 1, day, hours, minutes);
  }
  return (
    <>
      <div className="welcome">
        <h2>
          Dobro došli, {currentUserData && currentUserData?.ime.split(" ")[0]}
        </h2>
        <h2>
          {date.getDate()}.{mesec}.{date.getFullYear()}
        </h2>
      </div>

      <div className="optionsShortcuts">
        <div
          className="shortcut"
          onClick={() => {
            setCFI(true);
          }}
        >
          <div className="ikonicaWrap">
            <BsClockHistory color="white" size={50} />
          </div>
          <h3>Interaktivni</h3>
        </div>
        {currentUserData?.koJeInstruktorID !== "" &&
          currentUserData?.polozioTeoriju === true && (
            <div
              className="shortcut"
              onClick={() => {
                navigate(`/kalendar/${currentUserData?.koJeInstruktorID}`);
              }}
            >
              <div className="ikonicaWrap">
                <AiFillCar color="white" size={50} />
              </div>
              <h3>Termini</h3>
            </div>
          )}

        <div
          className="shortcut"
          onClick={() => {
            navigate(`/lekcije/${currentUserData?.autoskolaId}`);
          }}
        >
          <div className="ikonicaWrap">
            <GiTeacher color="white" size={50} />
          </div>
          <h3>Lekcije</h3>
        </div>
        <div
          className="shortcut"
          onClick={() => {
            navigate(`/testovi/${currentUserData?.autoskolaId}`);
          }}
        >
          <div className="ikonicaWrap">
            <MdQuiz color="white" size={50} />
          </div>
          <h3>Testovi</h3>
        </div>
        <div
          className="shortcut"
          onClick={() => {
            navigate(`/prezentacije/${currentUserData?.autoskolaId}`);
          }}
        >
          <div className="ikonicaWrap">
            <AiFillFilePpt color="white" size={50} />
          </div>
          <h3>Prezentacije</h3>
        </div>
        <div className="shortcut">
          <div className="ikonicaWrap">
            <BiHelpCircle color="white" size={50} />
          </div>
          <h3>Pomoć</h3>
        </div>

        <div
          className="shortcut"
          onClick={() => {
            logOut();
            navigate("/login");
          }}
        >
          <div className="ikonicaWrap">
            <BiLogOutCircle color="white" size={60} />
          </div>
          <h3>Izlogujte se</h3>
        </div>
      </div>
      {currentUserData?.subscribed === false && (
        <div className="optionsShortcuts">
          <h3 style={{ color: "white" }}>
            Notifikacije nisu aktivirane. Kako biste ih aktivirali, pošaljite
            poruku na WhatsApp-u sa sadržajem "Prihvatam" na broj 065 840 54 80
          </h3>
        </div>
      )}

      <div className="opcijeKontejner">
        {currentUserData?.polozioTeoriju === true && (
          <div className="opcije Desno">
            <div className="opcijeDesnoHeader">
              <h3>Sledeći čas vožnje</h3>
            </div>
            <div className="opcijeDesnoBody">
              {(sledeceVoznje === undefined ||
                sledeceVoznje === null ||
                sledeceVoznje.length === 0) && (
                <div className="stavka">
                  <h4>Nema zakazanih termina</h4>
                </div>
              )}
              {sledeceVoznje !== undefined &&
                sledeceVoznje !== null &&
                sledeceVoznje.length !== 0 && (
                  <div className="stavka">
                    <h4>Datum</h4>
                    <p>
                      {new Date(
                        sledeceVoznje[0]?.fullDateStart as string
                      ).getDate()}
                      .
                      {new Date(
                        sledeceVoznje[0]?.fullDateStart as string
                      ).getMonth() + 1}
                      .
                      {new Date(
                        sledeceVoznje[0]?.fullDateStart as string
                      ).getFullYear()}
                    </p>
                  </div>
                )}

              {sledeceVoznje !== undefined &&
                sledeceVoznje !== null &&
                sledeceVoznje.length !== 0 && (
                  <div className="stavka">
                    <h4>Vreme početka</h4>
                    <p>{sledeceVoznje[0]?.vremePocetka}</p>
                  </div>
                )}
              {sledeceVoznje !== undefined &&
                sledeceVoznje !== null &&
                sledeceVoznje.length !== 0 && (
                  <div className="stavka">
                    <h4>Vreme kraja</h4>
                    <p>{sledeceVoznje[0]?.vremeKraja}</p>
                  </div>
                )}
              {sledeceVoznje !== undefined &&
                sledeceVoznje !== null &&
                sledeceVoznje.length !== 0 && (
                  <div className="stavka">
                    <h4>Tip časa</h4>
                    <p>{sledeceVoznje[0]?.tipTermina}</p>
                  </div>
                )}
              {sledeceVoznje !== undefined &&
                sledeceVoznje !== null &&
                sledeceVoznje.length !== 0 && (
                  <div className="stavka">
                    <h4>Instruktor</h4>
                    <p>{currentUserData?.koJeInstruktorIme}</p>
                  </div>
                )}
            </div>
          </div>
        )}

        {currentUserData?.polozioTeoriju === false && (
          <div className="opcije Desno">
            <div className="opcijeDesnoHeader">
              <h3>Sledeći čas teorije</h3>
            </div>
            <div className="opcijeDesnoBody">
              {(nizSledecihPredavanja === undefined ||
                nizSledecihPredavanja === null ||
                nizSledecihPredavanja.length === 0) && (
                <div className="stavka">
                  <h4>Nema zakazanih časova teorije</h4>
                </div>
              )}
              {nizSledecihPredavanja !== undefined &&
                nizSledecihPredavanja !== null &&
                nizSledecihPredavanja.length !== 0 && (
                  <div className="stavka">
                    <h4>Datum</h4>
                    <p>
                      {new Date(
                        nizSledecihPredavanja[0].datum as string
                      ).getDate()}
                      .
                      {new Date(
                        nizSledecihPredavanja[0].datum as string
                      ).getMonth() + 1}
                      .
                      {new Date(
                        nizSledecihPredavanja[0].datum as string
                      ).getFullYear()}
                    </p>
                  </div>
                )}
              {nizSledecihPredavanja !== undefined &&
                nizSledecihPredavanja !== null &&
                nizSledecihPredavanja.length !== 0 && (
                  <div className="stavka">
                    <h4>Vreme početka</h4>
                    <p>{nizSledecihPredavanja[0].vreme}</p>
                  </div>
                )}
              {nizSledecihPredavanja !== undefined &&
                nizSledecihPredavanja !== null &&
                nizSledecihPredavanja.length !== 0 && (
                  <div className="stavka">
                    <h4>Lekcija</h4>
                    <p>{nizSledecihPredavanja[0].lekcija.ime}</p>
                  </div>
                )}
              {nizSledecihPredavanja !== undefined &&
                nizSledecihPredavanja !== null &&
                nizSledecihPredavanja.length !== 0 && (
                  <div className="stavka">
                    <h4>Grupa</h4>
                    <p>{nizSledecihPredavanja[0].grupa.ime}</p>
                  </div>
                )}
            </div>
          </div>
        )}

        {currentUserData?.polozioTeoriju === false && (
          <div className="opcije Sredina">
            <div className="opcijeSredinaHeader">
              <div className=""></div>
              <h3>Lekcije</h3>
              <div className=""></div>
            </div>
            <div className="opcijeSredinaBody">
              {nizLekcija.map((lekcija) => {
                return (
                  <div className="korisnik">
                    <div
                      className="korisnikInfo"
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <h3>{lekcija.ime}</h3>
                      <p>Autor: {lekcija.kreirao}</p>
                      <p>
                        Datum kreiranja: {new Date(lekcija.datum).getDate()}.
                        {new Date(lekcija.datum).getMonth() + 1}.
                        {new Date(lekcija.datum).getFullYear()}
                      </p>
                    </div>
                    <div className="opcijeKorisnik">
                      <div
                        className="okvirOpcije"
                        onClick={() => navigate(`/lekcija/${lekcija.id}`)}
                      >
                        <AiFillEye color="white" size={20} />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {currentUserData?.polozioTeoriju === true && (
          <div className="opcije Sredina">
            <div className="opcijeSredinaHeader">
              <div className=""></div>
              <h3>Zakazani termini</h3>
              <div className=""></div>
            </div>
            <div className="opcijeSredinaBody">
              {sledeceVoznje.map((slVoznja) => {
                return (
                  <div
                    key={`${slVoznja?.vremeKraja} ${slVoznja?.vremePocetka}`}
                    className={`termin Zauzet`}
                  >
                    <div className="terminInfo">
                      {slVoznja !== null && slVoznja !== undefined && (
                        <h3>
                          {new Date(slVoznja.kojiDatum as string).getDate()}.
                          {new Date(slVoznja.kojiDatum as string).getMonth() +
                            1}
                          .
                          {new Date(slVoznja.kojiDatum as string).getFullYear()}
                        </h3>
                      )}
                      <h3>
                        {slVoznja?.vremePocetka} - {slVoznja?.vremeKraja}
                      </h3>
                      <h3>{slVoznja?.tipTermina}</h3>
                      <h3>{slVoznja?.studentIme}</h3>
                    </div>
                  </div>
                );
              })}
              {sledeceVoznje.length === 0 && <h3>Nema zakazanih termina</h3>}
            </div>
          </div>
        )}

        <div className="opcije Levo">
          <div className="opcijeLevoDiv">
            <div className="opcijeLevoHeader">
              <div className="opcijeLevoHeaderImage">
                <img
                  src={currentUserData?.profilnaURL}
                  alt={currentUserData?.ime}
                />
              </div>
              <div className="opcijeLevoHeaderTekst">
                <h3>{currentUserData?.ime}</h3>
              </div>
            </div>
            <div className="opcijeLevoBody">
              <div className="stavka">
                <h4>Email</h4>
                <p>{currentUserData?.email}</p>
              </div>
              <div className="stavka">
                <h4>Auto škola</h4>
                <p>{currentUserData?.autoskolaId}</p>
              </div>
              <div className="stavka">
                <h4>Grupa</h4>
                <p>
                  {currentUserData?.grupa !== ""
                    ? currentUserData?.grupa
                    : "Nije dodeljana grupa"}
                </p>
              </div>
              <div className="stavka">
                <h4>Uloga</h4>
                <p>Kandidat</p>
              </div>
              <div className="stavka">
                <h4>ID</h4>
                <p>{currentUserData?.id}</p>
              </div>
            </div>
          </div>
          <button
            onClick={() => {
              navigate(`/profile/${currentUserData?.id}`);
            }}
          >
            Profil
          </button>
        </div>
      </div>

      {/* {isUpit && <NewUpit setIsUpit={setIsUpit} />} */}
      {isCFI && <CheckForInteractive setCFI={setCFI} />}
    </>
  );
}
