import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import _ from "lodash";
import { AppContext } from "../functions/AppProvider";
import ProfilePicture from "../profilepicture.png";
import DarkProfile from "../DarkProfile.png";
import Skijanje from "../skijanje.png";
import "../styles/profile.scss";
import { UserData, UserRole, autoSkola } from "../utils/types";
import { AiFillEdit } from "react-icons/ai";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db, imageDb, korisniciRef } from "../utils/firebase";
import { getDownloadURL, listAll, ref, uploadBytes } from "firebase/storage";
import uuid from "react-uuid";
import { NewUploadOverlay } from "../components/NewUploadOverlay";
import { NameChangeOverlay } from "../components/NameChangeOverlay";
import { PhoneChangeOverlay } from "../components/PhoneChangeOverlay";
import { GroupChangeOverlay } from "../components/GroupChangeOverlay";
import { TheoryChange } from "../components/TheoryChange";
import { KreditChange } from "../components/KreditChange";
import { BrojCasovaChange } from "../components/BrojCasovaChange";
import { BrojNocnihChange } from "../components/BrojNocnihChange";
import { InstruktorChange } from "../components/InstruktorChange";
import { CarChange } from "../components/CarChange";
import { PrikazivanjeTerminaChange } from "../components/PrikazivanjeTerminaChange";
import { KvotaChange } from "../components/KvotaChange";
import { PasswordReset } from "../components/PasswordReset";
interface Props {}

export function Profil(props: Props) {
  const { profilID } = useParams();
  const [uloga, setUloga] = useState<string>("");
  const [isNewUO, setIsNewUO] = useState<boolean>(false);
  const [img, setImg] = useState<File>();
  const [profilna, setProfilna] = useState<string>(DarkProfile);
  const [korisnik, setKorisnik] = useState<UserData>();
  const { currentUserData } = useContext(AppContext);
  const [isNameChange, setNameChange] = useState<boolean>(false);
  const [isPhoneChange, setPhoneChange] = useState<boolean>(false);
  const [isGrupaChange, setGrupaChange] = useState<boolean>(false);
  const [isPolozioTChange, setPolozioTChange] = useState<boolean>(false);
  const [isKreditChange, setKreditChange] = useState<boolean>(false);
  const [isBrojCasovaChange, setBrojCasovaChange] = useState<boolean>(false);
  const [isBrojNocnihChange, setBrojNocnihChange] = useState<boolean>(false);
  const [isInstruktorChange, setInstruktorChange] = useState<boolean>(false);
  const [isCarChange, setCarChange] = useState<boolean>(false);
  const [isPTChange, setIsPTChange] = useState<boolean>(false);
  const [isKvotaChange, setIsKvotaChange] = useState<boolean>(false);
  const [isResetSifre, setIsResetSifre] = useState<boolean>(false);

  function dohvatiKorisnika() {
    getDoc(doc(korisniciRef, profilID)).then((korisnik) => {
      setKorisnik(korisnik.data() as UserData);
    });
  }

  useEffect(() => {
    if (profilID !== null && profilID !== undefined) {
      dohvatiKorisnika();
    }
  }, [profilID]);
  useEffect(() => {
    if (korisnik === null || korisnik === undefined) return;
    setUloga(korisnik.uloga);
    if (
      korisnik.profilnaURL !== undefined &&
      korisnik.profilnaURL !== null &&
      korisnik.profilnaURL !== ""
    ) {
      setProfilna(korisnik.profilnaURL);
    }
  }, [korisnik]);

  function roleToUloga(role: UserRole) {
    if (role === "globaladmin") return "Globalni admin";
    if (role === "schooladmin") return "Admin škole";
    if (role === "schoolinstructor") return "Instruktor";
    if (role === "schoolteacher") return "Predavač";
    if (role === "student") return "Kandidat";
  }
  function handleUpload() {
    const imgRef = ref(imageDb, `files/${uuid()}`);
    if (img === undefined) return;
    uploadBytes(imgRef, img).then((img) => {
      console.log(img);
      getDownloadURL(img.ref).then((url) => {
        console.log(url);
      });
    });
    listAll(ref(imageDb, "files")).then((imgs) => {
      console.log(imgs);
    });
  }
  return (
    <>
      <div className="profilHeader">
        <div style={{ position: "relative" }}>
          <img src={profilna} alt="" />
          {currentUserData?.id === profilID && (
            <div className="imageOverlay" onClick={() => setIsNewUO(true)}>
              <AiFillEdit size={50} color="white" />
            </div>
          )}
        </div>

        <p>
          {korisnik?.ime},{" "}
          {korisnik !== undefined &&
            korisnik !== null &&
            roleToUloga(korisnik.uloga)}
        </p>
      </div>
      <div className="profilBody">
        <h3>Ime</h3>
        <div className="bodyStavka">
          <p>{korisnik?.ime}</p>
          {currentUserData?.uloga !== "student" && (
            <button onClick={() => setNameChange(true)}>
              <AiFillEdit color="white" size={20} />
            </button>
          )}
        </div>
        <h3>Email</h3>
        <div className="bodyStavka">
          <p>{korisnik?.email}</p>
        </div>
        <h3>ID</h3>
        <div className="bodyStavka">
          <p>{korisnik?.id}</p>
        </div>
        <h3>Registrovan</h3>
        <div className="bodyStavka">
          <p>{`${new Date(korisnik?.datum as string).getDate()}-${
            new Date(korisnik?.datum as string).getMonth() + 1
          }-${new Date(korisnik?.datum as string).getFullYear()}`}</p>
        </div>
        <h3>Broj telefona</h3>
        <div className="bodyStavka">
          <p>{korisnik?.telefon !== "" ? korisnik?.telefon : "Nije dodat"}</p>
          {currentUserData?.uloga !== "student" && (
            <button onClick={() => setPhoneChange(true)}>
              <AiFillEdit color="white" size={20} />
            </button>
          )}
        </div>
        {/* S T U D E N T  P O C E T A K */}
        {korisnik?.uloga === "student" && <h3>Grupa</h3>}
        {korisnik?.uloga === "student" && (
          <div className="bodyStavka">
            <p>
              {korisnik?.grupa !== "" &&
              korisnik.grupa !== null &&
              korisnik.grupa !== undefined
                ? korisnik.grupa
                : "Nije dodeljena"}
            </p>
            {currentUserData?.uloga !== "student" && (
              <button onClick={() => setGrupaChange(true)}>
                <AiFillEdit color="white" size={20} />
              </button>
            )}
          </div>
        )}
        {korisnik?.uloga === "student" && <h3>Položena teorija?</h3>}
        {korisnik?.uloga === "student" && (
          <div className="bodyStavka">
            <p>
              {korisnik?.polozioTeoriju === true
                ? "Da"
                : korisnik?.polozioTeoriju === false
                ? "Ne"
                : ""}
            </p>
            {currentUserData?.uloga !== "student" && (
              <button onClick={() => setPolozioTChange(true)}>
                <AiFillEdit color="white" size={20} />
              </button>
            )}
          </div>
        )}
        {korisnik?.uloga === "student" && korisnik.polozioTeoriju === true && (
          <h3>Krediti</h3>
        )}
        {korisnik?.uloga === "student" && korisnik.polozioTeoriju === true && (
          <div className="bodyStavka">
            <p>{korisnik?.krediti}</p>
            {currentUserData?.uloga !== "student" && (
              <button onClick={() => setKreditChange(true)}>
                <AiFillEdit color="white" size={20} />
              </button>
            )}
          </div>
        )}
        {korisnik?.uloga === "student" && korisnik.polozioTeoriju === true && (
          <h3>Broj gotovih praktičnih časova</h3>
        )}
        {korisnik?.uloga === "student" && korisnik.polozioTeoriju === true && (
          <div className="bodyStavka">
            <p>{korisnik?.brOdvozanihCasova}</p>
            {currentUserData?.uloga !== "student" && (
              <button onClick={() => setBrojCasovaChange(true)}>
                <AiFillEdit color="white" size={20} />
              </button>
            )}
          </div>
        )}
        {korisnik?.uloga === "student" && korisnik.polozioTeoriju === true && (
          <h3>Broj noćnih vožnji</h3>
        )}
        {korisnik?.uloga === "student" && korisnik.polozioTeoriju === true && (
          <div className="bodyStavka">
            <p>{korisnik?.brNocnih}</p>
            {currentUserData?.uloga !== "student" && (
              <button onClick={() => setBrojNocnihChange(true)}>
                <AiFillEdit color="white" size={20} />
              </button>
            )}
          </div>
        )}
        {korisnik?.uloga === "student" && korisnik.polozioTeoriju === true && (
          <h3>Instruktor vožnje</h3>
        )}
        {korisnik?.uloga === "student" && korisnik.polozioTeoriju === true && (
          <div className="bodyStavka">
            <p>
              {korisnik?.koJeInstruktorIme !== ""
                ? korisnik.koJeInstruktorIme
                : "Nije dodeljen"}
            </p>
            {currentUserData?.uloga !== "student" && (
              <button onClick={() => setInstruktorChange(true)}>
                <AiFillEdit color="white" size={20} />
              </button>
            )}
          </div>
        )}
        {korisnik?.uloga === "student" &&
          korisnik.polozioTeoriju === true &&
          korisnik.nedeljnaKvota !== undefined &&
          korisnik.nedeljnaKvota !== null &&
          korisnik.koJeInstruktorID !== "" && (
            <h3>Broj dozvoljenih časova nedeljno</h3>
          )}
        {korisnik?.uloga === "student" &&
          korisnik.polozioTeoriju === true &&
          korisnik.koJeInstruktorID !== "" && (
            <div className="bodyStavka">
              <p>{korisnik?.nedeljnaKvota}</p>
            </div>
          )}

        {/* S T U D E N T  K R A J */}

        {/* I N S T R U K T O R  P O C E T A K */}
        {korisnik?.uloga === "schoolinstructor" && <h3>Model automobila</h3>}
        {korisnik?.uloga === "schoolinstructor" && (
          <div className="bodyStavka">
            <p>{korisnik?.voziAuto}</p>
            {currentUserData?.uloga !== "student" && (
              <button onClick={() => setCarChange(true)}>
                <AiFillEdit color="white" size={20} />
              </button>
            )}
          </div>
        )}
        {korisnik?.uloga === "schoolinstructor" && (
          <h3>Prikazivanje termina</h3>
        )}
        {korisnik?.uloga === "schoolinstructor" && (
          <div className="bodyStavka">
            <p>
              {korisnik.prikazivanjeTermina === "Default" && "Osnovno"}
              {korisnik.prikazivanjeTermina === "Advanced" && "Napredno"}
            </p>
            {currentUserData?.uloga !== "student" && (
              <button onClick={() => setIsPTChange(true)}>
                <AiFillEdit color="white" size={20} />
              </button>
            )}
          </div>
        )}
        {korisnik?.uloga === "schoolinstructor" && (
          <h3>Broj dozvoljenih časova</h3>
        )}
        {korisnik?.uloga === "schoolinstructor" && (
          <div className="bodyStavka">
            <p>{korisnik.nedeljnaKvota}</p>
            <button onClick={() => setIsKvotaChange(true)}>
              <AiFillEdit color="white" size={20} />
            </button>
          </div>
        )}
        {/* I N S T R U K T O R  K R A J */}

        {currentUserData?.id === profilID && (
          <button className="dugmence" onClick={() => setIsResetSifre(true)}>
            Resetujte šifru
          </button>
        )}
      </div>
      {isNewUO && (
        <NewUploadOverlay setIsNewUO={setIsNewUO} setProfilna={setProfilna} />
      )}
      {isNameChange && (
        <NameChangeOverlay
          korisnik={korisnik as UserData}
          setIsNameChange={setNameChange}
          dohvatiKorisnika={dohvatiKorisnika}
        />
      )}
      {isPhoneChange && (
        <PhoneChangeOverlay
          korisnik={korisnik as UserData}
          setPhoneChange={setPhoneChange}
          dohvatiKorisnika={dohvatiKorisnika}
        />
      )}
      {isGrupaChange && (
        <GroupChangeOverlay
          korisnik={korisnik as UserData}
          setGroupChange={setGrupaChange}
          dohvatiKorisnika={dohvatiKorisnika}
        />
      )}
      {isPolozioTChange && (
        <TheoryChange
          korisnik={korisnik as UserData}
          setPolozioTChange={setPolozioTChange}
          dohvatiKorisnika={dohvatiKorisnika}
        />
      )}
      {isKreditChange && (
        <KreditChange
          korisnik={korisnik as UserData}
          setKreditChange={setKreditChange}
          dohvatiKorisnika={dohvatiKorisnika}
        />
      )}
      {isBrojCasovaChange && (
        <BrojCasovaChange
          korisnik={korisnik as UserData}
          dohvatiKorisnika={dohvatiKorisnika}
          setBrojCasovaChange={setBrojCasovaChange}
        />
      )}
      {isBrojNocnihChange && (
        <BrojNocnihChange
          korisnik={korisnik as UserData}
          dohvatiKorisnika={dohvatiKorisnika}
          setBrojNocnihChange={setBrojNocnihChange}
        />
      )}
      {isInstruktorChange && (
        <InstruktorChange
          korisnik={korisnik as UserData}
          dohvatiKorisnika={dohvatiKorisnika}
          setInstruktorChange={setInstruktorChange}
        />
      )}
      {isCarChange && (
        <CarChange
          korisnik={korisnik as UserData}
          dohvatiKorisnika={dohvatiKorisnika}
          setIsCarChange={setCarChange}
        />
      )}
      {isPTChange && (
        <PrikazivanjeTerminaChange
          korisnik={korisnik as UserData}
          dohvatiKorisnika={dohvatiKorisnika}
          setIsPTChange={setIsPTChange}
        />
      )}
      {isKvotaChange && (
        <KvotaChange
          dohvatiKorisnika={dohvatiKorisnika}
          korisnik={korisnik as UserData}
          setIsKvotaChange={setIsKvotaChange}
        />
      )}
      {isResetSifre && (
        <PasswordReset
          setIsPasswordReset={setIsResetSifre}
          dohvatiKorisnika={dohvatiKorisnika}
        />
      )}
    </>
  );
}
