import React, { useEffect, useState } from "react";
import "../styles/newSTOverlay.scss";
import { useNavigate } from "react-router";
import useKeyPress from "react-use-keypress";
interface Props {
  setIsNewTest?: any;
}
interface Test {
  imeTesta: string;
  pitanja: Array<PitanjeTest>;
}
interface PitanjeTest {
  pitanje: string;
  a: string;
  b: string;
  c: string;
  d: string;
  odgovor: string;
}
export function NewTestOverlay(props: Props) {
  const { setIsNewTest } = props;
  const [imeTesta, setImeTesta] = useState<string>("");
  const [opisTesta, setOpisTesta] = useState<string>("");

  useKeyPress("Enter", () => {
    navigate(`/noviTest/${imeTesta}/false`);
  });
  useKeyPress("Escape", () => {
    setIsNewTest(false);
  });

  const navigate = useNavigate();
  const [imePitanja, setImePitanja] = useState<string>("");
  const [a, setA] = useState<string>("");
  const [b, setB] = useState<string>("");
  const [c, setC] = useState<string>("");
  const [d, setD] = useState<string>("");
  const [tacanOdgovor, setTacanOdgovor] = useState<string>("A");
  const [pitanje, setPitanje] = useState<PitanjeTest>({
    a: "",
    b: "",
    c: "",
    d: "",
    odgovor: "",
    pitanje: "",
  });
  const [test, setTest] = useState<Test | null>(null);
  const [i, setI] = useState<number>(0);
  const [klikPrethodno, setKlikPrethodno] = useState<boolean>(false);

  useEffect(() => {
    console.log(test);
    // console.log(Test?.pitanja.length);
  }, [test]);
  useEffect(() => {
    console.log("PROMENEJNO I", i);
  }, [i]);
  return (
    <div className="newSTOverlay">
      <div className="newSTBox">
        <h2>Novi Test</h2>
        <input
          type="text"
          name="imeTesta"
          id="imeTesta"
          placeholder="Ime novog testa"
          onChange={(e) => setImeTesta(e.target.value)}
          value={imeTesta}
          required
        />

        <div className="buttonOptions">
          <button
            onClick={() => {
              navigate(`/noviTest/${imeTesta}/false`);
            }}
          >
            Započni
          </button>
          <button onClick={() => setIsNewTest(false)}>Odustani</button>
        </div>
        <button
          className="x"
          onClick={() => {
            setIsNewTest(false);
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}
