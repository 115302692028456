import React, { useContext, useEffect, useState } from "react";
import "../styles/login.scss";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../functions/AppProvider";
import {
  korisniciRef,
  noviKorisniciRef,
  skoleRef,
  terminiRef,
} from "../utils/firebase";
import {
  arrayUnion,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { UserData } from "../utils/types";
import _ from "lodash";
import useKeyPress from "react-use-keypress";

interface Props {}

export function ForgottenP1(props: Props) {
  const { setLoading, setAlert, setIsAlert } = useContext(AppContext);
  const [email, setEmail] = useState<string>("");
  const auth = getAuth();
  const navigate = useNavigate();
  useKeyPress("Enter", () => {
    handleReset();
  });

  function handleReset() {
    if (email === "" || email === null || email === undefined) {
      setAlert("Unesite email");
      setIsAlert(true);
      return;
    }

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setAlert("Proverite Vaš mejl.");
        setIsAlert(true);
        navigate("/login");
      })
      .catch((error) => {
        setAlert(error.message);
        setIsAlert(true);
      });
  }
  return (
    <div className="loginPage">
      <div className="loginContainer">
        <h2>Zaboravljen password</h2>

        <input
          type="email"
          placeholder="Email"
          name="email"
          id="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <div
          className="loginOptions"
          style={{
            margin: "0",
          }}
        >
          <button
            onClick={() => {
              handleReset();
            }}
            style={{
              padding: "10px",
            }}
          >
            Potvrdi
          </button>
        </div>
      </div>
    </div>
  );
}
