import React, { useContext, useEffect, useState } from "react";
import "../styles/kalendar.scss";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { terminiRef } from "../utils/firebase";
import { AppContext } from "../functions/AppProvider";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { Termin } from "../utils/types";
import _ from "lodash";
import { useNavigate, useParams } from "react-router";
import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  subDays,
  addDays,
} from "date-fns";
interface Props {}

interface Day {
  date: Date;
}
export function Kalendar(props: Props) {
  const { currentUserData } = useContext(AppContext);
  const [izabraniDatum, setIzabraniDatum] = useState<Date>(new Date());
  const [nizTermina, setNizTermina] = useState<Array<Termin>>([]);
  const [nizDana, setNizDana] = useState<Array<Day>>([]);
  const navigate = useNavigate();
  const { instruktorID } = useParams();

  useEffect(() => {
    if (izabraniDatum !== null && izabraniDatum !== undefined) {
      obradiIzabraniMesec();
    }
  }, [izabraniDatum]);

  function prevediDan(dan: number) {
    if (dan === 0) return "Nedelja";
    if (dan === 1) return "Ponedeljak";
    if (dan === 2) return "Utorak";
    if (dan === 3) return "Sreda";
    if (dan === 4) return "Četvrtak";
    if (dan === 5) return "Petak";
    if (dan === 6) return "Subota";
  }
  function prevediMesec(brojMeseca: number): string {
    console.log("FULLMESEC PREVEDI", brojMeseca, izabraniDatum);
    const meseci = [
      "Januar",
      "Februar",
      "Mart",
      "April",
      "Maj",
      "Jun",
      "Jul",
      "Avgust",
      "Septembar",
      "Oktobar",
      "Novembar",
      "Decembar",
    ];

    // Provera da li je broj meseca validan (1-12)
    if (brojMeseca < 1 || brojMeseca > 12) {
      return "Nevažeći broj meseca";
    }

    // Vraćanje odgovarajućeg meseca
    return meseci[brojMeseca - 1];
  }
  function obradiIzabraniMesec() {
    const godina = izabraniDatum.getFullYear();
    const mesec = izabraniDatum.getMonth() + 1;
    const dan = izabraniDatum.getDate();

    const startDate = startOfMonth(new Date(godina, mesec - 1, 1));
    const endDate = endOfMonth(new Date(godina, mesec - 1, 1));

    const emptyDaysBefore: Day[] = [];
    const emptyDaysAfter: Day[] = [];
    const firstDayOfWeek = startDate.getDay();
    const lastDayOfWeek = endDate.getDay();

    if (firstDayOfWeek > 1) {
      const daysToAdd = firstDayOfWeek - 1;
      for (let i = 1; i <= daysToAdd; i++) {
        emptyDaysBefore.push({ date: subDays(startDate, i) });
      }
    }

    const daysInMonth: Day[] = eachDayOfInterval({
      start: startDate,
      end: endDate,
    }).map((date) => ({ date }));

    if (lastDayOfWeek < 7) {
      const daysToAdd = 7 - lastDayOfWeek;
      for (let i = 1; i <= daysToAdd; i++) {
        emptyDaysAfter.push({ date: addDays(endDate, i) });
      }
    }

    const fullMesec = [
      ...emptyDaysBefore.reverse(),
      ...daysInMonth,
      ...emptyDaysAfter,
    ];
    setNizDana(fullMesec);
    console.log("FULLMESEC", fullMesec);
  }

  async function handleClickKocka(dan: number, mesec: number, godina: number) {
    if (currentUserData === null || currentUserData === undefined) return;

    navigate(`/kalendarv2/${instruktorID}/${godina}-${mesec}-${dan}`);
  }
  const dohvatanjeTermina = async () => {
    if (currentUserData === null || currentUserData === undefined) return;

    const querySnapshot = await getDoc(doc(terminiRef, instruktorID));
    const podaci = querySnapshot.data();
    if (podaci === undefined || podaci === null) return;
    console.log(podaci.termini);
    setNizTermina(podaci.termini);
  };
  useEffect(() => {
    dohvatanjeTermina();
  }, [currentUserData]);

  useEffect(() => {
    if (nizDana !== null && nizDana !== undefined && nizDana.length !== 0) {
      console.log("USAO U SKROL");
      if (window.screen.width < 1025) {
        const danas = new Date();
        const element = document.getElementById(
          `${danas.getFullYear()}/${danas.getMonth() + 1}/${danas.getDate()}`
        );
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
          console.log("SKROLUJEM");
        }
      }
    }
  }, [nizDana]);
  return (
    <>
      <div className="kalendarHeader">
        <button
          onClick={() => {
            const danasnjiDatum: Date = izabraniDatum;
            const prosliDatum = new Date(danasnjiDatum);

            prosliDatum.setMonth(danasnjiDatum.getMonth() - 1);

            setIzabraniDatum(prosliDatum);
          }}
        >
          <IoArrowBack color="white" size={40} />
        </button>
        <h2>
          {prevediMesec(izabraniDatum?.getMonth() + 1)} '
          {izabraniDatum.getFullYear().toString().substring(2)}
        </h2>
        <button
          onClick={() => {
            const danasnjiDatum: Date = izabraniDatum;
            const buduciDatum = new Date(danasnjiDatum);

            buduciDatum.setMonth(danasnjiDatum.getMonth() + 1);

            setIzabraniDatum(buduciDatum);
          }}
        >
          <IoArrowForward color="white" size={40} />
        </button>
      </div>
      <div className="kalendar">
        {nizDana.map((dan) => {
          const terminiTogDana: Array<any> = _.filter(nizTermina, {
            kojiDatum: `${dan.date.getFullYear()}/${
              dan.date.getMonth() + 1
            }/${dan.date.getDate()}`,
          });
          const nizTerminaTogDana: Array<Termin> = terminiTogDana;
          return (
            <div
              className="kockaKalendara"
              id={`${dan.date.getFullYear()}/${
                dan.date.getMonth() + 1
              }/${dan.date.getDate()}`}
              onClick={() =>
                handleClickKocka(
                  dan.date.getDate(),
                  dan.date.getMonth() + 1,
                  dan.date.getFullYear()
                )
              }
            >
              <div className="imeKocke">
                <p>
                  {dan.date.getDate()}.{dan.date.getMonth() + 1},{" "}
                  {prevediDan(dan.date.getDay())?.substring(0, 3)}
                </p>
              </div>
              <div className="bodyKocke">
                {nizTerminaTogDana.map((ter) => {
                  return (
                    <div
                      className={`terminKocke ${
                        ter.status === "Slobodan" && "slobodan"
                      } ${ter.status === "Zauzet" && "zauzet"} ${
                        ter.status === "Gotov" && "gotov"
                      }`}
                    >
                      <p>
                        {ter.vremePocetka}-{ter.vremeKraja}
                      </p>
                      <p>Tip: {ter?.tipTermina}</p>
                      {ter?.studentIme !== "" &&
                        currentUserData?.uloga !== "student" && (
                          <p>Kandidat: {ter.studentIme}</p>
                        )}
                      {ter?.studentIme !== "" &&
                        currentUserData?.uloga !== "student" &&
                        ter.studentID === currentUserData?.id && (
                          <p>Kandidat: {ter.studentIme}</p>
                        )}
                      {ter.studentIme !== "" &&
                        ter.studentIme !== null &&
                        ter.studentIme !== undefined &&
                        currentUserData?.uloga === "student" &&
                        currentUserData.id === ter.studentID && (
                          <p>Kandidat: {ter.studentIme}</p>
                        )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className="kalendarHeader">
        <button
          onClick={() => {
            const danasnjiDatum: Date = izabraniDatum;
            const prosliDatum = new Date(danasnjiDatum);

            prosliDatum.setMonth(danasnjiDatum.getMonth() - 1);

            setIzabraniDatum(prosliDatum);
          }}
        >
          <IoArrowBack color="white" size={40} />
        </button>
        <h2>
          {prevediMesec(izabraniDatum?.getMonth() + 1)} '
          {izabraniDatum.getFullYear().toString().substring(2)}
        </h2>
        <button
          onClick={() => {
            const danasnjiDatum: Date = izabraniDatum;
            const buduciDatum = new Date(danasnjiDatum);

            buduciDatum.setMonth(danasnjiDatum.getMonth() + 1);

            setIzabraniDatum(buduciDatum);
          }}
        >
          <IoArrowForward color="white" size={40} />
        </button>
      </div>
    </>
  );
}
