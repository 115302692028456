import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import "../styles/home.scss";
import { AppContext } from "../functions/AppProvider";
import {
  AiFillCloseCircle,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import "react-spin-game/dist/index.css";
import { Test, PitanjeTesta } from "../utils/types";
import { testoviRef } from "../utils/firebase";
import { doc, getDoc } from "firebase/firestore";
import _ from "lodash";
import { MdArrowBack, MdOutlineSpellcheck } from "react-icons/md";
import { TestResultOverlay } from "../components/TestResultOverlay";
import { IconContext } from "react-icons/lib";
interface Props {}

export function TestPage(props: Props) {
  const { currentUserData, setLoading } = useContext(AppContext);
  const { testID } = useParams();
  const [slikaZaPitanje, setSlikuZaPitanje] = useState<string>("");
  const [test, setTest] = useState<Test>();
  const praznoPitanje: PitanjeTesta = {
    pitanje: "",
    a: "",
    b: "",
    c: "",
    d: "",
    tacanOdgovor: "",
    slika: "",
  };
  const [nizPitanja, setNizPitanja] = useState<Array<PitanjeTesta>>([
    praznoPitanje,
  ]);
  const [nizOdgovora, setNizOdgovora] = useState<Array<string>>([]);
  const [brojTacnih, setBrojTacnih] = useState<number>(0);
  const [indikator, setIndikator] = useState<number>(0);
  const [isResult, setIsResult] = useState<boolean>(false);
  const navigate = useNavigate();

  function dohvatiTest() {
    getDoc(doc(testoviRef, testID)).then((Test) => {
      const pitanja = Test?.data()?.nizPitanja;
      setNizPitanja(pitanja);
      setTest(Test?.data() as Test);
    });
  }
  useEffect(() => {
    dohvatiTest();
  }, []);
  useEffect(() => {
    if (slikaZaPitanje !== "") {
      let newArr = [...nizPitanja];
      newArr[indikator].slika = slikaZaPitanje;
      setNizPitanja(newArr);
    }
  }, [slikaZaPitanje]);
  function prethodnoPitanje() {
    setIndikator(indikator - 1);
  }
  function sledecePitanje() {
    setIndikator(indikator + 1);
  }
  function proveriOdgovore() {
    console.log(nizPitanja, nizOdgovora);
    setLoading(true);
    if (nizOdgovora.length < nizPitanja.length) {
      alert("Morate odgovoriti na svako pitanje");
      setLoading(false);
      return;
    }
    setIsResult(true);
    let x = 0;
    for (let i = 0; i < nizOdgovora.length; i++) {
      if (nizPitanja[i].tacanOdgovor === nizOdgovora[i]) {
        x = x + 1;
        setBrojTacnih(x);
      }
    }
    setLoading(false);
  }
  return (
    <>
      <div className="welcome">
        <div className="welcomeTitle">
          <IconContext.Provider value={{ className: "backIkonica" }}>
            <div className="ikonicaOmotac" onClick={() => navigate(-1)}>
              <MdArrowBack />
            </div>
          </IconContext.Provider>
          <h2>Test - {test?.ime}</h2>
        </div>
      </div>

      <div className="optionsShortcuts" id="shortcuts">
        <div
          className="shortcut"
          onClick={() => {
            navigate(`/testovi/${currentUserData?.autoskolaId}`);
          }}
        >
          <div className="ikonicaWrap">
            <AiFillCloseCircle color="white" size={50} />
          </div>
          <h3>Zatvori test</h3>
        </div>
        {indikator !== 0 && (
          <div
            className="shortcut"
            onClick={() => {
              prethodnoPitanje();
            }}
          >
            <div className="ikonicaWrap">
              <AiOutlineArrowLeft color="white" size={50} />
            </div>
            <h3>Prethodno pitanje</h3>
          </div>
        )}
        {indikator + 1 < nizPitanja.length && (
          <div
            className="shortcut"
            onClick={() => {
              sledecePitanje();
            }}
          >
            <div className="ikonicaWrap">
              <AiOutlineArrowRight color="white" size={50} />
            </div>
            <h3>Sledeće pitanje</h3>
          </div>
        )}
        {indikator + 1 === nizPitanja.length && (
          <div
            className="shortcut"
            onClick={() => {
              proveriOdgovore();
            }}
          >
            <div className="ikonicaWrap">
              <MdOutlineSpellcheck color="white" size={50} />
            </div>
            <h3>Proveri odgovore</h3>
          </div>
        )}
      </div>

      <div className="list">
        <div className="listPieceVertical">
          <h2>Pitanje {indikator + 1}</h2>
          <div className="listPieceRow">
            <div className="listPieceRowQuestions">
              {/* <textarea
                name="pitanje"
                id="pitanje"
                placeholder="Unesite pitanje"
                disabled
                value={
                  nizPitanja[indikator].pitanje !== "" &&
                  nizPitanja[indikator].pitanje !== null &&
                  nizPitanja[indikator].pitanje !== undefined
                    ? nizPitanja[indikator].pitanje
                    : ""
                }
                onChange={(e) => {
                  let newArr = [...nizPitanja];
                  newArr[indikator].pitanje = e.target.value;
                  setNizPitanja(newArr);
                }}
              /> */}
              <p>
                {nizPitanja[indikator].pitanje !== "" &&
                nizPitanja[indikator].pitanje !== null &&
                nizPitanja[indikator].pitanje !== undefined
                  ? nizPitanja[indikator].pitanje
                  : ""}
              </p>
              <label htmlFor="a">A</label>
              {/* <textarea
                placeholder="Odgovor A"
                disabled
                value={
                  nizPitanja[indikator].a !== "" &&
                  nizPitanja[indikator].a !== null &&
                  nizPitanja[indikator].a !== undefined
                    ? nizPitanja[indikator].a
                    : ""
                }
                onChange={(e) => {
                  let newArr = [...nizPitanja];
                  newArr[indikator].a = e.target.value;
                  setNizPitanja(newArr);
                }}
              /> */}
              <p>
                {nizPitanja[indikator].a !== "" &&
                nizPitanja[indikator].a !== null &&
                nizPitanja[indikator].a !== undefined
                  ? nizPitanja[indikator].a
                  : ""}
              </p>
              <label htmlFor="b">B</label>
              {/* <textarea
                placeholder="Odgovor B"
                disabled
                value={
                  nizPitanja[indikator].b !== "" &&
                  nizPitanja[indikator].b !== null &&
                  nizPitanja[indikator].b !== undefined
                    ? nizPitanja[indikator].b
                    : ""
                }
                onChange={(e) => {
                  let newArr = [...nizPitanja];
                  newArr[indikator].b = e.target.value;
                  setNizPitanja(newArr);
                }}
              /> */}
              <p>
                {nizPitanja[indikator].b !== "" &&
                nizPitanja[indikator].b !== null &&
                nizPitanja[indikator].b !== undefined
                  ? nizPitanja[indikator].b
                  : ""}
              </p>

              {/* <textarea
                placeholder="Odgovor C"
                disabled
                value={
                  nizPitanja[indikator].c !== "" &&
                  nizPitanja[indikator].c !== null &&
                  nizPitanja[indikator].c !== undefined
                    ? nizPitanja[indikator].c
                    : ""
                }
                onChange={(e) => {
                  let newArr = [...nizPitanja];
                  newArr[indikator].c = e.target.value;
                  setNizPitanja(newArr);
                }}
              /> */}
              {nizPitanja[indikator].c !== "" &&
                nizPitanja[indikator].c !== null &&
                nizPitanja[indikator].c !== undefined && (
                  <label htmlFor="c">C</label>
                )}

              {nizPitanja[indikator].c !== "" &&
                nizPitanja[indikator].c !== null &&
                nizPitanja[indikator].c !== undefined && (
                  <p>{nizPitanja[indikator].c}</p>
                )}

              {nizPitanja[indikator].d !== "" &&
                nizPitanja[indikator].d !== null &&
                nizPitanja[indikator].d !== undefined && (
                  <label htmlFor="d">D</label>
                )}

              {nizPitanja[indikator].d !== "" &&
                nizPitanja[indikator].d !== null &&
                nizPitanja[indikator].d !== undefined && (
                  <p>{nizPitanja[indikator].d}</p>
                )}

              {/* <textarea
                placeholder="Odgovor D"
                disabled
                value={
                  nizPitanja[indikator].d !== "" &&
                  nizPitanja[indikator].d !== null &&
                  nizPitanja[indikator].d !== undefined
                    ? nizPitanja[indikator].d
                    : ""
                }
                onChange={(e) => {
                  let newArr = [...nizPitanja];
                  newArr[indikator].d = e.target.value;
                  setNizPitanja(newArr);
                }}
              /> */}
            </div>
            <div className="listPieceRowImage">
              {nizPitanja[indikator].slika !== "" &&
                nizPitanja[indikator].slika !== null &&
                nizPitanja[indikator].slika !== undefined && (
                  <img
                    src={nizPitanja[indikator].slika}
                    alt="test"
                    id="slika"
                  />
                )}

              <h2>Vaš odgovor</h2>
              <div className="listPieceRowImageButtons">
                <button
                  className={nizOdgovora[indikator] === "a" ? "correct" : ""}
                  onClick={() => {
                    let newArr = [...nizOdgovora];
                    newArr[indikator] = "a";
                    setNizOdgovora(newArr);
                    if (indikator + 1 !== nizPitanja.length) {
                      sledecePitanje();
                      const slicica = document.getElementById("slika");
                      if (slicica) {
                        slicica?.scrollIntoView({ behavior: "smooth" });
                      }
                    } else {
                      const shortcuts = document.getElementById("shortcuts");
                      if (shortcuts) {
                        shortcuts.scrollIntoView({ behavior: "smooth" });
                      }
                    }
                  }}
                >
                  A
                </button>
                <button
                  className={nizOdgovora[indikator] === "b" ? "correct" : ""}
                  onClick={() => {
                    let newArr = [...nizOdgovora];
                    newArr[indikator] = "b";
                    setNizOdgovora(newArr);
                    if (indikator + 1 !== nizPitanja.length) {
                      sledecePitanje();
                      const slicica = document.getElementById("slika");
                      if (slicica) {
                        slicica?.scrollIntoView({ behavior: "smooth" });
                      }
                    } else {
                      const shortcuts = document.getElementById("shortcuts");
                      if (shortcuts) {
                        shortcuts.scrollIntoView({ behavior: "smooth" });
                      }
                    }
                  }}
                >
                  B
                </button>
                {nizPitanja[indikator].c !== "" &&
                  nizPitanja[indikator].c !== null &&
                  nizPitanja[indikator].c !== undefined && (
                    <button
                      className={
                        nizOdgovora[indikator] === "c" ? "correct" : ""
                      }
                      onClick={() => {
                        let newArr = [...nizOdgovora];
                        newArr[indikator] = "c";
                        setNizOdgovora(newArr);
                        if (indikator + 1 !== nizPitanja.length) {
                          sledecePitanje();
                          const slicica = document.getElementById("slika");
                          if (slicica) {
                            slicica?.scrollIntoView({ behavior: "smooth" });
                          }
                        } else {
                          const shortcuts =
                            document.getElementById("shortcuts");
                          if (shortcuts) {
                            shortcuts.scrollIntoView({ behavior: "smooth" });
                          }
                        }
                      }}
                    >
                      C
                    </button>
                  )}
                {nizPitanja[indikator].d !== "" &&
                  nizPitanja[indikator].d !== null &&
                  nizPitanja[indikator].d !== undefined && (
                    <button
                      className={
                        nizOdgovora[indikator] === "d" ? "correct" : ""
                      }
                      onClick={() => {
                        let newArr = [...nizOdgovora];
                        newArr[indikator] = "d";
                        setNizOdgovora(newArr);
                        if (indikator + 1 !== nizPitanja.length) {
                          sledecePitanje();
                          const slicica = document.getElementById("slika");
                          if (slicica) {
                            slicica?.scrollIntoView({ behavior: "smooth" });
                          }
                        } else {
                          const shortcuts =
                            document.getElementById("shortcuts");
                          if (shortcuts) {
                            shortcuts.scrollIntoView({ behavior: "smooth" });
                          }
                        }
                      }}
                    >
                      D
                    </button>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isResult && (
        <TestResultOverlay
          setIsResult={setIsResult}
          brojPitanja={nizPitanja.length}
          brojTacnih={brojTacnih}
          nizOdgovora={nizOdgovora}
          testID={testID as string}
        />
      )}
    </>
  );
}
