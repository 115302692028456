import React, { useContext, useState } from "react";
import "../styles/newSTOverlay.scss";
import { Grupa, UserData, autoSkola } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import {
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  grupeRef,
  korisniciRef,
  noviKorisniciRef,
  skoleRef,
} from "../utils/firebase";
import _ from "lodash";
import useKeyPress from "react-use-keypress";
import uuid from "react-uuid";

interface Props {
  korisnik: UserData;
  setIsEmailChange: any;
  dohvatiStudente: any;
}

export function EmailChangeOverlay(props: Props) {
  const { korisnik, setIsEmailChange, dohvatiStudente } = props;
  const { setLoading, setAlert, setIsAlert } = useContext(AppContext);
  const [ime, setIme] = useState<string>(korisnik.ime);

  useKeyPress("Escape", () => {
    setIsEmailChange(false);
  });
  useKeyPress("Enter", () => {
    promeniIme(ime);
  });

  function promeniIme(novoIme: string) {
    setLoading(true);
    if (korisnik === undefined || korisnik === null) return;
    console.log("KORISNIK", korisnik);
    const promenjenKorisnik: UserData = {
      autoskolaId: korisnik.autoskolaId,
      email: ime,
      id: "",
      ime: korisnik.ime,
      profilnaURL: korisnik.profilnaURL,
      uloga: korisnik.uloga,
      brOdvozanihCasova: korisnik.brOdvozanihCasova,
      brNocnih: korisnik.brNocnih,
      koJeInstruktorID: korisnik.koJeInstruktorID,
      koJeInstruktorIme: korisnik.koJeInstruktorIme,
      polozioTeoriju: korisnik.polozioTeoriju,
      datum: korisnik.datum.toString(),
      telefon: korisnik.telefon,
      aktiviran: korisnik.aktiviran,
      voziAuto: korisnik.voziAuto,
      editovanInfoPrviPut: korisnik.editovanInfoPrviPut,
      grupa: korisnik.grupa,
      grupaID: korisnik.grupaID,
      uploads: korisnik.uploads,
      krediti: korisnik.krediti,
      nizOdvozanihTermina: korisnik.nizOdvozanihTermina,
      nizStudenata: korisnik.nizStudenata,
      nizZakazanihTermina: korisnik.nizZakazanihTermina,
      prikazivanjeTermina: korisnik.prikazivanjeTermina,
      nedeljnaKvota: korisnik.nedeljnaKvota,
      subscribed: korisnik.subscribed,
    };

    deleteDoc(doc(noviKorisniciRef, korisnik.email)).then(() => {
      getDoc(doc(skoleRef, promenjenKorisnik.autoskolaId)).then((skola) => {
        const skolaInfo = skola.data() as autoSkola;
        const nizNovih: Array<UserData> =
          skolaInfo.noviStudenti as Array<UserData>;
        const fitlerNizNovih = _.filter(nizNovih, function (o) {
          if (o.email !== korisnik.email) return o;
        });

        setDoc(doc(noviKorisniciRef, ime), promenjenKorisnik).then(() => {
          updateDoc(doc(skoleRef, promenjenKorisnik.autoskolaId), {
            noviStudenti: [...fitlerNizNovih, promenjenKorisnik],
          }).then(() => {
            dohvatiStudente();
            setLoading(false);
            setIsEmailChange(false);
            setAlert("Uspešno promenjen mejl.");
            setIsAlert(true);
          });
        });
      });
    });
  }

  return (
    <div className="newSTOverlay">
      <div className="newSTBox">
        <h2>Promena email adrese</h2>
        <input
          type="text"
          name="novoIme"
          id="novoIme"
          placeholder={korisnik.email}
          onChange={(e) => setIme(e.target.value)}
        />
        <div className="buttonOptions">
          <button onClick={() => promeniIme(ime)}>Potvrdi</button>
          <button onClick={() => setIsEmailChange(false)}>Odustani</button>
        </div>
        <button
          className="x"
          onClick={() => {
            setIsEmailChange(false);
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}
