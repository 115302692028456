import React, { useContext, useEffect, useState } from "react";
import "../styles/home.scss";
import {
  BsPersonAdd,
  BsSortNumericDownAlt,
  BsSortNumericUpAlt,
  BsTrashFill,
} from "react-icons/bs";
import { Grupa, Lekcija, Predavanje } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { predavanjeRef, skoleRef } from "../utils/firebase";
import _ from "lodash";
import { NewPredavanjeOverlay } from "../components/NewPredavanjeOverlay";
import { IconContext } from "react-icons/lib";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router";

interface Props {}

export function Predavanja(props: Props) {
  const [nizLekcija, setNizLekcija] = useState<Array<Lekcija>>([]);
  const [nizGrupa, setNizGrupa] = useState<Array<Grupa>>([]);
  const [nizPredavanja, setNizPredavanja] = useState<Array<Predavanje>>([]);
  const [sekundarniNiz, setSekundarniNiz] = useState<Array<Predavanje>>([]);
  const [isNewPredavanje, setIsNewPredavanje] = useState<boolean>(false);
  const { currentUserData, setLoading, setAlert, setIsAlert } =
    useContext(AppContext);
  const [trenutniSort, setTrenutniSort] = useState<"datumAsc" | "datumDesc">();
  const navigate = useNavigate();

  useEffect(() => {
    dohvatiPredavanja();
  }, [currentUserData]);

  function dohvatiPredavanja() {
    if (
      currentUserData === null ||
      currentUserData === undefined ||
      currentUserData.autoskolaId === null ||
      currentUserData.autoskolaId === undefined ||
      currentUserData.autoskolaId === "" ||
      skoleRef === null ||
      skoleRef === undefined
    )
      return;
    setLoading(true);

    getDoc(doc(skoleRef, currentUserData.autoskolaId)).then((skola) => {
      const predavanja = skola.data()?.predavanja;
      setNizPredavanja(predavanja);
      setSekundarniNiz(predavanja);
      setLoading(false);
    });
  }
  function search(q: string) {
    const duzina = q.length;
    const niz = nizPredavanja;
    if (q === "") {
      setSekundarniNiz(nizPredavanja);
      return;
    }
    const filtriranNiz = _.filter(niz, function (o) {
      const ime = `${new Date(o.datum).getDate()}-${
        new Date(o.datum).getMonth() + 1
      }-${new Date(o.datum).getFullYear()}` as string;
      if (
        ime.toLowerCase().substring(0, duzina) ===
        q.toLowerCase().substring(0, duzina)
      )
        return o;
    });
    console.log("FILTER", filtriranNiz);
    setSekundarniNiz(filtriranNiz as Array<Predavanje>);
  }
  function parseDate(dateString: string, vreme: string) {
    const [year, month, day] = dateString.split("-").map(Number);
    const [hours, minutes] = vreme.split(":").map(Number);
    return new Date(year, month - 1, day, hours, minutes);
  }

  function sortDateAsc() {
    const niz = nizPredavanja;
    // const sortiraniNiz = _.orderBy(niz, ["datum"], ["asc"]);
    const sortiraniNiz = niz.sort((a, b) => {
      const dateA = parseDate(a.datum, a.vreme);
      const dateB = parseDate(b.datum, b.vreme);

      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    });
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("datumAsc");
  }
  function sortDateDesc() {
    const niz = nizPredavanja;
    const sortiraniNiz = [...niz].sort((a, b) => {
      const dateA = parseDate(a.datum, a.vreme);
      const dateB = parseDate(b.datum, b.vreme);

      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      return 0;
    });
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("datumDesc");
  }
  async function obrisi(kojePredavanje: Predavanje) {
    setLoading(true);
    const filtriraniNiz = _.filter(nizPredavanja, function (o) {
      if (o.id !== kojePredavanje.id) return o;
    });

    await updateDoc(doc(skoleRef, currentUserData?.autoskolaId), {
      predavanja: filtriraniNiz,
    }).then(() => {
      deleteDoc(doc(predavanjeRef, kojePredavanje.id)).then(() => {
        dohvatiPredavanja();
        setLoading(false);
        setAlert(
          `Uspešno obrisano predavanje: ${kojePredavanje.datum} ${kojePredavanje.vreme}`
        );
        setIsAlert(true);
      });
    });
  }
  return (
    <>
      <div className="welcome">
        <div className="welcomeTitle">
          <IconContext.Provider value={{ className: "backIkonica" }}>
            <div className="ikonicaOmotac" onClick={() => navigate(-1)}>
              <MdArrowBack />
            </div>
          </IconContext.Provider>
          <h2>Predavanja</h2>
        </div>
        <input
          type="text"
          name="pretragaStudenata"
          id="pretragaStudenata"
          placeholder="Pretražite"
          onChange={(e) => search(e.target.value)}
        />
      </div>
      <div className="optionsShortcuts">
        {currentUserData?.uloga !== "student" && (
          <div className="shortcut" onClick={() => setIsNewPredavanje(true)}>
            <div className="ikonicaWrap">
              <BsPersonAdd color="white" size={50} />
            </div>
            <h3>Zakaži predavanje</h3>
          </div>
        )}
        <div className="shortcut" onClick={() => sortDateDesc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "datumDesc" ? "correct" : ""
            }`}
          >
            <BsSortNumericDownAlt color="white" size={50} />
          </div>
          <h3>Najnovije - Najstarije</h3>
        </div>
        <div className="shortcut" onClick={() => sortDateAsc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "datumAsc" ? "correct" : ""
            }`}
          >
            <BsSortNumericUpAlt color="white" size={50} />
          </div>
          <h3>Najstarije - Najnovije</h3>
        </div>
      </div>
      <div className="list">
        {sekundarniNiz.map((predavanje) => {
          return (
            <div className="listPiece">
              <div className="pieceInfo">
                <div className="pieceInfoText">
                  <h3>
                    {new Date(predavanje.datum).getDate()}.
                    {new Date(predavanje.datum).getMonth() + 1}.
                    {new Date(predavanje.datum).getFullYear()} -{" "}
                    {predavanje.vreme}
                  </h3>
                  <p>Grupa: {predavanje.grupa.ime}</p>
                  <p>Lekcija: {predavanje.lekcija.ime}</p>
                </div>
              </div>
              <div className="pieceOptions">
                {currentUserData?.uloga !== "student" && (
                  <div
                    className="pieceOption"
                    onClick={() => {
                      obrisi(predavanje);
                    }}
                  >
                    <div className="pieceOptionIcon">
                      <BsTrashFill color="white" size={50} />
                    </div>
                    <p>Obriši</p>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {isNewPredavanje && (
        <NewPredavanjeOverlay
          setIsNewPredavanje={setIsNewPredavanje}
          dohvatiPredavanja={dohvatiPredavanja}
        />
      )}
    </>
  );
}
