import React, { useContext, useEffect, useState } from "react";
import "../styles/newSTOverlay.scss";
import { Grupa, UserData, autoSkola } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { doc, setDoc, updateDoc, arrayUnion, getDoc } from "firebase/firestore";
import { grupeRef, korisniciRef, skoleRef } from "../utils/firebase";
import _, { update } from "lodash";
import useKeyPress from "react-use-keypress";
import uuid from "react-uuid";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface Props {
  korisnik: UserData;
  setGroupChange: any;
  dohvatiKorisnika: any;
}

export function GroupChangeOverlay(props: Props) {
  const { korisnik, setGroupChange, dohvatiKorisnika } = props;
  const { setLoading, setAlert, setIsAlert } = useContext(AppContext);
  const [novaGrupa, setNovaGrupa] = useState<Grupa>();
  const [imeIzabraneGrupe, setImeIzabraneGrupe] = useState<string>(
    korisnik.grupa !== null &&
      korisnik.grupa !== undefined &&
      korisnik.grupa !== ""
      ? korisnik.grupa
      : ""
  );
  const [nizGrupa, setNizGrupa] = useState<Array<Grupa>>([]);

  useKeyPress("Escape", () => {
    setGroupChange(false);
  });
  useKeyPress("Enter", () => {
    promeniGrupu();
  });

  useEffect(() => {
    dohvatiGrupe();
  }, [korisnik]);
  function dohvatiGrupe() {
    if (korisnik === null || korisnik === undefined) {
      return;
    }
    getDoc(doc(skoleRef, korisnik.autoskolaId)).then((skola) => {
      setNizGrupa(skola?.data()?.grupe as Array<Grupa>);
    });
  }
  function handleChangeGrupe(event: SelectChangeEvent) {
    setImeIzabraneGrupe(event.target.value);
  }
  function promeniGrupu() {
    setLoading(true);
    console.log(novaGrupa);
    if (
      korisnik === undefined ||
      korisnik === null ||
      novaGrupa === null ||
      novaGrupa === undefined
    )
      return;
    if (novaGrupa.id === korisnik.grupaID) {
      setAlert("Već je u toj grupi.");
      setIsAlert(true);
      setGroupChange(false);
      setLoading(false);
      return;
    }
    // NOVI OBJEKAT KORISNIK
    const korisnikPodaci: UserData = {
      autoskolaId: korisnik.autoskolaId,
      email: korisnik.email,
      id: korisnik.id,
      ime: korisnik.ime,
      profilnaURL: korisnik.profilnaURL,
      uloga: korisnik.uloga,
      aktiviran: korisnik.aktiviran,
      brNocnih: korisnik.brNocnih,
      brOdvozanihCasova: korisnik.brOdvozanihCasova,
      datum: korisnik.datum,
      editovanInfoPrviPut: korisnik.editovanInfoPrviPut,
      koJeInstruktorID: korisnik.koJeInstruktorID,
      grupa: novaGrupa.ime,
      grupaID: novaGrupa.id,
      koJeInstruktorIme: korisnik.koJeInstruktorIme,
      krediti: korisnik.krediti,
      polozioTeoriju: korisnik.polozioTeoriju,
      telefon: korisnik.telefon,
      uploads: korisnik.uploads,
      voziAuto: korisnik.voziAuto,
      nizOdvozanihTermina: korisnik.nizOdvozanihTermina,
      nizStudenata: korisnik.nizStudenata,
      nizZakazanihTermina: korisnik.nizZakazanihTermina,
      prikazivanjeTermina: korisnik.prikazivanjeTermina,
      nedeljnaKvota: korisnik.nedeljnaKvota,
      subscribed: korisnik.subscribed,
    };

    if (
      korisnik.grupaID !== "" &&
      korisnik.grupaID !== null &&
      korisnik.grupaID !== undefined
    ) {
      const trenutnaGrupa = _.find(nizGrupa, function (o) {
        if (o.id === korisnik.grupaID) return o;
      });

      console.log("TRENUTNA GRUPA", trenutnaGrupa);

      // AKO JE TRENTNO U NEKOJ GRUPI ONDA IZBACI IZ NIZA STUDENATA U GRUPI I NIZU GRUPA U SKOLI
      const trenutnaGrupaObjekat: Grupa = trenutnaGrupa as Grupa;
      if (trenutnaGrupaObjekat !== null && trenutnaGrupaObjekat !== undefined) {
        const filtriraniNizStudenataUTrenutnojGrupi = _.filter(
          trenutnaGrupaObjekat.studenti,
          function (o) {
            if (o.id !== korisnik.id) return o;
          }
        );
        console.log(
          "TRENUTNA GRUPA",
          filtriraniNizStudenataUTrenutnojGrupi,
          trenutnaGrupaObjekat
        );

        const trenutnaGrupaObjekatCeo: Grupa = {
          datum: trenutnaGrupaObjekat.datum,
          id: trenutnaGrupaObjekat.id,
          ime: trenutnaGrupaObjekat.ime,
          kreirao: trenutnaGrupaObjekat.kreirao,
          kreiraoID: trenutnaGrupaObjekat.kreiraoID,
          studenti: filtriraniNizStudenataUTrenutnojGrupi as Array<UserData>,
        };

        const filtriraniNizGrupaBezTrenutne = _.filter(nizGrupa, function (o) {
          if (o.id !== trenutnaGrupaObjekat.id) return o;
        });
        console.log(
          "Filtrirani niz grupa bez trenutne",
          filtriraniNizGrupaBezTrenutne
        );
        console.log("Trenutna grupa objekat ceo", trenutnaGrupaObjekatCeo);
        updateDoc(doc(skoleRef, korisnik.autoskolaId), {
          grupe: [...filtriraniNizGrupaBezTrenutne, trenutnaGrupaObjekatCeo],
        }).then(() => {
          console.log([
            ...filtriraniNizGrupaBezTrenutne,
            trenutnaGrupaObjekatCeo,
          ]);
          updateDoc(doc(grupeRef, trenutnaGrupaObjekat.id), {
            studenti: filtriraniNizStudenataUTrenutnojGrupi,
          }).then(() => {
            console.log(filtriraniNizStudenataUTrenutnojGrupi);
          });
        });
      }
    }
    // DODAJ U NOVU GRUPU STUDENTA I
    updateDoc(doc(grupeRef, novaGrupa.id), {
      studenti: arrayUnion(korisnikPodaci),
    }).then(() => {
      updateDoc(doc(korisniciRef, korisnik.id), {
        grupa: novaGrupa.ime,
        grupaID: novaGrupa.id,
      }).then(() => {
        getDoc(doc(skoleRef, korisnik.autoskolaId)).then((skola) => {
          const skolaData = skola.data();

          const filtriraniNizGrupaBezNove = _.filter(
            skola?.data()?.grupe,
            function (o) {
              if (o.id !== novaGrupa.id) return o;
            }
          );
          const novagrupaData: Grupa = {
            datum: novaGrupa.datum,
            id: novaGrupa.id,
            ime: novaGrupa.ime,
            kreirao: novaGrupa.kreirao,
            kreiraoID: novaGrupa.kreiraoID,
            studenti: [...novaGrupa.studenti, korisnikPodaci],
          };

          if (korisnik.uloga === "student") {
            const filtriraniNizStudenata = _.filter(
              skolaData?.studenti,
              function (o) {
                if (o.id !== korisnik.id) return o;
              }
            );

            updateDoc(doc(skoleRef, korisnik.autoskolaId), {
              studenti: [...filtriraniNizStudenata, korisnikPodaci],
              grupe: [...filtriraniNizGrupaBezNove, novagrupaData],
            })
              .then(() => {
                if (
                  korisnik.koJeInstruktorID !== null &&
                  korisnik.koJeInstruktorID !== undefined &&
                  korisnik.koJeInstruktorID !== "" &&
                  korisnik.uloga === "student"
                ) {
                  getDoc(doc(korisniciRef, korisnik.koJeInstruktorID)).then(
                    (instruktor) => {
                      const instruktorData = instruktor.data() as UserData;
                      const filtriraniNizStudenataKodInstruktora = _.filter(
                        instruktorData.nizStudenata,
                        function (o) {
                          if (o.id !== korisnik.id) return o;
                        }
                      );
                      const filterStudenataBezOvog: Array<UserData> =
                        filtriraniNizStudenataKodInstruktora as Array<UserData>;
                      const newInstruktorData: UserData = {
                        aktiviran: instruktorData.aktiviran,
                        autoskolaId: instruktorData.autoskolaId,
                        brNocnih: instruktorData.brNocnih,
                        brOdvozanihCasova: instruktorData.brOdvozanihCasova,
                        datum: instruktorData.datum,
                        editovanInfoPrviPut: instruktorData.editovanInfoPrviPut,
                        email: instruktorData.email,
                        grupa: instruktorData.grupa,
                        grupaID: instruktorData.grupaID,
                        id: instruktorData.id,
                        ime: instruktorData.ime,
                        koJeInstruktorID: instruktorData.koJeInstruktorID,
                        koJeInstruktorIme: instruktorData.koJeInstruktorIme,
                        krediti: instruktorData.krediti,
                        nizOdvozanihTermina: instruktorData.nizOdvozanihTermina,
                        nizStudenata: [
                          ...filterStudenataBezOvog,
                          korisnikPodaci,
                        ],
                        nizZakazanihTermina: instruktorData.nizZakazanihTermina,
                        polozioTeoriju: instruktorData.polozioTeoriju,
                        prikazivanjeTermina: instruktorData.prikazivanjeTermina,
                        profilnaURL: instruktorData.profilnaURL,
                        telefon: instruktorData.telefon,
                        uloga: instruktorData.uloga,
                        uploads: instruktorData.uploads,
                        voziAuto: instruktorData.voziAuto,
                        nedeljnaKvota: instruktorData.nedeljnaKvota,
                        subscribed: instruktorData.subscribed,
                      };
                      getDoc(doc(skoleRef, korisnik.autoskolaId)).then(
                        (skola) => {
                          const skolaInfo = skola.data() as autoSkola;
                          const filterInstruktora = _.filter(
                            skolaInfo.instruktori,
                            function (o) {
                              if (o.id !== instruktorData.id) return o;
                            }
                          );
                          const nizInstruktora: Array<UserData> =
                            filterInstruktora as Array<UserData>;

                          updateDoc(doc(skoleRef, korisnik.autoskolaId), {
                            instruktori: [...nizInstruktora, newInstruktorData],
                          }).then(() => {
                            updateDoc(doc(korisniciRef, instruktorData.id), {
                              nizStudenata: [
                                ...filterStudenataBezOvog,
                                korisnikPodaci,
                              ],
                            });
                          });
                        }
                      );
                    }
                  );
                }
              })
              .then(() => {
                setAlert("Uspešno promenjena grupa");
                dohvatiKorisnika();
                setIsAlert(true);
                setLoading(false);
                setGroupChange(false);
              });
          }
        });
      });
    });
  }

  return (
    <div className="newSTOverlay">
      <div className="newSTBox">
        <h2>Promena grupe</h2>
        <FormControl fullWidth className="izborDatumaMUI">
          <InputLabel id="izborGrupe">Izaberite grupu</InputLabel>
          <Select
            labelId="izborGrupe"
            id="selectGrupe"
            label="Izaberite grupu"
            value={imeIzabraneGrupe}
            onChange={handleChangeGrupe}
            className="izborDatumaMUI"
          >
            {nizGrupa.map((grupa) => {
              return (
                <MenuItem value={grupa.ime} onClick={() => setNovaGrupa(grupa)}>
                  {grupa.ime}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <div className="buttonOptions">
          <button onClick={() => promeniGrupu()}>Potvrdi</button>
          <button onClick={() => setGroupChange(false)}>Odustani</button>
        </div>
        <button
          className="x"
          onClick={() => {
            setGroupChange(false);
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}
