import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../functions/AppProvider";
import "../styles/home.scss";
import { BiHelpCircle, BiLogOutCircle, BiPlus } from "react-icons/bi";
import { GiBookCover, GiTeacher } from "react-icons/gi";
import { MdGroups, MdQuiz } from "react-icons/md";
import { BsPeopleFill, BsTrashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { AiFillFilePpt } from "react-icons/ai";
import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { predavanjeRef, skoleRef } from "../utils/firebase";
import { Predavanje } from "../utils/types";
import _ from "lodash";
import { NewPredavanjeOverlay } from "../components/NewPredavanjeOverlay";

interface Props {}

export function HomeST(props: Props) {
  const date = new Date();
  const mesec = date.getMonth() + 1;
  const navigate = useNavigate();
  const [isNewPredavanje, setIsNewPredavanje] = useState<boolean>(false);
  const [nizPredavanja, setNizPredavanja] = useState<Array<Predavanje>>([]);
  const [sledecaPredavanja, setSledecaPredavanja] = useState<Array<Predavanje>>(
    []
  );
  const [sekundarniNiz, setSekundarniNiz] = useState<Array<Predavanje>>([]);
  const { currentUserData, logOut, setLoading, setAlert, setIsAlert } =
    useContext(AppContext);

  function parseDate(dateString: string, vreme: string) {
    const [year, month, day] = dateString.split("-").map(Number);
    const [hours, minutes] = vreme.split(":").map(Number);
    return new Date(year, month - 1, day, hours, minutes);
  }

  useEffect(() => {
    if (currentUserData !== null && currentUserData !== undefined) {
      dohvatiSkolu();
    }
  }, [currentUserData]);
  useEffect(() => {
    if (
      nizPredavanja !== null &&
      nizPredavanja !== undefined &&
      nizPredavanja.length !== 0
    ) {
      const nextPredavanja = _.filter(nizPredavanja, function (o) {
        if (new Date(o.datum as string) > date) return o;
      });
      const slPred: Array<Predavanje> = nextPredavanja as Array<Predavanje>;
      const nextPredavanjaSortirano = slPred.sort((a, b) => {
        const dateA = parseDate(a.datum, a.vreme);
        const dateB = parseDate(b.datum, b.vreme);
        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
        return 0;
      });
      setSledecaPredavanja(nextPredavanjaSortirano);
      setSekundarniNiz(nextPredavanjaSortirano);
    }
  }, [nizPredavanja]);
  function dohvatiSkolu() {
    getDoc(doc(skoleRef, currentUserData?.autoskolaId)).then((skola) => {
      const skolaData = skola.data();
      if (skolaData === undefined || skolaData === null) return;
      setNizPredavanja(skolaData.predavanja as Array<Predavanje>);
    });
  }
  async function obrisiPredavanje(kojePredavanje: Predavanje) {
    setLoading(true);
    const filtriraniNiz = _.filter(nizPredavanja, function (o) {
      if (o.id !== kojePredavanje.id) return o;
    });

    await updateDoc(doc(skoleRef, currentUserData?.autoskolaId), {
      predavanja: filtriraniNiz,
    }).then(() => {
      deleteDoc(doc(predavanjeRef, kojePredavanje.id)).then(() => {
        dohvatiSkolu();
        setLoading(false);
        setAlert(
          `Uspešno obrisano predavanje: ${kojePredavanje.datum} ${kojePredavanje.vreme}`
        );
        setIsAlert(true);
      });
    });
  }

  function search(q: string) {
    const duzina = q.length;
    const niz = sledecaPredavanja;
    if (q === "") {
      setSekundarniNiz(sledecaPredavanja);
      return;
    }
    const filtriranNiz = _.filter(niz, function (o) {
      const ime = `${new Date(o.datum).getDate()}-${
        new Date(o.datum).getMonth() + 1
      }-${new Date(o.datum).getFullYear()}` as string;
      if (
        ime.toLowerCase().substring(0, duzina) ===
        q.toLowerCase().substring(0, duzina)
      )
        return o;
    });
    console.log("FILTER", filtriranNiz);
    setSekundarniNiz(filtriranNiz as Array<Predavanje>);
  }
  return (
    <>
      <div className="welcome">
        <h2>
          Dobro došli, {currentUserData && currentUserData?.ime.split(" ")[0]}
        </h2>
        <h2>
          {date.getDate()}.{mesec}.{date.getFullYear()}
        </h2>
      </div>
      <div className="optionsShortcuts">
        <div
          className="shortcut"
          onClick={() => {
            navigate(`/studenti/${currentUserData?.autoskolaId}`);
          }}
        >
          <div className="ikonicaWrap">
            <BsPeopleFill color="white" size={50} />
          </div>
          <h3>Kandidati</h3>
        </div>

        <div
          className="shortcut"
          onClick={() => {
            navigate(`/grupe/${currentUserData?.autoskolaId}`);
          }}
        >
          <div className="ikonicaWrap">
            <MdGroups color="white" size={50} />
          </div>
          <h3>Grupe</h3>
        </div>

        <div
          className="shortcut"
          onClick={() => {
            navigate(`/predavanja/${currentUserData?.autoskolaId}`);
          }}
        >
          <div className="ikonicaWrap">
            <GiBookCover color="white" size={50} />
          </div>
          <h3>Predavanja</h3>
        </div>

        <div
          className="shortcut"
          onClick={() => {
            navigate(`/lekcije/${currentUserData?.autoskolaId}`);
          }}
        >
          <div className="ikonicaWrap">
            <GiTeacher color="white" size={50} />
          </div>
          <h3>Lekcije</h3>
        </div>

        <div
          className="shortcut"
          onClick={() => {
            navigate(`/prezentacije/${currentUserData?.autoskolaId}`);
          }}
        >
          <div className="ikonicaWrap">
            <AiFillFilePpt color="white" size={50} />
          </div>
          <h3>Prezentacije</h3>
        </div>

        <div
          className="shortcut"
          onClick={() => {
            navigate(`/testovi/${currentUserData?.autoskolaId}`);
          }}
        >
          <div className="ikonicaWrap">
            <MdQuiz color="white" size={50} />
          </div>
          <h3>Testovi</h3>
        </div>
        <div className="shortcut">
          <div className="ikonicaWrap">
            <BiHelpCircle color="white" size={50} />
          </div>
          <h3>Pomoć</h3>
        </div>

        <div
          className="shortcut"
          onClick={() => {
            logOut();
            navigate("/login");
          }}
        >
          <div className="ikonicaWrap">
            <BiLogOutCircle color="white" size={60} />
          </div>
          <h3>Izlogujte se</h3>
        </div>
      </div>
      {currentUserData?.subscribed === false && (
        <div className="optionsShortcuts">
          <h3 style={{ color: "white" }}>
            Notifikacije nisu aktivirane. Kako biste ih aktivirali, pošaljite
            poruku na WhatsApp-u sa sadržajem "Prihvatam" na broj 065 840 54 80
          </h3>
        </div>
      )}
      <div className="opcijeKontejner">
        <div className="opcije Desno">
          <div className="opcijeDesnoHeader">
            <h3>Sledeće predavanje</h3>
          </div>
          <div className="opcijeDesnoBody">
            {sledecaPredavanja.length === 0 && (
              <div className="stavka">
                <h4>Nema zakazanih predavanja</h4>
              </div>
            )}
            {sledecaPredavanja !== null &&
              sledecaPredavanja !== undefined &&
              sledecaPredavanja.length !== 0 && (
                <div className="stavka">
                  <h4>Datum</h4>
                  <p>
                    {new Date(sledecaPredavanja[0]?.datum).getDate()}.
                    {new Date(sledecaPredavanja[0]?.datum).getMonth() + 1}.
                    {new Date(sledecaPredavanja[0]?.datum).getFullYear()}
                  </p>
                </div>
              )}
            {sledecaPredavanja !== null &&
              sledecaPredavanja !== undefined &&
              sledecaPredavanja.length !== 0 && (
                <div className="stavka">
                  <h4>Vreme početka</h4>
                  <p>{sledecaPredavanja[0]?.vreme}</p>
                </div>
              )}
            {sledecaPredavanja !== null &&
              sledecaPredavanja !== undefined &&
              sledecaPredavanja.length !== 0 && (
                <div className="stavka">
                  <h4>Grupa</h4>
                  <p>{sledecaPredavanja[0]?.grupa.ime}</p>
                </div>
              )}
            {sledecaPredavanja !== null &&
              sledecaPredavanja !== undefined &&
              sledecaPredavanja.length !== 0 && (
                <div className="stavka clickable">
                  <h4>Lekcija</h4>
                  <p
                    onClick={() => {
                      navigate(`/lekcija/${sledecaPredavanja[0]?.lekcija.id}`);
                    }}
                  >
                    {sledecaPredavanja[0]?.lekcija.ime}
                  </p>
                </div>
              )}
          </div>
        </div>
        <div className="opcije Sredina">
          <div className="opcijeSredinaHeader">
            <h3>Predavanja</h3>
            <div className="optionsHeader">
              <div
                className="okvirOpcije"
                onClick={() => setIsNewPredavanje(true)}
              >
                <BiPlus color="white" size={20} />
              </div>
              <input
                type="text"
                name="pretragaPredavanja"
                id="pretragaPredavanja"
                placeholder="Pretražite"
                onChange={(e) => search(e.target.value)}
              />
            </div>
          </div>
          <div className="opcijeSredinaBody">
            {sekundarniNiz.map((predavanje) => {
              return (
                <div className="korisnik">
                  <div
                    className="korisnikInfo"
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <h3>
                      {new Date(predavanje.datum).getDate()}-
                      {new Date(predavanje.datum).getMonth() + 1}-
                      {new Date(predavanje.datum).getFullYear()} -{" "}
                      {predavanje.vreme}
                    </h3>
                    <p>Grupa: {predavanje.grupa.ime}</p>
                    <p>Lekcija: {predavanje.lekcija.ime}</p>
                  </div>
                  <div className="opcijeKorisnik">
                    <div
                      className="okvirOpcije"
                      onClick={() => {
                        obrisiPredavanje(predavanje);
                      }}
                    >
                      <BsTrashFill color="white" size={20} />
                    </div>
                  </div>
                </div>
              );
            })}
            {sekundarniNiz.length === 0 && <h3>Nema zakazanih predavanja</h3>}
          </div>
        </div>
        <div className="opcije Levo">
          <div className="opcijeLevoDiv">
            <div className="opcijeLevoHeader">
              <div className="opcijeLevoHeaderImage">
                <img
                  src={currentUserData?.profilnaURL}
                  alt={currentUserData?.ime}
                />
              </div>
              <div className="opcijeLevoHeaderTekst">
                <h3>{currentUserData?.ime}</h3>
              </div>
            </div>
            <div className="opcijeLevoBody">
              <div className="stavka">
                <h4>Email</h4>
                <p>{currentUserData?.email}</p>
              </div>
              <div className="stavka">
                <h4>Auto škola</h4>
                <p>{currentUserData?.autoskolaId}</p>
              </div>
              <div className="stavka">
                <h4>Uloga</h4>
                <p>Predavač</p>
              </div>
              <div className="stavka">
                <h4>ID</h4>
                <p>{currentUserData?.id}</p>
              </div>
            </div>
          </div>
          <button
            onClick={() => {
              navigate(`/profile/${currentUserData?.id}`);
            }}
          >
            Profil
          </button>
        </div>
      </div>
      {isNewPredavanje && (
        <NewPredavanjeOverlay
          setIsNewPredavanje={setIsNewPredavanje}
          dohvatiSkolu={dohvatiSkolu}
        />
      )}
    </>
  );
}
