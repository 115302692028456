import React, { useContext, useState } from "react";
import "../styles/newSTOverlay.scss";
import { Grupa, UserData, autoSkola } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { doc, setDoc, updateDoc, arrayUnion, getDoc } from "firebase/firestore";
import { grupeRef, korisniciRef, skoleRef } from "../utils/firebase";
import _ from "lodash";
import useKeyPress from "react-use-keypress";
import uuid from "react-uuid";

interface Props {
  korisnik: UserData;
  setPhoneChange: any;
  dohvatiKorisnika: any;
}

export function PhoneChangeOverlay(props: Props) {
  const { korisnik, setPhoneChange, dohvatiKorisnika } = props;
  const { setLoading, setAlert, setIsAlert } = useContext(AppContext);
  const [broj, setBroj] = useState<string>(korisnik.telefon as string);

  useKeyPress("Escape", () => {
    setPhoneChange(false);
  });
  useKeyPress("Enter", () => {
    promeniBroj(broj);
  });

  function promeniBroj(novoIme: string) {
    setLoading(true);
    if (korisnik === undefined || korisnik === null) return;

    if (broj === "" || broj === korisnik.ime) {
      setAlert("Unesite novi broj");
      setIsAlert(true);
      return;
    }
    updateDoc(doc(korisniciRef, korisnik.id), {
      telefon: broj,
    }).then(() => {
      const korisnikPodaci: UserData = {
        autoskolaId: korisnik.autoskolaId,
        email: korisnik.email,
        id: korisnik.id,
        ime: korisnik.ime,
        profilnaURL: korisnik.profilnaURL,
        uloga: korisnik.uloga,
        aktiviran: korisnik.aktiviran,
        brNocnih: korisnik.brNocnih,
        brOdvozanihCasova: korisnik.brOdvozanihCasova,
        datum: korisnik.datum,
        editovanInfoPrviPut: korisnik.editovanInfoPrviPut,
        grupa: korisnik.grupa,
        koJeInstruktorID: korisnik.koJeInstruktorID,
        grupaID: korisnik.grupaID,
        koJeInstruktorIme: korisnik.koJeInstruktorIme,
        krediti: korisnik.krediti,
        polozioTeoriju: korisnik.polozioTeoriju,
        telefon: broj,
        uploads: korisnik.uploads,
        voziAuto: korisnik.voziAuto,
        nizOdvozanihTermina: korisnik.nizOdvozanihTermina,
        nizStudenata: korisnik.nizStudenata,
        nizZakazanihTermina: korisnik.nizZakazanihTermina,
        prikazivanjeTermina: korisnik.prikazivanjeTermina,
        nedeljnaKvota: korisnik.nedeljnaKvota,
        subscribed: korisnik.subscribed,
      };
      //UPDATE GRUPE AKO POSTOJI
      if (
        korisnik.grupa !== null &&
        korisnik.grupa !== undefined &&
        korisnik.grupa !== "" &&
        korisnik.uloga === "student"
      ) {
        getDoc(doc(grupeRef, korisnik.grupaID)).then((grupaInfo) => {
          const grupaData = grupaInfo.data() as Grupa;
          const filtriraniNizStudenataUGrupi = _.filter(
            grupaData.studenti,
            function (o) {
              if (o.id !== korisnik.id) return o;
            }
          );

          const grupaDataObjekat: Grupa = {
            datum: grupaData.datum,
            id: grupaData.id,
            ime: grupaData.ime,
            kreirao: grupaData.kreirao,
            kreiraoID: grupaData.kreiraoID,
            studenti: [
              ...(filtriraniNizStudenataUGrupi as Array<UserData>),
              korisnikPodaci,
            ],
          };

          updateDoc(doc(grupeRef, korisnik.grupaID), {
            studenti: [...filtriraniNizStudenataUGrupi, korisnikPodaci],
          });
          getDoc(doc(skoleRef, korisnik.autoskolaId)).then((skola) => {
            const skolaData = skola.data() as autoSkola;
            if (skolaData === undefined || skolaData === null) return;
            const filterGrupaBezTrenutne = _.filter(
              skolaData.grupe,
              function (o) {
                if (o.id !== grupaDataObjekat.id) return o;
              }
            );
            updateDoc(doc(skoleRef, korisnik.autoskolaId), {
              grupe: [...filterGrupaBezTrenutne, grupaDataObjekat],
            });
          });
        });
      }
      // UPDATE INSTRUKTORA AKO GA IMA
      if (
        korisnik.koJeInstruktorID !== null &&
        korisnik.koJeInstruktorID !== undefined &&
        korisnik.koJeInstruktorID !== "" &&
        korisnik.uloga === "student"
      ) {
        getDoc(doc(korisniciRef, korisnik.koJeInstruktorID)).then(
          (instruktor) => {
            const instruktorData = instruktor.data() as UserData;
            const filtriraniNizStudenataKodInstruktora = _.filter(
              instruktorData.nizStudenata,
              function (o) {
                if (o.id !== korisnik.id) return o;
              }
            );
            const filterStudenataBezOvog: Array<UserData> =
              filtriraniNizStudenataKodInstruktora as Array<UserData>;
            const newInstruktorData: UserData = {
              aktiviran: instruktorData.aktiviran,
              autoskolaId: instruktorData.autoskolaId,
              brNocnih: instruktorData.brNocnih,
              brOdvozanihCasova: instruktorData.brOdvozanihCasova,
              datum: instruktorData.datum,
              editovanInfoPrviPut: instruktorData.editovanInfoPrviPut,
              email: instruktorData.email,
              grupa: instruktorData.grupa,
              grupaID: instruktorData.grupaID,
              id: instruktorData.id,
              ime: instruktorData.ime,
              koJeInstruktorID: instruktorData.koJeInstruktorID,
              koJeInstruktorIme: instruktorData.koJeInstruktorIme,
              krediti: instruktorData.krediti,
              nizOdvozanihTermina: instruktorData.nizOdvozanihTermina,
              nizStudenata: [...filterStudenataBezOvog, korisnikPodaci],
              nizZakazanihTermina: instruktorData.nizZakazanihTermina,
              polozioTeoriju: instruktorData.polozioTeoriju,
              prikazivanjeTermina: instruktorData.prikazivanjeTermina,
              profilnaURL: instruktorData.profilnaURL,
              telefon: instruktorData.telefon,
              uloga: instruktorData.uloga,
              uploads: instruktorData.uploads,
              voziAuto: instruktorData.voziAuto,
              nedeljnaKvota: instruktorData.nedeljnaKvota,
              subscribed: instruktorData.subscribed,
            };
            getDoc(doc(skoleRef, korisnik.autoskolaId)).then((skola) => {
              const skolaInfo = skola.data() as autoSkola;
              const filterInstruktora = _.filter(
                skolaInfo.instruktori,
                function (o) {
                  if (o.id !== instruktorData.id) return o;
                }
              );
              const nizInstruktora: Array<UserData> =
                filterInstruktora as Array<UserData>;

              updateDoc(doc(skoleRef, korisnik.autoskolaId), {
                instruktori: [...nizInstruktora, newInstruktorData],
              }).then(() => {
                updateDoc(doc(korisniciRef, instruktorData.id), {
                  nizStudenata: [...filterStudenataBezOvog, korisnikPodaci],
                });
              });
            });
          }
        );
      }

      getDoc(doc(skoleRef, korisnik.autoskolaId)).then((skola) => {
        const skolaData = skola.data();

        if (korisnik.uloga === "student") {
          const filtriraniNizStudenata = _.filter(
            skolaData?.studenti,
            function (o) {
              if (o.id !== korisnik.id) return o;
            }
          );

          updateDoc(doc(skoleRef, korisnik.autoskolaId), {
            studenti: [...filtriraniNizStudenata, korisnikPodaci],
          }).then(() => {
            setAlert("Uspešno promenjen broj");
            dohvatiKorisnika();
            setIsAlert(true);
            setLoading(false);
            setPhoneChange(false);
          });
        }
        if (korisnik.uloga === "schoolteacher") {
          const filtriraniNizPredavaca = _.filter(
            skolaData?.predavaci,
            function (o) {
              if (o.id !== korisnik.id) return o;
            }
          );

          updateDoc(doc(skoleRef, korisnik.autoskolaId), {
            predavaci: [...filtriraniNizPredavaca, korisnikPodaci],
          }).then(() => {
            setAlert("Uspešno promenjen broj");
            dohvatiKorisnika();
            setIsAlert(true);
            setLoading(false);
            setPhoneChange(false);
          });
        }
        if (korisnik.uloga === "schoolinstructor") {
          const filtriraniNizInstruktora = _.filter(
            skolaData?.instruktori,
            function (o) {
              if (o.id !== korisnik.id) return o;
            }
          );

          updateDoc(doc(skoleRef, korisnik.autoskolaId), {
            instruktori: [...filtriraniNizInstruktora, korisnikPodaci],
          }).then(() => {
            setAlert("Uspešno promenjen broj");
            dohvatiKorisnika();
            setIsAlert(true);
            setLoading(false);
            setPhoneChange(false);
          });
        }
      });
    });
  }

  return (
    <div className="newSTOverlay">
      <div className="newSTBox">
        <h2>Promena telefona</h2>
        <input
          type="text"
          name="novoIme"
          id="novoIme"
          placeholder={korisnik.telefon}
          onChange={(e) => setBroj(e.target.value)}
        />
        <div className="buttonOptions">
          <button onClick={() => promeniBroj(broj)}>Potvrdi</button>
          <button onClick={() => setPhoneChange(false)}>Odustani</button>
        </div>
        <button
          className="x"
          onClick={() => {
            setPhoneChange(false);
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}
