import React, { useEffect, useState } from "react";
import "../styles/newUserOverlay.scss";
import { UserData, UserRole, autoSkola } from "../utils/types";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { db, korisniciRef } from "../utils/firebase";

interface Props {
  autoSkole: Array<autoSkola>;
  setIsNewUser: any;
}

export function NewUserOverlay(props: Props) {
  const { setIsNewUser } = props;
  const [autoSkole, setAutoSkole] = useState<Array<autoSkola>>([]);
  const auth = getAuth();

  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<UserRole>("schooladmin");
  const [ime, setIme] = useState<string>("");
  const [skolaID, setSkolaID] = useState<string>("");

  const dohvatanjeSkola = async () => {
    const querySnapshot = await getDocs(collection(db, "autoSkole"));
    const skole = querySnapshot.docs.map((doc) => ({ ...doc.data() }));
    setAutoSkole(skole as Array<autoSkola>);
    console.log(skole);
  };
  useEffect(() => {
    dohvatanjeSkola();
  }, []);

  useEffect(() => {
    console.log(skolaID);
  }, [skolaID]);

  const dodajNovogKorisnika = async () => {
    createUserWithEmailAndPassword(auth, email, "123456")
      .then(async (userCredential) => {
        const user = userCredential.user;
        const userData: UserData = {
          email: email,
          id: user.uid,
          uloga: role,
          autoskolaId: skolaID,
          ime: ime,
          profilnaURL: "",
          aktiviran: true,
          brNocnih: 0,
          brOdvozanihCasova: 0,
          datum: new Date().toString(),
          editovanInfoPrviPut: false,
          grupa: "",
          grupaID: "",
          koJeInstruktorID: "",
          koJeInstruktorIme: "",
          krediti: 0,
          nizOdvozanihTermina: [],
          nizStudenata: [],
          nizZakazanihTermina: [],
          polozioTeoriju: false,
          telefon: "",
          uploads: [],
          voziAuto: "",
          prikazivanjeTermina: "Default",
          nedeljnaKvota: 3,
          subscribed: false,
        };
        console.log("KREIRANJE", korisniciRef, user, userData);
        await setDoc(doc(korisniciRef, user.uid), userData).then(() => {
          setEmail("");
          setIme("");
          setIsNewUser(false);
        });
      })
      .catch((error) => {
        alert(error);
      });
  };
  return (
    <div className="newUserOverlay">
      <div className="newUserBox">
        <h2>Dodajte novog korisnika</h2>
        <input
          type="email"
          name="emailKorisnika"
          id="emailKorisnika"
          placeholder="Email korisnika"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="text"
          name="imeKorisnika"
          id="imeKorisnika"
          onChange={(e) => setIme(e.target.value)}
          placeholder="Ime korisnika"
        />
        <select
          name="izborSkole"
          id="izborSkole"
          placeholder="Izaberite školu"
          onChange={(e) => setSkolaID(e.target.value)}
          // defaultValue="Izaberite školu"
        >
          <option disabled selected>
            Izaberite auto skolu
          </option>
          {autoSkole.map((skola) => {
            return <option value={skola.ime}>{skola.ime}</option>;
          })}
        </select>
        <select
          name="roleChoice"
          id="rolechoice"
          onChange={(e) => setRole(e.target.value as UserRole)}
        >
          <option disabled selected>
            Izaberite ulogu
          </option>
          <option value="schooladmin">schoolAdmin</option>
          <option value="schoolteacher">schoolTeacher</option>
          <option value="schoolinstructor">schoolInstructor</option>
          <option value="student">Kandidat</option>
        </select>
        <div className="buttonOptions">
          <button onClick={() => dodajNovogKorisnika()}>Potvrdi</button>
          <button onClick={() => setIsNewUser(false)}>Odustani</button>
        </div>
      </div>
    </div>
  );
}
