import React, { useContext, useEffect, useState } from "react";
import "../styles/home.scss";
import {
  BsFillCalendarDateFill,
  BsPersonAdd,
  BsSortNumericDownAlt,
  BsSortNumericUpAlt,
} from "react-icons/bs";
import {
  AiFillEdit,
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { BiSortDown } from "react-icons/bi";
import { UserData } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { doc, getDoc } from "firebase/firestore";
import { skoleRef } from "../utils/firebase";
import { MdArrowBack, MdAttachMoney } from "react-icons/md";
import _ from "lodash";
import { CgProfile } from "react-icons/cg";
import { NewSIOverlay } from "../components/NewSIOverlay";
import { useNavigate } from "react-router";
import { IconContext } from "react-icons/lib";

interface Props {}

export function Instruktori(props: Props) {
  const [nizInstruktora, setNizInstruktora] = useState<Array<UserData>>([]);
  const [sekundarniNiz, setSekundarniNiz] = useState<Array<UserData>>([]);
  const [isNewSI, setIsNewSI] = useState<boolean>(false);
  const { currentUserData, setLoading } = useContext(AppContext);
  const [trenutniSort, setTrenutniSort] = useState<
    "imeAZ" | "imeZA" | "datumAsc" | "datumDesc"
  >();
  const navigate = useNavigate();
  useEffect(() => {
    dohvatiInstruktore();
  }, [currentUserData]);
  function dohvatiInstruktore() {
    if (
      currentUserData === null ||
      currentUserData === undefined ||
      currentUserData.autoskolaId === null ||
      currentUserData.autoskolaId === undefined ||
      currentUserData.autoskolaId === "" ||
      skoleRef === null ||
      skoleRef === undefined
    )
      return;
    setLoading(true);
    console.log("SKOLA 1", currentUserData);
    getDoc(doc(skoleRef, currentUserData.autoskolaId)).then((skola) => {
      console.log("SKOLA", skola?.data()?.instruktori);
      const nizNovih = skola?.data()?.noviInstruktori;
      const nizPostojecih = skola?.data()?.instruktori;
      if (nizNovih !== undefined && nizNovih !== null) {
        const spojeniNiz = _.concat(nizNovih, nizPostojecih);
        setNizInstruktora(spojeniNiz);
        setSekundarniNiz(spojeniNiz);
        setLoading(false);
      } else {
        setNizInstruktora(nizPostojecih);
        setSekundarniNiz(nizPostojecih);
        setLoading(false);
      }
    });
  }
  function search(q: string) {
    const duzina = q.length;
    const niz = nizInstruktora;
    if (q === "") {
      setSekundarniNiz(nizInstruktora);
      return;
    }
    const filtriranNiz = _.filter(niz, function (o) {
      const ime = o.ime;
      console.log(
        "FILTER PRVI",
        ime.toLowerCase().substring(0, duzina),
        q.toLowerCase().substring(0, duzina)
      );
      if (
        ime.toLowerCase().substring(0, duzina) ===
          q.toLowerCase().substring(0, duzina) ||
        ime.toLowerCase().split(" ")[1].substring(0, duzina) ===
          q.toLowerCase().substring(0, duzina)
      )
        return o;
    });
    console.log("FILTER", filtriranNiz);
    setSekundarniNiz(filtriranNiz as Array<UserData>);
  }
  function sortIme() {
    const niz = nizInstruktora;
    const sortiraniNiz = _.orderBy(niz, ["ime"], ["asc"]);
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("imeAZ");
  }
  function sortImeDesc() {
    const niz = nizInstruktora;
    const sortiraniNiz = _.orderBy(niz, ["ime"], ["desc"]);
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("imeZA");
  }
  function sortDateAsc() {
    const niz = nizInstruktora;
    const sortiraniNiz = niz.sort((a, b) => {
      const dateA = new Date(a.datum as string);
      const dateB = new Date(b.datum as string);
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    });
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("datumAsc");
  }
  function sortDateDesc() {
    const niz = nizInstruktora;
    const sortiraniNiz = [...niz].sort((a, b) => {
      const dateA = new Date(a.datum as string);
      const dateB = new Date(b.datum as string);

      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      return 0;
    });
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("datumDesc");
  }
  return (
    <>
      <div className="welcome">
        <div className="welcomeTitle">
          <IconContext.Provider value={{ className: "backIkonica" }}>
            <div className="ikonicaOmotac" onClick={() => navigate(-1)}>
              <MdArrowBack />
            </div>
          </IconContext.Provider>
          <h2>Instruktori ({nizInstruktora.length})</h2>
        </div>
        <input
          type="text"
          name="pretragaStudenata"
          id="pretragaStudenata"
          placeholder="Pretražite"
          onChange={(e) => search(e.target.value)}
        />
      </div>
      <div className="optionsShortcuts">
        <div className="shortcut" onClick={() => setIsNewSI(true)}>
          <div className="ikonicaWrap">
            <BsPersonAdd color="white" size={50} />
          </div>
          <h3>Novi instruktor</h3>
        </div>
        <div className="shortcut" onClick={() => sortIme()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "imeAZ" ? "correct" : ""
            }`}
          >
            <AiOutlineSortAscending color="white" size={50} />
          </div>
          <h3>Ime A-Z</h3>
        </div>
        <div className="shortcut" onClick={() => sortImeDesc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "imeZA" ? "correct" : ""
            }`}
          >
            <AiOutlineSortDescending color="white" size={50} />
          </div>
          <h3>Ime Z-A</h3>
        </div>
        <div className="shortcut" onClick={() => sortDateDesc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "datumDesc" ? "correct" : ""
            }`}
          >
            <BsSortNumericDownAlt color="white" size={50} />
          </div>
          <h3>Najnoviji - Najstariji</h3>
        </div>
        <div className="shortcut" onClick={() => sortDateAsc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "datumAsc" ? "correct" : ""
            }`}
          >
            <BsSortNumericUpAlt color="white" size={50} />
          </div>
          <h3>Najstariji - Najnoviji</h3>
        </div>
      </div>
      <div className="list">
        {sekundarniNiz.map((instruktor) => {
          return (
            <div className="listPiece">
              <div className="pieceInfo">
                <img src={instruktor?.profilnaURL} alt={instruktor?.ime} />
                <div className="pieceInfoText">
                  <h3>{instruktor?.ime}</h3>
                  {instruktor?.aktiviran === false && (
                    <h4>Nalog nije aktiviran</h4>
                  )}
                  {instruktor?.aktiviran !== false && (
                    <p>Telefon: {instruktor?.telefon}</p>
                  )}
                  <p>
                    {instruktor?.aktiviran !== false && "Auto: "}
                    {instruktor?.aktiviran !== false && instruktor?.voziAuto}
                  </p>
                </div>
              </div>
              <div className="pieceOptions">
                {instruktor.aktiviran === true && (
                  <div
                    className="pieceOption"
                    onClick={() => {
                      navigate(`/kalendar/${instruktor.id}`);
                    }}
                  >
                    <div className="pieceOptionIcon">
                      <BsFillCalendarDateFill color="white" size={40} />
                    </div>
                    <p>Kalendar</p>
                  </div>
                )}
                {instruktor.aktiviran === true && (
                  <div
                    className="pieceOption"
                    onClick={() => navigate(`/profile/${instruktor.id}`)}
                  >
                    <div className="pieceOptionIcon">
                      <CgProfile color="white" size={50} />
                    </div>
                    <p>Pogledaj profil</p>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {isNewSI && (
        <NewSIOverlay
          setIsNewSI={setIsNewSI}
          dohvatiInstruktore={dohvatiInstruktore}
        />
      )}
    </>
  );
}
