import React, { useContext, useEffect, useState } from "react";
import "../styles/home.scss";
import { InteraktivniKviz, InteraktivniOdgovor } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { arrayUnion, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { interaktivniRef } from "../utils/firebase";
import _ from "lodash";
import { CgClose } from "react-icons/cg";
import { useNavigate, useParams } from "react-router";
import Lottie from "react-lottie";
import loadingCar from "../loadingCar.json";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { GiCheckMark } from "react-icons/gi";

interface Props {}

export function InteraktivniKvizStudent(props: Props) {
  const { currentUserData, setLoading, setIsAlert, setAlert } =
    useContext(AppContext);
  const [isAnswered, setIsAnswered] = useState<boolean>(false);
  const [result, setResult] = useState<string>("");
  const [preostaloVreme, setPreostaloVreme] = useState<number>(0);
  const [dateObjekatPocetkaStarted, setDateObjekat] = useState<Date>();
  const [stotinke, setStotinke] = useState<number>(100);
  const { ikID, testID } = useParams();
  const [interaktivniKviz, setInteraktivniKviz] = useState<InteraktivniKviz>();
  const date = new Date();
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingCar,
  };
  useEffect(() => {
    if (currentUserData !== null && currentUserData !== undefined) {
      slusajPromene();
    }
  }, [currentUserData]);
  useEffect(() => {
    if (interaktivniKviz?.status === "prestart") {
      setIsAnswered(false);
    }
    if (interaktivniKviz?.status === "quit") {
      navigate(`/home`);
      setAlert("Kviz je ugašen.");
      setIsAlert(true);
    }
    if (interaktivniKviz?.status === "started") {
      const datumPocetka = new Date();
      setDateObjekat(datumPocetka);
    }
  }, [interaktivniKviz?.status]);

  //   useEffect(() => {
  //     console.log("PREOSTALO VREME", preostaloVreme);
  //     if (preostaloVreme === preostaloVremeDubl) {
  //       setStotinke(stotinke + 1);
  //     } else {
  //       setStotinke(1);
  //     }
  //     setPreostaloVremeDubl(preostaloVreme);
  //   }, [preostaloVreme]);
  useEffect(() => {
    console.log("STOTINKE", stotinke);
  }, [stotinke]);

  function slusajPromene() {
    const unsubscribe = onSnapshot(doc(interaktivniRef, ikID), (snap) => {
      if (!snap.exists) {
        alert("Greska u programu.");
        return;
      }
      const trenutniPodaci = snap.data();
      if (trenutniPodaci === undefined) return;
      setInteraktivniKviz(trenutniPodaci as InteraktivniKviz);
    });
    return () => unsubscribe();
  }

  function answer(ans: string) {
    if (
      interaktivniKviz === undefined ||
      interaktivniKviz === null ||
      currentUserData === null ||
      currentUserData === undefined ||
      dateObjekatPocetkaStarted === undefined
    )
      return;
    const datumOdgovora = new Date();
    let razlika =
      1000 -
      Math.abs(dateObjekatPocetkaStarted.getTime() - datumOdgovora.getTime()) /
        15;
    console.log(
      "RAZLIKA OD POCETKA",
      1020.4 -
        Math.abs(
          dateObjekatPocetkaStarted.getTime() - datumOdgovora.getTime()
        ) /
          15,
      Math.round(
        1000 -
          Math.abs(
            dateObjekatPocetkaStarted.getTime() - datumOdgovora.getTime()
          ) /
            15
      )
    );
    if (
      ans ===
      interaktivniKviz.test.nizPitanja[interaktivniKviz.trenutnoPitanje]
        .tacanOdgovor
    ) {
      setResult("Vaš odgovor je tačan!");
      if (razlika > 977.4) {
        razlika = 1000;
      } else if (razlika <= 977.4 && razlika > 100) {
        razlika = Math.round(razlika);
      } else if (razlika < 100) {
        razlika = 100;
      }
      setIsAnswered(true);
    } else {
      setResult("Vaš odgovor je netačan!");
      setIsAnswered(true);
      razlika = 0;
    }
    const odgovorData: InteraktivniOdgovor = {
      idStudenta: currentUserData.id,
      imeStudenta: currentUserData.ime,
      odgovor: ans,
      ocena: razlika,
      rbPitanja: interaktivniKviz.trenutnoPitanje,
    };
    if (ans === "") {
      updateDoc(doc(interaktivniRef, interaktivniKviz.id), {
        ukupnoNeodgovorenih: interaktivniKviz.ukupnoNeodgovorenih + 1,
        nizOdgovora: arrayUnion({
          idStudenta: currentUserData.id,
          imeStudenta: currentUserData.ime,
          odgovor: "",
          ocena: razlika,
        }),
      });
    }
    if (ans === "a") {
      updateDoc(doc(interaktivniRef, interaktivniKviz.id), {
        a: interaktivniKviz.a + 1,
        ukupnoOdgovora: interaktivniKviz.ukupnoOdgovora + 1,
        nizOdgovora: arrayUnion(odgovorData),
      });
    }
    if (ans === "b") {
      updateDoc(doc(interaktivniRef, interaktivniKviz.id), {
        b: interaktivniKviz.b + 1,
        ukupnoOdgovora: interaktivniKviz.ukupnoOdgovora + 1,
        nizOdgovora: arrayUnion(odgovorData),
      });
    }
    if (ans === "c") {
      updateDoc(doc(interaktivniRef, interaktivniKviz.id), {
        c: interaktivniKviz.c + 1,
        ukupnoOdgovora: interaktivniKviz.ukupnoOdgovora + 1,
        nizOdgovora: arrayUnion(odgovorData),
      });
    }
    if (ans === "d") {
      updateDoc(doc(interaktivniRef, interaktivniKviz.id), {
        d: interaktivniKviz.d + 1,
        ukupnoOdgovora: interaktivniKviz.ukupnoOdgovora + 1,
        nizOdgovora: arrayUnion(odgovorData),
      });
    }
  }

  return (
    <>
      {/* W E L C O M E  S T A R T */}
      {interaktivniKviz?.status === "waiting" && (
        <div className="welcome">
          <h2>Čekaonica</h2>
        </div>
      )}
      {interaktivniKviz?.status === "started" && (
        <div className="welcome">
          <h2>Pitanje {interaktivniKviz.trenutnoPitanje + 1}</h2>
        </div>
      )}
      {/* W E L C O M E  E N D */}

      {/* L I S T   S T A R T */}
      {interaktivniKviz?.status === "waiting" && (
        <div className="resultBox">
          <Lottie height={300} width={300} options={defaultOptions} />
          <h4
            style={{
              color: "white",
              fontSize: 30,
              margin: "0px",
            }}
          >
            Sačekajte početak kviza...
          </h4>
        </div>
      )}

      {interaktivniKviz?.status === "prestart" && (
        <div className="resultBox">
          <Lottie height={300} width={300} options={defaultOptions} />
          <h4
            style={{
              color: "white",
              fontSize: 30,
              margin: "0px",
            }}
          >
            Pročitajte pitanje na projekciji...
          </h4>
        </div>
      )}

      {interaktivniKviz?.status === "started" && isAnswered === false && (
        <div className="answersBox">
          <div className="answersBoxRow">
            <div
              className="answersBoxColumn"
              onClick={() => {
                answer("a");
              }}
            >
              <h1>A</h1>
            </div>
            <div
              className="answersBoxColumn"
              onClick={() => {
                answer("b");
              }}
            >
              <h1>B</h1>
            </div>
          </div>
          <div className="answersBoxRow">
            <div
              className="answersBoxColumn"
              onClick={() => {
                answer("c");
              }}
            >
              <h1>C</h1>
            </div>
            <div
              className="answersBoxColumn"
              onClick={() => {
                answer("d");
              }}
            >
              <h1>D</h1>
            </div>
          </div>
          <div className="timer">
            <CountdownCircleTimer
              isPlaying
              duration={15}
              colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
              colorsTime={[7, 5, 2, 0]}
              size={150}
              onComplete={() => {
                answer("");
                return {
                  //   shouldRepeat: true,
                };
              }}
            >
              {({ remainingTime }) => {
                // console.log(remainingTime);
                setPreostaloVreme(remainingTime);
                if (remainingTime === preostaloVreme) {
                  setStotinke(stotinke - 1);
                } else {
                  setStotinke(100);
                }
                return remainingTime;
              }}
            </CountdownCircleTimer>
          </div>
        </div>
      )}
      {interaktivniKviz?.status === "started" && isAnswered === true && (
        // <div className="resultBox">
        //   {result === "Vaš odgovor je tačan!" && (
        //     <GiCheckMark color="green" size={100} />
        //   )}
        //   {result === "Vaš odgovor je tačan!" && <h1>Vaš odgovor je tačan!</h1>}
        //   {result === "Vaš odgovor je netačan!" && (
        //     <CgClose color="red" size={100} />
        //   )}
        //   {result === "Vaš odgovor je netačan!" && (
        //     <h1>Vaš odgovor je netačan!</h1>
        //   )}
        // </div>
        <div className="resultBox">
          <Lottie height={300} width={300} options={defaultOptions} />
          <h4
            style={{
              color: "white",
              fontSize: 30,
              margin: "0px",
            }}
          >
            Sačekajte da svi odgovore...
          </h4>
        </div>
      )}
      {interaktivniKviz?.status === "inbetween" && (
        <div className="resultBox">
          {result === "Vaš odgovor je tačan!" && (
            <GiCheckMark color="green" size={100} />
          )}
          {result === "Vaš odgovor je tačan!" && <h1>Vaš odgovor je tačan!</h1>}
          {result === "Vaš odgovor je netačan!" && (
            <CgClose color="red" size={100} />
          )}
          {result === "Vaš odgovor je netačan!" && (
            <h1>Vaš odgovor je netačan!</h1>
          )}
        </div>
      )}
      {interaktivniKviz?.status === "results" && (
        <div className="resultBox">
          <h1>
            {`Vaš ukupan rezultat je: 
            ${
              _.find(interaktivniKviz.nizRezultata, {
                idStudenta: currentUserData?.id,
              })?.ukupanRezultat
            }
            poena`}
          </h1>
        </div>
      )}

      {/* L I S T  E N D */}
    </>
  );
}
