import React, { useContext, useEffect, useState } from "react";
import "../styles/newSTOverlay.scss";
import { useNavigate } from "react-router";
import useKeyPress from "react-use-keypress";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AppContext } from "../functions/AppProvider";

interface Props {
  setIsResult: any;
  brojTacnih: number;
  brojPitanja: number;
  nizOdgovora: Array<string>;
  testID: string;
}

export function TestResultOverlay(props: Props) {
  const { setIsResult, brojPitanja, brojTacnih, nizOdgovora, testID } = props;
  const { currentUserData } = useContext(AppContext);
  const [procenat, setProcenat] = useState<number>(0);
  const [x, setX] = useState<number>(0);
  const [stringOdgovora, setStringOdgovora] = useState<string>("");
  const navigate = useNavigate();

  useKeyPress("Escape", () => {
    setIsResult(false);
  });

  useEffect(() => {
    if (
      brojPitanja !== undefined &&
      brojPitanja !== null &&
      brojTacnih !== undefined &&
      brojTacnih !== null
    ) {
      setProcenat((brojTacnih / brojPitanja) * 100);
      console.log(nizOdgovora);
    }
  }, [brojPitanja, brojTacnih]);

  function povecajBroj() {
    setX((prevNumber) => Math.min(prevNumber + 1, procenat));
  }
  useEffect(() => {
    if (x < procenat) {
      const timer = setTimeout(povecajBroj, 1000 / procenat);
      return () => clearTimeout(timer);
    }
  }, [x, procenat]);

  function prikaziResenja() {
    if (nizOdgovora !== undefined && nizOdgovora !== null) {
      let y = "";
      for (let i = 0; i < nizOdgovora.length; i++) {
        if (i === 0) {
          y = y.concat(`${nizOdgovora[i]}`);
        } else {
          y = y.concat(`-${nizOdgovora[i]}`);
        }
      }
      console.log(y);
      navigate(`/test/${testID}/${y}`);
    }
  }
  return (
    <div className="newSTOverlay">
      <div className="newSTBox">
        <h2>Rezultati testa</h2>
        <CircularProgressbar
          value={x}
          text={`${x.toFixed(2)}%`}
          styles={buildStyles({
            pathColor: "#2664eb",
            textColor: "#2664eb",
          })}
        />
        ;
        <div className="buttonOptions">
          <button
            onClick={() => {
              // navigate(`/test/resenja/`);
              prikaziResenja();
            }}
          >
            Prikaži rešenja
          </button>
          <button
            onClick={() => {
              setIsResult(false);
              navigate(`/testovi/${currentUserData?.autoskolaId}`);
            }}
          >
            Zatvori
          </button>
        </div>
        <button
          className="x"
          onClick={() => {
            setIsResult(false);
            navigate(`/testovi/${currentUserData?.autoskolaId}`);
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}
