import React, { useContext, useEffect, useState } from "react";
import "../styles/home.scss";
import {
  BsClockHistory,
  BsFillCloudDownloadFill,
  BsFillTrashFill,
  BsPersonAdd,
  BsSortNumericDownAlt,
  BsSortNumericUpAlt,
  BsTrashFill,
} from "react-icons/bs";
import {
  AiFillEdit,
  AiFillEye,
  AiFillFilePpt,
  AiFillPlayCircle,
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { Lekcija, Prezentacija, Test } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { lekcijeRef, skoleRef } from "../utils/firebase";
import { MdArrowBack, MdQuiz } from "react-icons/md";
import _ from "lodash";
import { NewLessonOverlay } from "../components/NewLessonOverlay";
import { AddPresentationToLection } from "../components/AddPresentationToLection";
import { AddTestToLection } from "../components/AddTestToLection";
import { useNavigate, useParams } from "react-router";
import { IconContext } from "react-icons/lib";

interface Props {}

export function LekcijaPage(props: Props) {
  const { lekcijaID } = useParams();
  const { currentUserData, setLoading, setAlert, setIsAlert } =
    useContext(AppContext);
  const [nizLekcija, setNizLekcija] = useState<Array<Lekcija>>([]);
  const [lekcija, setLekcija] = useState<Lekcija>();
  const [prikazujemo, setPrikazujemo] = useState<"testovi" | "prezentacije">(
    "prezentacije"
  );
  const [nizPrezentacija, setNizPrezentacija] = useState<Array<Prezentacija>>(
    []
  );
  const [sekundarniNizPrezentacija, setSekundarniNizPrezentacija] = useState<
    Array<Prezentacija>
  >([]);
  const [nizTestova, setNizTestova] = useState<Array<Test>>([]);
  const [sekundarniNizTestova, setSekundarniNizTestova] = useState<Array<Test>>(
    []
  );
  const [trenutniSort, setTrenutniSort] = useState<"imeAsc" | "imeDesc">();
  const [dohvatili, setDohvatili] = useState<boolean>(false);
  const [isAPTL, setIsAPTL] = useState<boolean>(false);
  const [isATTL, setIsATTL] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    dohvatiLekciju();
  }, [currentUserData, lekcijaID]);

  function dohvatiLekciju() {
    console.log("Dohvatanje lekcija", lekcijaID);
    if (
      lekcijaID === null ||
      lekcijaID === undefined ||
      currentUserData === null ||
      currentUserData === undefined
    )
      return;
    getDoc(doc(lekcijeRef, lekcijaID))
      .then((lekcija) => {
        setLekcija(lekcija.data() as Lekcija);
        setNizPrezentacija(
          lekcija?.data()?.prezentacije as Array<Prezentacija>
        );
        setSekundarniNizPrezentacija(
          lekcija?.data()?.prezentacije as Array<Prezentacija>
        );
        setNizTestova(lekcija?.data()?.testovi as Array<Test>);
        setSekundarniNizTestova(lekcija?.data()?.testovi as Array<Test>);
        console.log("Postavljeno", lekcija.data());
      })
      .then(() => {
        getDoc(doc(skoleRef, currentUserData?.autoskolaId))
          .then((skola) => {
            setNizLekcija(skola?.data()?.lekcije as Array<Lekcija>);
          })
          .then(() => {
            setDohvatili(true);
          });
      });
  }

  useEffect(() => {
    if (dohvatili === true) {
      if (
        prikazujemo === "prezentacije" &&
        sekundarniNizPrezentacija.length !== 0
      ) {
        sortIme();
        setTrenutniSort("imeAsc");
      }
      if (prikazujemo === "testovi" && sekundarniNizTestova.length !== 0) {
        sortIme();
        setTrenutniSort("imeAsc");
      }
    }
  }, [dohvatili]);

  function sortIme() {
    const niz = prikazujemo === "prezentacije" ? nizPrezentacija : nizTestova;
    const sortiraniNiz = _.orderBy(niz, ["ime"], ["asc"]);
    if (prikazujemo === "prezentacije") {
      setSekundarniNizPrezentacija(sortiraniNiz as Array<Prezentacija>);
      setTrenutniSort("imeAsc");
    } else {
      setSekundarniNizTestova(sortiraniNiz as Array<Test>);
      setTrenutniSort("imeAsc");
    }
  }
  function sortImeDesc() {
    const niz = prikazujemo === "prezentacije" ? nizPrezentacija : nizTestova;
    const sortiraniNiz = _.orderBy(niz, ["ime"], ["desc"]);
    console.log(sekundarniNizPrezentacija);
    if (prikazujemo === "prezentacije") {
      setSekundarniNizPrezentacija(sortiraniNiz as Array<Prezentacija>);
      setTrenutniSort("imeDesc");
    } else {
      setSekundarniNizTestova(sortiraniNiz as Array<Test>);
      setTrenutniSort("imeDesc");
    }
  }

  function download(url: string, ime: string) {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = (event) => {
      const blob = xhr.response;
      const uri = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = uri;
      a.download = ime;
      a.click();
      window.URL.revokeObjectURL(uri);
    };
    xhr.open("GET", url);
    xhr.send();
  }

  function deleteTest(kojiTest: Test) {
    setLoading(true);
    if (
      lekcija === undefined ||
      lekcija === null ||
      currentUserData === null ||
      currentUserData === undefined
    ) {
      setLoading(false);
      return;
    }
    const filtriraniNiz = _.filter(nizTestova, function (o) {
      if (o.id !== kojiTest.id) return o;
    });
    const filtriraniNizLekcija = _.filter(nizLekcija, function (o) {
      if (o.id !== lekcija.id) return o;
    });
    const lekcijaData: Lekcija = {
      datum: lekcija.datum,
      id: lekcija.id,
      ime: lekcija.ime,
      kreirao: lekcija.kreirao,
      kreiraoID: lekcija.kreiraoID,
      prezentacije: lekcija.prezentacije,
      testovi: filtriraniNiz as Array<Test>,
    };
    updateDoc(doc(skoleRef, currentUserData?.autoskolaId), {
      lekcije: [...filtriraniNizLekcija, lekcijaData],
    }).then(() => {
      updateDoc(doc(lekcijeRef, lekcija.id), {
        testovi: filtriraniNiz,
      }).then(() => {
        dohvatiLekciju();
        setLoading(false);
        setAlert(`Uspešno obrisan test: ${kojiTest.ime}`);
        setIsAlert(true);
      });
    });
  }
  function deletePrez(kojaPrez: Prezentacija) {
    setLoading(true);
    if (
      lekcija === undefined ||
      lekcija === null ||
      currentUserData === null ||
      currentUserData === undefined
    ) {
      setLoading(false);
      return;
    }
    const filtriraniNiz = _.filter(nizPrezentacija, function (o) {
      if (o.id !== kojaPrez.id) return o;
    });
    const filtriraniNizLekcija = _.filter(nizLekcija, function (o) {
      if (o.id !== lekcija.id) return o;
    });
    const lekcijaData: Lekcija = {
      datum: lekcija.datum,
      id: lekcija.id,
      ime: lekcija.ime,
      kreirao: lekcija.kreirao,
      kreiraoID: lekcija.kreiraoID,
      testovi: lekcija.testovi,
      prezentacije: filtriraniNiz as Array<Prezentacija>,
    };
    updateDoc(doc(skoleRef, currentUserData.autoskolaId), {
      lekcije: [...filtriraniNizLekcija, lekcijaData],
    }).then(() => {
      updateDoc(doc(lekcijeRef, lekcija.id), {
        prezentacije: filtriraniNiz,
      }).then(() => {
        dohvatiLekciju();
        setLoading(false);
        setAlert(`Uspešno obrisana prezentacija: ${kojaPrez.ime}`);
        setIsAlert(true);
      });
    });
  }

  return (
    <>
      <div className="welcome">
        <div className="welcomeTitle">
          <IconContext.Provider value={{ className: "backIkonica" }}>
            <div className="ikonicaOmotac" onClick={() => navigate(-1)}>
              <MdArrowBack />
            </div>
          </IconContext.Provider>
          <h2>Lekcija - {lekcija?.ime}</h2>
        </div>
        <input
          type="text"
          name="pretraga"
          id="pretraga"
          placeholder="Pretražite"
        />
      </div>
      <div className="optionsShortcuts">
        {currentUserData?.uloga !== "student" && (
          <div className="shortcut" onClick={() => setIsAPTL(true)}>
            <div className="ikonicaWrap">
              <AiFillFilePpt color="white" size={50} />
            </div>
            <h3>Dodaj prez.</h3>
          </div>
        )}
        {currentUserData?.uloga !== "student" && (
          <div className="shortcut" onClick={() => setIsATTL(true)}>
            <div className="ikonicaWrap">
              <MdQuiz color="white" size={50} />
            </div>
            <h3>Dodaj test</h3>
          </div>
        )}
        <div
          className="shortcut"
          onClick={() => setPrikazujemo("prezentacije")}
        >
          <div
            className={`ikonicaWrap ${
              prikazujemo === "prezentacije" ? "correct" : ""
            }`}
          >
            <AiFillFilePpt color="white" size={50} />
          </div>
          <h3>Prezentacije</h3>
        </div>
        <div className="shortcut" onClick={() => setPrikazujemo("testovi")}>
          <div
            className={`ikonicaWrap ${
              prikazujemo === "testovi" ? "correct" : ""
            }`}
          >
            <MdQuiz color="white" size={50} />
          </div>
          <h3>Testovi</h3>
        </div>
        <div className="shortcut" onClick={() => sortIme()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "imeAsc" ? "correct" : ""
            }`}
          >
            <AiOutlineSortAscending color="white" size={50} />
          </div>
          <h3>Ime A-Z</h3>
        </div>
        <div className="shortcut" onClick={() => sortImeDesc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "imeDesc" ? "correct" : ""
            }`}
          >
            <AiOutlineSortDescending color="white" size={50} />
          </div>
          <h3>Ime Z-A</h3>
        </div>
      </div>

      {prikazujemo === "testovi" && (
        <div className="list">
          {sekundarniNizTestova !== null &&
            sekundarniNizTestova !== undefined &&
            sekundarniNizTestova.map((test) => {
              if (test.pp === "Privatno") return;
              return (
                <div className="listPiece">
                  <div className="pieceInfo">
                    <div className="pieceInfoText">
                      <h3>{test.ime}</h3>
                      <p>{`Opis: ${test.opis}`}</p>
                      <p>{`Datum: ${new Date(test.datum).getDate()}.${
                        new Date(test.datum).getMonth() + 1
                      }.${new Date(test.datum).getFullYear()}`}</p>
                      <p>{`Autor: ${test.kreiraoIme}`}</p>
                    </div>
                  </div>
                  <div className="pieceOptions">
                    {currentUserData?.uloga !== "student" && (
                      <div
                        className="pieceOption"
                        onClick={() => {
                          navigate(`/interaktivniKvizAdmin/${test.id}`);
                        }}
                      >
                        <div className="pieceOptionIcon">
                          <BsClockHistory color="white" size={40} />
                        </div>
                        <p>Pokreni interaktivno</p>
                      </div>
                    )}
                    <div
                      className="pieceOption"
                      onClick={() => {
                        navigate(`/test/${test.id}`);
                      }}
                    >
                      <div className="pieceOptionIcon">
                        <AiFillPlayCircle color="white" size={40} />
                      </div>
                      <p>Započni</p>
                    </div>
                    {currentUserData?.uloga !== "student" && (
                      <div
                        className="pieceOption"
                        onClick={() => {
                          navigate(`/noviTest/${test.ime}/true/${test.id}`);
                        }}
                      >
                        <div className="pieceOptionIcon">
                          <AiFillEdit color="white" size={40} />
                        </div>
                        <p>Izmeni</p>
                      </div>
                    )}
                    {currentUserData?.uloga !== "student" && (
                      <div
                        className="pieceOption"
                        onClick={() => deleteTest(test)}
                      >
                        <div className="pieceOptionIcon">
                          <BsFillTrashFill color="white" size={40} />
                        </div>
                        <p>Obriši</p>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          {sekundarniNizTestova.length === 0 && (
            <div className="list">
              <h2>Nema testova za ovu lekciju</h2>
            </div>
          )}
        </div>
      )}
      {prikazujemo === "prezentacije" && (
        <div className="list">
          {sekundarniNizPrezentacija !== null &&
            sekundarniNizPrezentacija !== undefined &&
            sekundarniNizPrezentacija.map((prezentacija) => {
              return (
                <div className="listPiece">
                  <div className="pieceInfo">
                    <div className="pieceInfoText">
                      <h3>{prezentacija.ime}</h3>
                      <p>{`Datum otpremanja: ${new Date(
                        prezentacija.datum
                      ).getDate()}.${
                        new Date(prezentacija.datum).getMonth() + 1
                      }.${new Date(prezentacija.datum).getFullYear()}`}</p>
                      <p>{`Korisnik: ${prezentacija.korisnik}`}</p>
                    </div>
                  </div>
                  <div className="pieceOptions">
                    <div
                      className="pieceOption"
                      onClick={() =>
                        download(prezentacija.url, prezentacija.ime)
                      }
                    >
                      <div className="pieceOptionIcon">
                        <BsFillCloudDownloadFill color="white" size={40} />
                      </div>
                      <p>Preuzmi</p>
                    </div>
                    <div
                      className="pieceOption"
                      onClick={() =>
                        navigate(`/prezentacija/${prezentacija.id}`)
                      }
                    >
                      <div className="pieceOptionIcon">
                        <AiFillEye color="white" size={50} />
                      </div>
                      <p>Pogledaj</p>
                    </div>
                    {currentUserData?.uloga !== "student" && (
                      <div
                        className="pieceOption"
                        onClick={() => {
                          deletePrez(prezentacija);
                        }}
                      >
                        <div className="pieceOptionIcon">
                          <BsTrashFill color="white" size={50} />
                        </div>
                        <p>Obriši</p>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          {sekundarniNizPrezentacija.length === 0 && (
            <div className="list">
              <h2>Nema prezentacija za ovu lekciju</h2>
            </div>
          )}
        </div>
      )}
      {isATTL && (
        <AddTestToLection
          setIsATTL={setIsATTL}
          kojaLekcija={lekcija as Lekcija}
          dohvatiLekcije={dohvatiLekciju}
          nizLekcija={nizLekcija}
        />
      )}
      {isAPTL && (
        <AddPresentationToLection
          dohvatiLekcije={dohvatiLekciju}
          kojaLekcija={lekcija as Lekcija}
          nizLekcija={nizLekcija}
          setIsAPTL={setIsAPTL}
        />
      )}
    </>
  );
}
