import React, { useContext, useState } from "react";
import "../styles/newSTOverlay.scss";
import { Grupa, UserData } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { doc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { grupeRef, skoleRef } from "../utils/firebase";
import _ from "lodash";
import useKeyPress from "react-use-keypress";
import uuid from "react-uuid";

interface Props {
  setIsNewGroup: any;
  nizStudenata: Array<UserData>;
  dohvatiStudenteIGrupe: any;
}

export function NewGroupOverlay(props: Props) {
  const { nizStudenata, setIsNewGroup, dohvatiStudenteIGrupe } = props;
  const [ime, setIme] = useState<string>("");
  const { currentUserData, setLoading, setAlert, setIsAlert } =
    useContext(AppContext);
  useKeyPress("Enter", () => {
    dodajNovuGrupu();
  });
  useKeyPress("Escape", () => {
    setIsNewGroup(false);
  });

  async function dodajNovuGrupu() {
    setLoading(true);
    if (currentUserData === undefined || currentUserData === null) return;
    if (ime === "") {
      setAlert("Unesite ime grupe");
      setIsAlert(true);
      return;
    }
    const jb = uuid();
    const datum = new Date();
    const grupaData: Grupa = {
      ime: ime,
      datum: datum.toString(),
      id: jb,
      kreirao: currentUserData.ime,
      kreiraoID: currentUserData.id,
      studenti: [],
    };
    await setDoc(doc(grupeRef, jb), grupaData).then(async () => {
      await updateDoc(doc(skoleRef, currentUserData.autoskolaId), {
        grupe: arrayUnion(grupaData),
      }).then(() => {
        dohvatiStudenteIGrupe();
        setLoading(false);
        setIsNewGroup(false);
      });
    });
  }

  return (
    <div className="newSTOverlay">
      <div className="newSTBox">
        <h2>Nova grupa</h2>
        <input
          type="text"
          name="imeGrupe"
          id="imeGrupe"
          placeholder="Ime grupe"
          onChange={(e) => setIme(e.target.value)}
        />
        <div className="buttonOptions">
          <button onClick={() => dodajNovuGrupu()}>Potvrdi</button>
          <button onClick={() => setIsNewGroup(false)}>Odustani</button>
        </div>
        <button
          className="x"
          onClick={() => {
            setIsNewGroup(false);
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}
