import React from "react";
import Lottie from "react-lottie";
import loadingCar from "../loadingCar.json";
interface Props {}

export function Loading(props: Props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingCar,
  };
  return (
    <div
      className="loadingOverlay"
      style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: "0",
        left: "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#1e293b",
        zIndex: "9999",
      }}
    >
      <Lottie height={300} width={300} options={defaultOptions} />
      <h4
        style={{
          color: "white",
          fontSize: 30,
          margin: "0px",
        }}
      >
        Učitavanje...
      </h4>
    </div>
  );
}
