import React, { useContext, useEffect } from "react";
import "../styles/newSTOverlay.scss";
import { AppContext } from "../functions/AppProvider";

interface Props {}

export function Alert(props: Props) {
  const { alert, setIsAlert } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      setIsAlert(false);
    }, 2000);
  }, []);
  return (
    <div className="alertBox">
      <p>{alert}</p>
    </div>
  );
}
