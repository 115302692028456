import React, { useContext, useEffect, useState } from "react";
import "../styles/newSTOverlay.scss";
import { Grupa, UserData, autoSkola } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import {
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  grupeRef,
  korisniciRef,
  noviKorisniciRef,
  skoleRef,
} from "../utils/firebase";
import _ from "lodash";
import useKeyPress from "react-use-keypress";
import uuid from "react-uuid";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface Props {
  korisnik: UserData;
  setIsKvotaChange: any;
  dohvatiKorisnika: any;
}

export function KvotaChange(props: Props) {
  const { korisnik, setIsKvotaChange, dohvatiKorisnika } = props;
  const { setLoading, setAlert, setIsAlert } = useContext(AppContext);
  const [kvota, setKvota] = useState<number>(0);

  useKeyPress("Escape", () => {
    setIsKvotaChange(false);
  });
  useKeyPress("Enter", () => {
    promeniKvotu();
  });

  function promeniKvotu() {
    setLoading(true);
    if (korisnik === undefined || korisnik === null) return;
    console.log("KORISNIK", korisnik);

    let noviNizStudenata: Array<UserData> = [];

    for (let i = 0; i < korisnik.nizStudenata.length; i++) {
      getDoc(doc(korisniciRef, korisnik.nizStudenata[i].id)).then((student) => {
        const studentInfo: UserData = student.data() as UserData;
        const newStudentInfo: UserData = {
          autoskolaId: studentInfo.autoskolaId,
          email: studentInfo.email,
          id: studentInfo.id,
          ime: studentInfo.ime,
          profilnaURL: studentInfo.profilnaURL,
          uloga: studentInfo.uloga,
          brOdvozanihCasova: studentInfo.brOdvozanihCasova,
          brNocnih: studentInfo.brNocnih,
          koJeInstruktorID: studentInfo.koJeInstruktorID,
          koJeInstruktorIme: studentInfo.koJeInstruktorIme,
          polozioTeoriju: studentInfo.polozioTeoriju,
          datum: studentInfo.datum.toString(),
          telefon: studentInfo.telefon,
          aktiviran: studentInfo.aktiviran,
          voziAuto: studentInfo.voziAuto,
          editovanInfoPrviPut: studentInfo.editovanInfoPrviPut,
          grupa: studentInfo.grupa,
          grupaID: studentInfo.grupaID,
          uploads: studentInfo.uploads,
          krediti: studentInfo.krediti,
          nizOdvozanihTermina: studentInfo.nizOdvozanihTermina,
          nizStudenata: studentInfo.nizStudenata,
          nizZakazanihTermina: studentInfo.nizZakazanihTermina,
          prikazivanjeTermina: studentInfo.prikazivanjeTermina,
          nedeljnaKvota: kvota,
          subscribed: studentInfo.subscribed,
        };
        noviNizStudenata.push(newStudentInfo);

        const filtriraniNizStudenata = _.filter(
          korisnik.nizStudenata,
          function (o) {
            if (o.id !== studentInfo.id) return o;
          }
        );

        updateDoc(doc(korisniciRef, studentInfo.id), {
          nedeljnaKvota: kvota,
        }).then(() => {
          updateDoc(doc(korisniciRef, korisnik.id), {
            nizStudenata: [...filtriraniNizStudenata, newStudentInfo],
          });
        });
      });
    }
    console.log("NOVINIZSTUDENATA", noviNizStudenata);
    const noviNS = noviNizStudenata;

    const promenjenInstruktor: UserData = {
      autoskolaId: korisnik.autoskolaId,
      email: korisnik.email,
      id: korisnik.id,
      ime: korisnik.ime,
      profilnaURL: korisnik.profilnaURL,
      uloga: korisnik.uloga,
      brOdvozanihCasova: korisnik.brOdvozanihCasova,
      brNocnih: korisnik.brNocnih,
      koJeInstruktorID: korisnik.koJeInstruktorID,
      koJeInstruktorIme: korisnik.koJeInstruktorIme,
      polozioTeoriju: korisnik.polozioTeoriju,
      datum: korisnik.datum.toString(),
      telefon: korisnik.telefon,
      aktiviran: korisnik.aktiviran,
      voziAuto: korisnik.voziAuto,
      editovanInfoPrviPut: korisnik.editovanInfoPrviPut,
      grupa: korisnik.grupa,
      grupaID: korisnik.grupaID,
      uploads: korisnik.uploads,
      krediti: korisnik.krediti,
      nizOdvozanihTermina: korisnik.nizOdvozanihTermina,
      nizStudenata: noviNS,
      nizZakazanihTermina: korisnik.nizZakazanihTermina,
      prikazivanjeTermina: korisnik.prikazivanjeTermina,
      nedeljnaKvota: kvota,
      subscribed: korisnik.subscribed,
    };
    updateDoc(doc(korisniciRef, korisnik.id), {
      nedeljnaKvota: kvota,
    }).then(() => {
      console.log("NOVINIZSTUDENATA2", noviNS);
      getDoc(doc(skoleRef, korisnik.autoskolaId)).then((skola) => {
        const skolaInfo: autoSkola = skola.data() as autoSkola;
        const nizInstruktora: Array<UserData> =
          skolaInfo.instruktori as Array<UserData>;

        const filtriraniNizInstruktora = _.filter(nizInstruktora, function (o) {
          if (o.id !== korisnik.id) return o;
        });

        updateDoc(doc(skoleRef, korisnik.autoskolaId), {
          instruktori: [...filtriraniNizInstruktora, promenjenInstruktor],
        }).then(() => {
          dohvatiKorisnika();
          setLoading(false);
          setIsKvotaChange(false);
          setAlert("Uspešno promenjen način prikazivanja termina");
          setIsAlert(true);
        });
      });
    });
  }

  return (
    <div className="newSTOverlay">
      <div className="newSTBox">
        <h2>Promena broja dozvoljenih časova nedeljno</h2>
        <p>Trenutno: {korisnik?.nedeljnaKvota}</p>
        <input
          type="number"
          name="kvota"
          id="kvota"
          onChange={(e) => setKvota(parseInt(e.target.value))}
        />

        <div className="buttonOptions">
          <button onClick={() => promeniKvotu()}>Potvrdi</button>
          <button onClick={() => setIsKvotaChange(false)}>Odustani</button>
        </div>
        <button
          className="x"
          onClick={() => {
            setIsKvotaChange(false);
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}
