import React, { useContext, useEffect, useState } from "react";
import "../styles/newSTOverlay.scss";
import { Lekcija, Test } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { lekcijeRef, skoleRef } from "../utils/firebase";
import useKeyPress from "react-use-keypress";
import _ from "lodash";
import { BiPlus } from "react-icons/bi";

interface Props {
  setIsATTL: any;
  kojaLekcija: Lekcija;
  nizLekcija: Array<Lekcija>;
  dohvatiLekcije: any;
}

export function AddTestToLection(props: Props) {
  const [nizTestova, setNizTestova] = useState<Array<Test>>([]);
  const [sekundarniNiz, setSekundarniNiz] = useState<Array<Test>>([]);
  const { setIsATTL, kojaLekcija, nizLekcija, dohvatiLekcije } = props;
  const { currentUserData, setLoading, setAlert, setIsAlert } =
    useContext(AppContext);
  const [kojaLekcijaObjekat, setKojaLekcijaObjekat] = useState<Lekcija>();
  const [dohvatiliLekciju, setDohvatiliLekciju] = useState<boolean>(false);

  useKeyPress("Escape", () => {
    setIsATTL(false);
  });

  useEffect(() => {
    dohvatiLekciju();
  }, [currentUserData]);

  function dohvatiLekciju() {
    if (
      currentUserData === null ||
      currentUserData === undefined ||
      kojaLekcija === null ||
      kojaLekcija === undefined
    ) {
      return;
    }
    getDoc(doc(lekcijeRef, kojaLekcija.id)).then((lekcija) => {
      setKojaLekcijaObjekat(lekcija.data() as Lekcija);
      setDohvatiliLekciju(true);
    });
  }
  useEffect(() => {
    if (dohvatiliLekciju === true) {
      dohvatiTestove();
    }
  }, [dohvatiliLekciju]);
  function dohvatiTestove() {
    if (
      currentUserData === null ||
      currentUserData === undefined ||
      currentUserData.autoskolaId === null ||
      currentUserData.autoskolaId === undefined ||
      currentUserData.autoskolaId === "" ||
      skoleRef === null ||
      skoleRef === undefined ||
      kojaLekcijaObjekat === null ||
      kojaLekcijaObjekat === undefined
    )
      return;
    setLoading(true);
    getDoc(doc(skoleRef, currentUserData.autoskolaId)).then((skola) => {
      console.log("SKOLA", skola?.data()?.testovi);
      const nizT = skola?.data()?.testovi as Array<Test>;
      const filtriranNizT = _.filter(nizT, function (o) {
        let x = 0;
        for (let i = 0; i < kojaLekcijaObjekat.testovi.length; i++) {
          if (o.id === kojaLekcijaObjekat.testovi[i].id) {
            x++;
          }
        }
        if (x === 0) {
          return o;
        }
      });
      setNizTestova(filtriranNizT as Array<Test>);
      setSekundarniNiz(filtriranNizT as Array<Test>);
      setLoading(false);
    });
  }
  function search(q: string) {
    const duzina = q.length;
    const niz = nizTestova;
    if (q === "") {
      setSekundarniNiz(nizTestova);
      return;
    }
    const filtriranNiz = _.filter(niz, function (o) {
      const ime = o.ime;
      console.log(
        "FILTER PRVI",
        ime.toLowerCase().substring(0, duzina),
        q.toLowerCase().substring(0, duzina)
      );
      if (
        ime.toLowerCase().substring(0, duzina) ===
          q.toLowerCase().substring(0, duzina) ||
        ime.toLowerCase().split(".")[1].substring(0, duzina) ===
          q.toLowerCase().substring(0, duzina)
      )
        return o;
    });
    console.log("FILTER", filtriranNiz);
    setSekundarniNiz(filtriranNiz as Array<Test>);
  }

  function dodajTestLekciji(kojiTest: Test) {
    if (
      currentUserData === null ||
      currentUserData === undefined ||
      kojaLekcijaObjekat === undefined ||
      kojaLekcijaObjekat === null
    )
      return;
    setLoading(true);
    const nizLekcijaBezDate = _.filter(nizLekcija, function (o) {
      if (o.id !== kojaLekcijaObjekat.id) {
        return o;
      }
    });
    const lekcijaData: Lekcija = {
      datum: kojaLekcijaObjekat.datum,
      id: kojaLekcijaObjekat.id,
      ime: kojaLekcijaObjekat.ime,
      kreirao: kojaLekcijaObjekat.kreirao,
      kreiraoID: kojaLekcijaObjekat.kreiraoID,
      testovi: [...kojaLekcijaObjekat.testovi, kojiTest],
      prezentacije: kojaLekcijaObjekat.prezentacije,
    };
    updateDoc(doc(lekcijeRef, kojaLekcijaObjekat.id), lekcijaData).then(() => {
      updateDoc(doc(skoleRef, currentUserData.autoskolaId), {
        lekcije: [...nizLekcijaBezDate, lekcijaData],
      }).then(() => {
        setLoading(false);
        const noviNizT = _.filter(nizTestova, function (o) {
          if (o.id !== kojiTest.id) return o;
        });
        setNizTestova(noviNizT as Array<Test>);
        setSekundarniNiz(noviNizT as Array<Test>);
        dohvatiLekciju();
      });
    });
  }
  return (
    <div className="newSTOverlay">
      <div className="newSTBox">
        <div className="newSTBoxHeader">
          <h2>Dodajte testove</h2>
          <input
            type="text"
            name="pretragaPrezentacija"
            id="pretragaPrezentacija"
            placeholder="Pretražite"
            onChange={(e) => search(e.target.value)}
          />
        </div>
        <div className="newSTList">
          {sekundarniNiz.map((test) => {
            return (
              <div className="studentBox">
                <div className="studentBoxInfo">
                  <div className="studentBoxInfoText">
                    <h2>{test.ime}</h2>
                    {/* <p>{prez.korisnik}</p> */}
                  </div>
                </div>
                <div className="studentBoxOptions">
                  <button
                    onClick={() => {
                      dodajTestLekciji(test);
                    }}
                  >
                    <BiPlus color="white" size={40} />
                  </button>
                </div>
              </div>
            );
          })}
          {sekundarniNiz.length === 0 && <h3>Nema dostupnih testova</h3>}
        </div>
        <button
          className="x"
          onClick={() => {
            dohvatiLekcije();
            dohvatiTestove();
            setIsATTL(false);
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}
