import React, { useContext, useEffect, useState } from "react";
import "../styles/home.scss";
import { getAuth, signOut } from "firebase/auth";
import { AuthContext } from "../functions/Auth";
import { AppContext } from "../functions/AppProvider";
import { NewSchoolOverlay } from "../components/NewSchoolOverlay";
import {
  getDocs,
  collection,
  updateDoc,
  doc,
  getDoc,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";
// import { db, izloguj } from "../utils/firebase";
import { useNavigate } from "react-router-dom";
import { Grupa, UserData, autoSkola } from "../utils/types";
import { NewUserOverlay } from "../components/NewUserOverlay";
import { BiLogOutCircle } from "react-icons/bi";
import {
  db,
  grupeRef,
  korisniciRef,
  porukeRef,
  sendUltra,
  skoleRef,
} from "../utils/firebase";
import { IoNotifications } from "react-icons/io5";
import { BsPeopleFill } from "react-icons/bs";
import { GiTeacher } from "react-icons/gi";
import _ from "lodash";
interface Props {}
type PorukaWa = {
  od: string;
  text: string;
  id: string;
};
export function Home(props: Props) {
  const date = new Date();
  const mesec = date.getMonth() + 1;
  const {
    currentUser,
    currentUserData,
    logOut,
    setLoading,
    setAlert,
    setIsAlert,
  } = useContext(AppContext);
  const [isNewSchool, setIsNewSchool] = useState<boolean>(false);
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const [autoSkole, setAutoSkole] = useState<Array<autoSkola>>([]);
  const [sviKorisnici, setSviKorisnici] = useState<Array<UserData>>([]);
  const navigate = useNavigate();

  const dohvatanje = async () => {
    const querySnapshot = await getDocs(collection(db, "korisnici"));
    const korisnici = querySnapshot.docs.map((doc) => ({ ...doc.data() }));
    setSviKorisnici(korisnici as Array<UserData>);
    console.log(korisnici);
  };
  useEffect(() => {
    dohvatanje();
  }, []);

  function dozvoliNotifikacije(kojiKandidat: UserData) {
    setLoading(true);
    if (kojiKandidat === undefined || kojiKandidat === null) return;

    updateDoc(doc(korisniciRef, kojiKandidat.id), {
      subscribed: true,
    }).then(() => {
      const korisnikPodaci: UserData = {
        autoskolaId: kojiKandidat.autoskolaId,
        email: kojiKandidat.email,
        id: kojiKandidat.id,
        ime: kojiKandidat.ime,
        profilnaURL: kojiKandidat.profilnaURL,
        uloga: kojiKandidat.uloga,
        aktiviran: kojiKandidat.aktiviran,
        brNocnih: kojiKandidat.brNocnih,
        brOdvozanihCasova: kojiKandidat.brOdvozanihCasova,
        datum: kojiKandidat.datum,
        editovanInfoPrviPut: kojiKandidat.editovanInfoPrviPut,
        grupa: kojiKandidat.grupa,
        koJeInstruktorID: kojiKandidat.koJeInstruktorID,
        grupaID: kojiKandidat.grupaID,
        koJeInstruktorIme: kojiKandidat.koJeInstruktorIme,
        krediti: kojiKandidat.krediti,
        polozioTeoriju: kojiKandidat.polozioTeoriju,
        telefon: kojiKandidat.telefon,
        uploads: kojiKandidat.uploads,
        voziAuto: kojiKandidat.voziAuto,
        nizOdvozanihTermina: kojiKandidat.nizOdvozanihTermina,
        nizStudenata: kojiKandidat.nizStudenata,
        nizZakazanihTermina: kojiKandidat.nizZakazanihTermina,
        prikazivanjeTermina: kojiKandidat.prikazivanjeTermina,
        nedeljnaKvota: kojiKandidat.nedeljnaKvota,
        subscribed: kojiKandidat.subscribed,
      };
      //UPDATE GRUPE AKO POSTOJI
      if (
        kojiKandidat.grupa !== null &&
        kojiKandidat.grupa !== undefined &&
        kojiKandidat.grupa !== "" &&
        kojiKandidat.uloga === "student"
      ) {
        getDoc(doc(grupeRef, kojiKandidat.grupaID)).then((grupaInfo) => {
          const grupaData = grupaInfo.data() as Grupa;
          const filtriraniNizStudenataUGrupi = _.filter(
            grupaData.studenti,
            function (o) {
              if (o.id !== kojiKandidat.id) return o;
            }
          );
          const grupaDataObjekat: Grupa = {
            datum: grupaData.datum,
            id: grupaData.id,
            ime: grupaData.ime,
            kreirao: grupaData.kreirao,
            kreiraoID: grupaData.kreiraoID,
            studenti: [
              ...(filtriraniNizStudenataUGrupi as Array<UserData>),
              korisnikPodaci,
            ],
          };

          updateDoc(doc(grupeRef, kojiKandidat.grupaID), {
            studenti: [...filtriraniNizStudenataUGrupi, korisnikPodaci],
          });
          getDoc(doc(skoleRef, kojiKandidat.autoskolaId)).then((skola) => {
            const skolaData = skola.data();
            if (skolaData === undefined || skolaData === null) return;
            const filterGrupaBezTrenutne = _.filter(
              skolaData.grupe,
              function (o) {
                if (o.id !== grupaDataObjekat.id) return o;
              }
            );

            updateDoc(doc(skoleRef, kojiKandidat.autoskolaId), {
              grupe: [...filterGrupaBezTrenutne, grupaDataObjekat],
            });
          });
        });
      }
      //UPDATE INSTRUKTORA AKO GA IMA
      if (
        kojiKandidat.koJeInstruktorID !== null &&
        kojiKandidat.koJeInstruktorID !== undefined &&
        kojiKandidat.koJeInstruktorID !== "" &&
        kojiKandidat.uloga === "student"
      ) {
        getDoc(doc(korisniciRef, kojiKandidat.koJeInstruktorID)).then(
          (instruktor) => {
            const instruktorData = instruktor.data() as UserData;
            const filtriraniNizStudenataKodInstruktora = _.filter(
              instruktorData.nizStudenata,
              function (o) {
                if (o.id !== kojiKandidat.id) return o;
              }
            );
            const filterStudenataBezOvog: Array<UserData> =
              filtriraniNizStudenataKodInstruktora as Array<UserData>;
            const newInstruktorData: UserData = {
              aktiviran: instruktorData.aktiviran,
              autoskolaId: instruktorData.autoskolaId,
              brNocnih: instruktorData.brNocnih,
              brOdvozanihCasova: instruktorData.brOdvozanihCasova,
              datum: instruktorData.datum,
              editovanInfoPrviPut: instruktorData.editovanInfoPrviPut,
              email: instruktorData.email,
              grupa: instruktorData.grupa,
              grupaID: instruktorData.grupaID,
              id: instruktorData.id,
              ime: instruktorData.ime,
              koJeInstruktorID: instruktorData.koJeInstruktorID,
              koJeInstruktorIme: instruktorData.koJeInstruktorIme,
              krediti: instruktorData.krediti,
              nizOdvozanihTermina: instruktorData.nizOdvozanihTermina,
              nizStudenata: [...filterStudenataBezOvog, korisnikPodaci],
              nizZakazanihTermina: instruktorData.nizZakazanihTermina,
              polozioTeoriju: instruktorData.polozioTeoriju,
              prikazivanjeTermina: instruktorData.prikazivanjeTermina,
              profilnaURL: instruktorData.profilnaURL,
              telefon: instruktorData.telefon,
              uloga: instruktorData.uloga,
              uploads: instruktorData.uploads,
              voziAuto: instruktorData.voziAuto,
              nedeljnaKvota: instruktorData.nedeljnaKvota,
              subscribed: instruktorData.subscribed,
            };
            getDoc(doc(skoleRef, kojiKandidat.autoskolaId)).then((skola) => {
              const skolaInfo = skola.data() as autoSkola;
              const filterInstruktora = _.filter(
                skolaInfo.instruktori,
                function (o) {
                  if (o.id !== instruktorData.id) return o;
                }
              );
              const nizInstruktora: Array<UserData> =
                filterInstruktora as Array<UserData>;

              updateDoc(doc(skoleRef, kojiKandidat.autoskolaId), {
                instruktori: [...nizInstruktora, newInstruktorData],
              }).then(() => {
                updateDoc(doc(korisniciRef, instruktorData.id), {
                  nizStudenata: [...filterStudenataBezOvog, korisnikPodaci],
                });
              });
            });
          }
        );
      }
      getDoc(doc(skoleRef, kojiKandidat.autoskolaId)).then((skola) => {
        const skolaData = skola.data();

        if (kojiKandidat.uloga === "student") {
          const filtriraniNizStudenata = _.filter(
            skolaData?.studenti,
            function (o) {
              if (o.id !== kojiKandidat.id) return o;
            }
          );

          updateDoc(doc(skoleRef, kojiKandidat.autoskolaId), {
            studenti: [...filtriraniNizStudenata, korisnikPodaci],
          }).then(() => {
            setAlert("Uspešno dozvoljene notifikacije");
            setIsAlert(true);
            setLoading(false);
            dohvatanje();
          });
        }
        if (kojiKandidat.uloga === "schoolteacher") {
          const filtriraniNizPredavaca = _.filter(
            skolaData?.predavaci,
            function (o) {
              if (o.id !== kojiKandidat.id) return o;
            }
          );

          updateDoc(doc(skoleRef, kojiKandidat.autoskolaId), {
            predavaci: [...filtriraniNizPredavaca, korisnikPodaci],
          }).then(() => {
            setAlert("Uspešno dozvoljene notifikacije");
            setIsAlert(true);
            setLoading(false);
            dohvatanje();
          });
        }
        if (kojiKandidat.uloga === "schoolinstructor") {
          const filtriraniNizInstruktora = _.filter(
            skolaData?.instruktori,
            function (o) {
              if (o.id !== kojiKandidat.id) return o;
            }
          );

          updateDoc(doc(skoleRef, kojiKandidat.autoskolaId), {
            instruktori: [...filtriraniNizInstruktora, korisnikPodaci],
          }).then(() => {
            setAlert("Uspešno dozvoljene notifikacije");
            setIsAlert(true);
            setLoading(false);
            dohvatanje();
          });
        }
      });
    });
  }

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "poruke"), (snap) => {
      if (snap.empty === true) {
        return;
      }
      const trenutniPodaci = snap.docs;
      for (let i = 0; i < trenutniPodaci.length; i++) {
        const trenutnaPoruka: PorukaWa = {
          id: trenutniPodaci[i].ref.path.split("/")[1],
          od: "0".concat(
            trenutniPodaci[i].data().data.from.split("@")[0].split("381")[1]
          ),
          text: trenutniPodaci[i].data().data.body,
        };
        console.log(trenutnaPoruka);

        const pronadjiKorisnika = _.filter(sviKorisnici, function (o) {
          if (o.telefon === trenutnaPoruka.od) return o;
        });

        if (trenutnaPoruka.text === "Prihvatam") {
          if (
            pronadjiKorisnika !== null &&
            pronadjiKorisnika !== undefined &&
            pronadjiKorisnika.length === 1
          ) {
            const korisnik: UserData = pronadjiKorisnika[0] as UserData;
            if (korisnik.subscribed === false) {
              dozvoliNotifikacije(korisnik);
              sendUltra(
                `+381${korisnik.telefon}`,
                "Dobrodošli na platformu Vožnja. \n Uspešno ste aktivirali notifikacije. \n\n Vaš VožnjaRS tim!"
              );
            } else {
              setAlert("Vec su ukljucene notifikacije");
            }
            deleteDoc(doc(porukeRef, trenutnaPoruka.id));
          }
        }
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <>
      {/* <h1>Početna</h1> */}
      <div className="welcome">
        <h2>Dobro došli, Voznja.rs</h2>
        <h2>
          {date.getDate()}.{mesec}.{date.getFullYear()}
        </h2>
      </div>
      <div className="optionsShortcuts">
        <div className="shortcut" onClick={() => setIsNewSchool(true)}>
          <div className="ikonicaWrap">
            <GiTeacher color="white" size={50} />
          </div>
          <h3>Nova škola</h3>
        </div>
        <div className="shortcut" onClick={() => setIsNewUser(true)}>
          <div className="ikonicaWrap">
            <BsPeopleFill color="white" size={50} />
          </div>
          <h3>Novi korisnik</h3>
        </div>
        <div
          className="shortcut"
          onClick={() => {
            logOut();
            navigate("/login");
          }}
        >
          <div className="ikonicaWrap">
            <BiLogOutCircle color="white" size={60} />
          </div>
          <h3>Izlogujte se</h3>
        </div>
      </div>
      {isNewSchool && <NewSchoolOverlay setIsNewSchool={setIsNewSchool} />}
      {isNewUser && (
        <NewUserOverlay autoSkole={autoSkole} setIsNewUser={setIsNewUser} />
      )}
      <div className="list">
        {sviKorisnici.map((student) => {
          return (
            <div className="listPiece">
              <div className="pieceInfo">
                <img src={student.profilnaURL} alt={student.ime} />
                <div className="pieceInfoText">
                  <h3>{student.ime}</h3>
                  <p>{student.uloga}</p>
                  {student.aktiviran === false && <h4>Nalog nije aktiviran</h4>}
                  {student.aktiviran === false && <h4>{student.email}</h4>}

                  {student.aktiviran === true && <p>{student.id}</p>}
                  <p>
                    {student.aktiviran === true && "Auto škola: "}
                    {student.aktiviran === true && student.autoskolaId}
                  </p>
                  <p>
                    {student.aktiviran === true && "Broj telefona:"}
                    {student.aktiviran && student.telefon}
                  </p>
                </div>
              </div>
              <div className="pieceOptions">
                {student.aktiviran === true && student.subscribed === false && (
                  <div
                    className="pieceOption"
                    onClick={() => {
                      dozvoliNotifikacije(student);
                    }}
                  >
                    <div className="pieceOptionIcon">
                      <IoNotifications color="white" size={50} />
                    </div>
                    <p>Dozvoli</p>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
