import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import "../styles/home.scss";
import { AppContext } from "../functions/AppProvider";
import {
  AiFillCloseCircle,
  AiOutlineAppstoreAdd,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { BiLock, BiSave } from "react-icons/bi";
import { IoTextOutline } from "react-icons/io5";
import { NewUploadOverlay } from "../components/NewUploadOverlay";
import { Test, PitanjeTesta } from "../utils/types";
import uuid from "react-uuid";
import { testoviRef, skoleRef } from "../utils/firebase";
import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { TestDescription } from "../components/TestDescription";
import _ from "lodash";
import { IconContext } from "react-icons/lib";
import { MdArrowBack } from "react-icons/md";
import { BsPeopleFill, BsTrashFill } from "react-icons/bs";

interface Props {}

const NewTest = (props: Props) => {
  const { currentUser, currentUserData, setLoading } = useContext(AppContext);
  const { imeTesta, edit, testID } = useParams();
  const [isSlikaZaPitanje, setIsSlikaZaPitanje] = useState<boolean>(false);
  const [slikaZaPitanje, setSlikuZaPitanje] = useState<string>("");
  const [opis, setOpis] = useState<string>("");
  const [isDesc, setIsDesc] = useState<boolean>(false);
  const [pp, setPP] = useState<"Javno" | "Privatno">("Javno");
  const praznoPitanje: PitanjeTesta = {
    pitanje: "",
    a: "",
    b: "",
    c: "",
    d: "",
    tacanOdgovor: "",
    slika: "",
  };
  const [nizPitanja, setNizPitanja] = useState<Array<PitanjeTesta>>([
    praznoPitanje,
  ]);
  const [indikator, setIndikator] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (edit === "true" && testID !== undefined && testID !== null) {
      getDoc(doc(testoviRef, testID)).then((Test) => {
        const pitanja = Test?.data()?.nizPitanja;
        setNizPitanja(pitanja);
        setOpis(Test?.data()?.opis);
      });
    }
  }, []);
  useEffect(() => {
    if (slikaZaPitanje !== "") {
      let newArr = [...nizPitanja];
      newArr[indikator].slika = slikaZaPitanje;
      setNizPitanja(newArr);
    }
  }, [slikaZaPitanje]);
  function dodajPitanje() {
    setNizPitanja([...nizPitanja, praznoPitanje]);
    setIndikator(indikator + 1);
  }
  function prethodnoPitanje() {
    setIndikator(indikator - 1);
  }
  function sledecePitanje() {
    setIndikator(indikator + 1);
  }

  async function saveTest() {
    //FUNKCIJA ZA CUVANJE TestA
    if (currentUserData === undefined || currentUserData === null) return;
    setLoading(true);
    let jb = uuid();
    if (edit === "true" && testID !== undefined && testID !== null) {
      jb = testID;
    }
    const TestObjekat: Test = {
      datum: new Date().toString(),
      ime: imeTesta as string,
      kreiraoID: currentUserData.id,
      kreiraoIme: currentUserData.ime,
      nizPitanja: nizPitanja,
      opis: opis,
      id: jb,
      pp: pp,
    };
    if (edit === "true" && testID !== undefined && testID !== null) {
      await getDoc(doc(skoleRef, currentUserData.autoskolaId)).then(
        async (skola) => {
          const nizTestova = skola?.data()?.testovi as Array<Test>;
          const filtriraniNiz = _.filter(nizTestova, function (o) {
            if (o.id !== testID) return o;
          });
          const konacanNiz = [...filtriraniNiz, TestObjekat];
          await updateDoc(doc(testoviRef, testID), TestObjekat).then(() => {
            updateDoc(doc(skoleRef, currentUserData.autoskolaId), {
              testovi: konacanNiz,
            }).then(() => {
              setLoading(false);
              navigate(`/testovi/${currentUserData.autoskolaId}`);
            });
          });
        }
      );
    } else {
      await setDoc(doc(testoviRef, jb), TestObjekat).then(async () => {
        await updateDoc(doc(skoleRef, currentUserData.autoskolaId), {
          testovi: arrayUnion(TestObjekat),
        }).then(() => {
          setLoading(false);
          navigate(`/testovi/${currentUserData.autoskolaId}`);
        });
      });
    }
  }

  const freeSpinGifts = [
    ["test1", "red"],
    ["test2", "black"],
    ["test3", "#808080"],
    ["test4", "blue"],
    ["test5", "gray"],
    ["test6", "blue"],
  ];

  const ref = useRef<any>(null);
  return (
    <>
      <div className="welcome">
        <div className="welcomeTitle">
          <IconContext.Provider value={{ className: "backIkonica" }}>
            <div className="ikonicaOmotac" onClick={() => navigate(-1)}>
              <MdArrowBack />
            </div>
          </IconContext.Provider>
          <h2>Novi test - {imeTesta}</h2>
        </div>
      </div>
      <div className="optionsShortcuts">
        <div className="shortcut" onClick={() => saveTest()}>
          <div className="ikonicaWrap">
            <BiSave color="white" size={50} />
          </div>
          <h3>Sačuvaj test</h3>
        </div>

        <div className="shortcut" onClick={() => setPP("Privatno")}>
          <div className={`ikonicaWrap ${pp === "Privatno" && `correct`}`}>
            <BiLock color="white" size={50} />
          </div>
          <h3>Privatno</h3>
        </div>
        <div className="shortcut" onClick={() => setPP("Javno")}>
          <div className={`ikonicaWrap ${pp === "Javno" && `correct`}`}>
            <BsPeopleFill color="white" size={50} />
          </div>
          <h3>Javno</h3>
        </div>

        <div className="shortcut" onClick={() => setIsDesc(true)}>
          <div className="ikonicaWrap">
            <IoTextOutline color="white" size={50} />
          </div>
          <h3>Opis testa</h3>
        </div>

        {indikator !== 0 && (
          <div
            className="shortcut"
            onClick={() => {
              prethodnoPitanje();
            }}
          >
            <div className="ikonicaWrap">
              <AiOutlineArrowLeft color="white" size={50} />
            </div>
            <h3>Prethodno pitanje</h3>
          </div>
        )}
        {indikator + 1 < nizPitanja.length && (
          <div
            className="shortcut"
            onClick={() => {
              sledecePitanje();
            }}
          >
            <div className="ikonicaWrap">
              <AiOutlineArrowRight color="white" size={50} />
            </div>
            <h3>Sledeće pitanje</h3>
          </div>
        )}
        {indikator + 1 === nizPitanja.length && (
          <div
            className="shortcut"
            onClick={() => {
              dodajPitanje();
            }}
          >
            <div className="ikonicaWrap">
              <AiOutlineAppstoreAdd color="white" size={50} />
            </div>
            <h3>Dodaj pitanje</h3>
          </div>
        )}
        {nizPitanja.length !== 1 && (
          <div
            className="shortcut"
            onClick={() => {
              let izbaceno = _.pullAt(nizPitanja, indikator);
              // nizPitanja.splice(indikator);
              prethodnoPitanje();
              console.log(
                //   noviniz.splice(indikator),
                //   "noviniz",
                //   noviniz,
                "Niz pitanja",
                nizPitanja
              );
            }}
          >
            <div className="ikonicaWrap">
              <BsTrashFill color="white" size={50} />
            </div>
            <h3>Obriši pitanje</h3>
          </div>
        )}

        <div
          className="shortcut"
          onClick={() => {
            navigate(`/testovi/${currentUserData?.autoskolaId}`);
          }}
        >
          <div className="ikonicaWrap">
            <AiFillCloseCircle color="white" size={50} />
          </div>
          <h3>Odustani</h3>
        </div>
      </div>
      <div className="list">
        <div className="listPieceVertical">
          <h2>Pitanje {indikator + 1}</h2>
          <div className="listPieceRow">
            <div className="listPieceRowQuestions">
              <textarea
                name="pitanje"
                id="pitanje"
                placeholder="Unesite pitanje"
                value={
                  nizPitanja[indikator].pitanje !== "" &&
                  nizPitanja[indikator].pitanje !== null &&
                  nizPitanja[indikator].pitanje !== undefined
                    ? nizPitanja[indikator].pitanje
                    : ""
                }
                onChange={(e) => {
                  let newArr = [...nizPitanja];
                  newArr[indikator].pitanje = e.target.value;
                  setNizPitanja(newArr);
                }}
              />
              <label htmlFor="a">A</label>
              <textarea
                placeholder="Odgovor A"
                value={
                  nizPitanja[indikator].a !== "" &&
                  nizPitanja[indikator].a !== null &&
                  nizPitanja[indikator].a !== undefined
                    ? nizPitanja[indikator].a
                    : ""
                }
                onChange={(e) => {
                  let newArr = [...nizPitanja];
                  newArr[indikator].a = e.target.value;
                  setNizPitanja(newArr);
                }}
              />
              <label htmlFor="b">B</label>
              <textarea
                placeholder="Odgovor B"
                value={
                  nizPitanja[indikator].b !== "" &&
                  nizPitanja[indikator].b !== null &&
                  nizPitanja[indikator].b !== undefined
                    ? nizPitanja[indikator].b
                    : ""
                }
                onChange={(e) => {
                  let newArr = [...nizPitanja];
                  newArr[indikator].b = e.target.value;
                  setNizPitanja(newArr);
                }}
              />
              <label htmlFor="c">C</label>
              <textarea
                placeholder="Odgovor C"
                value={
                  nizPitanja[indikator].c !== "" &&
                  nizPitanja[indikator].c !== null &&
                  nizPitanja[indikator].c !== undefined
                    ? nizPitanja[indikator].c
                    : ""
                }
                onChange={(e) => {
                  let newArr = [...nizPitanja];
                  newArr[indikator].c = e.target.value;
                  setNizPitanja(newArr);
                }}
              />
              <label htmlFor="d">D</label>
              <textarea
                placeholder="Odgovor D"
                value={
                  nizPitanja[indikator].d !== "" &&
                  nizPitanja[indikator].d !== null &&
                  nizPitanja[indikator].d !== undefined
                    ? nizPitanja[indikator].d
                    : ""
                }
                onChange={(e) => {
                  let newArr = [...nizPitanja];
                  newArr[indikator].d = e.target.value;
                  setNizPitanja(newArr);
                }}
              />
            </div>
            <div className="listPieceRowImage">
              {nizPitanja[indikator].slika !== "" &&
                nizPitanja[indikator].slika !== null &&
                nizPitanja[indikator].slika !== undefined && (
                  <img src={nizPitanja[indikator].slika} alt="" />
                )}
              {(nizPitanja[indikator].slika === "" ||
                nizPitanja[indikator].slika === null ||
                nizPitanja[indikator].slika === undefined) && (
                <button onClick={() => setIsSlikaZaPitanje(true)}>
                  Dodaj sliku
                </button>
              )}
              <h2>Tačan odgovor</h2>
              <div className="listPieceRowImageButtons">
                <button
                  className={
                    nizPitanja[indikator].tacanOdgovor === "a" ? "correct" : ""
                  }
                  onClick={() => {
                    let newArr = [...nizPitanja];
                    newArr[indikator].tacanOdgovor = "a";
                    setNizPitanja(newArr);
                  }}
                >
                  A
                </button>
                <button
                  className={
                    nizPitanja[indikator].tacanOdgovor === "b" ? "correct" : ""
                  }
                  onClick={() => {
                    let newArr = [...nizPitanja];
                    newArr[indikator].tacanOdgovor = "b";
                    setNizPitanja(newArr);
                  }}
                >
                  B
                </button>
                <button
                  className={
                    nizPitanja[indikator].tacanOdgovor === "c" ? "correct" : ""
                  }
                  onClick={() => {
                    let newArr = [...nizPitanja];
                    newArr[indikator].tacanOdgovor = "c";
                    setNizPitanja(newArr);
                  }}
                >
                  C
                </button>
                <button
                  className={
                    nizPitanja[indikator].tacanOdgovor === "d" ? "correct" : ""
                  }
                  onClick={() => {
                    let newArr = [...nizPitanja];
                    newArr[indikator].tacanOdgovor = "d";
                    setNizPitanja(newArr);
                  }}
                >
                  D
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSlikaZaPitanje && (
        <NewUploadOverlay
          setIsNewUO={setIsSlikaZaPitanje}
          tip="test"
          setSlikuZaPitanje={setSlikuZaPitanje}
        />
      )}
      {isDesc && (
        <TestDescription
          testDescription={opis}
          setIsDesc={setIsDesc}
          setTestDescription={setOpis}
        />
      )}
    </>
  );
};

export default NewTest;
