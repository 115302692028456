import React, { useContext, useState } from "react";
import "../styles/newSTOverlay.scss";
import { Grupa, UserData, autoSkola } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { doc, setDoc, updateDoc, arrayUnion, getDoc } from "firebase/firestore";
import { grupeRef, korisniciRef, skoleRef } from "../utils/firebase";
import _ from "lodash";
import useKeyPress from "react-use-keypress";
import uuid from "react-uuid";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface Props {
  korisnik: UserData;
  setPolozioTChange: any;
  dohvatiKorisnika: any;
}

export function TheoryChange(props: Props) {
  const { korisnik, setPolozioTChange, dohvatiKorisnika } = props;
  const { setLoading, setAlert, setIsAlert } = useContext(AppContext);
  const [polozioT, setPoloziOT] = useState<boolean>(
    korisnik.polozioTeoriju !== null && korisnik.polozioTeoriju !== undefined
      ? korisnik.polozioTeoriju
      : false
  );
  const [polozioTeorijuIme, setPolozioTeorijuIme] = useState<string>(
    korisnik.polozioTeoriju !== null && korisnik.polozioTeoriju !== undefined
      ? korisnik.polozioTeoriju === true
        ? "Da"
        : "Ne"
      : "Ne"
  );
  useKeyPress("Escape", () => {
    setPolozioTChange(false);
  });
  useKeyPress("Enter", () => {
    promeniTeoriju();
  });
  function handleChange(event: SelectChangeEvent) {
    setPolozioTeorijuIme(event.target.value);
  }

  function promeniTeoriju() {
    setLoading(true);
    if (korisnik === undefined || korisnik === null) return;

    updateDoc(doc(korisniciRef, korisnik.id), {
      polozioTeoriju: polozioT,
    }).then(() => {
      const korisnikPodaci: UserData = {
        autoskolaId: korisnik.autoskolaId,
        email: korisnik.email,
        id: korisnik.id,
        ime: korisnik.ime,
        profilnaURL: korisnik.profilnaURL,
        uloga: korisnik.uloga,
        aktiviran: korisnik.aktiviran,
        brNocnih: korisnik.brNocnih,
        brOdvozanihCasova: korisnik.brOdvozanihCasova,
        datum: korisnik.datum,
        editovanInfoPrviPut: korisnik.editovanInfoPrviPut,
        grupa: korisnik.grupa,
        koJeInstruktorID: korisnik.koJeInstruktorID,
        grupaID: korisnik.grupaID,
        koJeInstruktorIme: korisnik.koJeInstruktorIme,
        krediti: korisnik.krediti,
        polozioTeoriju: polozioT,
        telefon: korisnik.telefon,
        uploads: korisnik.uploads,
        voziAuto: korisnik.voziAuto,
        nizOdvozanihTermina: korisnik.nizOdvozanihTermina,
        nizStudenata: korisnik.nizStudenata,
        nizZakazanihTermina: korisnik.nizZakazanihTermina,
        prikazivanjeTermina: korisnik.prikazivanjeTermina,
        nedeljnaKvota: korisnik.nedeljnaKvota,
        subscribed: korisnik.subscribed,
      };
      getDoc(doc(skoleRef, korisnik.autoskolaId)).then((skola) => {
        const skolaData = skola.data();
        //UPDATE GRUPE AKO POSTOJI
        if (
          korisnik.grupaID !== null &&
          korisnik.grupaID !== undefined &&
          korisnik.grupaID !== ""
        ) {
          const trenutnaGrupa = _.find(skolaData?.grupe, function (o) {
            if (o.id === korisnik.grupaID) return o;
          });
          const filterStudenataUTrenutnojGrupi = _.filter(
            trenutnaGrupa.studenti,
            function (o) {
              if (o.id !== korisnik.id) return o;
            }
          );
          const filterGrupaBezTrenutne = _.filter(
            skolaData?.grupe,
            function (o) {
              if (o.id !== korisnik.grupaID) return o;
            }
          );
          const trenutnaGrupaObjekat: Grupa = {
            datum: trenutnaGrupa.datum,
            id: trenutnaGrupa.id,
            ime: trenutnaGrupa.ime,
            kreirao: trenutnaGrupa.kreirao,
            kreiraoID: trenutnaGrupa.kreiraoID,
            studenti: [...filterStudenataUTrenutnojGrupi, korisnikPodaci],
          };
          updateDoc(doc(grupeRef, korisnik.grupaID), {
            studenti: [...filterStudenataUTrenutnojGrupi, korisnikPodaci],
          }).then(() => {
            updateDoc(doc(skoleRef, korisnik.autoskolaId), {
              grupe: [...filterGrupaBezTrenutne, trenutnaGrupaObjekat],
            });
          });
        }
        //UPDATE INSTRUKTORA AKO GA IMA
        if (
          korisnik.koJeInstruktorID !== null &&
          korisnik.koJeInstruktorID !== undefined &&
          korisnik.koJeInstruktorID !== "" &&
          korisnik.uloga === "student"
        ) {
          getDoc(doc(korisniciRef, korisnik.koJeInstruktorID)).then(
            (instruktor) => {
              const instruktorData = instruktor.data() as UserData;
              const filtriraniNizStudenataKodInstruktora = _.filter(
                instruktorData.nizStudenata,
                function (o) {
                  if (o.id !== korisnik.id) return o;
                }
              );
              const filterStudenataBezOvog: Array<UserData> =
                filtriraniNizStudenataKodInstruktora as Array<UserData>;
              const newInstruktorData: UserData = {
                aktiviran: instruktorData.aktiviran,
                autoskolaId: instruktorData.autoskolaId,
                brNocnih: instruktorData.brNocnih,
                brOdvozanihCasova: instruktorData.brOdvozanihCasova,
                datum: instruktorData.datum,
                editovanInfoPrviPut: instruktorData.editovanInfoPrviPut,
                email: instruktorData.email,
                grupa: instruktorData.grupa,
                grupaID: instruktorData.grupaID,
                id: instruktorData.id,
                ime: instruktorData.ime,
                koJeInstruktorID: instruktorData.koJeInstruktorID,
                koJeInstruktorIme: instruktorData.koJeInstruktorIme,
                krediti: instruktorData.krediti,
                nizOdvozanihTermina: instruktorData.nizOdvozanihTermina,
                nizStudenata: [...filterStudenataBezOvog, korisnikPodaci],
                nizZakazanihTermina: instruktorData.nizZakazanihTermina,
                polozioTeoriju: instruktorData.polozioTeoriju,
                prikazivanjeTermina: instruktorData.prikazivanjeTermina,
                profilnaURL: instruktorData.profilnaURL,
                telefon: instruktorData.telefon,
                uloga: instruktorData.uloga,
                uploads: instruktorData.uploads,
                voziAuto: instruktorData.voziAuto,
                nedeljnaKvota: instruktorData.nedeljnaKvota,
                subscribed: instruktorData.subscribed,
              };
              getDoc(doc(skoleRef, korisnik.autoskolaId)).then((skola) => {
                const skolaInfo = skola.data() as autoSkola;
                const filterInstruktora = _.filter(
                  skolaInfo.instruktori,
                  function (o) {
                    if (o.id !== instruktorData.id) return o;
                  }
                );
                const nizInstruktora: Array<UserData> =
                  filterInstruktora as Array<UserData>;

                updateDoc(doc(skoleRef, korisnik.autoskolaId), {
                  instruktori: [...nizInstruktora, newInstruktorData],
                }).then(() => {
                  updateDoc(doc(korisniciRef, instruktorData.id), {
                    nizStudenata: [...filterStudenataBezOvog, korisnikPodaci],
                  });
                });
              });
            }
          );
        }
        if (korisnik.uloga === "student") {
          const filtriraniNizStudenata = _.filter(
            skolaData?.studenti,
            function (o) {
              if (o.id !== korisnik.id) return o;
            }
          );

          updateDoc(doc(skoleRef, korisnik.autoskolaId), {
            studenti: [...filtriraniNizStudenata, korisnikPodaci],
          }).then(() => {
            setAlert("Uspešno promenjena teorija");
            dohvatiKorisnika();
            setPolozioTChange(false);
            setIsAlert(true);
            setLoading(false);
          });
        }
      });
    });
  }

  return (
    <div className="newSTOverlay">
      <div className="newSTBox">
        <h2>Položena teorija?</h2>
        <FormControl fullWidth className="izborDatumaMUI">
          <InputLabel id="izborGrupe">Polozio teoriju?</InputLabel>
          <Select
            label="Polozio teoriju?"
            labelId="polozioTeoriju"
            id="polozioTeoriju"
            onChange={handleChange}
            className="izborDatumaMUI"
            value={polozioTeorijuIme}
          >
            <MenuItem value={"Da"} onClick={() => setPoloziOT(true)}>
              Da
            </MenuItem>
            <MenuItem value={"Ne"} onClick={() => setPoloziOT(false)}>
              Ne
            </MenuItem>
          </Select>
        </FormControl>
        <div className="buttonOptions">
          <button onClick={() => promeniTeoriju()}>Potvrdi</button>
          <button onClick={() => setPolozioTChange(false)}>Odustani</button>
        </div>
        <button
          className="x"
          onClick={() => {
            setPolozioTChange(false);
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}
