import React, { useContext, useState } from "react";
import "../styles/newSTOverlay.scss";
import { Grupa, UserData } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { doc, setDoc, updateDoc, arrayUnion, getDoc } from "firebase/firestore";
import { grupeRef, korisniciRef, skoleRef } from "../utils/firebase";
import _ from "lodash";
import useKeyPress from "react-use-keypress";
import uuid from "react-uuid";

interface Props {
  korisnik: UserData;
  setIsCarChange: any;
  dohvatiKorisnika: any;
}

export function CarChange(props: Props) {
  const { korisnik, setIsCarChange, dohvatiKorisnika } = props;
  const { setLoading, setAlert, setIsAlert } = useContext(AppContext);
  const [ime, setIme] = useState<string>(korisnik.ime);

  useKeyPress("Escape", () => {
    setIsCarChange(false);
  });
  useKeyPress("Enter", () => {
    promeniAuto();
  });

  function promeniAuto() {
    setLoading(true);
    if (korisnik === undefined || korisnik === null) return;

    updateDoc(doc(korisniciRef, korisnik.id), {
      voziAuto: ime,
    }).then(() => {
      const korisnikPodaci: UserData = {
        autoskolaId: korisnik.autoskolaId,
        email: korisnik.email,
        id: korisnik.id,
        ime: korisnik.ime,
        profilnaURL: korisnik.profilnaURL,
        uloga: korisnik.uloga,
        aktiviran: korisnik.aktiviran,
        brNocnih: korisnik.brNocnih,
        brOdvozanihCasova: korisnik.brOdvozanihCasova,
        datum: korisnik.datum,
        editovanInfoPrviPut: korisnik.editovanInfoPrviPut,
        grupa: korisnik.grupa,
        koJeInstruktorID: korisnik.koJeInstruktorID,
        grupaID: korisnik.grupaID,
        koJeInstruktorIme: korisnik.koJeInstruktorIme,
        krediti: korisnik.krediti,
        polozioTeoriju: korisnik.polozioTeoriju,
        telefon: korisnik.telefon,
        uploads: korisnik.uploads,
        voziAuto: ime,
        nizOdvozanihTermina: korisnik.nizOdvozanihTermina,
        nizStudenata: korisnik.nizStudenata,
        nizZakazanihTermina: korisnik.nizZakazanihTermina,
        prikazivanjeTermina: korisnik.prikazivanjeTermina,
        nedeljnaKvota: korisnik.nedeljnaKvota,
        subscribed: korisnik.subscribed,
      };
      getDoc(doc(skoleRef, korisnik.autoskolaId)).then((skola) => {
        const skolaData = skola.data();

        if (korisnik.uloga === "schoolinstructor") {
          const filtriraniNizInstruktora = _.filter(
            skolaData?.instruktori,
            function (o) {
              if (o.id !== korisnik.id) return o;
            }
          );

          updateDoc(doc(skoleRef, korisnik.autoskolaId), {
            instruktori: [...filtriraniNizInstruktora, korisnikPodaci],
          }).then(() => {
            setAlert("Uspešno promenjen auto");
            setIsAlert(true);
            setLoading(false);
            dohvatiKorisnika();
            setIsCarChange(false);
          });
        }
      });
    });
  }

  return (
    <div className="newSTOverlay">
      <div className="newSTBox">
        <h2>Promena automobila</h2>
        <input
          type="text"
          name="novoIme"
          id="novoIme"
          placeholder="Naziv automobila"
          onChange={(e) => setIme(e.target.value)}
        />
        <div className="buttonOptions">
          <button onClick={() => promeniAuto()}>Potvrdi</button>
          <button onClick={() => setIsCarChange(false)}>Odustani</button>
        </div>
        <button
          className="x"
          onClick={() => {
            setIsCarChange(false);
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}
