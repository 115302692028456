import React, { useContext, useEffect, useState } from "react";
import "../styles/home.scss";
import {
  BsPersonAdd,
  BsSortNumericDownAlt,
  BsSortNumericUpAlt,
  BsTrashFill,
} from "react-icons/bs";
import {
  AiFillEye,
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { BiSortDown } from "react-icons/bi";
import { Grupa, UserData } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { grupeRef, skoleRef } from "../utils/firebase";
import { MdArrowBack, MdAttachMoney, MdGroupAdd } from "react-icons/md";
import _ from "lodash";
import { NewGroupOverlay } from "../components/NewGroupOverlay";
import { AddStudentsToGroups } from "../components/AddStudentsToGroups";
import { useNavigate, useParams } from "react-router";
import { IconContext } from "react-icons/lib";
import { KreditChange } from "../components/KreditChange";

interface Props {}

export function GrupaPage(props: Props) {
  const [grupa, setGrupa] = useState<Grupa>();
  const [nizStudenata, setNizStudnata] = useState<Array<UserData>>([]);
  const [sekundarniNiz, setSekundarniNiz] = useState<Array<UserData>>([]);
  const [nizStudenataBezGrupe, setNizStudenataBezGrupe] = useState<
    Array<UserData>
  >([]);

  const [komStudentuMenjamo, setKomStudentuMenjamo] = useState<UserData>();
  const [isKreditChange, setKreditChange] = useState<boolean>(false);

  const [nizGrupa, setNizGrupa] = useState<Array<Grupa>>([]);
  const [trenutniSort, setTrenutniSort] = useState<
    "imeAZ" | "imeZA" | "datumAsc" | "datumDesc"
  >();
  const [isASTG, setIsASTG] = useState<boolean>(false);
  const { grupaID } = useParams();
  const { setLoading, currentUserData } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (grupaID !== undefined && grupaID !== null && grupaID !== "") {
      dohvatiGrupu();
    }
  }, [grupaID, currentUserData]);
  useEffect(() => {
    if (
      (trenutniSort === null || trenutniSort === undefined) &&
      sekundarniNiz !== null &&
      sekundarniNiz !== undefined &&
      sekundarniNiz.length !== 0
    ) {
      sortIme();
    }
  }, [sekundarniNiz]);
  function dohvatiGrupu() {
    if (
      currentUserData === null ||
      currentUserData === undefined ||
      skoleRef === null ||
      skoleRef === undefined ||
      currentUserData.autoskolaId === null ||
      currentUserData.autoskolaId === undefined ||
      currentUserData.autoskolaId === ""
    )
      return;
    getDoc(doc(skoleRef, currentUserData.autoskolaId)).then((skola) => {
      const grupe = skola?.data()?.grupe as Array<Grupa>;
      const nizStudenata = skola?.data()?.studenti as Array<UserData>;
      const filtriraniNizStudenata = _.filter(nizStudenata, function (o) {
        if (o.grupaID === "") return o;
      });
      setNizStudenataBezGrupe(filtriraniNizStudenata as Array<UserData>);
      setNizGrupa(grupe);
    });
    getDoc(doc(grupeRef, grupaID)).then((grupa) => {
      setGrupa(grupa.data() as Grupa);
      setNizStudnata(grupa.data()?.studenti as Array<UserData>);
      setSekundarniNiz(grupa.data()?.studenti as Array<UserData>);
      setLoading(false);
    });
  }
  function search(q: string) {
    const duzina = q.length;
    const niz = nizStudenata;
    if (q === "") {
      setSekundarniNiz(nizStudenata);
      return;
    }
    const filtriranNiz = _.filter(niz, function (o) {
      const ime = o.ime;
      console.log(
        "FILTER PRVI",
        ime.toLowerCase().substring(0, duzina),
        q.toLowerCase().substring(0, duzina)
      );
      if (
        ime.toLowerCase().substring(0, duzina) ===
          q.toLowerCase().substring(0, duzina) ||
        ime.toLowerCase().split(" ")[1].substring(0, duzina) ===
          q.toLowerCase().substring(0, duzina)
      )
        return o;
    });
    console.log("FILTER", filtriranNiz);
    setSekundarniNiz(filtriranNiz as Array<UserData>);
  }
  function sortIme() {
    const niz = nizStudenata;
    const sortiraniNiz = _.orderBy(niz, ["ime"], ["asc"]);
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("imeAZ");
  }
  function sortImeDesc() {
    const niz = nizStudenata;
    const sortiraniNiz = _.orderBy(niz, ["ime"], ["desc"]);
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("imeZA");
  }
  function sortDateAsc() {
    const niz = nizStudenata;
    const sortiraniNiz = niz.sort((a, b) => {
      const dateA = new Date(a.datum as string);
      const dateB = new Date(b.datum as string);
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    });
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("datumAsc");
  }
  function sortDateDesc() {
    const niz = nizStudenata;
    const sortiraniNiz = [...niz].sort((a, b) => {
      const dateA = new Date(a.datum as string);
      const dateB = new Date(b.datum as string);

      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      return 0;
    });
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("datumDesc");
  }
  return (
    <>
      <div className="welcome">
        <div className="welcomeTitle">
          <IconContext.Provider value={{ className: "backIkonica" }}>
            <div className="ikonicaOmotac" onClick={() => navigate(-1)}>
              <MdArrowBack />
            </div>
          </IconContext.Provider>
          <h2>Grupa {grupa?.ime}</h2>
        </div>
        <input
          type="text"
          name="pretragaStudenata"
          id="pretragaStudenata"
          placeholder="Pretražite"
          onChange={(e) => search(e.target.value)}
        />
      </div>
      <div className="optionsShortcuts">
        <div className="shortcut" onClick={() => setIsASTG(true)}>
          <div className="ikonicaWrap">
            <BsPersonAdd color="white" size={50} />
          </div>
          <h3>Dodaj kandidata</h3>
        </div>
        <div className="shortcut" onClick={() => sortIme()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "imeAZ" ? "correct" : ""
            }`}
          >
            <AiOutlineSortAscending color="white" size={50} />
          </div>
          <h3>Ime A-Z</h3>
        </div>
        <div className="shortcut" onClick={() => sortImeDesc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "imeZA" ? "correct" : ""
            }`}
          >
            <AiOutlineSortDescending color="white" size={50} />
          </div>
          <h3>Ime Z-A</h3>
        </div>
        <div className="shortcut" onClick={() => sortDateDesc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "datumDesc" ? "correct" : ""
            }`}
          >
            <BsSortNumericDownAlt color="white" size={50} />
          </div>
          <h3>Najnoviji - Najstariji</h3>
        </div>
        <div className="shortcut" onClick={() => sortDateAsc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "datumAsc" ? "correct" : ""
            }`}
          >
            <BsSortNumericUpAlt color="white" size={50} />
          </div>
          <h3>Najstariji - Najnoviji</h3>
        </div>
      </div>
      <div className="list">
        {sekundarniNiz.map((student) => {
          return (
            <div className="listPiece">
              <div className="pieceInfo">
                <img src={student.profilnaURL} alt={student.ime} />
                <div className="pieceInfoText">
                  <h3>{student.ime}</h3>
                  <p>
                    {student.aktiviran === true &&
                      `Krediti: ${student.krediti}`}
                  </p>
                  {student.aktiviran === false && <h4>Nalog nije aktiviran</h4>}
                  {student.aktiviran !== false && (
                    <p>
                      Položena teorija:
                      {student.polozioTeoriju === true ? "Da" : "Ne"}
                    </p>
                  )}
                  <p>
                    {student.polozioTeoriju === true && "Odvozani časovi: "}
                    {student.polozioTeoriju === true &&
                      student.brOdvozanihCasova}
                  </p>
                  <p>
                    {student.polozioTeoriju === true && "Instruktor: "}
                    {student.polozioTeoriju === true &&
                      student.koJeInstruktorIme}
                  </p>
                  <p>
                    {student.polozioTeoriju === false &&
                      student.aktiviran !== false &&
                      `Grupa: ${student.grupa}`}
                  </p>
                </div>
              </div>
              <div className="pieceOptions">
                {student.aktiviran === true && (
                  <div
                    className="pieceOption"
                    onClick={() => navigate(`/profile/${student.id}`)}
                  >
                    <div className="pieceOptionIcon">
                      <AiFillEye color="white" size={50} />
                    </div>
                    <p>Profil</p>
                  </div>
                )}
                {student.aktiviran === true && (
                  <div
                    className="pieceOption"
                    onClick={() => {
                      setKreditChange(true);
                      setKomStudentuMenjamo(student);
                    }}
                  >
                    <div className="pieceOptionIcon">
                      <MdAttachMoney color="white" size={50} />
                    </div>
                    <p>Dodaj kredite</p>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {isASTG && (
        <AddStudentsToGroups
          dohvatiStudenteIGrupe={dohvatiGrupu}
          nizStudenata={nizStudenataBezGrupe}
          setIsASTG={setIsASTG}
          grupa={grupa as Grupa}
          nizGrupa={nizGrupa}
        />
      )}
      {isKreditChange && (
        <KreditChange
          korisnik={komStudentuMenjamo as UserData}
          setKreditChange={setKreditChange}
          dohvatiKorisnika={dohvatiGrupu}
        />
      )}
    </>
  );
}
