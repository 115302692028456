import React, { useContext, useState } from "react";
import { getAuth } from "firebase/auth";
import { arrayUnion, doc, setDoc, updateDoc } from "firebase/firestore";
import { AppContext } from "../functions/AppProvider";
import {
  noviKorisniciRef,
  sendUltra,
  skoleRef,
  terminiRef,
} from "../utils/firebase";
import { UserData } from "../utils/types";
import _ from "lodash";
import useKeyPress from "react-use-keypress";

import "../styles/newSTOverlay.scss";

interface Props {
  setIsNewSI: any;
  dohvatiInstruktore: any;
}

export function NewSIOverlay(props: Props) {
  const { setIsNewSI, dohvatiInstruktore } = props;
  const [email, setEmail] = useState<string>("");
  const [auto, setAuto] = useState<string>("");
  const [ime, setIme] = useState<string>("");
  const [brojTelefona, setBrojTelefona] = useState<string>("");
  const { currentUserData, setLoading, setAlert, setIsAlert } =
    useContext(AppContext);
  const auth = getAuth();

  useKeyPress("Enter", () => {
    dodajNovogInstruktora();
  });
  useKeyPress("Escape", () => {
    setIsNewSI(false);
  });

  const dodajNovogInstruktora = async () => {
    if (currentUserData === null || currentUserData === undefined) return;
    setLoading(true);

    if (ime === "" || email === "" || brojTelefona === "" || auto === "") {
      setAlert("Sva polja moraju biti popunjena!");
      setIsAlert(true);
      setLoading(false);
      return;
    }

    const datum = new Date();

    const userData: UserData = {
      autoskolaId: currentUserData.autoskolaId,
      email: email,
      id: "",
      ime: ime,
      profilnaURL:
        "https://firebasestorage.googleapis.com/v0/b/pas1-64f21.appspot.com/o/files%2FDarkProfile.png?alt=media&token=2671c205-04a5-435c-8875-d3624c62da81",
      uloga: "schoolinstructor",
      datum: datum.toString(),
      telefon: brojTelefona,
      aktiviran: false,
      brNocnih: 0,
      brOdvozanihCasova: 0,
      koJeInstruktorID: "",
      koJeInstruktorIme: "",
      polozioTeoriju: false,
      voziAuto: auto,
      editovanInfoPrviPut: false,
      grupa: "",
      grupaID: "",
      krediti: 0,
      nizOdvozanihTermina: [],
      nizStudenata: [],
      nizZakazanihTermina: [],
      uploads: [],
      prikazivanjeTermina: "Default",
      nedeljnaKvota: 3,
      subscribed: false,
    };

    await setDoc(doc(noviKorisniciRef, email), userData).then(async () => {
      setIsNewSI(false);
      await updateDoc(doc(skoleRef, currentUserData.autoskolaId), {
        noviInstruktori: arrayUnion(userData),
      }).then(() => {
        // sendUltra(
        //   `+381${brojTelefona}`,
        //   `Poštovani, ${ime}, \nKreiran Vam je nalog na Voznja-rs od strane Vaše auto škole ${currentUserData.autoskolaId}.\n\n Vaš email za prijavu je ${email}\nPrilikom prve prijave unosite šifru koju želite, nakon toga uvek koristite nju.\n\nVaš VoznjaRS tim!`
        // );
        dohvatiInstruktore();
        setAlert("Uspešno kreiran instruktor.");
        setIsAlert(true);
        setLoading(false);
      });
    });
  };
  return (
    <div className="newSTOverlay">
      <div className="newSTBox">
        <h2>Dodajte novog instruktora</h2>
        <label htmlFor="imeInstruktora">Ime prezime</label>
        <input
          type="text"
          name="imeInstruktora"
          id="imeInstruktora"
          placeholder="Ime Prezime"
          onChange={(e) => setIme(e.target.value)}
          required
        />
        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="emailInstruktora"
          id="emailInstruktora"
          placeholder="Email instruktora"
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="auto">Auto</label>
        <input
          type="text"
          name="auto"
          id="auto"
          placeholder="Auto"
          required
          onChange={(e) => setAuto(e.target.value)}
        />
        <label htmlFor="brTel">Broj telefona</label>
        <input
          type="text"
          name="brojTel"
          id="brojTel"
          placeholder="Broj telefona"
          onChange={(e) => setBrojTelefona(e.target.value)}
        />
        <div className="buttonOptions">
          <button onClick={() => dodajNovogInstruktora()}>Potvrdi</button>
          <button onClick={() => setIsNewSI(false)}>Odustani</button>
        </div>
        <button
          className="x"
          onClick={() => {
            setIsNewSI(false);
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}
