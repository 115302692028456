import React, { useContext, useEffect, useState } from "react";
import "../styles/home.scss";
import {
  BsMailbox,
  BsPersonAdd,
  BsSortNumericDownAlt,
  BsSortNumericUpAlt,
  BsTrashFill,
} from "react-icons/bs";
import {
  AiFillEdit,
  AiFillEye,
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { BiSortDown } from "react-icons/bi";
import { Grupa, UserData, Zahtev, autoSkola } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db, noviKorisniciRef, skoleRef, zahteviRef } from "../utils/firebase";
import { MdArrowBack } from "react-icons/md";
import _ from "lodash";
import { NewSSOverlay } from "../components/NewSSOverlay";
import { IconContext } from "react-icons/lib";
import { useNavigate } from "react-router";

interface Props {}

export function Zahtevi(props: Props) {
  const [nizZahteva, setNizZahteva] = useState<Array<Zahtev>>([]);
  const [sekundarniNiz, setSekundarniNiz] = useState<Array<Zahtev>>([]);
  const [isNewSS, setIsNewSS] = useState<boolean>(false);
  const [kojiZahtev, setKojiZahtev] = useState<Zahtev>();
  const [komStudentuMenjamo, setKomStudentuMenjamo] = useState<UserData>();
  const { currentUserData, setLoading, setAlert, setIsAlert } =
    useContext(AppContext);
  const [trenutniSort, setTrenutniSort] = useState<
    "imeAZ" | "imeZA" | "datumAsc" | "datumDesc"
  >();
  const navigate = useNavigate();
  useEffect(() => {
    dohvatiStudente();
  }, [currentUserData]);
  useEffect(() => {
    if (
      (trenutniSort === null || trenutniSort === undefined) &&
      sekundarniNiz !== null &&
      sekundarniNiz !== undefined &&
      sekundarniNiz.length !== 0
    ) {
      sortIme();
    }
  }, [sekundarniNiz]);
  async function dohvatiStudente() {
    console.log("DOHVATAM");
    if (
      currentUserData === null ||
      currentUserData === undefined ||
      currentUserData.autoskolaId === null ||
      currentUserData.autoskolaId === undefined ||
      currentUserData.autoskolaId === "" ||
      skoleRef === null ||
      skoleRef === undefined
    )
      return;
    setLoading(true);
    console.log("SKOLA 1", currentUserData);

    // getDoc(doc(skoleRef, currentUserData.autoskolaId)).then((skola) => {
    //   console.log("SKOLA", skola?.data()?.studenti);
    //   const nizNovih = skola?.data()?.noviStudenti;
    //   const nizPostojecih = skola?.data()?.studenti;
    //   if (nizNovih !== undefined && nizNovih !== null) {
    //     const spojeniNiz = _.concat(nizNovih, nizPostojecih);
    //     setNizStudenata(spojeniNiz);
    //     setSekundarniNiz(spojeniNiz);
    //     setLoading(false);
    //   } else {
    //     setNizStudenata(nizPostojecih);
    //     setSekundarniNiz(nizPostojecih);
    //     setLoading(false);
    //   }
    // });

    const querySnap = await getDocs(collection(db, "zahtevi"));
    const zahtevi = querySnap.docs.map((doc) => ({ ...doc.data() }));
    setNizZahteva(zahtevi as Array<Zahtev>);
    setSekundarniNiz(zahtevi as Array<Zahtev>);
    setLoading(false);
  }
  function search(q: string) {
    const duzina = q.length;
    const niz = nizZahteva;
    if (q === "") {
      setSekundarniNiz(nizZahteva);
      return;
    }
    const filtriranNiz = _.filter(niz, function (o) {
      const ime = o.ime;
      console.log(
        "FILTER PRVI",
        ime.toLowerCase().substring(0, duzina),
        q.toLowerCase().substring(0, duzina)
      );
      if (
        ime.toLowerCase().substring(0, duzina) ===
          q.toLowerCase().substring(0, duzina) ||
        ime.toLowerCase().split(" ")[1].substring(0, duzina) ===
          q.toLowerCase().substring(0, duzina)
      )
        return o;
    });
    console.log("FILTER", filtriranNiz);
    setSekundarniNiz(filtriranNiz as Array<Zahtev>);
  }
  function sortIme() {
    const niz = nizZahteva;
    const sortiraniNiz = _.orderBy(niz, ["ime"], ["asc"]);
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("imeAZ");
  }
  function sortImeDesc() {
    const niz = nizZahteva;
    const sortiraniNiz = _.orderBy(niz, ["ime"], ["desc"]);
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("imeZA");
  }
  function sortDateAsc() {
    const niz = nizZahteva;
    const sortiraniNiz = niz.sort((a, b) => {
      const dateA = new Date(a.datum as string);
      const dateB = new Date(b.datum as string);
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    });
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("datumAsc");
  }
  function sortDateDesc() {
    const niz = nizZahteva;
    const sortiraniNiz = [...niz].sort((a, b) => {
      const dateA = new Date(a.datum as string);
      const dateB = new Date(b.datum as string);

      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      return 0;
    });
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("datumDesc");
  }

  function obrisiZahtev(kojiZahtev: Zahtev) {
    if (currentUserData === null || currentUserData === undefined) return;

    deleteDoc(doc(zahteviRef, kojiZahtev.email)).then(() => {
      setAlert("Uspešno obrisan kandidat");
      setIsAlert(true);
      dohvatiStudente();
      setLoading(false);
    });
  }

  return (
    <>
      <div className="welcome">
        <div className="welcomeTitle">
          <IconContext.Provider value={{ className: "backIkonica" }}>
            <div className="ikonicaOmotac" onClick={() => navigate(-1)}>
              <MdArrowBack />
            </div>
          </IconContext.Provider>
          <h2>Zahtevi ({nizZahteva.length})</h2>
        </div>
        <input
          type="text"
          name="pretragaStudenata"
          id="pretragaStudenata"
          placeholder="Pretražite"
          onChange={(e) => search(e.target.value)}
        />
      </div>
      <div className="optionsShortcuts">
        <div className="shortcut" onClick={() => sortIme()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "imeAZ" ? "correct" : ""
            }`}
          >
            <AiOutlineSortAscending color="white" size={50} />
          </div>
          <h3>Ime A-Z</h3>
        </div>
        <div className="shortcut" onClick={() => sortImeDesc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "imeZA" ? "correct" : ""
            }`}
          >
            <AiOutlineSortDescending color="white" size={50} />
          </div>
          <h3>Ime Z-A</h3>
        </div>
        <div className="shortcut" onClick={() => sortDateDesc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "datumDesc" ? "correct" : ""
            }`}
          >
            <BsSortNumericDownAlt color="white" size={50} />
          </div>
          <h3>Najnoviji - Najstariji</h3>
        </div>
        <div className="shortcut" onClick={() => sortDateAsc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "datumAsc" ? "correct" : ""
            }`}
          >
            <BsSortNumericUpAlt color="white" size={50} />
          </div>
          <h3>Najstariji - Najnoviji</h3>
        </div>
      </div>
      <div className="list">
        {sekundarniNiz.map((zahtev) => {
          //   if (zahtev.skola !== currentUserData?.autoskolaId) return;
          return (
            <div className="listPiece">
              <div className="pieceInfo">
                <img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/pas1-64f21.appspot.com/o/files%2FDarkProfile.png?alt=media&token=2671c205-04a5-435c-8875-d3624c62da81"
                  }
                  alt={zahtev.ime}
                />
                <div className="pieceInfoText">
                  <h3>{zahtev.ime}</h3>
                  <p>Obuka: {zahtev.obuka}</p>
                  <p>Email: {zahtev.email}</p>
                  <p>Telefon: {zahtev.broj}</p>
                  {zahtev.obuka === "Praktična Obuka" && (
                    <p>Broj odvozanih časova: {zahtev.brOdC}</p>
                  )}
                  <p>
                    Datum: {new Date(zahtev.datum as string).getDate()}.
                    {new Date(zahtev.datum as string).getMonth() + 1}.
                    {new Date(zahtev.datum as string).getFullYear()}
                  </p>
                </div>
              </div>
              <div className="pieceOptions">
                <div
                  className="pieceOption"
                  onClick={() => {
                    setKojiZahtev(zahtev);
                    setIsNewSS(true);
                  }}
                >
                  <div className="pieceOptionIcon">
                    <BsPersonAdd color="white" size={50} />
                  </div>
                  <p>Kreiraj</p>
                </div>
                <div
                  className="pieceOption"
                  onClick={() => {
                    obrisiZahtev(zahtev);
                  }}
                >
                  <div className="pieceOptionIcon">
                    <BsTrashFill color="white" size={50} />
                  </div>
                  <p>Obriši</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {isNewSS && (
        <NewSSOverlay
          setIsNewSS={setIsNewSS}
          dohvatiStudente={dohvatiStudente}
          brP={kojiZahtev?.broj}
          emailP={kojiZahtev?.email}
          imeP={kojiZahtev?.ime}
          obukaP={kojiZahtev?.obuka}
          brcP={kojiZahtev?.brOdC}
        />
      )}
    </>
  );
}
