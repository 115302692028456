import React, { useContext } from "react";
import "../styles/newSTOverlay.scss";
import { Grupa, UserData, autoSkola } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { grupeRef, korisniciRef, skoleRef } from "../utils/firebase";
import { BsPersonAdd } from "react-icons/bs";
import useKeyPress from "react-use-keypress";
import _ from "lodash";

interface Props {
  setIsASTG: any;
  dohvatiStudenteIGrupe: any;
  nizStudenata: Array<UserData>;
  grupa: Grupa;
  nizGrupa: Array<Grupa>;
}

export function AddStudentsToGroups(props: Props) {
  const { dohvatiStudenteIGrupe, setIsASTG, nizStudenata, grupa, nizGrupa } =
    props;

  const { setIsAlert, setAlert, setLoading, currentUserData } =
    useContext(AppContext);
  useKeyPress("Escape", () => {
    setIsASTG(false);
  });

  async function dodajStudentaUGrupu(student: UserData) {
    if (currentUserData === null || currentUserData === undefined) return;
    if (grupa === undefined || grupa === null) return;
    setLoading(true);
    const studentData: UserData = {
      autoskolaId:
        student.autoskolaId !== null && student.autoskolaId !== undefined
          ? student.autoskolaId
          : "",
      email:
        student.email !== null && student.email !== undefined
          ? student.email
          : "",
      id: student.id !== null && student.id !== undefined ? student.id : "",
      ime: student.ime !== null && student.ime !== undefined ? student.ime : "",
      profilnaURL:
        student.profilnaURL !== null && student.profilnaURL !== undefined
          ? student.profilnaURL
          : "",
      uloga:
        student.uloga !== null && student.uloga !== undefined
          ? student.uloga
          : "student",
      aktiviran:
        student.aktiviran !== null && student.aktiviran !== undefined
          ? student.aktiviran
          : true,
      brNocnih:
        student.brNocnih !== null && student.brNocnih !== undefined
          ? student.brNocnih
          : 0,
      brOdvozanihCasova:
        student.brOdvozanihCasova !== null &&
        student.brOdvozanihCasova !== undefined
          ? student.brOdvozanihCasova
          : 0,
      datum:
        student.datum !== null && student.datum !== undefined
          ? student.datum
          : "",
      editovanInfoPrviPut:
        student.editovanInfoPrviPut !== null &&
        student.editovanInfoPrviPut !== undefined
          ? student.editovanInfoPrviPut
          : true,
      grupa: grupa.ime,
      grupaID: grupa.id,
      koJeInstruktorID:
        student.koJeInstruktorID !== null &&
        student.koJeInstruktorID !== undefined
          ? student.koJeInstruktorID
          : "",
      koJeInstruktorIme:
        student.koJeInstruktorIme !== null &&
        student.koJeInstruktorIme !== undefined
          ? student.koJeInstruktorIme
          : "",
      polozioTeoriju:
        student.polozioTeoriju !== null && student.polozioTeoriju !== undefined
          ? student.polozioTeoriju
          : false,
      telefon:
        student.telefon !== null && student.telefon !== undefined
          ? student.telefon
          : "",
      uploads:
        student.uploads !== null && student.uploads !== undefined
          ? student.uploads
          : [],
      voziAuto:
        student.voziAuto !== null && student.voziAuto !== undefined
          ? student.voziAuto
          : "",
      krediti:
        student.krediti !== null && student.krediti !== undefined
          ? student.krediti
          : 0,
      nizOdvozanihTermina:
        student.nizOdvozanihTermina !== null &&
        student.nizOdvozanihTermina !== undefined
          ? student.nizOdvozanihTermina
          : [],
      nizStudenata:
        student.nizStudenata !== null && student.nizStudenata !== undefined
          ? student.nizStudenata
          : [],
      nizZakazanihTermina:
        student.nizZakazanihTermina !== null &&
        student.nizZakazanihTermina !== undefined
          ? student.nizZakazanihTermina
          : [],
      prikazivanjeTermina: student.prikazivanjeTermina,
      nedeljnaKvota: student.nedeljnaKvota,
      subscribed: student.subscribed,
    };

    if (
      student.grupaID !== "" &&
      student.grupaID !== null &&
      student.grupaID !== undefined
    ) {
      const trenutnaGrupa = _.find(nizGrupa, function (o) {
        if (o.id === student.grupaID) return o;
      });
      const trenutnaGrupaObjekat: Grupa = trenutnaGrupa as Grupa;
      if (trenutnaGrupaObjekat !== null && trenutnaGrupaObjekat !== undefined) {
        const filtriraniNizStudenataUTrenutnojGrupi = _.filter(
          trenutnaGrupaObjekat.studenti,
          function (o) {
            if (o.id !== student.id) return o;
          }
        );
        console.log(
          "TRENUTNA GRUPA",
          filtriraniNizStudenataUTrenutnojGrupi,
          trenutnaGrupaObjekat
        );

        const trenutnaGrupaObjekatCeo: Grupa = {
          datum: trenutnaGrupaObjekat.datum,
          id: trenutnaGrupaObjekat.id,
          ime: trenutnaGrupaObjekat.ime,
          kreirao: trenutnaGrupaObjekat.kreirao,
          kreiraoID: trenutnaGrupaObjekat.kreiraoID,
          studenti: filtriraniNizStudenataUTrenutnojGrupi as Array<UserData>,
        };

        const filtriraniNizGrupaBezTrenutne = _.filter(nizGrupa, function (o) {
          if (o.id !== trenutnaGrupaObjekat.id) return o;
        });
        console.log(
          "Filtrirani niz grupa bez trenutne",
          filtriraniNizGrupaBezTrenutne
        );
        console.log("Trenutna grupa objekat ceo", trenutnaGrupaObjekatCeo);
        updateDoc(doc(skoleRef, student.autoskolaId), {
          grupe: [...filtriraniNizGrupaBezTrenutne, trenutnaGrupaObjekatCeo],
        }).then(() => {
          console.log([
            ...filtriraniNizGrupaBezTrenutne,
            trenutnaGrupaObjekatCeo,
          ]);
          updateDoc(doc(grupeRef, trenutnaGrupaObjekat.id), {
            studenti: filtriraniNizStudenataUTrenutnojGrupi,
          }).then(() => {
            console.log(filtriraniNizStudenataUTrenutnojGrupi);
          });
        });
      }
    }
    // DODAJ U NOVU GRUPU STUDENTA I
    updateDoc(doc(grupeRef, grupa.id), {
      studenti: arrayUnion(studentData),
    }).then(() => {
      updateDoc(doc(korisniciRef, studentData.id), {
        grupa: grupa.ime,
        grupaID: grupa.id,
      }).then(() => {
        getDoc(doc(skoleRef, studentData.autoskolaId)).then((skola) => {
          const skolaData = skola.data();

          const filtriraniNizGrupaBezNove = _.filter(
            skola?.data()?.grupe,
            function (o) {
              if (o.id !== grupa.id) return o;
            }
          );
          const novagrupaData: Grupa = {
            datum: grupa.datum,
            id: grupa.id,
            ime: grupa.ime,
            kreirao: grupa.kreirao,
            kreiraoID: grupa.kreiraoID,
            studenti: [...grupa.studenti, studentData],
          };

          if (studentData.uloga === "student") {
            const filtriraniNizStudenata = _.filter(
              skolaData?.studenti,
              function (o) {
                if (o.id !== studentData.id) return o;
              }
            );

            updateDoc(doc(skoleRef, studentData.autoskolaId), {
              studenti: [...filtriraniNizStudenata, studentData],
              grupe: [...filtriraniNizGrupaBezNove, novagrupaData],
            })
              .then(() => {
                if (
                  studentData.koJeInstruktorID !== null &&
                  studentData.koJeInstruktorID !== undefined &&
                  studentData.koJeInstruktorID !== "" &&
                  studentData.uloga === "student"
                ) {
                  getDoc(doc(korisniciRef, studentData.koJeInstruktorID)).then(
                    (instruktor) => {
                      const instruktorData = instruktor.data() as UserData;
                      const filtriraniNizStudenataKodInstruktora = _.filter(
                        instruktorData.nizStudenata,
                        function (o) {
                          if (o.id !== studentData.id) return o;
                        }
                      );
                      const filterStudenataBezOvog: Array<UserData> =
                        filtriraniNizStudenataKodInstruktora as Array<UserData>;
                      const newInstruktorData: UserData = {
                        aktiviran: instruktorData.aktiviran,
                        autoskolaId: instruktorData.autoskolaId,
                        brNocnih: instruktorData.brNocnih,
                        brOdvozanihCasova: instruktorData.brOdvozanihCasova,
                        datum: instruktorData.datum,
                        editovanInfoPrviPut: instruktorData.editovanInfoPrviPut,
                        email: instruktorData.email,
                        grupa: instruktorData.grupa,
                        grupaID: instruktorData.grupaID,
                        id: instruktorData.id,
                        ime: instruktorData.ime,
                        koJeInstruktorID: instruktorData.koJeInstruktorID,
                        koJeInstruktorIme: instruktorData.koJeInstruktorIme,
                        krediti: instruktorData.krediti,
                        nizOdvozanihTermina: instruktorData.nizOdvozanihTermina,
                        nizStudenata: [...filterStudenataBezOvog, studentData],
                        nizZakazanihTermina: instruktorData.nizZakazanihTermina,
                        polozioTeoriju: instruktorData.polozioTeoriju,
                        prikazivanjeTermina: instruktorData.prikazivanjeTermina,
                        profilnaURL: instruktorData.profilnaURL,
                        telefon: instruktorData.telefon,
                        uloga: instruktorData.uloga,
                        uploads: instruktorData.uploads,
                        voziAuto: instruktorData.voziAuto,
                        nedeljnaKvota: instruktorData.nedeljnaKvota,
                        subscribed: instruktorData.subscribed,
                      };
                      getDoc(doc(skoleRef, studentData.autoskolaId)).then(
                        (skola) => {
                          const skolaInfo = skola.data() as autoSkola;
                          const filterInstruktora = _.filter(
                            skolaInfo.instruktori,
                            function (o) {
                              if (o.id !== instruktorData.id) return o;
                            }
                          );
                          const nizInstruktora: Array<UserData> =
                            filterInstruktora as Array<UserData>;

                          updateDoc(doc(skoleRef, studentData.autoskolaId), {
                            instruktori: [...nizInstruktora, newInstruktorData],
                          }).then(() => {
                            updateDoc(doc(korisniciRef, instruktorData.id), {
                              nizStudenata: [
                                ...filterStudenataBezOvog,
                                studentData,
                              ],
                            });
                          });
                        }
                      );
                    }
                  );
                }
              })
              .then(() => {
                setAlert("Uspešno promenjena grupa");
                dohvatiStudenteIGrupe();
                setIsAlert(true);
                setLoading(false);
                setIsASTG(false);
              });
          }
        });
      });
    });
  }

  return (
    <div className="newSTOverlay">
      <div className="newSTBox">
        <h2>Dodajte kandidate</h2>

        <div className="newSTList">
          {nizStudenata.map((student) => {
            if (
              student.grupa === undefined ||
              student.grupa === null ||
              student.grupa === ""
            ) {
              return (
                <div className="studentBox">
                  <div className="studentBoxInfo">
                    <div className="studentBoxImage">
                      <img src={student.profilnaURL} alt={student.ime} />
                    </div>
                    <div className="studentBoxInfoText">
                      <h2>{student.ime}</h2>
                      <p>{`Registrovan: ${new Date(
                        student.datum as string
                      ).getDate()}.${
                        new Date(student.datum as string).getMonth() + 1
                      }.${new Date(student.datum as string).getFullYear()}`}</p>
                    </div>
                  </div>
                  <div className="studentBoxOptions">
                    <button onClick={() => dodajStudentaUGrupu(student)}>
                      <BsPersonAdd color="white" size={50} />
                    </button>
                  </div>
                </div>
              );
            }
          })}
          {nizStudenata.length === 0 && <h3>Nema dostupnih kandidata</h3>}
        </div>
        <button className="x" onClick={() => setIsASTG(false)}>
          X
        </button>
      </div>
    </div>
  );
}
