import React, { useContext, useEffect, useState } from "react";
import { BiCommentDetail, BiHelpCircle, BiLogOutCircle } from "react-icons/bi";
import "../styles/home.scss";
import { izloguj, korisniciRef, terminiRef } from "../utils/firebase";
import { useNavigate } from "react-router";
import { AppContext } from "../functions/AppProvider";
import { Instruktor, Termin, UserData } from "../utils/types";
import axios from "axios";
import _ from "lodash";
import {
  BsFillCalendarDateFill,
  BsPeopleFill,
  BsTrashFill,
} from "react-icons/bs";
import {
  IoArrowBack,
  IoArrowForward,
  IoCalendarOutline,
} from "react-icons/io5";
import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { GiCheckMark } from "react-icons/gi";
import { MdEmojiPeople } from "react-icons/md";

interface Props {}

export function HomeSI(props: Props) {
  const navigate = useNavigate();
  const [nizInstruktora, setNizInstruktora] = useState<Array<Instruktor>>([]);
  const {
    currentUser,
    currentUserData,
    logOut,
    setLoading,
    setAlert,
    setIsAlert,
  } = useContext(AppContext);
  const [trenutniInstruktor, setTrenutniInstruktor] = useState<Instruktor>();
  const [izabraniDatum, setIzabraniDatum] = useState(new Date());
  const [danasnjiTermini, setDanasnjiTermini] = useState<Array<Termin>>([]);
  const [sviTermini, setSviTermini] = useState<Array<Termin>>([]);
  const [sledeciTermini, setSledeciTermini] = useState<Array<Termin>>([]);
  const date = new Date();
  const mesec = date.getMonth() + 1;
  async function dohvatanjeInstruktora() {
    // try {
    //   axios.get("http://localhost:8000/api/v1/instruktors").then((response) => {
    //     setNizInstruktora(response.data.data);
    //     console.log("Uspesno dohvatanje instruktora");
    //     setTrenutniInstruktor(
    //       _.find(response.data.data, { korisnikID: currentUserData?.id })
    //     );
    //     console.log(currentUser, response.data.data);
    //   });
    // } catch (e) {
    //   console.log("Problem sa dohvatanjem instruktora", e);
    // }
  }
  useEffect(() => {
    if (currentUser !== undefined) {
      dohvatanjeInstruktora();
    }
  }, [currentUser]);
  function imeDana() {
    const brojdana = izabraniDatum.getDay();
    console.log("BROJ DANA", brojdana, izabraniDatum);
    if (brojdana === 1) return "Ponedeljak";
    if (brojdana === 2) return "Utorak";
    if (brojdana === 3) return "Sreda";
    if (brojdana === 4) return "Četvrtak";
    if (brojdana === 5) return "Petak";
    if (brojdana === 6) return "Subota";
    if (brojdana === 0) return "Nedelja";
  }
  function juce() {
    setIzabraniDatum((prevDate) => {
      const dateObj = new Date(prevDate);
      dateObj.setDate(dateObj.getDate() - 1);
      return dateObj;
    });
  }
  function sutra() {
    setIzabraniDatum((prevDate) => {
      const dateObj = new Date(prevDate);
      dateObj.setDate(dateObj.getDate() + 1);
      return dateObj;
    });
  }
  const dohvatanjeTermina = async () => {
    if (currentUserData === null || currentUserData === undefined) return;

    const querySnapshot = await getDoc(doc(terminiRef, currentUserData.id));
    const podaci = querySnapshot.data();
    if (podaci === undefined || podaci === null) return;
    console.log(podaci.termini);
    setSviTermini(podaci.termini);
    const nesortiraniTermini = _.filter(podaci.termini, {
      kojiDatum: `${izabraniDatum.getFullYear()}/${
        izabraniDatum.getMonth() + 1
      }/${izabraniDatum.getDate()}`,
    });
    const nesortirani: Array<Termin> = nesortiraniTermini as Array<Termin>;
    const sortirani = nesortirani.sort((a, b) => {
      const dateA = new Date(a.fullDateStart as string);
      const dateB = new Date(b.fullDateStart as string);

      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    });
    setDanasnjiTermini(sortirani);
  };
  useEffect(() => {
    dohvatanjeTermina();
  }, [currentUserData, izabraniDatum]);

  function filterTermina() {
    const trenutnoVremeDatum = new Date();
    const sledeciTermini = _.filter(sviTermini, function (t) {
      const datumObjekatPocetka = new Date(t.fullDateStart as string);

      if (datumObjekatPocetka > trenutnoVremeDatum && t.status === "Zauzet")
        return t;
    });
    const nizSledecihT: Array<Termin> = sledeciTermini as Array<Termin>;
    const sortirani = nizSledecihT.sort((a, b) => {
      const dateA = new Date(a.fullDateStart as string);
      const dateB = new Date(b.fullDateStart as string);

      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    });
    setSledeciTermini(sortirani as Array<Termin>);
  }
  useEffect(() => {
    filterTermina();
  }, [sviTermini]);

  function zavrsiTermin(kojiTermin: Termin) {
    if (kojiTermin === null || kojiTermin === undefined) return;
    if (currentUserData === null || currentUserData === undefined) return;
    setLoading(true);
    const gotovTermin: Termin = {
      beleskaInstruktora: kojiTermin.beleskaInstruktora,
      fullDateEnd: kojiTermin.fullDateEnd,
      fullDateStart: kojiTermin.fullDateStart,
      kojiDatum: kojiTermin.kojiDatum,
      status: "Gotov",
      studentID: kojiTermin.studentID,
      studentIme: kojiTermin.studentIme,
      tipTermina: kojiTermin.tipTermina,
      vremeKraja: kojiTermin.vremeKraja,
      vremePocetka: kojiTermin.vremePocetka,
    };

    const kandidatID: string = kojiTermin.studentID as string;
    getDoc(doc(korisniciRef, kandidatID)).then((kandidat) => {
      const kandidatInfo: UserData = kandidat.data() as UserData;
      const filtriraniNizZakazanih = _.filter(
        kandidatInfo.nizZakazanihTermina,
        function (o) {
          if (
            o.fullDateStart !== kojiTermin.fullDateStart &&
            o.fullDateEnd !== kojiTermin.fullDateEnd
          )
            return o;
        }
      );
      if (kojiTermin.tipTermina === "Jednocas") {
        updateDoc(doc(korisniciRef, kandidatID), {
          brOdvozanihCasova: kandidatInfo.brOdvozanihCasova + 1,
        });
      }
      if (kojiTermin.tipTermina === "Dvocas") {
        updateDoc(doc(korisniciRef, kandidatID), {
          brOdvozanihCasova: kandidatInfo.brOdvozanihCasova + 2,
        });
      }
      if (kojiTermin.tipTermina === "Nocna voznja") {
        updateDoc(doc(korisniciRef, kandidatID), {
          brNocnih: kandidatInfo.brNocnih + 2,
        });
      }
      updateDoc(doc(korisniciRef, kandidatID), {
        nizZakazanihTermina: filtriraniNizZakazanih,
        nizOdvozanihTermina: arrayUnion(gotovTermin),
      }).then(() => {
        const filtriraniSviTermini = _.filter(sviTermini, function (o) {
          if (
            o.fullDateEnd !== kojiTermin.fullDateEnd &&
            o.fullDateStart !== kojiTermin.fullDateStart
          )
            return o;
        });
        updateDoc(doc(terminiRef, currentUserData?.id), {
          termini: [...filtriraniSviTermini, gotovTermin],
        }).then(() => {
          setLoading(false);
          setAlert("Uspešno završen termin");
          setIsAlert(true);
          dohvatanjeTermina();
        });
      });
    });
  }

  function obrisiTermin(termin: Termin) {
    // TREBA DA SE IZBRISE IZ NIZA ZAKAZANIH AKO JE NEKO VEC ZAKAZAO
    if (currentUserData === undefined || currentUserData === null) return;
    setLoading(true);
    const prvobitniTermini = sviTermini;
    const izbaceniTermini = _.remove(prvobitniTermini, {
      kojiDatum: termin.kojiDatum,
      vremeKraja: termin.vremeKraja,
      vremePocetka: termin.vremePocetka,
      status: termin.status,
      studentIme: termin.studentIme,
      studentID: termin.studentID,
    });
    updateDoc(doc(terminiRef, currentUserData.id), {
      termini: prvobitniTermini,
    }).then(() => {
      if (termin.status === "Zauzet") {
        getDoc(doc(korisniciRef, termin.studentID as string)).then(
          (kandidat) => {
            if (kandidat === null || kandidat === undefined) return;
            const kandidatInfo: UserData = kandidat.data() as UserData;
            const filtriraniNizZakazanih = _.filter(
              kandidatInfo.nizZakazanihTermina,
              function (o) {
                if (
                  o.fullDateEnd !== termin.fullDateEnd &&
                  o.fullDateStart !== termin.fullDateStart
                )
                  return o;
              }
            );
            updateDoc(doc(korisniciRef, termin.studentID as string), {
              nizZakazanihTermina: filtriraniNizZakazanih,
            }).then(() => {
              dohvatanjeTermina();
              setAlert("Uspešno obrisan termin");
              setIsAlert(true);
              setLoading(false);
            });
          }
        );
      } else {
        dohvatanjeTermina();
        setAlert("Uspešno obrisan termin");
        setIsAlert(true);
        setLoading(false);
      }
    });
  }
  return (
    <>
      <div className="welcome">
        <h2>
          Dobro došli, {currentUserData && currentUserData?.ime.split(" ")[0]}
        </h2>
        <h2>
          {date.getDate()}.{mesec}.{date.getFullYear()}
        </h2>
      </div>
      <div className="optionsShortcuts">
        <div
          className="shortcut"
          onClick={() => {
            navigate(`/kalendar/${currentUserData?.id}`);
          }}
        >
          <div className="ikonicaWrap">
            <IoCalendarOutline color="white" size={50} />
          </div>
          <h3>Kalendar</h3>
        </div>
        <div
          className="shortcut"
          onClick={() => {
            navigate(`/zahtevi`);
          }}
        >
          <div className="ikonicaWrap">
            <MdEmojiPeople color="white" size={50} />
          </div>
          <h3>Zahtevi</h3>
        </div>
        <div
          className="shortcut"
          onClick={() => navigate(`/studenti/${currentUserData?.autoskolaId}`)}
        >
          <div className="ikonicaWrap">
            <BsPeopleFill color="white" size={50} />
          </div>
          <h3>Kandidati</h3>
        </div>
        <div className="shortcut">
          <div className="ikonicaWrap">
            <BiHelpCircle color="white" size={50} />
          </div>
          <h3>Pomoć</h3>
        </div>
        <div
          className="shortcut"
          onClick={() => {
            logOut();
            navigate("/login");
          }}
        >
          <div className="ikonicaWrap">
            <BiLogOutCircle color="white" size={60} />
          </div>
          <h3>Izlogujte se</h3>
        </div>
      </div>
      {currentUserData?.subscribed === false && (
        <div className="optionsShortcuts">
          <h3 style={{ color: "white" }}>
            Notifikacije nisu aktivirane. Kako biste ih aktivirali, pošaljite
            poruku na WhatsApp-u sa sadržajem "Prihvatam" na broj 065 840 54 80
          </h3>
        </div>
      )}
      <div className="opcijeKontejner">
        <div className="opcije Desno">
          <div className="opcijeDesnoHeader">
            <h3>Sledeća vožnja</h3>
          </div>
          {sledeciTermini.length !== 0 && (
            <div className="opcijeDesnoBody">
              <div className="stavka">
                <h4>Datum</h4>
                <p>{sledeciTermini[0]?.kojiDatum}</p>
              </div>
              <div className="stavka">
                <h4>Vreme početka</h4>
                <p>{sledeciTermini[0]?.vremePocetka}</p>
              </div>
              <div className="stavka">
                <h4>Vreme kraja</h4>
                <p>{sledeciTermini[0]?.vremeKraja}</p>
              </div>
              <div className="stavka">
                <h4>Tip časa</h4>
                <p>{sledeciTermini[0]?.tipTermina}</p>
              </div>
              <div className="stavka">
                <h4>Kandidat</h4>
                <p>{sledeciTermini[0]?.studentIme}</p>
              </div>
            </div>
          )}
          {sledeciTermini.length === 0 && (
            <div className="opcijeDesnoBody">
              <div className="stavka">
                <h4>Nema zakazanih termina</h4>
              </div>
            </div>
          )}
        </div>

        <div className="opcije Sredina">
          <div className="opcijeSredinaHeader">
            <p onClick={() => juce()}>
              <IoArrowBack color="white" size={30} />
            </p>

            <h3>
              {imeDana()}, {izabraniDatum.getDate()}.
              {izabraniDatum.getMonth() + 1}.{izabraniDatum.getFullYear()}
            </h3>
            <p onClick={() => sutra()}>
              <IoArrowForward color="white" size={30} />
            </p>
          </div>
          <div className="opcijeSredinaBody">
            {danasnjiTermini.map((termin) => {
              return (
                <div
                  key={`${termin.vremeKraja} ${termin.vremePocetka} ${termin.kojiDatum}`}
                  className={`termin ${
                    termin.status === "Slobodan" && "free"
                  } ${termin.status === "Gotov" && "closed"} ${
                    termin.status === "Zauzet" && "reserved"
                  }`}
                >
                  <div className="terminInfo">
                    <h3>
                      {termin.vremePocetka}-{termin.vremeKraja}
                    </h3>
                    <h3>{termin.status}</h3>
                    <h3>{termin?.tipTermina}</h3>
                    <h3>{termin?.studentIme}</h3>
                  </div>
                  <div className="terminOptions">
                    {termin.status !== "Slobodan" &&
                      termin.status !== "Gotov" &&
                      new Date(termin.fullDateEnd as string) < new Date() && (
                        <div
                          className="okvirOpcije"
                          onClick={() => zavrsiTermin(termin)}
                        >
                          <GiCheckMark color="white" size={20} />
                        </div>
                      )}
                    {termin.status !== "Gotov" && (
                      <div
                        className="okvirOpcije"
                        onClick={() => obrisiTermin(termin)}
                      >
                        <BsTrashFill color="white" size={20} />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            {danasnjiTermini.length === 0 && (
              <h3>Nema termina za izabrani datum.</h3>
            )}
          </div>
        </div>
        {/* PROFILNA KARTICA */}
        <div className="opcije Levo">
          <div className="opcijeLevoDiv">
            <div className="opcijeLevoHeader">
              <div className="opcijeLevoHeaderImage">
                <img
                  src={currentUserData?.profilnaURL}
                  alt={currentUserData?.ime}
                />
              </div>
              <div className="opcijeLevoHeaderTekst">
                <h3>{currentUserData?.ime}</h3>
              </div>
            </div>
            <div className="opcijeLevoBody">
              <div className="stavka">
                <h4>Email</h4>
                <p>{currentUserData?.email}</p>
              </div>
              <div className="stavka">
                <h4>Auto skola</h4>
                <p>{currentUserData?.autoskolaId}</p>
              </div>
              <div className="stavka">
                <h4>Uloga</h4>
                <p>Instruktor</p>
              </div>
              <div className="stavka">
                <h4>ID</h4>
                <p>{currentUserData?.id}</p>
              </div>
            </div>
          </div>
          <button onClick={() => navigate(`/profile/${currentUserData?.id}`)}>
            Profil
          </button>
        </div>
        {/* KRAJ PROFILNE KARTICE */}
      </div>
    </>
  );
}
