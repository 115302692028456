import React, { useContext, useEffect, useState } from "react";
import "../styles/home.scss";
import {
  BsMailbox,
  BsPersonAdd,
  BsSortNumericDownAlt,
  BsSortNumericUpAlt,
  BsTrashFill,
} from "react-icons/bs";
import {
  AiFillEdit,
  AiFillEye,
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { BiSortDown } from "react-icons/bi";
import { Grupa, UserData, autoSkola } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import {
  grupeRef,
  korisniciRef,
  noviKorisniciRef,
  skoleRef,
} from "../utils/firebase";
import {
  MdArrowBack,
  MdAttachMoney,
  MdOutlineAlternateEmail,
} from "react-icons/md";
import _ from "lodash";
import { NewSSOverlay } from "../components/NewSSOverlay";
import { IconContext } from "react-icons/lib";
import { useNavigate } from "react-router";
import { EmailChangeOverlay } from "../components/EmailChangeOverlay";
import { KreditChange } from "../components/KreditChange";
import { ProveraBrisanjaStudenta } from "../components/ProveraBrisanjaStudenta";

interface Props {}

export function Studenti(props: Props) {
  const [nizStudenata, setNizStudenata] = useState<Array<UserData>>([]);
  const [sekundarniNiz, setSekundarniNiz] = useState<Array<UserData>>([]);
  const [isNewSS, setIsNewSS] = useState<boolean>(false);
  const [komStudentuMenjamo, setKomStudentuMenjamo] = useState<UserData>();
  const [isEmailChange, setIsEmailChange] = useState<boolean>(false);
  const [isKreditChange, setKreditChange] = useState<boolean>(false);
  const [isBrisanje, setIsBrisanje] = useState<boolean>(false);
  const {
    currentUser,
    currentUserData,
    logOut,
    setLoading,
    setAlert,
    setIsAlert,
  } = useContext(AppContext);
  const [trenutniSort, setTrenutniSort] = useState<
    "imeAZ" | "imeZA" | "datumAsc" | "datumDesc"
  >();
  const navigate = useNavigate();
  useEffect(() => {
    dohvatiStudente();
  }, [currentUserData]);
  useEffect(() => {
    if (
      (trenutniSort === null || trenutniSort === undefined) &&
      sekundarniNiz !== null &&
      sekundarniNiz !== undefined &&
      sekundarniNiz.length !== 0
    ) {
      sortIme();
    }
  }, [sekundarniNiz]);
  function dohvatiStudente() {
    console.log("DOHVATAM");
    if (
      currentUserData === null ||
      currentUserData === undefined ||
      currentUserData.autoskolaId === null ||
      currentUserData.autoskolaId === undefined ||
      currentUserData.autoskolaId === "" ||
      skoleRef === null ||
      skoleRef === undefined
    )
      return;
    setLoading(true);
    console.log("SKOLA 1", currentUserData);
    getDoc(doc(skoleRef, currentUserData.autoskolaId)).then((skola) => {
      console.log("SKOLA", skola?.data()?.studenti);
      const nizNovih = skola?.data()?.noviStudenti;
      const nizPostojecih = skola?.data()?.studenti;
      if (nizNovih !== undefined && nizNovih !== null) {
        const spojeniNiz = _.concat(nizNovih, nizPostojecih);
        setNizStudenata(spojeniNiz);
        setSekundarniNiz(spojeniNiz);
        setLoading(false);
      } else {
        setNizStudenata(nizPostojecih);
        setSekundarniNiz(nizPostojecih);
        setLoading(false);
      }
    });
  }
  function search(q: string) {
    const duzina = q.length;
    const niz = nizStudenata;
    if (q === "") {
      setSekundarniNiz(nizStudenata);
      return;
    }
    const filtriranNiz = _.filter(niz, function (o) {
      const ime = o.ime;
      console.log(
        "FILTER PRVI",
        ime.toLowerCase().substring(0, duzina),
        q.toLowerCase().substring(0, duzina)
      );
      if (
        ime.toLowerCase().substring(0, duzina) ===
          q.toLowerCase().substring(0, duzina) ||
        ime.toLowerCase().split(" ")[1].substring(0, duzina) ===
          q.toLowerCase().substring(0, duzina)
      )
        return o;
    });
    console.log("FILTER", filtriranNiz);
    setSekundarniNiz(filtriranNiz as Array<UserData>);
  }
  function sortIme() {
    const niz = nizStudenata;
    const sortiraniNiz = _.orderBy(niz, ["ime"], ["asc"]);
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("imeAZ");
  }
  function sortImeDesc() {
    const niz = nizStudenata;
    const sortiraniNiz = _.orderBy(niz, ["ime"], ["desc"]);
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("imeZA");
  }
  function sortDateAsc() {
    const niz = nizStudenata;
    const sortiraniNiz = niz.sort((a, b) => {
      const dateA = new Date(a.datum as string);
      const dateB = new Date(b.datum as string);
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    });
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("datumAsc");
  }
  function sortDateDesc() {
    const niz = nizStudenata;
    const sortiraniNiz = [...niz].sort((a, b) => {
      const dateA = new Date(a.datum as string);
      const dateB = new Date(b.datum as string);

      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      return 0;
    });
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("datumDesc");
  }

  function obrisiNovogStudenta(kojiStudent: UserData) {
    if (currentUserData === null || currentUserData === undefined) return;
    setLoading(true);
    getDoc(doc(skoleRef, currentUserData.autoskolaId)).then((skola) => {
      const skolaInfo = skola.data() as autoSkola;
      const nizNovih: Array<UserData> =
        skolaInfo.noviStudenti as Array<UserData>;

      const filtriraniNizNovih = _.filter(nizNovih, function (o) {
        if (o.email !== kojiStudent.email) return o;
      });

      updateDoc(doc(skoleRef, currentUserData.autoskolaId), {
        noviStudenti: filtriraniNizNovih,
      }).then(() => {
        deleteDoc(doc(noviKorisniciRef, kojiStudent.email)).then(() => {
          setAlert("Uspešno obrisan kandidat");
          setIsAlert(true);
          dohvatiStudente();
          setLoading(false);
        });
      });
    });
  }

  function obrisiStudenta(kojiStudent: UserData) {
    setLoading(true);
    getDoc(doc(skoleRef, kojiStudent.autoskolaId)).then((skola) => {
      const skolaData: autoSkola = skola.data() as autoSkola;

      const filtriraniStudentiSkole = _.filter(
        skolaData.studenti,
        function (o) {
          if (o.id !== kojiStudent.id) return o;
        }
      );

      // UPDATUJEMO NIZ STUDENATA SKOLE

      updateDoc(doc(skoleRef, kojiStudent.autoskolaId), {
        studenti: filtriraniStudentiSkole,
      });

      if (
        kojiStudent.koJeInstruktorID !== "" &&
        kojiStudent.koJeInstruktorID !== null &&
        kojiStudent.koJeInstruktorID !== undefined
      ) {
        getDoc(doc(korisniciRef, kojiStudent.koJeInstruktorID)).then((ins) => {
          const instruktorData: UserData = ins.data() as UserData;
          const filtriraniNizInstruktoraSkole = _.filter(
            skolaData.instruktori,
            function (o) {
              if (o.id !== instruktorData.id) return o;
            }
          );
          const filtriraniStudentiKodInstruktora = _.filter(
            instruktorData.nizStudenata,
            function (o) {
              if (o.id !== kojiStudent.id) return o;
            }
          );
          const instruktorNewData: UserData = {
            aktiviran: instruktorData.aktiviran,
            autoskolaId: instruktorData.autoskolaId,
            brNocnih: instruktorData.brNocnih,
            brOdvozanihCasova: instruktorData.brOdvozanihCasova,
            datum: instruktorData.datum,
            editovanInfoPrviPut: instruktorData.editovanInfoPrviPut,
            email: instruktorData.email,
            grupa: instruktorData.grupa,
            grupaID: instruktorData.grupaID,
            id: instruktorData.id,
            ime: instruktorData.ime,
            koJeInstruktorID: instruktorData.koJeInstruktorID,
            koJeInstruktorIme: instruktorData.koJeInstruktorIme,
            krediti: instruktorData.krediti,
            nedeljnaKvota: instruktorData.nedeljnaKvota,
            nizOdvozanihTermina: instruktorData.nizOdvozanihTermina,
            nizStudenata: filtriraniStudentiKodInstruktora as Array<UserData>,
            nizZakazanihTermina: instruktorData.nizZakazanihTermina,
            polozioTeoriju: instruktorData.polozioTeoriju,
            prikazivanjeTermina: instruktorData.prikazivanjeTermina,
            profilnaURL: instruktorData.profilnaURL,
            telefon: instruktorData.telefon,
            uloga: instruktorData.uloga,
            uploads: instruktorData.uploads,
            voziAuto: instruktorData.voziAuto,
            subscribed: instruktorData.subscribed,
          };
          // UPDATUJEMO NIZ STUDENATA KOD INSTRUKTORA
          updateDoc(doc(korisniciRef, instruktorData.id), {
            nizStudenata: filtriraniStudentiKodInstruktora,
          }).then(() => {
            // UPDATUJEMO NIZ INSTRUKTORA U SKOLI
            updateDoc(doc(skoleRef, kojiStudent.autoskolaId), {
              instruktori: [
                ...filtriraniNizInstruktoraSkole,
                instruktorNewData,
              ],
            });
          });
        });
      }
      if (
        kojiStudent.grupaID !== "" &&
        kojiStudent.grupaID !== null &&
        kojiStudent.grupaID !== undefined
      ) {
        getDoc(doc(grupeRef, kojiStudent.grupaID)).then((gr) => {
          const grupaData: Grupa = gr.data() as Grupa;
          const filtriraniNizStudenataGrupe = _.filter(
            grupaData.studenti,
            function (o) {
              if (o.id !== kojiStudent.id) return o;
            }
          );
          const filtriraniNizGrupaSkole = _.filter(
            skolaData.grupe,
            function (o) {
              if (o.id !== grupaData.id) return o;
            }
          );
          const grupaNewData: Grupa = {
            datum: grupaData.datum,
            id: grupaData.id,
            ime: grupaData.ime,
            kreirao: grupaData.kreirao,
            kreiraoID: grupaData.kreiraoID,
            studenti: filtriraniNizStudenataGrupe as Array<UserData>,
          };

          // UPDATUJEMO NIZ STUDENATA GRUPE
          updateDoc(doc(grupeRef, kojiStudent.grupaID), {
            studenti: filtriraniNizStudenataGrupe,
          }).then(() => {
            // UPDATUJEMO NIZ GRUPA SKOLE
            updateDoc(doc(skoleRef, kojiStudent.autoskolaId), {
              grupe: [...filtriraniNizGrupaSkole, grupaNewData],
            });
          });
        });
      }
      setIsAlert(true);
      setAlert("Uspešno obrisan student");
      setLoading(false);
    });
  }
  return (
    <>
      <div className="welcome">
        <div className="welcomeTitle">
          <IconContext.Provider value={{ className: "backIkonica" }}>
            <div className="ikonicaOmotac" onClick={() => navigate(-1)}>
              <MdArrowBack />
            </div>
          </IconContext.Provider>
          <h2>Kandidati ({nizStudenata.length})</h2>
        </div>
        <input
          type="text"
          name="pretragaStudenata"
          id="pretragaStudenata"
          placeholder="Pretražite"
          onChange={(e) => search(e.target.value)}
        />
      </div>
      <div className="optionsShortcuts">
        <div className="shortcut" onClick={() => setIsNewSS(true)}>
          <div className="ikonicaWrap">
            <BsPersonAdd color="white" size={50} />
          </div>
          <h3>Dodaj</h3>
        </div>
        <div className="shortcut" onClick={() => sortIme()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "imeAZ" ? "correct" : ""
            }`}
          >
            <AiOutlineSortAscending color="white" size={50} />
          </div>
          <h3>Ime A-Z</h3>
        </div>
        <div className="shortcut" onClick={() => sortImeDesc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "imeZA" ? "correct" : ""
            }`}
          >
            <AiOutlineSortDescending color="white" size={50} />
          </div>
          <h3>Ime Z-A</h3>
        </div>
        <div className="shortcut" onClick={() => sortDateDesc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "datumDesc" ? "correct" : ""
            }`}
          >
            <BsSortNumericDownAlt color="white" size={50} />
          </div>
          <h3>Najnoviji - Najstariji</h3>
        </div>
        <div className="shortcut" onClick={() => sortDateAsc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "datumAsc" ? "correct" : ""
            }`}
          >
            <BsSortNumericUpAlt color="white" size={50} />
          </div>
          <h3>Najstariji - Najnoviji</h3>
        </div>
      </div>
      <div className="list">
        {sekundarniNiz.map((student) => {
          return (
            <div className="listPiece">
              <div className="pieceInfo">
                <img src={student.profilnaURL} alt={student.ime} />
                <div className="pieceInfoText">
                  <h3>{student.ime}</h3>
                  {student.aktiviran === false && <h4>Nalog nije aktiviran</h4>}
                  {student.aktiviran === false && <h4>{student.email}</h4>}
                  {student.aktiviran !== false && (
                    <p>
                      Položena teorija:
                      {student.polozioTeoriju === true ? "Da" : "Ne"}
                    </p>
                  )}
                  {student.aktiviran === true &&
                    student.polozioTeoriju === true &&
                    student.krediti !== null &&
                    student.krediti !== undefined && (
                      <p>Krediti: {student.krediti}</p>
                    )}
                  <p>
                    {student.polozioTeoriju === true &&
                      student.aktiviran === true &&
                      "Odvozani časovi: "}
                    {student.polozioTeoriju === true &&
                      student.aktiviran === true &&
                      student.brOdvozanihCasova}
                  </p>
                  <p>
                    {student.polozioTeoriju === true &&
                      student.aktiviran === true &&
                      "Instruktor: "}
                    {student.polozioTeoriju === true &&
                      student.aktiviran === true &&
                      student.koJeInstruktorIme}
                  </p>
                  <p>
                    {student.polozioTeoriju === false &&
                      student.aktiviran !== false &&
                      student.grupaID !== "" &&
                      student.grupaID !== null &&
                      student.grupaID !== undefined &&
                      "Grupa: "}
                    {student.polozioTeoriju === false &&
                      student.aktiviran !== false &&
                      student.grupaID !== "" &&
                      student.grupaID !== null &&
                      student.grupaID !== undefined &&
                      student.grupa}
                  </p>
                </div>
              </div>
              <div className="pieceOptions">
                {student.aktiviran === false && (
                  <div
                    className="pieceOption"
                    onClick={() => {
                      setKomStudentuMenjamo(student);
                      setIsEmailChange(true);
                    }}
                  >
                    <div className="pieceOptionIcon">
                      <MdOutlineAlternateEmail color="white" size={50} />
                    </div>
                    <p>Promeni</p>
                  </div>
                )}
                {student.aktiviran === false && (
                  <div
                    className="pieceOption"
                    onClick={() => obrisiNovogStudenta(student)}
                  >
                    <div className="pieceOptionIcon">
                      <BsTrashFill color="white" size={50} />
                    </div>
                    <p>Obriši</p>
                  </div>
                )}

                {student.aktiviran === true && (
                  <div
                    className="pieceOption"
                    onClick={() => navigate(`/profile/${student.id}`)}
                  >
                    <div className="pieceOptionIcon">
                      <AiFillEye color="white" size={50} />
                    </div>
                    <p>Profil</p>
                  </div>
                )}
                {student.aktiviran === true && (
                  <div
                    className="pieceOption"
                    onClick={() => {
                      setKreditChange(true);
                      setKomStudentuMenjamo(student);
                    }}
                  >
                    <div className="pieceOptionIcon">
                      <MdAttachMoney color="white" size={50} />
                    </div>
                    <p>Krediti</p>
                  </div>
                )}
                {student.aktiviran === true && (
                  <div
                    className="pieceOption"
                    onClick={() => {
                      setKomStudentuMenjamo(student);
                      setIsBrisanje(true);
                    }}
                  >
                    <div className="pieceOptionIcon">
                      <BsTrashFill color="white" size={50} />
                    </div>
                    <p>Obriši</p>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {isNewSS && (
        <NewSSOverlay
          setIsNewSS={setIsNewSS}
          dohvatiStudente={dohvatiStudente}
        />
      )}
      {isEmailChange && (
        <EmailChangeOverlay
          korisnik={komStudentuMenjamo as UserData}
          dohvatiStudente={dohvatiStudente}
          setIsEmailChange={setIsEmailChange}
        />
      )}
      {isKreditChange && (
        <KreditChange
          korisnik={komStudentuMenjamo as UserData}
          setKreditChange={setKreditChange}
          dohvatiKorisnika={dohvatiStudente}
        />
      )}
      {isBrisanje && (
        <ProveraBrisanjaStudenta
          kojiStudent={komStudentuMenjamo as UserData}
          setIsProvera={setIsBrisanje}
          dohvatiStudente={dohvatiStudente}
        />
      )}
    </>
  );
}
