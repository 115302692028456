import React, { useContext, useEffect, useState } from "react";
import "../styles/newSTOverlay.scss";
import { db, terminiRef } from "../utils/firebase";
import { AppContext } from "../functions/AppProvider";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { Termin } from "../utils/types";
import { DatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import useKeyPress from "react-use-keypress";
import dayjs from "dayjs";

interface Props {
  setIsNewTermin: any;
  kojiDatum: string;
  danasnjiTermini: Array<Termin>;
}

export function NewTermin(props: Props) {
  const { setIsNewTermin, kojiDatum, danasnjiTermini } = props;
  const [pocetak, setPocetak] = useState<string>("");
  const [kraj, setKraj] = useState<string>("");
  const [tipTermina, setTipTermina] = useState<string>("Dvocas");
  const { currentUserData, setAlert, setIsAlert } = useContext(AppContext);

  useKeyPress("Escape", () => {
    setIsNewTermin(false);
  });
  useKeyPress("Enter", () => {
    dodajTermin();
  });

  useEffect(() => {
    if (
      danasnjiTermini !== null &&
      danasnjiTermini !== undefined &&
      danasnjiTermini.length !== 0
    ) {
      const krajDate = dayjs(
        `${kojiDatum}T${danasnjiTermini[danasnjiTermini.length - 1].vremeKraja}`
      )
        .add(120, "minute")
        .toDate();

      const noviPocetak = dayjs(
        `${kojiDatum}T${danasnjiTermini[danasnjiTermini.length - 1].vremeKraja}`
      )
        .add(30, "minute")
        .toDate();
      if (noviPocetak.getMinutes() === 0) {
        setPocetak(`${noviPocetak.getHours()}:00`);
      } else if (noviPocetak.getMinutes() !== 0) {
        setPocetak(`${noviPocetak.getHours()}:${noviPocetak.getMinutes()}`);
      }
      if (krajDate.getMinutes() === 0) {
        setKraj(`${krajDate.getHours()}:00`);
      } else if (krajDate.getMinutes() !== 0) {
        setKraj(`${krajDate.getHours()}:${krajDate.getMinutes()}`);
      }
    }
  }, [danasnjiTermini]);
  async function dodajTermin() {
    if (currentUserData === undefined || currentUserData === null) return;
    if (pocetak === "" || kraj === "") {
      setAlert("Nisu uneti početak ili kraj");
      setIsAlert(true);
      return;
    }
    const fullDateStart = new Date(`${kojiDatum} ${pocetak}`);
    const fullDateEnd = new Date(`${kojiDatum} ${kraj}`);
    const terminData: Termin = {
      status: "Slobodan",
      tipTermina: tipTermina,
      vremePocetka: pocetak,
      vremeKraja: kraj,
      kojiDatum: kojiDatum,
      fullDateStart: fullDateStart.toString(),
      fullDateEnd: fullDateEnd.toString(),
      studentID: "",
      studentIme: "",
      beleskaInstruktora: "",
    };
    await updateDoc(doc(terminiRef, currentUserData.id), {
      termini: arrayUnion(terminData),
    }).then(() => {
      setAlert("Uspešno dodat termin.");
      setIsAlert(true);
      setIsNewTermin(false);
    });
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="newSTOverlay">
        <div className="newSTBox">
          <div className="newSTBoxHeader">
            <h2>Novi termin</h2>
            <p>{kojiDatum}</p>
          </div>
          <label htmlFor="tipTermina">Tip termina</label>
          <select
            className="vreme"
            name="tipTermina"
            id="tipTermina"
            onChange={(e) => setTipTermina(e.target.value)}
            defaultValue={"Dvocas"}
          >
            <option value="Dvocas">Dvocas</option>
            <option value="Jednocas">Jednocas</option>
            <option value="Nocna voznja">Nocna voznja</option>
          </select>
          <label htmlFor="vremePocetka">Pocetak</label>
          <MobileTimePicker
            label="Vreme pocetka"
            className="izborDatumaMUI"
            format="HH:mm"
            ampm={false}
            minutesStep={5}
            closeOnSelect
            localeText={{
              toolbarTitle: "Vreme pocetka",
              cancelButtonLabel: "Odustani",
              okButtonLabel: "Potvrdi",
            }}
            value={pocetak !== "" ? dayjs(`${kojiDatum}T${pocetak}`) : null}
            onChange={(e: any) => {
              console.log(e);
              if (e === null) return;
              console.log(new Date(e.$d).getHours());
              console.log(new Date(e.$d).getMinutes());
              let sati = new Date(e.$d).getHours();
              let minuti = new Date(e.$d).getMinutes();
              if (minuti === 0) {
                setPocetak(`${sati}:00`);
                if (tipTermina === "Dvocas" || tipTermina === "Nocna voznja") {
                  const kraj = dayjs(`${kojiDatum}T${sati}:${minuti}`).add(
                    90,
                    "minute"
                  );
                  if (kraj.toDate().getMinutes() === 0) {
                    setKraj(`${kraj.toDate().getHours()}:00`);
                  } else if (kraj.toDate().getMinutes() !== 0) {
                    setKraj(
                      `${kraj.toDate().getHours()}:${kraj
                        .toDate()
                        .getMinutes()}`
                    );
                  }
                } else if (tipTermina === "Jednocas") {
                  const kraj = dayjs(`${kojiDatum}T${sati}:${minuti}`).add(
                    45,
                    "minute"
                  );
                  if (kraj.toDate().getMinutes() === 0) {
                    setKraj(`${kraj.toDate().getHours()}:00`);
                  } else if (kraj.toDate().getMinutes() !== 0) {
                    setKraj(
                      `${kraj.toDate().getHours()}:${kraj
                        .toDate()
                        .getMinutes()}`
                    );
                  }
                }
              } else {
                setPocetak(`${sati}:${minuti}`);
                if (tipTermina === "Dvocas" || tipTermina === "Nocna voznja") {
                  const kraj = dayjs(`${kojiDatum}T${sati}:${minuti}`).add(
                    90,
                    "minute"
                  );
                  if (kraj.toDate().getMinutes() === 0) {
                    setKraj(`${kraj.toDate().getHours()}:00`);
                  } else if (kraj.toDate().getMinutes() !== 0) {
                    setKraj(
                      `${kraj.toDate().getHours()}:${kraj
                        .toDate()
                        .getMinutes()}`
                    );
                  }
                } else if (tipTermina === "Jednocas") {
                  const kraj = dayjs(`${kojiDatum}T${sati}:${minuti}`).add(
                    45,
                    "minute"
                  );
                  if (kraj.toDate().getMinutes() === 0) {
                    setKraj(`${kraj.toDate().getHours()}:00`);
                  } else if (kraj.toDate().getMinutes() !== 0) {
                    setKraj(
                      `${kraj.toDate().getHours()}:${kraj
                        .toDate()
                        .getMinutes()}`
                    );
                  }
                }
              }
            }}
          />
          <label htmlFor="vremePocetka">Kraj</label>
          <MobileTimePicker
            label="Vreme kraja"
            className="izborDatumaMUI"
            format="HH:mm"
            ampm={false}
            minutesStep={5}
            localeText={{
              toolbarTitle: "Vreme kraja",
              cancelButtonLabel: "Odustani",
              okButtonLabel: "Potvrdi",
            }}
            value={pocetak !== "" ? dayjs(`${kojiDatum}T${kraj}`) : null}
            onChange={(e: any) => {
              console.log(e);
              if (e === null) return;
              console.log(new Date(e.$d).getHours());
              console.log(new Date(e.$d).getMinutes());
              let sati = new Date(e.$d).getHours();
              let minuti = new Date(e.$d).getMinutes();
              if (minuti === 0) {
                setKraj(`${sati}:00`);
              } else {
                setKraj(`${sati}:${minuti}`);
              }
            }}
          />

          <div className="buttonOptions">
            <button onClick={() => dodajTermin()}>Sačuvaj</button>
            <button onClick={() => setIsNewTermin(false)}>Odustani</button>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
}
