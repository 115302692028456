import React, { createContext, useEffect, useState } from "react";
import { User, getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db, korisniciRef } from "../utils/firebase";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { UserData } from "../utils/types";

interface IAppContext {
  currentUser: User | undefined | null;
  setCurrentUser: any;
  currentUserData: UserData | null;
  logOut: any;
  loading: boolean;
  setLoading: any;
  alert: string;
  setAlert: any;
  isAlert: boolean;
  setIsAlert: any;
}

export const AppContext = createContext<IAppContext>({
  currentUser: undefined,
  currentUserData: {
    ime: "",
    email: "",
    uloga: "globaladmin",
    autoskolaId: "",
    id: "0",
    profilnaURL: "",
    aktiviran: true,
    brNocnih: 0,
    brOdvozanihCasova: 0,
    datum: "",
    editovanInfoPrviPut: false,
    grupa: "",
    grupaID: "",
    koJeInstruktorID: "",
    koJeInstruktorIme: "",
    krediti: 0,
    nizOdvozanihTermina: [],
    nizStudenata: [],
    nizZakazanihTermina: [],
    polozioTeoriju: false,
    telefon: "",
    uploads: [],
    voziAuto: "",
    prikazivanjeTermina: "Default",
    nedeljnaKvota: 3,
    subscribed: false,
  },
  logOut: () => {},
  setCurrentUser: () => {},
  loading: false,
  setLoading: () => {},
  alert: "",
  isAlert: false,
  setAlert: () => {},
  setIsAlert: () => {},
});

const AppProvider = ({ children }: { children: any }) => {
  // const b64DecodeUnicode = (str: string) => {
  //   return decodeURIComponent(
  //     atob(str).replace(/(.)/g, function (m, p) {
  //       let code = p.charCodeAt(0).toString(16).toUpperCase();
  //       if (code.length < 2) {
  //         code = "0" + code;
  //       }
  //       return "%" + code;
  //     })
  //   );
  // };
  const [loading, setLoading] = useState<boolean>(false);
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const [alert, setAlert] = useState<string>("");
  const [currentUser, setCurrentUser] = useState<User | null>();
  const [currentUserData, setCurrentUserData] = useState<UserData | null>({
    ime: "",
    email: "",
    uloga: "globaladmin",
    autoskolaId: "",
    id: "0",
    profilnaURL: "",
    aktiviran: true,
    brNocnih: 0,
    brOdvozanihCasova: 0,
    datum: "",
    editovanInfoPrviPut: false,
    grupa: "",
    grupaID: "",
    koJeInstruktorID: "",
    koJeInstruktorIme: "",
    krediti: 0,
    nizOdvozanihTermina: [],
    nizStudenata: [],
    nizZakazanihTermina: [],
    polozioTeoriju: false,
    telefon: "",
    uploads: [],
    voziAuto: "",
    prikazivanjeTermina: "Default",
    nedeljnaKvota: 3,
    subscribed: false,
  });
  // const navigate = useNavigate();
  const logOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log("Korisnik izlogovan");
        setCurrentUser(null);
        setCurrentUserData(null);
      })
      .catch((error) => {
        console.log("Error prilikom odjavljivanja");
      });
  };

  // useEffect(() => {
  //   if (currentUser === null || currentUser === undefined) {
  //     const storedObject = localStorage.getItem("korisnik");
  //     console.log("STAVLJENO");
  //     if (storedObject) {
  //       setCurrentUser(JSON.parse(storedObject));
  //       console.log("STAVLJENO");
  //     }
  //   }
  // }, []);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        let currentUser = user;
        setCurrentUser(currentUser as User);
        console.log("POSTAVLJEN CURRENT USER", currentUser);
      } else {
        setCurrentUser(null);
      }
    });
  }, []);

  useEffect(() => {
    console.log("POKUSVAM");
    if (currentUser !== null && currentUser !== undefined) {
      const unsubscribe = onSnapshot(
        doc(korisniciRef, currentUser.uid),
        (snap) => {
          if (!snap.exists) {
            return;
          }
          const trenutniPodaci = snap.data();
          if (trenutniPodaci === undefined) return;
          setCurrentUserData(snap.data() as UserData);
        }
      );

      return () => unsubscribe();
      // getDoc(doc(korisniciRef, currentUser.uid)).then((korisnik) => {
      //   setCurrentUserData(korisnik.data() as UserData);
      // });
    }
  }, [currentUser]);

  return (
    <AppContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        logOut,
        currentUserData,
        loading,
        setLoading,
        alert,
        isAlert,
        setAlert,
        setIsAlert,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default AppProvider;
