import React, { useContext, useEffect, useState } from "react";
import "../styles/home.scss";
import {
  BsPersonAdd,
  BsSortNumericDownAlt,
  BsSortNumericUpAlt,
  BsTrashFill,
} from "react-icons/bs";
import {
  AiFillEye,
  AiFillFilePpt,
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { Lekcija } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { lekcijeRef, skoleRef } from "../utils/firebase";
import { MdArrowBack, MdQuiz } from "react-icons/md";
import _ from "lodash";
import { NewLessonOverlay } from "../components/NewLessonOverlay";
import { AddPresentationToLection } from "../components/AddPresentationToLection";
import { AddTestToLection } from "../components/AddTestToLection";
import { useNavigate } from "react-router";
import { IconContext } from "react-icons/lib";

interface Props {}

export function Lekcije(props: Props) {
  const [nizLekcija, setNizLekcija] = useState<Array<Lekcija>>([]);
  const [sekundarniNiz, setSekundarniNiz] = useState<Array<Lekcija>>([]);
  const [isNewLesson, setIsNewLesson] = useState<boolean>(false);
  const [isNewAPTL, setIsNewAPTL] = useState<boolean>(false);
  const [isATTL, setIsATTL] = useState<boolean>(false);
  const [kojaLekcija, setKojaLekcija] = useState<Lekcija>();
  const { currentUserData, setLoading, setAlert, setIsAlert } =
    useContext(AppContext);
  const [trenutniSort, setTrenutniSort] = useState<
    "imeAZ" | "imeZA" | "datumAsc" | "datumDesc"
  >();
  const [dohvatiliLekcije, setDohvatiliLekcije] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    dohvatiLekcije();
  }, [currentUserData]);

  function dohvatiLekcije() {
    if (
      currentUserData === null ||
      currentUserData === undefined ||
      currentUserData.autoskolaId === null ||
      currentUserData.autoskolaId === undefined ||
      currentUserData.autoskolaId === "" ||
      skoleRef === null ||
      skoleRef === undefined
    )
      return;

    setLoading(true);
    getDoc(doc(skoleRef, currentUserData.autoskolaId)).then((skola) => {
      const lekcije = skola?.data()?.lekcije;
      if (lekcije !== null && lekcije !== undefined && lekcije.length !== 0) {
        setNizLekcija(lekcije);
        setSekundarniNiz(lekcije);
        setDohvatiliLekcije(true);
        setLoading(false);
      } else if (lekcije.length === 0) {
        setNizLekcija([]);
        setSekundarniNiz([]);
        setDohvatiliLekcije(true);
        setLoading(false);
      }
    });
  }
  useEffect(() => {
    if (nizLekcija.length !== 0) {
      sortIme();
    }
  }, [nizLekcija]);

  function search(q: string) {
    const duzina = q.length;
    const niz = nizLekcija;
    if (q === "") {
      setSekundarniNiz(nizLekcija);
      return;
    }
    const filtriranNiz = _.filter(niz, function (o) {
      const ime = o.ime as string;
      console.log(
        "FILTER PRVI",
        ime.toLowerCase().substring(0, duzina),
        q.toLowerCase().substring(0, duzina)
      );
      if (
        ime.toLowerCase().substring(0, duzina) ===
          q.toLowerCase().substring(0, duzina) ||
        ime.toLowerCase().split(" ")[1].substring(0, duzina) ===
          q.toLowerCase().substring(0, duzina)
      )
        return o;
    });
    console.log("FILTER", filtriranNiz);
    setSekundarniNiz(filtriranNiz as Array<Lekcija>);
  }

  function sortIme() {
    const niz = nizLekcija;
    const sortiraniNiz = _.orderBy(niz, ["ime"], ["asc"]);
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("imeAZ");
  }
  function sortImeDesc() {
    const niz = nizLekcija;
    const sortiraniNiz = _.orderBy(niz, ["ime"], ["desc"]);
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("imeZA");
  }
  function sortDateAsc() {
    const niz = nizLekcija;
    const sortiraniNiz = niz.sort((a, b) => {
      const dateA = new Date(a.datum);
      const dateB = new Date(b.datum);
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    });
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("datumAsc");
  }
  function sortDateDesc() {
    const niz = nizLekcija;
    const sortiraniNiz = [...niz].sort((a, b) => {
      const dateA = new Date(a.datum);
      const dateB = new Date(b.datum);

      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      return 0;
    });
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("datumDesc");
  }
  async function obrisi(kojaLekcija: Lekcija) {
    setLoading(true);
    const filtiraniNiz = _.filter(nizLekcija, function (o) {
      if (o.id !== kojaLekcija.id) return o;
    });

    await updateDoc(doc(skoleRef, currentUserData?.autoskolaId), {
      lekcije: filtiraniNiz,
    }).then(() => {
      deleteDoc(doc(lekcijeRef, kojaLekcija.id)).then(() => {
        dohvatiLekcije();
        setLoading(false);
        setAlert(`Uspešno obrisana lekcija: ${kojaLekcija.ime}`);
        setIsAlert(true);
      });
    });
  }

  return (
    <>
      <div className="welcome">
        <div className="welcomeTitle">
          <IconContext.Provider value={{ className: "backIkonica" }}>
            <div className="ikonicaOmotac" onClick={() => navigate(-1)}>
              <MdArrowBack />
            </div>
          </IconContext.Provider>
          <h2>Lekcije</h2>
        </div>
        <input
          type="text"
          name="pretragaStudenata"
          id="pretragaStudenata"
          placeholder="Pretražite"
          onChange={(e) => search(e.target.value)}
        />
      </div>
      <div className="optionsShortcuts">
        {currentUserData?.uloga !== "student" && (
          <div className="shortcut" onClick={() => setIsNewLesson(true)}>
            <div className="ikonicaWrap">
              <BsPersonAdd color="white" size={50} />
            </div>
            <h3>Nova lekcija</h3>
          </div>
        )}
        <div className="shortcut" onClick={() => sortIme()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "imeAZ" ? "correct" : ""
            }`}
          >
            <AiOutlineSortAscending color="white" size={50} />
          </div>
          <h3>Ime A-Z</h3>
        </div>
        <div className="shortcut" onClick={() => sortImeDesc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "imeZA" ? "correct" : ""
            }`}
          >
            <AiOutlineSortDescending color="white" size={50} />
          </div>
          <h3>Ime Z-A</h3>
        </div>
        <div className="shortcut" onClick={() => sortDateDesc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "datumDesc" ? "correct" : ""
            }`}
          >
            <BsSortNumericDownAlt color="white" size={50} />
          </div>
          <h3>Najnovija - Najstarija</h3>
        </div>
        <div className="shortcut" onClick={() => sortDateAsc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "datumAsc" ? "correct" : ""
            }`}
          >
            <BsSortNumericUpAlt color="white" size={50} />
          </div>
          <h3>Najstarija - Najnovija</h3>
        </div>
      </div>
      <div className="list">
        {sekundarniNiz.map((lekcija) => {
          return (
            <div className="listPiece">
              <div className="pieceInfo">
                <div className="pieceInfoText">
                  <h3>{lekcija.ime}</h3>
                  <p>{`Datum kreiranja: ${new Date(lekcija.datum).getDate()}.${
                    new Date(lekcija.datum).getMonth() + 1
                  }.${new Date(lekcija.datum).getFullYear()}`}</p>
                  <p>{`Korisnik: ${lekcija.kreirao}`}</p>
                </div>
              </div>

              {currentUserData?.uloga !== "student" && (
                <div className="pieceOptions">
                  <div
                    className="pieceOption"
                    onClick={() => {
                      setKojaLekcija(lekcija);
                      setIsNewAPTL(true);
                    }}
                  >
                    <div className="pieceOptionIcon">
                      <AiFillFilePpt color="white" size={50} />
                    </div>
                    <p>Dodaj prez.</p>
                  </div>
                  <div
                    className="pieceOption"
                    onClick={() => {
                      setKojaLekcija(lekcija);
                      setIsATTL(true);
                    }}
                  >
                    <div className="pieceOptionIcon">
                      <MdQuiz color="white" size={50} />
                    </div>
                    <p>Dodaj test</p>
                  </div>
                  <div
                    className="pieceOption"
                    onClick={() => {
                      navigate(`/lekcija/${lekcija.id}`);
                    }}
                  >
                    <div className="pieceOptionIcon">
                      <AiFillEye color="white" size={50} />
                    </div>
                    <p>Pogledaj</p>
                  </div>
                  <div
                    className="pieceOption"
                    onClick={() => {
                      obrisi(lekcija);
                    }}
                  >
                    <div className="pieceOptionIcon">
                      <BsTrashFill color="white" size={50} />
                    </div>
                    <p>Obriši</p>
                  </div>
                </div>
              )}
              {currentUserData?.uloga === "student" && (
                <div className="pieceOptions">
                  <div
                    className="pieceOption"
                    onClick={() => {
                      navigate(`/lekcija/${lekcija.id}`);
                    }}
                  >
                    <div className="pieceOptionIcon">
                      <AiFillEye color="white" size={50} />
                    </div>
                    <p>Pogledaj</p>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {isNewLesson && (
        <NewLessonOverlay
          dohvatiLekcije={dohvatiLekcije}
          setIsNewLesson={setIsNewLesson}
        />
      )}
      {isNewAPTL && (
        <AddPresentationToLection
          setIsAPTL={setIsNewAPTL}
          kojaLekcija={kojaLekcija as Lekcija}
          nizLekcija={nizLekcija}
          dohvatiLekcije={dohvatiLekcije}
        />
      )}
      {isATTL && (
        <AddTestToLection
          setIsATTL={setIsATTL}
          kojaLekcija={kojaLekcija as Lekcija}
          nizLekcija={nizLekcija}
          dohvatiLekcije={dohvatiLekcije}
        />
      )}
    </>
  );
}
