import React, { useContext, useState } from "react";
import "../styles/newSTOverlay.scss";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { Grupa, UserData, autoSkola } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import {
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  grupeRef,
  korisniciRef,
  noviKorisniciRef,
  sendUltra,
  skoleRef,
} from "../utils/firebase";
import useKeyPress from "react-use-keypress";
import { IconContext } from "react-icons/lib";
import { IoEye, IoEyeOff } from "react-icons/io5";
import _ from "lodash";

interface Props {
  setIsProvera: any;
  kojiStudent: UserData;
  dohvatiStudente: any;
}

export function ProveraBrisanjaStudenta(props: Props) {
  const { kojiStudent, setIsProvera, dohvatiStudente } = props;
  const auth = getAuth();
  const user = auth.currentUser;
  const { currentUserData, setLoading, setAlert, setIsAlert } =
    useContext(AppContext);

  useKeyPress("Enter", () => {
    obrisiStudenta();
  });
  useKeyPress("Escape", () => {
    setIsProvera(false);
  });

  function obrisiStudenta() {
    setLoading(true);
    getDoc(doc(skoleRef, kojiStudent.autoskolaId)).then((skola) => {
      const skolaData: autoSkola = skola.data() as autoSkola;

      const filtriraniStudentiSkole = _.filter(
        skolaData.studenti,
        function (o) {
          if (o.id !== kojiStudent.id) return o;
        }
      );

      // UPDATUJEMO NIZ STUDENATA SKOLE

      updateDoc(doc(skoleRef, kojiStudent.autoskolaId), {
        studenti: filtriraniStudentiSkole,
      });

      if (
        kojiStudent.koJeInstruktorID !== "" &&
        kojiStudent.koJeInstruktorID !== null &&
        kojiStudent.koJeInstruktorID !== undefined
      ) {
        getDoc(doc(korisniciRef, kojiStudent.koJeInstruktorID)).then((ins) => {
          const instruktorData: UserData = ins.data() as UserData;
          const filtriraniNizInstruktoraSkole = _.filter(
            skolaData.instruktori,
            function (o) {
              if (o.id !== instruktorData.id) return o;
            }
          );
          const filtriraniStudentiKodInstruktora = _.filter(
            instruktorData.nizStudenata,
            function (o) {
              if (o.id !== kojiStudent.id) return o;
            }
          );
          const instruktorNewData: UserData = {
            aktiviran: instruktorData.aktiviran,
            autoskolaId: instruktorData.autoskolaId,
            brNocnih: instruktorData.brNocnih,
            brOdvozanihCasova: instruktorData.brOdvozanihCasova,
            datum: instruktorData.datum,
            editovanInfoPrviPut: instruktorData.editovanInfoPrviPut,
            email: instruktorData.email,
            grupa: instruktorData.grupa,
            grupaID: instruktorData.grupaID,
            id: instruktorData.id,
            ime: instruktorData.ime,
            koJeInstruktorID: instruktorData.koJeInstruktorID,
            koJeInstruktorIme: instruktorData.koJeInstruktorIme,
            krediti: instruktorData.krediti,
            nedeljnaKvota: instruktorData.nedeljnaKvota,
            nizOdvozanihTermina: instruktorData.nizOdvozanihTermina,
            nizStudenata: filtriraniStudentiKodInstruktora as Array<UserData>,
            nizZakazanihTermina: instruktorData.nizZakazanihTermina,
            polozioTeoriju: instruktorData.polozioTeoriju,
            prikazivanjeTermina: instruktorData.prikazivanjeTermina,
            profilnaURL: instruktorData.profilnaURL,
            telefon: instruktorData.telefon,
            uloga: instruktorData.uloga,
            uploads: instruktorData.uploads,
            voziAuto: instruktorData.voziAuto,
            subscribed: instruktorData.subscribed,
          };
          // UPDATUJEMO NIZ STUDENATA KOD INSTRUKTORA
          updateDoc(doc(korisniciRef, instruktorData.id), {
            nizStudenata: filtriraniStudentiKodInstruktora,
          }).then(() => {
            // UPDATUJEMO NIZ INSTRUKTORA U SKOLI
            updateDoc(doc(skoleRef, kojiStudent.autoskolaId), {
              instruktori: [
                ...filtriraniNizInstruktoraSkole,
                instruktorNewData,
              ],
            });
          });
        });
      }
      if (
        kojiStudent.grupaID !== "" &&
        kojiStudent.grupaID !== null &&
        kojiStudent.grupaID !== undefined
      ) {
        getDoc(doc(grupeRef, kojiStudent.grupaID)).then((gr) => {
          const grupaData: Grupa = gr.data() as Grupa;
          const filtriraniNizStudenataGrupe = _.filter(
            grupaData.studenti,
            function (o) {
              if (o.id !== kojiStudent.id) return o;
            }
          );
          const filtriraniNizGrupaSkole = _.filter(
            skolaData.grupe,
            function (o) {
              if (o.id !== grupaData.id) return o;
            }
          );
          const grupaNewData: Grupa = {
            datum: grupaData.datum,
            id: grupaData.id,
            ime: grupaData.ime,
            kreirao: grupaData.kreirao,
            kreiraoID: grupaData.kreiraoID,
            studenti: filtriraniNizStudenataGrupe as Array<UserData>,
          };

          // UPDATUJEMO NIZ STUDENATA GRUPE
          updateDoc(doc(grupeRef, kojiStudent.grupaID), {
            studenti: filtriraniNizStudenataGrupe,
          }).then(() => {
            // UPDATUJEMO NIZ GRUPA SKOLE
            updateDoc(doc(skoleRef, kojiStudent.autoskolaId), {
              grupe: [...filtriraniNizGrupaSkole, grupaNewData],
            });
          });
        });
      }

      deleteDoc(doc(korisniciRef, kojiStudent.id)).then(() => {
        setIsAlert(true);
        setAlert("Uspešno obrisan student");
        setLoading(false);
        setIsProvera(false);
        dohvatiStudente();
      });
    });
  }
  return (
    <div className="newSTOverlay">
      <div className="newSTBox">
        <h2>
          Da li ste sigurni da želite da obrišete kandidata {kojiStudent?.ime}
        </h2>
        <div className="buttonOptions">
          <button onClick={() => obrisiStudenta()}>Potvrdi</button>
          <button onClick={() => setIsProvera(false)}>Odustani</button>
        </div>

        <button
          className="x"
          onClick={() => {
            setIsProvera(false);
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}
