import React, { useContext, useEffect, useState } from "react";
import "../styles/newSTOverlay.scss";
import { Grupa, UserData } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { doc, updateDoc, getDoc, arrayUnion } from "firebase/firestore";
import { grupeRef, korisniciRef, skoleRef } from "../utils/firebase";
import _ from "lodash";
import useKeyPress from "react-use-keypress";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface Props {
  korisnik: UserData;
  setInstruktorChange: any;
  dohvatiKorisnika: any;
}

export function InstruktorChange(props: Props) {
  const { korisnik, setInstruktorChange, dohvatiKorisnika } = props;
  const { setLoading, setAlert, setIsAlert } = useContext(AppContext);
  const [nizInstruktora, setNizInstruktora] = useState<Array<UserData>>([]);
  const [noviInstruktor, setNoviInstruktor] = useState<UserData>();
  const [imeNovogInstruktora, setImeNovogInstruktora] = useState<string>();
  useKeyPress("Escape", () => {
    setInstruktorChange(false);
  });
  useKeyPress("Enter", () => {
    promeniInstruktora();
  });
  function handleChangeInstruktora(event: SelectChangeEvent) {
    setImeNovogInstruktora(event.target.value);
  }

  useEffect(() => {
    if (korisnik === null || korisnik === undefined) return;
    dohvatiInstruktore();
  }, [korisnik]);

  function dohvatiInstruktore() {
    getDoc(doc(skoleRef, korisnik.autoskolaId)).then((skola) => {
      setNizInstruktora(skola?.data()?.instruktori as Array<UserData>);
    });
  }

  function promeniInstruktora() {
    setLoading(true);
    if (
      korisnik === undefined ||
      korisnik === null ||
      noviInstruktor === null ||
      noviInstruktor === undefined
    ) {
      setLoading(false);
      return;
    }

    updateDoc(doc(korisniciRef, korisnik.id), {
      koJeInstruktorID: noviInstruktor.id,
      koJeInstruktorIme: noviInstruktor.ime,
      prikazivanjeTermina: noviInstruktor.prikazivanjeTermina,
      nedeljnaKvota: noviInstruktor.nedeljnaKvota,
    }).then(() => {
      const korisnikPodaci: UserData = {
        autoskolaId: korisnik.autoskolaId,
        email: korisnik.email,
        id: korisnik.id,
        ime: korisnik.ime,
        profilnaURL: korisnik.profilnaURL,
        uloga: korisnik.uloga,
        aktiviran: korisnik.aktiviran,
        brNocnih: korisnik.brNocnih,
        brOdvozanihCasova: korisnik.brOdvozanihCasova,
        datum: korisnik.datum,
        editovanInfoPrviPut: korisnik.editovanInfoPrviPut,
        grupa: korisnik.grupa,
        grupaID: korisnik.grupaID,
        koJeInstruktorID: noviInstruktor.id,
        koJeInstruktorIme: noviInstruktor.ime,
        krediti: korisnik.krediti,
        polozioTeoriju: korisnik.polozioTeoriju,
        telefon: korisnik.telefon,
        uploads: korisnik.uploads,
        voziAuto: korisnik.voziAuto,
        nizOdvozanihTermina: korisnik.nizOdvozanihTermina,
        nizStudenata: korisnik.nizStudenata,
        nizZakazanihTermina: korisnik.nizZakazanihTermina,
        prikazivanjeTermina: korisnik.prikazivanjeTermina,
        nedeljnaKvota: korisnik.nedeljnaKvota,
        subscribed: korisnik.subscribed,
      };
      getDoc(doc(skoleRef, korisnik.autoskolaId)).then((skola) => {
        const skolaData = skola.data();
        if (
          korisnik.grupaID !== null &&
          korisnik.grupaID !== undefined &&
          korisnik.grupaID !== ""
        ) {
          const trenutnaGrupa = _.find(skolaData?.grupe, function (o) {
            if (o.id === korisnik.grupaID) return o;
          });
          const filterStudenataUTrenutnojGrupi = _.filter(
            trenutnaGrupa.studenti,
            function (o) {
              if (o.id !== korisnik.id) return o;
            }
          );
          if (skolaData === undefined || skolaData === null) return;
          const filterGrupaBezTrenutne = _.filter(
            skolaData.grupe,
            function (o) {
              if (o.id !== korisnik.grupaID) return o;
            }
          );
          const trenutnaGrupaObjekat: Grupa = {
            datum: trenutnaGrupa.datum,
            id: trenutnaGrupa.id,
            ime: trenutnaGrupa.ime,
            kreirao: trenutnaGrupa.kreirao,
            kreiraoID: trenutnaGrupa.kreiraoID,
            studenti: [...filterStudenataUTrenutnojGrupi, korisnikPodaci],
          };

          updateDoc(doc(grupeRef, korisnik.grupaID), {
            studenti: [...filterStudenataUTrenutnojGrupi, korisnikPodaci],
          }).then(() => {
            updateDoc(doc(skoleRef, korisnik.autoskolaId), {
              grupe: [...filterGrupaBezTrenutne, trenutnaGrupaObjekat],
            });
          });
        }

        updateDoc(doc(korisniciRef, noviInstruktor.id.toString()), {
          nizStudenata: arrayUnion(korisnikPodaci),
        }).then(() => {
          if (korisnik.koJeInstruktorID !== "") {
            getDoc(doc(korisniciRef, korisnik.koJeInstruktorID)).then(
              (stariInstruktor) => {
                const stariInstruktorData: UserData =
                  stariInstruktor.data() as UserData;
                const filterStudenataKodStarog = _.filter(
                  stariInstruktorData.nizStudenata,
                  function (o) {
                    if (o.id !== korisnik.id) return o;
                  }
                );
                const stariInstruktorDataObjekat: UserData = {
                  autoskolaId: stariInstruktorData.autoskolaId,
                  email: stariInstruktorData.email,
                  id: stariInstruktorData.id,
                  ime: stariInstruktorData.ime,
                  profilnaURL: stariInstruktorData.profilnaURL,
                  uloga: stariInstruktorData.uloga,
                  aktiviran: stariInstruktorData.aktiviran,
                  brNocnih: stariInstruktorData.brNocnih,
                  brOdvozanihCasova: stariInstruktorData.brOdvozanihCasova,
                  datum: stariInstruktorData.datum,
                  editovanInfoPrviPut: stariInstruktorData.editovanInfoPrviPut,
                  grupa: stariInstruktorData.grupa,
                  koJeInstruktorID: stariInstruktorData.koJeInstruktorID,
                  grupaID: stariInstruktorData.grupaID,
                  koJeInstruktorIme: stariInstruktorData.koJeInstruktorIme,
                  krediti: stariInstruktorData.krediti,
                  polozioTeoriju: stariInstruktorData.polozioTeoriju,
                  telefon: stariInstruktorData.telefon,
                  uploads: stariInstruktorData.uploads,
                  voziAuto: stariInstruktorData.voziAuto,
                  nizStudenata: filterStudenataKodStarog as Array<UserData>,
                  nizOdvozanihTermina: stariInstruktorData.nizOdvozanihTermina,
                  nizZakazanihTermina: stariInstruktorData.nizZakazanihTermina,
                  prikazivanjeTermina: stariInstruktorData.prikazivanjeTermina,
                  nedeljnaKvota: stariInstruktorData.nedeljnaKvota,
                  subscribed: stariInstruktorData.subscribed,
                };
                let novinizstudenata: Array<UserData> = [];
                if (
                  noviInstruktor.nizStudenata !== undefined &&
                  noviInstruktor.nizStudenata !== null &&
                  noviInstruktor.nizStudenata.length !== 0
                ) {
                  console.log("NOVINIZ", noviInstruktor);
                  novinizstudenata = [
                    ...noviInstruktor.nizStudenata,
                    korisnikPodaci,
                  ];
                } else {
                  novinizstudenata = [...novinizstudenata, korisnikPodaci];
                }
                const noviInstruktorDataObjekat: UserData = {
                  autoskolaId: noviInstruktor.autoskolaId,
                  email: noviInstruktor.email,
                  id: noviInstruktor.id,
                  ime: noviInstruktor.ime,
                  profilnaURL: noviInstruktor.profilnaURL,
                  uloga: noviInstruktor.uloga,
                  aktiviran: noviInstruktor.aktiviran,
                  brNocnih: noviInstruktor.brNocnih,
                  brOdvozanihCasova: noviInstruktor.brOdvozanihCasova,
                  datum: noviInstruktor.datum,
                  editovanInfoPrviPut: noviInstruktor.editovanInfoPrviPut,
                  grupa: noviInstruktor.grupa,
                  koJeInstruktorID: noviInstruktor.koJeInstruktorID,
                  grupaID: noviInstruktor.grupaID,
                  koJeInstruktorIme: noviInstruktor.koJeInstruktorIme,
                  krediti: noviInstruktor.krediti,
                  polozioTeoriju: noviInstruktor.polozioTeoriju,
                  telefon: noviInstruktor.telefon,
                  uploads: noviInstruktor.uploads,
                  voziAuto: noviInstruktor.voziAuto,
                  nizStudenata: novinizstudenata,
                  nizOdvozanihTermina: noviInstruktor.nizOdvozanihTermina,
                  nizZakazanihTermina: noviInstruktor.nizZakazanihTermina,
                  prikazivanjeTermina: noviInstruktor.prikazivanjeTermina,
                  nedeljnaKvota: noviInstruktor.nedeljnaKvota,
                  subscribed: noviInstruktor.subscribed,
                };
                const filterInstruktoraBezNovogIStarog = _.filter(
                  nizInstruktora,
                  function (o) {
                    if (
                      o.id !== stariInstruktorData.id &&
                      o.id !== noviInstruktor.id
                    )
                      return o;
                  }
                );
                const noviNizInstruktora = [
                  ...filterInstruktoraBezNovogIStarog,
                  noviInstruktorDataObjekat,
                  stariInstruktorDataObjekat,
                ];
                console.log(
                  "UPDATEDOC1",
                  noviNizInstruktora,
                  stariInstruktorData
                );

                updateDoc(doc(skoleRef, korisnik.autoskolaId), {
                  instruktori: noviNizInstruktora,
                }).then(() => {
                  console.log("UPDATEDOC1 USPESNO");
                });
                if (
                  stariInstruktorData.nizStudenata !== null &&
                  stariInstruktorData.nizStudenata !== undefined &&
                  stariInstruktorData.nizStudenata.length !== 0
                ) {
                  const filtriraniNizStudenataStarogInstruktora = _.filter(
                    stariInstruktorData.nizStudenata,
                    function (o) {
                      if (o.id !== korisnik.id) return o;
                    }
                  );

                  updateDoc(doc(korisniciRef, korisnik.koJeInstruktorID), {
                    nizStudenata: filtriraniNizStudenataStarogInstruktora,
                  });
                }
              }
            );
          } else if (korisnik.koJeInstruktorID === "") {
            let novinizstudenata: Array<UserData> = [];

            if (
              noviInstruktor.nizStudenata !== undefined &&
              noviInstruktor.nizStudenata !== null &&
              noviInstruktor.nizStudenata.length !== 0
            ) {
              console.log("NOVINIZ", noviInstruktor);
              novinizstudenata = [
                ...noviInstruktor.nizStudenata,
                korisnikPodaci,
              ];
            } else {
              novinizstudenata = [...novinizstudenata, korisnikPodaci];
            }
            const noviInstruktorDataObjekat: UserData = {
              autoskolaId: noviInstruktor.autoskolaId,
              email: noviInstruktor.email,
              id: noviInstruktor.id,
              ime: noviInstruktor.ime,
              profilnaURL: noviInstruktor.profilnaURL,
              uloga: noviInstruktor.uloga,
              aktiviran: noviInstruktor.aktiviran,
              brNocnih: noviInstruktor.brNocnih,
              brOdvozanihCasova: noviInstruktor.brOdvozanihCasova,
              datum: noviInstruktor.datum,
              editovanInfoPrviPut: noviInstruktor.editovanInfoPrviPut,
              grupa: noviInstruktor.grupa,
              koJeInstruktorID: noviInstruktor.koJeInstruktorID,
              grupaID: noviInstruktor.grupaID,
              koJeInstruktorIme: noviInstruktor.koJeInstruktorIme,
              krediti: noviInstruktor.krediti,
              polozioTeoriju: noviInstruktor.polozioTeoriju,
              telefon: noviInstruktor.telefon,
              uploads: noviInstruktor.uploads,
              voziAuto: noviInstruktor.voziAuto,
              nizStudenata: novinizstudenata,
              nizOdvozanihTermina: noviInstruktor.nizOdvozanihTermina,
              nizZakazanihTermina: noviInstruktor.nizZakazanihTermina,
              prikazivanjeTermina: noviInstruktor.prikazivanjeTermina,
              nedeljnaKvota: noviInstruktor.nedeljnaKvota,
              subscribed: noviInstruktor.subscribed,
            };

            const filterInstruktoraBezNovog = _.filter(
              nizInstruktora,
              function (o) {
                if (o.id !== noviInstruktor.id) return o;
              }
            );
            const noviNizInstruktora = [
              ...filterInstruktoraBezNovog,
              noviInstruktorDataObjekat,
            ];

            updateDoc(doc(skoleRef, korisnik.autoskolaId), {
              instruktori: noviNizInstruktora,
            }).then(() => {
              console.log("UPDATEDOC1 USPESNO");
            });
          }
        });

        if (korisnik.uloga === "student") {
          const filtriraniNizStudenata = _.filter(
            skolaData?.studenti,
            function (o) {
              if (o.id !== korisnik.id) return o;
            }
          );

          updateDoc(doc(skoleRef, korisnik.autoskolaId), {
            studenti: [...filtriraniNizStudenata, korisnikPodaci],
          }).then(() => {
            setAlert("Uspešno promenjen instruktor");
            dohvatiKorisnika();
            setInstruktorChange(false);
            setIsAlert(true);
            setLoading(false);
          });
        }
      });
    });
  }

  return (
    <div className="newSTOverlay">
      <div className="newSTBox">
        <h2>Dodeli instruktora</h2>
        <FormControl fullWidth className="izborDatumaMUI">
          <InputLabel id="izborInstruktora">Izaberite instruktora</InputLabel>
          <Select
            labelId="izborInstruktora"
            id="izborInstruktora"
            label="Izaberite instruktora"
            value={imeNovogInstruktora}
            onChange={handleChangeInstruktora}
            className="izborDatumaMUI"
          >
            {nizInstruktora.map((instruktor) => {
              if (instruktor.id !== korisnik.koJeInstruktorID)
                return (
                  <MenuItem
                    value={instruktor.ime}
                    onClick={() => setNoviInstruktor(instruktor)}
                  >
                    {instruktor.ime}
                  </MenuItem>
                );
            })}
          </Select>
        </FormControl>
        <div className="buttonOptions">
          <button onClick={() => promeniInstruktora()}>Potvrdi</button>
          <button onClick={() => setInstruktorChange(false)}>Odustani</button>
        </div>
        <button
          className="x"
          onClick={() => {
            setInstruktorChange(false);
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}
