import React, { useContext, useEffect, useState } from "react";
import "../styles/newSTOverlay.scss";
import { Grupa, Lekcija, Predavanje } from "../utils/types";
import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { predavanjeRef, sendUltra, skoleRef } from "../utils/firebase";
import { AppContext } from "../functions/AppProvider";
import _ from "lodash";
import useKeyPress from "react-use-keypress";
import uuid from "react-uuid";
import { DatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface Props {
  setIsNewPredavanje: any;
  dohvatiPredavanja?: any;
  dohvatiSkolu?: any;
}

export function NewPredavanjeOverlay(props: Props) {
  const { setIsNewPredavanje, dohvatiPredavanja, dohvatiSkolu } = props;
  const { currentUserData, setLoading, setAlert, setIsAlert } =
    useContext(AppContext);
  const [datum, setDatum] = useState<string>("");
  const [vreme, setVreme] = useState<string>("");
  const [imeIzabraneGrupe, setImeIzabraneGrupe] = useState<string>("");
  const [izabranaGrupa, setIzabranaGrupa] = useState<Grupa>();
  const [imeIzabraneLekcije, setImeIzabraneLekcije] = useState<string>("");
  const [izabranaLekcija, setIzabranaLekcija] = useState<Lekcija>();
  const [nizGrupa, setNizGrupa] = useState<Array<Grupa>>([]);
  const [nizLekcija, setNizLekcija] = useState<Array<Lekcija>>([]);

  useKeyPress("Escape", () => {
    setIsNewPredavanje(false);
  });
  useEffect(() => {
    dohvatiGrupeILekcije();
  }, [currentUserData]);
  function handleChangeGrupe(event: SelectChangeEvent) {
    setImeIzabraneGrupe(event.target.value);
  }
  function handleChangeLekcije(event: SelectChangeEvent) {
    setImeIzabraneLekcije(event.target.value);
  }
  function dohvatiGrupeILekcije() {
    if (
      currentUserData === null ||
      currentUserData === undefined ||
      currentUserData.autoskolaId === null ||
      currentUserData.autoskolaId === undefined ||
      currentUserData.autoskolaId === "" ||
      skoleRef === null ||
      skoleRef === undefined
    )
      return;

    setLoading(true);
    getDoc(doc(skoleRef, currentUserData.autoskolaId)).then((skola) => {
      const grupe = skola?.data()?.grupe as Array<Grupa>;
      const lekcije = skola?.data()?.lekcije as Array<Lekcija>;
      setNizGrupa(grupe);
      setNizLekcija(lekcije);
      setLoading(false);
    });
  }

  function zakaziPredavanje() {
    console.log(izabranaGrupa?.ime, izabranaLekcija?.ime, datum, vreme);
    if (
      currentUserData === null ||
      currentUserData === undefined ||
      izabranaGrupa === null ||
      izabranaGrupa === undefined ||
      izabranaLekcija === null ||
      izabranaLekcija === undefined
    )
      return;
    setLoading(true);
    const jb = uuid();
    if (vreme.length === 4) {
      setVreme(vreme.concat("0"));
    }
    const predavanjeData: Predavanje = {
      datum: datum,
      vreme: vreme,
      id: jb,
      kreirao: currentUserData.ime,
      kreiraoID: currentUserData.id,
      grupa: izabranaGrupa,
      lekcija: izabranaLekcija,
    };
    const [godina, mesec, dan] = datum.split("-").map(Number);
    setDoc(doc(predavanjeRef, jb), predavanjeData).then(() => {
      updateDoc(doc(skoleRef, currentUserData.autoskolaId), {
        predavanja: arrayUnion(predavanjeData),
      }).then(() => {
        if (dohvatiPredavanja !== null && dohvatiPredavanja !== undefined) {
          dohvatiPredavanja();
        }
        if (dohvatiSkolu !== null && dohvatiSkolu !== undefined) {
          dohvatiSkolu();
        }
        for (let i = 0; i < izabranaGrupa.studenti.length; i++) {
          if (
            izabranaGrupa.studenti[i].polozioTeoriju === false &&
            izabranaGrupa.studenti[i].telefon !== "" &&
            izabranaGrupa.studenti[i].subscribed === true
          ) {
            sendUltra(
              `+381${izabranaGrupa.studenti[i].telefon}`,
              `Poštovani, ${izabranaGrupa.studenti[i].ime},\nZakazano je novo predavanje, ${dan}/${mesec}/${godina} u ${vreme}.\nLekcija: ${izabranaLekcija.ime}.\n Vaš VoznjaRS tim!`
            );
          }
        }
        setLoading(false);
        setAlert("Uspešno zakazano predavanje");
        setIsAlert(true);
        setIsNewPredavanje(false);
      });
    });
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="newSTOverlay">
        <div className="newSTBox">
          <label htmlFor="datumPredavanja">Datum</label>
          {/* <input type="date" name="datumPredavanja" id="datumPredavanja" /> */}
          <DatePicker
            label="Izaberite datum"
            className="izborDatumaMUI"
            format="DD/MM/YYYY"
            onChange={(e: any) => setDatum(`${e.$y}-${e.$M + 1}-${e.$D}`)}
          />
          <label htmlFor="vremePredavanja">Vreme</label>
          <MobileTimePicker
            label="Izaberite vreme"
            className="izborDatumaMUI"
            format="HH:mm"
            ampm={false}
            minutesStep={5}
            localeText={{
              toolbarTitle: "Izaberite vreme",
              cancelButtonLabel: "Odustani",
              okButtonLabel: "Potvrdi",
            }}
            onChange={(e: any) => {
              console.log(e);
              if (e === null) return;
              console.log(new Date(e.$d).getHours());
              console.log(new Date(e.$d).getMinutes());
              let sati = new Date(e.$d).getHours();
              let minuti = new Date(e.$d).getMinutes();
              if (minuti === 0) {
                setVreme(`${sati}:00`);
              } else {
                setVreme(`${sati}:${minuti}`);
              }
            }}
          />

          <label>Grupa</label>

          <FormControl fullWidth className="izborDatumaMUI">
            <InputLabel id="izborGrupe">Izaberite grupu</InputLabel>
            <Select
              labelId="izborGrupe"
              id="selectGrupe"
              label="Izaberite grupu"
              value={imeIzabraneGrupe}
              onChange={handleChangeGrupe}
              className="izborDatumaMUI"
            >
              {nizGrupa.map((grupa) => {
                return (
                  <MenuItem
                    value={grupa.ime}
                    onClick={() => {
                      setIzabranaGrupa(grupa);
                    }}
                  >
                    {grupa.ime}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <label>Lekcija</label>

          <FormControl fullWidth className="izborDatumaMUI">
            <InputLabel id="izborGrupe">Izaberite lekciju</InputLabel>
            <Select
              labelId="izborGrupe"
              id="selectGrupe"
              label="Izaberite lekciju"
              value={imeIzabraneLekcije}
              onChange={handleChangeLekcije}
              className="izborDatumaMUI"
            >
              {nizLekcija.map((lekcija) => {
                return (
                  <MenuItem
                    value={lekcija.ime}
                    onClick={() => {
                      setIzabranaLekcija(lekcija);
                    }}
                  >
                    {lekcija.ime}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <button className="dugme" onClick={() => zakaziPredavanje()}>
            Zakaži
          </button>
          <button
            className="x"
            onClick={() => {
              setIsNewPredavanje(false);
            }}
          >
            X
          </button>
        </div>
      </div>
    </LocalizationProvider>
  );
}
