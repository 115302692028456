import React, { useContext, useEffect, useState } from "react";
import "../styles/home.scss";
import {
  BsPersonAdd,
  BsSortNumericDownAlt,
  BsSortNumericUpAlt,
  BsTrashFill,
} from "react-icons/bs";
import {
  AiFillEye,
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { BiSortDown } from "react-icons/bi";
import { Grupa, UserData } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { grupeRef, skoleRef } from "../utils/firebase";
import { MdArrowBack, MdGroupAdd } from "react-icons/md";
import _ from "lodash";
import { NewGroupOverlay } from "../components/NewGroupOverlay";
import { AddStudentsToGroups } from "../components/AddStudentsToGroups";
import { useNavigate } from "react-router";
import { IconContext } from "react-icons/lib";

interface Props {}

export function Grupe(props: Props) {
  const [nizStudenata, setNizStudenata] = useState<Array<UserData>>([]);
  const [nizGrupa, setNizGrupa] = useState<Array<Grupa>>([]);
  const [sekundarniNiz, setSekundarniNiz] = useState<Array<Grupa>>([]);
  const [isASTG, setIsASTG] = useState<boolean>(false);
  const [kojaGrupa, setKojaGrupa] = useState<Grupa>();
  const [isNewGroup, setIsNewGroup] = useState<boolean>(false);
  const { currentUserData, setLoading } = useContext(AppContext);
  const [trenutniSort, setTrenutniSort] = useState<
    "imeAZ" | "imeZA" | "datumAsc" | "datumDesc"
  >();
  const navigate = useNavigate();
  useEffect(() => {
    dohvatiStudenteIGrupe();
  }, [currentUserData]);

  function dohvatiStudenteIGrupe() {
    console.log("GRUPE UCITAVANEJ");
    if (
      currentUserData === null ||
      currentUserData === undefined ||
      currentUserData.autoskolaId === null ||
      currentUserData.autoskolaId === undefined ||
      currentUserData.autoskolaId === "" ||
      skoleRef === null ||
      skoleRef === undefined
    )
      return;

    setLoading(true);
    getDoc(doc(skoleRef, currentUserData.autoskolaId)).then((skola) => {
      const studenti = skola?.data()?.studenti as Array<UserData>;
      const grupe = skola?.data()?.grupe as Array<Grupa>;
      setNizGrupa(grupe);
      setSekundarniNiz(grupe);
      setNizStudenata(studenti);
      setLoading(false);
    });
  }
  function search(q: string) {
    const duzina = q.length;
    const niz = nizGrupa;
    if (q === "") {
      setSekundarniNiz(nizGrupa);
      return;
    }
    const filtriranNiz = _.filter(niz, function (o) {
      const ime = o.ime;
      console.log(
        "FILTER PRVI",
        ime.toLowerCase().substring(0, duzina),
        q.toLowerCase().substring(0, duzina)
      );
      if (
        ime.toLowerCase().substring(0, duzina) ===
          q.toLowerCase().substring(0, duzina) ||
        ime.toLowerCase().split(" ")[1].substring(0, duzina) ===
          q.toLowerCase().substring(0, duzina)
      )
        return o;
    });
    console.log("FILTER", filtriranNiz);
    setSekundarniNiz(filtriranNiz as Array<Grupa>);
  }

  function sortIme() {
    const niz = nizGrupa;
    const sortiraniNiz = _.orderBy(niz, ["ime"], ["asc"]);
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("imeAZ");
  }
  function sortImeDesc() {
    const niz = nizGrupa;
    const sortiraniNiz = _.orderBy(niz, ["ime"], ["desc"]);
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("imeZA");
  }
  function sortDateAsc() {
    const niz = nizGrupa;
    const sortiraniNiz = niz.sort((a, b) => {
      const dateA = new Date(a.datum);
      const dateB = new Date(b.datum);
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    });
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("datumAsc");
  }
  function sortDateDesc() {
    const niz = nizGrupa;
    const sortiraniNiz = [...niz].sort((a, b) => {
      const dateA = new Date(a.datum);
      const dateB = new Date(b.datum);

      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      return 0;
    });
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("datumDesc");
  }
  async function obrisi(kojaGrupa: Grupa) {
    setLoading(true);
    const filtriraniNiz = _.filter(nizGrupa, function (o) {
      if (o.id !== kojaGrupa.id) return o;
    });

    await updateDoc(doc(skoleRef, currentUserData?.autoskolaId), {
      grupe: filtriraniNiz,
    }).then(() => {
      deleteDoc(doc(grupeRef, kojaGrupa.id)).then(() => {
        dohvatiStudenteIGrupe();
        setLoading(false);
      });
    });
  }

  return (
    <>
      <div className="welcome">
        <div className="welcomeTitle">
          <IconContext.Provider value={{ className: "backIkonica" }}>
            <div className="ikonicaOmotac" onClick={() => navigate(-1)}>
              <MdArrowBack />
            </div>
          </IconContext.Provider>
          <h2>Grupe ({nizGrupa.length})</h2>
        </div>
        <input
          type="text"
          name="pretragaStudenata"
          id="pretragaStudenata"
          placeholder="Pretražite"
          onChange={(e) => search(e.target.value)}
        />
      </div>
      <div className="optionsShortcuts">
        <div className="shortcut" onClick={() => setIsNewGroup(true)}>
          <div className="ikonicaWrap">
            <MdGroupAdd color="white" size={50} />
          </div>
          <h3>Nova grupa</h3>
        </div>
        <div className="shortcut" onClick={() => sortIme()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "imeAZ" ? "correct" : ""
            }`}
          >
            <AiOutlineSortAscending color="white" size={50} />
          </div>
          <h3>Ime A-Z</h3>
        </div>
        <div className="shortcut" onClick={() => sortImeDesc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "imeZA" ? "correct" : ""
            }`}
          >
            <AiOutlineSortDescending color="white" size={50} />
          </div>
          <h3>Ime Z-A</h3>
        </div>
        <div className="shortcut" onClick={() => sortDateDesc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "datumDesc" ? "correct" : ""
            }`}
          >
            <BsSortNumericDownAlt color="white" size={50} />
          </div>
          <h3>Najnoviji - Najstariji</h3>
        </div>
        <div className="shortcut" onClick={() => sortDateAsc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "datumAsc" ? "correct" : ""
            }`}
          >
            <BsSortNumericUpAlt color="white" size={50} />
          </div>
          <h3>Najstariji - Najnoviji</h3>
        </div>
      </div>

      <div className="list">
        {sekundarniNiz.map((grupa) => {
          return (
            <div className="listPiece">
              <div className="pieceInfo">
                <div className="pieceInfoText">
                  <h3>{grupa.ime}</h3>
                  <p>{`Datum kreiranja: ${new Date(grupa.datum).getDate()}.${
                    new Date(grupa.datum).getMonth() + 1
                  }.${new Date(grupa.datum).getFullYear()}`}</p>
                  <p>{`Korisnik: ${grupa.kreirao}`}</p>
                  <p>{`Broj kandidata: ${grupa.studenti.length}`}</p>
                </div>
              </div>
              <div className="pieceOptions">
                <div
                  className="pieceOption"
                  onClick={() => {
                    navigate(`/grupa/${grupa.id}`);
                  }}
                >
                  <div className="pieceOptionIcon">
                    <AiFillEye color="white" size={50} />
                  </div>
                  <p>Pogledaj</p>
                </div>
                <div
                  className="pieceOption"
                  onClick={() => {
                    setKojaGrupa(grupa);
                    setIsASTG(true);
                  }}
                >
                  <div className="pieceOptionIcon">
                    <BsPersonAdd color="white" size={50} />
                  </div>
                  <p>Dodaj</p>
                </div>
                <div className="pieceOption" onClick={() => obrisi(grupa)}>
                  <div className="pieceOptionIcon">
                    <BsTrashFill color="white" size={50} />
                  </div>
                  <p>Obriši</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {isNewGroup && (
        <NewGroupOverlay
          nizStudenata={nizStudenata}
          setIsNewGroup={setIsNewGroup}
          dohvatiStudenteIGrupe={dohvatiStudenteIGrupe}
        />
      )}
      {isASTG && (
        <AddStudentsToGroups
          dohvatiStudenteIGrupe={dohvatiStudenteIGrupe}
          nizStudenata={nizStudenata}
          setIsASTG={setIsASTG}
          grupa={kojaGrupa as Grupa}
          nizGrupa={nizGrupa}
        />
      )}
    </>
  );
}
