import React, { useContext, useEffect, useState } from "react";
import "../styles/login.scss";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../functions/AppProvider";
import {
  db,
  korisniciRef,
  noviKorisniciRef,
  skoleRef,
  terminiRef,
  zahteviRef,
} from "../utils/firebase";
import {
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { UserData, Zahtev, autoSkola } from "../utils/types";
import _ from "lodash";
import useKeyPress from "react-use-keypress";
import VoznjaLogo from "../voznjarslogo.png";
import Logo from "../voznjaLogo.png";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface Props {}

export function CreateAcc(props: Props) {
  const [ime, setIme] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [brTel, setBrTel] = useState<string>("");
  const [brOdC, setBrOdC] = useState<number>(0);
  const { setLoading, setAlert, setIsAlert, currentUserData, currentUser } =
    useContext(AppContext);
  const [autoSkole, setAutoSkole] = useState<Array<autoSkola>>([]);
  const [imeIzabraneSkole, setImeIzabraneSkole] = useState<string>("");
  const [izabranaSkola, setIzabranaSkola] = useState<autoSkola>();
  const [izabranaObuka, setIzabranaObuka] = useState<string>("");
  const [imeIzabraneObuke, setImeIzabraneObuke] = useState<string>("");
  const navigate = useNavigate();

  function handleChangeSkole(event: SelectChangeEvent) {
    setImeIzabraneSkole(event.target.value);
  }
  function handleChangeObuke(event: SelectChangeEvent) {
    setImeIzabraneObuke(event.target.value);
  }

  const dohvatanjeSkola = async () => {
    const querySnapshot = await getDocs(collection(db, "autoSkole"));
    const skole = querySnapshot.docs.map((doc) => ({ ...doc.data() }));
    setAutoSkole(skole as Array<autoSkola>);
    console.log(skole);
  };
  useEffect(() => {
    dohvatanjeSkola();
  }, []);

  useKeyPress("Enter", () => {
    posalji();
  });

  useEffect(() => {
    if (
      currentUserData !== null &&
      currentUserData !== undefined &&
      currentUser !== null &&
      currentUser !== undefined
    ) {
      navigate("/home");
    }
  }, [currentUserData]);

  async function posalji() {
    // alert(`${ime}, ${email}, ${brTel}, ${imeIzabraneSkole}, ${izabranaObuka}`);
    const zahtev: Zahtev = {
      broj: brTel,
      datum: new Date().toString(),
      email: email,
      ime: ime,
      obuka: izabranaObuka,
      skola: imeIzabraneSkole,
      brOdC: brOdC,
    };
    await setDoc(doc(zahteviRef, zahtev.email), zahtev).then(async () => {
      setAlert("Uspešno poslat zahtev. Odabrana škola će Vas kontaktirati.");
      setIsAlert(true);
      navigate("/");
    });
  }

  return (
    <div className="loginPage">
      <img src={Logo} alt="Voznja.rs" />
      <div className="loginContainer">
        <h2>Zahtev za nalog</h2>
        <input
          type="text"
          name="ime"
          id="ime"
          placeholder="Ime Prezime"
          onChange={(e) => setIme(e.target.value)}
        />
        <input
          type="email"
          name="email"
          id="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="text"
          name="tel"
          id="tel"
          placeholder="Broj telefona"
          onChange={(e) => setBrTel(e.target.value)}
        />
        <FormControl fullWidth className="izborDatumaMUI">
          <InputLabel id="izborGrupe">Izaberite školu</InputLabel>
          <Select
            labelId="izborGrupe"
            id="selectGrupe"
            label="Izaberite školu"
            value={imeIzabraneSkole}
            onChange={handleChangeSkole}
            className="izborDatumaMUI"
          >
            {autoSkole.map((skola) => {
              return (
                <MenuItem
                  value={skola.ime}
                  onClick={() => {
                    setIzabranaSkola(skola);
                  }}
                >
                  {skola.ime}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth className="izborDatumaMUI">
          <InputLabel id="izborGrupe">Teorija/Praksa</InputLabel>
          <Select
            labelId="izborGrupe"
            id="selectGrupe"
            label="Izaberite obuku"
            value={imeIzabraneObuke}
            onChange={handleChangeObuke}
            className="izborDatumaMUI"
          >
            <MenuItem
              value={"teorija"}
              onClick={() => {
                setIzabranaObuka("Teorijska Obuka");
              }}
            >
              Teorijska Obuka
            </MenuItem>
            <MenuItem
              value={"praksa"}
              onClick={() => {
                setIzabranaObuka("Praktična Obuka");
              }}
            >
              Praktična Obuka
            </MenuItem>
          </Select>
        </FormControl>
        {izabranaObuka === "Praktična Obuka" && (
          <input
            type="number"
            name="tel"
            id="tel"
            placeholder="Broj odvozanih časova"
            onChange={(e) => setBrOdC(parseInt(e.target.value))}
          />
        )}

        <div className="loginOptions">
          <button onClick={() => posalji()}>Pošalji zahtev</button>
        </div>
      </div>
      <p>Verzija 1.0</p>
      <h2
        onClick={() => {
          navigate("/login");
        }}
      >
        Već imam nalog
      </h2>
    </div>
  );
}
