import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { Navigation } from "./components/Navigation";
import { AppContext } from "./functions/AppProvider";
import handleSubmit from "./functions/handlesubmit";
import { Home } from "./pages/Home";
import { HomeSA } from "./pages/HomeSA";
import { HomeSI } from "./pages/HomeSI";
import { HomeSS } from "./pages/HomeSS";
import { HomeST } from "./pages/HomeST";
import { Kalendar } from "./pages/Kalendar";
import { Login } from "./pages/Login";
import { SchoolsList } from "./pages/SchoolsList";
import "./styles/app.scss";
import NewTest from "./pages/NewTest";
import UpitList from "./pages/UpitList";
import RandomList from "./pages/RandomList";
import { KalendarV2 } from "./pages/KalendarV2";
import { Profil } from "./pages/Profile";
import { Loading } from "./pages/Loading";
import { Studenti } from "./pages/Studenti";
import { Instruktori } from "./pages/Intstruktori";
import { Predavaci } from "./pages/Predavaci";
import { Prezentacije } from "./pages/Prezentacije";
import { Testovi } from "./pages/Testovi";
import { PrezentacijaPage } from "./pages/PrezentacijaPage";
import { TestPage } from "./pages/Test";
import { TestResenja } from "./pages/TestResenja";
import { Grupe } from "./pages/Grupe";
import { Alert } from "./components/Alert";
import { Lekcije } from "./pages/Lekcije";
import { Predavanja } from "./pages/Predavanja";
import { InteraktivniKvizAdmin } from "./pages/InteraktivniKvizAdmin";
import { InteraktivniKvizStudent } from "./pages/InteraktivniKvizStudent";
import { LekcijaPage } from "./pages/LekcijaPage";
import { ForgottenP1 } from "./pages/ForgottenP1";
import { GrupaPage } from "./pages/GrupaPage";
import { Pocetna } from "./pages/Pocetna";
import { CreateAcc } from "./pages/CreateAcc";
import { Zahtevi } from "./pages/Zahtevi";

function App() {
  const [probni, setProbni] = useState<String>("");
  const { currentUser, currentUserData, loading, setLoading, isAlert } =
    useContext(AppContext);
  useEffect(() => {
    console.log(probni, "aaaa");
  }, [probni]);
  function posalji() {
    handleSubmit(probni);
  }
  useEffect(() => {
    console.log("UCITAVANJE APP");
    setLoading(true);
  }, []);
  useEffect(() => {
    if (currentUserData !== null && currentUserData !== undefined) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [currentUserData]);
  return (
    <Router>
      <div className="app">
        {loading && <Loading />}
        <Routes>
          <Route path="/login" Component={Login} />
          <Route path="/newacc" Component={CreateAcc} />
          <Route path="/" Component={Pocetna} />
          <Route path="/zaboravljenasifra" Component={ForgottenP1} />
          <Route
            path="/home"
            element={
              currentUserData === null ||
              currentUserData === undefined ||
              currentUser === null ||
              currentUser === undefined ? (
                <Navigate to={{ pathname: "/login" }} />
              ) : currentUserData?.uloga === "schooladmin" ? (
                <HomeSA />
              ) : currentUserData?.uloga === "schoolinstructor" ? (
                <HomeSI />
              ) : currentUserData?.uloga === "schoolteacher" ? (
                <HomeST />
              ) : currentUserData?.uloga === "student" ? (
                <HomeSS />
              ) : currentUserData?.uloga === "globaladmin" ? (
                <Home />
              ) : (
                <p>User doesn't have a role</p>
              )
            }
          />
          <Route
            path="/zahtevi"
            element={
              currentUser !== null ? (
                <Zahtevi />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/schoolsList"
            element={
              currentUser !== null ? (
                <SchoolsList />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/studenti/:skolaID"
            element={
              currentUser !== null ? (
                <Studenti />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/instruktori/:skolaID"
            element={
              currentUser !== null ? (
                <Instruktori />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/testovi/:skolaID"
            element={
              currentUser !== null ? (
                <Testovi />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/predavaci/:skolaID"
            element={
              currentUser !== null ? (
                <Predavaci />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/prezentacije/:skolaID"
            element={
              currentUser !== null ? (
                <Prezentacije />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/lekcije/:skolaID"
            element={
              currentUser !== null ? (
                <Lekcije />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/predavanja/:skolaID"
            element={
              currentUser !== null ? (
                <Predavanja />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/grupe/:skolaID"
            element={
              currentUser !== null ? (
                <Grupe />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/grupa/:grupaID"
            element={
              currentUser !== null ? (
                <GrupaPage />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/prezentacija/:prezentacijaID"
            element={
              currentUser !== null ? (
                <PrezentacijaPage />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/noviTest/:imeTesta/:edit/:testID"
            element={
              currentUser !== null ? (
                <NewTest />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/noviTest/:imeTesta/:edit"
            element={
              currentUser !== null ? (
                <NewTest />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/interaktivniKvizAdmin/:testID"
            element={
              currentUser !== null ? (
                <InteraktivniKvizAdmin />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/interaktivniKvizStudent/:testID/:ikID"
            element={
              currentUser !== null ? (
                <InteraktivniKvizStudent />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/test/:testID"
            element={
              currentUser !== null ? (
                <TestPage />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/lekcija/:lekcijaID"
            element={
              currentUser !== null ? (
                <LekcijaPage />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/test/:testID/:odgovori"
            element={
              currentUser !== null ? (
                <TestResenja />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/allUpits/:korisnikID"
            element={
              currentUser !== null ? (
                <UpitList />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/kalendar/:instruktorID"
            element={
              currentUser !== null ? (
                <Kalendar />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/kalendarv2/:instruktorID"
            element={
              currentUser !== null ? (
                <KalendarV2 />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/kalendarv2/:instruktorID/:datum"
            element={
              currentUser !== null ? (
                <KalendarV2 />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
          <Route
            path="/profile/:profilID"
            element={
              currentUser !== null ? (
                <Profil />
              ) : (
                <Navigate to={{ pathname: "/login" }} />
              )
            }
          />
        </Routes>
      </div>
      {currentUserData !== null &&
        currentUserData !== undefined &&
        currentUser !== null &&
        currentUser !== undefined && <Navigation />}
      {isAlert && <Alert />}
    </Router>
  );
}

export default App;
