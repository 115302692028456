import React, { useContext, useEffect, useState } from "react";
import "../styles/newSTOverlay.scss";
import { UserData } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import {
  doc,
  setDoc,
  updateDoc,
  getDoc,
  arrayUnion,
  getDocs,
  collection,
  deleteDoc,
} from "firebase/firestore";
import {
  db,
  noviKorisniciRef,
  sendUltra,
  skoleRef,
  zahteviRef,
} from "../utils/firebase";
import useKeyPress from "react-use-keypress";
import _ from "lodash";

interface Props {
  setIsNewSS: any;
  dohvatiStudente: any;
  imeP?: string;
  brP?: string;
  emailP?: string;
  obukaP?: string;
  brcP?: number;
}
interface Instruktor {
  id: number;
  ime: string;
  korisnik_id: number;
  autoskola_id: number;
  auto: string;
  brojtelefona: string;
}
export function NewSSOverlay(props: Props) {
  const { setIsNewSS, dohvatiStudente, brP, emailP, imeP, obukaP, brcP } =
    props;
  // const [skola, setSkola] = useState<autoSkola | null>(null);
  const [email, setEmail] = useState<string>(
    emailP !== null && emailP !== undefined ? emailP : ""
  );
  const [polozioT, setPolozioT] = useState<boolean>(
    obukaP !== null && obukaP !== undefined && obukaP === "Teorijska Obuka"
      ? false
      : true
  );
  const [brOdC, setBrOdC] = useState<number>(
    brcP !== null && brcP !== undefined ? brcP : 0
  );
  const [brNocnih, setBrNocnih] = useState<number>(0);
  const { currentUserData, setLoading, setAlert, setIsAlert } =
    useContext(AppContext);
  const [instruktori, setInstruktori] = useState<Array<Instruktor>>([]);
  const [izabraniInstruktor, setIzabraniInstruktor] = useState<string>("");
  const [izabraniInstruktorObjekat, setIzabraniInstruktorObjekat] =
    useState<UserData>();
  const [brojTelefona, setBrojTelefona] = useState<string>(
    brP !== null && brP !== undefined ? brP : ""
  );
  const [ime, setIme] = useState<string>(
    imeP !== null && imeP !== undefined ? imeP : ""
  );

  useKeyPress("Escape", () => {
    setIsNewSS(false);
  });
  useKeyPress("Enter", () => {
    dodajNovogStudenta();
  });

  useEffect(() => {
    setLoading(true);
    getDoc(doc(skoleRef, currentUserData?.autoskolaId)).then((skola) => {
      setInstruktori(skola?.data()?.instruktori);
      setLoading(false);
    });
  }, [currentUserData]);
  const dodajNovogStudenta = async () => {
    setLoading(true);
    if (currentUserData === undefined || currentUserData === null) return;
    if (ime === "" || email === "" || brojTelefona === "") {
      setAlert("Sva polja moraju biti popunjena!");
      setAlert(true);
      setLoading(false);
      return;
    }
    if (izabraniInstruktor !== "") {
      const izabIns: any = _.find(instruktori, izabraniInstruktor);
      setIzabraniInstruktorObjekat(izabIns as UserData);
    }
    const datum = new Date();
    const userData: UserData = {
      autoskolaId: currentUserData.autoskolaId,
      email: email,
      id: "",
      ime: ime,
      profilnaURL:
        "https://firebasestorage.googleapis.com/v0/b/pas1-64f21.appspot.com/o/files%2FDarkProfile.png?alt=media&token=2671c205-04a5-435c-8875-d3624c62da81",

      uloga: "student",
      brOdvozanihCasova: brOdC,
      brNocnih: brNocnih,
      koJeInstruktorID:
        izabraniInstruktorObjekat !== undefined &&
        izabraniInstruktorObjekat !== null &&
        izabraniInstruktorObjekat.id !== null &&
        izabraniInstruktorObjekat.id !== undefined
          ? izabraniInstruktorObjekat.id
          : "",
      koJeInstruktorIme:
        izabraniInstruktorObjekat !== undefined &&
        izabraniInstruktorObjekat !== null &&
        izabraniInstruktorObjekat.id !== null &&
        izabraniInstruktorObjekat.id !== undefined
          ? izabraniInstruktorObjekat.ime
          : "",
      polozioTeoriju: polozioT,
      datum: datum.toString(),
      telefon: brojTelefona,
      aktiviran: false,
      voziAuto: "",
      editovanInfoPrviPut: false,
      grupa: "",
      grupaID: "",
      uploads: [],
      krediti: 0,
      nizOdvozanihTermina: [],
      nizStudenata: [],
      nizZakazanihTermina: [],
      prikazivanjeTermina: "Default",
      nedeljnaKvota: 3,
      subscribed: false,
    };

    const querySnap = await getDocs(collection(db, "zahtevi"));
    const zahtevi = querySnap.docs.map((doc) => ({ ...doc.data() }));
    const pronadjenZahtev = _.find(zahtevi, { email: email });

    if (pronadjenZahtev !== null && pronadjenZahtev !== undefined) {
      deleteDoc(doc(zahteviRef, email));
    }

    await setDoc(doc(noviKorisniciRef, email), userData).then(async () => {
      setIsNewSS(false);
      await updateDoc(doc(skoleRef, currentUserData.autoskolaId), {
        noviStudenti: arrayUnion(userData),
      }).then(() => {
        // sendUltra(
        //   `+381${brojTelefona}`,
        //   `Poštovani, ${ime}, \nKreiran Vam je nalog na Voznja-rs od strane Vaše auto škole ${currentUserData.autoskolaId}.\n\n Vaš email za prijavu je ${email}\nPrilikom prve prijave unosite šifru koju želite, nakon toga uvek koristite nju.\n\nVaš VoznjaRS tim!`
        // );
        dohvatiStudente();
        setAlert("Uspešno kreiran kandidat.");
        setIsAlert(true);
        setLoading(false);
      });
    });
  };
  return (
    <div className="newSTOverlay">
      <div className="newSTBox">
        <h2>Dodajte novog kandidata</h2>
        <input
          type="text"
          name="ime"
          id="ime"
          placeholder="Ime Prezime"
          value={ime}
          onChange={(e) => setIme(e.target.value)}
        />
        <input
          type="email"
          name="emalStudenta"
          id="emalStudenta"
          placeholder="Email kandidata"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required
        />
        <label htmlFor="polozioTeoriju">Položio teoriju?</label>
        <select
          name="polozioTeoriju"
          id="polozioTeoriju"
          value={polozioT === true ? "da" : "ne"}
          onChange={(e) => {
            if (e.target.value === "da") setPolozioT(true);
            if (e.target.value === "ne") setPolozioT(false);
          }}
        >
          {/* <option selected disabled>
            Polozena teorija?
          </option> */}
          <option value="da">Da</option>
          <option selected value="ne">
            Ne
          </option>
        </select>
        <label htmlFor="brojOdvozanih">Broj odvozanih časova</label>

        {polozioT === true && (
          <input
            type="number"
            name="brojOdvozanih"
            id="brojOdvozanih"
            placeholder="Broj odvozanih časova"
            value={brOdC}
            required
            onChange={(e) => setBrOdC(parseInt(e.target.value))}
          />
        )}
        {/* {polozioT === true && (
          <input
            type="text"
            name="brNocnih"
            id="brNocnih"
            placeholder="Broj odvozanih nocnih casova"
            onChange={(e) => setBrNocnih(parseInt(e.target.value))}
          />
        )} */}

        {polozioT === true && (
          <select
            name="instruktorChoice"
            id="instruktorChoice"
            onChange={(e) => setIzabraniInstruktor(e.target.value)}
          >
            <option disabled selected>
              Izaberite instruktora
            </option>
            {instruktori.map((instruktor) => {
              return <option value={instruktor.id}>{instruktor.ime}</option>;
            })}
          </select>
        )}

        <input
          type="text"
          name="brojTelefona"
          id="brojTelefona"
          placeholder="Broj telefona"
          value={brojTelefona}
          onChange={(e) => setBrojTelefona(e.target.value)}
        />
        <div className="buttonOptions">
          <button onClick={() => dodajNovogStudenta()}>Potvrdi</button>
          <button onClick={() => setIsNewSS(false)}>Odustani</button>
        </div>
        <button
          className="x"
          onClick={() => {
            setIsNewSS(false);
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}
