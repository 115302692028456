import React, { useContext, useState } from "react";
import "../styles/newSTOverlay.scss";
import { Lekcija } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { doc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { lekcijeRef, skoleRef } from "../utils/firebase";
import _ from "lodash";
import useKeyPress from "react-use-keypress";
import uuid from "react-uuid";

interface Props {
  setIsNewLesson: any;
  dohvatiLekcije: any;
}

export function NewLessonOverlay(props: Props) {
  const { dohvatiLekcije, setIsNewLesson } = props;
  const [ime, setIme] = useState<string>("");
  const { currentUserData, setLoading, setAlert, setIsAlert } =
    useContext(AppContext);

  useKeyPress("Enter", () => {
    dodajLekciju();
  });
  useKeyPress("Escape", () => {
    setIsNewLesson(false);
  });

  async function dodajLekciju() {
    setLoading(true);
    if (currentUserData === undefined || currentUserData === null) return;
    if (ime === "") {
      setLoading(false);
      setAlert("Unesite ime lekcije");
      setIsAlert(true);
      return;
    }
    const jb = uuid();
    const datum = new Date();
    const lekcijaData: Lekcija = {
      datum: datum.toString(),
      ime: ime,
      kreirao: currentUserData.ime,
      kreiraoID: currentUserData.id,
      prezentacije: [],
      testovi: [],
      id: jb,
    };
    await setDoc(doc(lekcijeRef, jb), lekcijaData).then(async () => {
      await updateDoc(doc(skoleRef, currentUserData.autoskolaId), {
        lekcije: arrayUnion(lekcijaData),
      }).then(() => {
        dohvatiLekcije();
        setLoading(false);
        setIsNewLesson(false);
        setAlert("Uspesno dodata lekcija");
        setIsAlert(true);
      });
    });
  }

  return (
    <div className="newSTOverlay">
      <div className="newSTBox">
        <h2>Nova lekcija</h2>
        <input
          type="text"
          name="imeLekcije"
          id="imeLekcije"
          placeholder="Ime lekcije"
          onChange={(e) => setIme(e.target.value)}
        />
        <div className="buttonOptions">
          <button onClick={() => dodajLekciju()}>Potvrdi</button>
          <button onClick={() => setIsNewLesson(false)}>Odustani</button>
        </div>
        <button
          className="x"
          onClick={() => {
            setIsNewLesson(false);
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}
