import React, { useContext, useEffect, useState } from "react";
import "../styles/home.scss";
import {
  BsBack,
  BsBackspace,
  BsFillCalendarDateFill,
  BsFillCloudDownloadFill,
  BsPersonAdd,
  BsSortNumericDownAlt,
  BsSortNumericUpAlt,
} from "react-icons/bs";
import {
  AiFillEdit,
  AiFillEye,
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { BiSortDown } from "react-icons/bi";
import { FileObject, Prezentacija, UserData } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { imageDb, skoleRef } from "../utils/firebase";
import { MdArrowBack, MdAttachMoney } from "react-icons/md";
import uuid from "react-uuid";
import _ from "lodash";
import { CgProfile } from "react-icons/cg";
import { NewSIOverlay } from "../components/NewSIOverlay";
import { useNavigate, useParams } from "react-router";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { DocumentViewer, IFrameReloader } from "react-documents";
import { IoArrowBackSharp } from "react-icons/io5";
import { IconContext } from "react-icons/lib";
interface Props {}

export function PrezentacijaPage(props: Props) {
  const { currentUserData, setLoading } = useContext(AppContext);
  const { prezentacijaID } = useParams();
  const [prez, setPrez] = useState<Prezentacija>();
  const navigate = useNavigate();
  async function dohvatiPrezentaciju() {
    setLoading(true);
    if (
      currentUserData !== null &&
      currentUserData !== undefined &&
      currentUserData.autoskolaId !== null &&
      currentUserData.autoskolaId !== null &&
      currentUserData.autoskolaId !== "" &&
      skoleRef !== undefined &&
      skoleRef !== null
    ) {
      await getDoc(doc(skoleRef, currentUserData.autoskolaId)).then(
        async (skola) => {
          const nizPrezentacija = skola?.data()
            ?.prezentacije as Array<Prezentacija>;
          const prezentacija = _.find(nizPrezentacija, function (o) {
            if (o.id === prezentacijaID) return o;
          });
          if (prezentacija !== null && prezentacija !== undefined) {
            setPrez(prezentacija as Prezentacija);
          }
          console.log(prezentacija);
          setLoading(false);
        }
      );
    }
  }
  useEffect(() => {
    if (prezentacijaID !== null && prezentacijaID !== undefined) {
      dohvatiPrezentaciju();
    }
  }, [prezentacijaID, currentUserData]);
  function download(url: string, ime: string) {
    setLoading(true);
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = (event) => {
      const blob = xhr.response;
      const uri = window.URL.createObjectURL(blob);
      console.log("URI", uri);
      const a = document.createElement("a");
      a.href = uri;
      a.download = ime;
      a.click();
      window.URL.revokeObjectURL(uri);
    };
    xhr.open("GET", url);
    xhr.send();
    setLoading(false);
  }

  return (
    <>
      <div className="welcome">
        <div className="welcomeTitle">
          <IconContext.Provider value={{ className: "backIkonica" }}>
            <div className="ikonicaOmotac" onClick={() => navigate(-1)}>
              <MdArrowBack />
            </div>
          </IconContext.Provider>
          <h2>{prez !== null && prez !== undefined && prez.ime}</h2>
        </div>
        <button
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            if (prez === undefined || prez === null) return;
            download(prez.url, prez.ime);
          }}
        >
          <p
            style={{
              margin: "0",
              marginRight: "10px",
            }}
          >
            Preuzmi
          </p>
          <BsFillCloudDownloadFill color="white" size={25} />
        </button>
      </div>
      <div
        className="list"
        style={{
          width: "100%",
        }}
      >
        {/* {prez !== undefined && prez.url !== undefined && prez !== null && (
          <DocumentViewer
            style={{
              height: "calc(100vh - 100px)",
              width: "100%",
            }}
            className="pregledac"
            url={prez.url}
            viewer="google"
            googleCheckContentLoaded={true}
            googleCheckInterval={100}
            loaded={() => {
              alert("Ucitano");
            }}
          />
        )} */}
        {prez !== null && prez !== undefined && (
          <iframe
            title="Prezentacija"
            className="pregledac"
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
              prez.url
            )}`}
            style={{
              height: "calc(100vh - 100px)",
            }}
            width={"100%"}
            frameBorder={"0"}
          ></iframe>
        )}
        {(prez === undefined ||
          prez.url === undefined ||
          prez === null ||
          prez.url === null) && <h3>Ucitavanje</h3>}
      </div>
    </>
  );
}
