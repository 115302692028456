import React, { useContext, useEffect, useRef, useState } from "react";
import "../styles/kalendarv2.scss";
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db, korisniciRef, sendUltra, terminiRef } from "../utils/firebase";
import { AppContext } from "../functions/AppProvider";
import {
  IoArrowBack,
  IoArrowForward,
  IoClose,
  IoCloseCircle,
} from "react-icons/io5";
import { NewTermin } from "../components/NewTermin";
import _, { filter } from "lodash";
import { IconContext } from "react-icons/lib";
import { BsFillTrashFill } from "react-icons/bs";
import { Termin, UserData } from "../utils/types";
import { useParams } from "react-router";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GiCheckMark } from "react-icons/gi";
import { eachDayOfInterval, endOfWeek, startOfWeek } from "date-fns";
import { PRNVDOverlay } from "../components/PRNVDOverlay";

interface Props {}

interface Day {
  date: Date;
}

export function KalendarV2(props: Props) {
  let date = new Date();
  date.setDate(date.getDate() - 1);
  const { instruktorID, datum } = useParams();
  const { currentUserData, setAlert, setIsAlert, setLoading } =
    useContext(AppContext);
  const [izabraniDatum, setIzabraniDatum] = useState(new Date());
  const [isNewTermin, setIsNewTermin] = useState<boolean>(false);
  const [danasnjiTermini, setDanasnjiTermini] = useState<Array<Termin>>([]);
  const [advancedTermini, setAdvancedTermini] = useState<Array<Termin>>([]);
  const [sviTermini, setSviTermini] = useState<Array<Termin>>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isPRNVD, setIsPRNVD] = useState<boolean>(false);

  useEffect(() => {
    if (datum !== null && datum !== undefined) {
      const [godina, mesec, dan] = datum.split("-").map(Number);
      const noviDatum = new Date(godina, mesec - 1, dan);
      setIzabraniDatum(noviDatum);
    }
  }, [datum]);
  function imeDana() {
    const brojdana = izabraniDatum.getDay();
    if (brojdana === 1) return "Ponedeljak";
    if (brojdana === 2) return "Utorak";
    if (brojdana === 3) return "Sreda";
    if (brojdana === 4) return "Četvrtak";
    if (brojdana === 5) return "Petak";
    if (brojdana === 6) return "Subota";
    if (brojdana === 0) return "Nedelja";
  }
  function imeMeseca() {
    const brojMeseca = izabraniDatum.getMonth();
    if (brojMeseca === 0) return "Januar";
    if (brojMeseca === 1) return "Februar";
    if (brojMeseca === 2) return "Mart";
    if (brojMeseca === 3) return "April";
    if (brojMeseca === 4) return "Maj";
    if (brojMeseca === 5) return "Jun";
    if (brojMeseca === 6) return "Jul";
    if (brojMeseca === 7) return "Avgust";
    if (brojMeseca === 8) return "Septembar";
    if (brojMeseca === 9) return "Oktobar";
    if (brojMeseca === 10) return "Novembar";
    if (brojMeseca === 11) return "Decembar";
  }
  function juce() {
    setIzabraniDatum((prevDate) => {
      const dateObj = new Date(prevDate);
      dateObj.setDate(dateObj.getDate() - 1);
      return dateObj;
    });
  }
  function sutra() {
    setIzabraniDatum((prevDate) => {
      const dateObj = new Date(prevDate);
      dateObj.setDate(dateObj.getDate() + 1);
      return dateObj;
    });
  }
  useEffect(() => {
    dohvati();
  }, []);
  function dohvati() {
    const unsubscribe = onSnapshot(doc(terminiRef, instruktorID), (snap) => {
      if (!snap.exists) {
        return;
      }
      const trenutniPodaci = snap.data();
      if (trenutniPodaci === undefined) return;
      setSviTermini(trenutniPodaci.termini);
    });

    return () => unsubscribe();
  }
  const dohvatanjeTermina = async () => {
    if (currentUserData === null || currentUserData === undefined) return;
    // dohvati();
    // const querySnapshot = await getDoc(doc(terminiRef, instruktorID));
    // const podaci = querySnapshot.data();
    // if (podaci === undefined || podaci === null) return;
    // console.log(podaci.termini);
    // setSviTermini(podaci.termini);
    const nesortiraniTermini = _.filter(sviTermini, {
      // kojiDatum: `${izabraniDatum.getDate()}-${
      //   izabraniDatum.getMonth() + 1
      // }-${izabraniDatum.getFullYear()}`,
      kojiDatum: `${izabraniDatum.getFullYear()}/${
        izabraniDatum.getMonth() + 1
      }/${izabraniDatum.getDate()}`,
    });
    const niz: Array<Termin> = nesortiraniTermini as Array<Termin>;

    const sortiraniNiz = niz.sort((a, b) => {
      const dateA = new Date(a.fullDateStart as string);
      const dateB = new Date(b.fullDateStart as string);

      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    });
    console.log(sortiraniNiz);

    setDanasnjiTermini(sortiraniNiz);
  };
  useEffect(() => {
    dohvatanjeTermina();
  }, [currentUserData, izabraniDatum, isNewTermin, sviTermini]);
  function obrisiTermin(termin: Termin) {
    // TREBA DA SE IZBRISE IZ NIZA ZAKAZANIH AKO JE NEKO VEC ZAKAZAO
    if (currentUserData === undefined || currentUserData === null) return;
    setLoading(true);
    console.log(danasnjiTermini);
    const prvobitniTermini = sviTermini;
    const izbaceniTermini = _.remove(prvobitniTermini, {
      kojiDatum: termin.kojiDatum,
      vremeKraja: termin.vremeKraja,
      vremePocetka: termin.vremePocetka,
      status: termin.status,
      studentIme: termin.studentIme,
      studentID: termin.studentID,
    });
    updateDoc(doc(terminiRef, instruktorID), {
      termini: prvobitniTermini,
    }).then(() => {
      if (termin.status === "Zauzet") {
        getDoc(doc(korisniciRef, termin.studentID as string)).then(
          (kandidat) => {
            if (kandidat === null || kandidat === undefined) return;
            const kandidatInfo: UserData = kandidat.data() as UserData;
            const filtriraniNizZakazanih = _.filter(
              kandidatInfo.nizZakazanihTermina,
              function (o) {
                if (
                  o.fullDateEnd !== termin.fullDateEnd &&
                  o.fullDateStart !== termin.fullDateStart
                )
                  return o;
              }
            );
            let dodatniKrediti = 0;
            if (
              termin.tipTermina === "Dvocas" ||
              termin.tipTermina === "Nocna voznja"
            ) {
              dodatniKrediti = 2;
            }
            if (termin.tipTermina === "Jednocas") {
              dodatniKrediti = 1;
            }
            updateDoc(doc(korisniciRef, termin.studentID as string), {
              nizZakazanihTermina: filtriraniNizZakazanih,
              krediti: kandidatInfo.krediti + dodatniKrediti,
            }).then(() => {
              dohvatanjeTermina();
              setAlert("Uspešno obrisan termin");
              setIsAlert(true);
              setLoading(false);
            });
          }
        );
      } else {
        dohvatanjeTermina();
        setAlert("Uspešno obrisan termin");
        setIsAlert(true);
        setLoading(false);
      }
    });
  }
  function rezervisiTermin(kojiTermin: Termin) {
    console.log("MAROLI", kojiTermin, currentUserData);
    if (currentUserData === null || currentUserData === undefined) return;
    const trenutniDatum = new Date();
    if (trenutniDatum > new Date(kojiTermin.fullDateStart as string)) {
      setAlert("Ne možete zakazati termin koji je već počeo");
      setIsAlert(true);
      return;
    }
    if (
      kojiTermin.tipTermina === "Jednocas" &&
      currentUserData?.krediti !== undefined &&
      currentUserData?.krediti < 1
    ) {
      setAlert("Nemate dovoljno kredita");
      setIsAlert(true);
      return;
    }
    if (
      kojiTermin.tipTermina === "Dvocas" &&
      currentUserData?.krediti !== undefined &&
      currentUserData.krediti < 2
    ) {
      setAlert("Nemate dovoljno kredita");
      setIsAlert(true);
      return;
    }
    if (
      kojiTermin.tipTermina === "Nocna voznja" &&
      currentUserData?.brNocnih !== undefined &&
      currentUserData?.brNocnih > 1 &&
      currentUserData.krediti !== undefined &&
      currentUserData.krediti < 2
    ) {
      setAlert("Nemate dovoljno kredita ili ste ispunili broj nocnih voznji");
      setIsAlert(true);
      return;
    }
    // if (
    //   currentUserData?.brOdvozanihCasova !== undefined &&
    //   currentUserData.brOdvozanihCasova === 40
    // ) {
    //   setAlert("Već ste odvozali 40 časova");
    //   setIsAlert(true);
    //   return;
    // }

    if (
      currentUserData.nizZakazanihTermina !== null &&
      currentUserData.nizZakazanihTermina !== undefined &&
      currentUserData.nizZakazanihTermina.length !== 0
    ) {
      const pronadji = _.find(
        currentUserData.nizZakazanihTermina,
        function (o) {
          if (o.kojiDatum === kojiTermin.kojiDatum) {
            return o;
          }
        }
      );
      console.log("ZAKAZIVANJE DRUGI PUT", pronadji, currentUserData);
      if (pronadji !== null && pronadji !== undefined) {
        setAlert("Već ste zakazali termin za današnji dan");
        setIsAlert(true);
        return;
      }
    }

    const startNedelje = startOfWeek(
      new Date(kojiTermin.fullDateStart as string)
    );
    const krajNedelje = endOfWeek(new Date(kojiTermin.fullDateStart as string));
    const daniOveNedelje: Day[] = eachDayOfInterval({
      start: startNedelje,
      end: krajNedelje,
    }).map((date) => ({ date }));

    let zakazanihOveNedelje = 0;

    for (let i = 0; i < daniOveNedelje.length; i++) {
      const godina = daniOveNedelje[i].date.getFullYear();
      const mesec = daniOveNedelje[i].date.getMonth() + 1;
      const dan = daniOveNedelje[i].date.getDate();

      const exactDate: string = `${godina}/${mesec}/${dan}`;

      for (let j = 0; j < currentUserData.nizZakazanihTermina.length; j++) {
        if (currentUserData.nizZakazanihTermina[j].kojiDatum === exactDate) {
          zakazanihOveNedelje++;
        }
      }
    }

    if (zakazanihOveNedelje >= currentUserData.nedeljnaKvota) {
      setAlert("Dostigli ste broj dozvoljenih časova na nedeljnom nivou");
      setIsAlert(true);
      return;
    }

    const TerminData: Termin = {
      beleskaInstruktora: kojiTermin.beleskaInstruktora,
      fullDateEnd: kojiTermin.fullDateEnd,
      fullDateStart: kojiTermin.fullDateStart,
      kojiDatum: kojiTermin.kojiDatum,
      status: "Zauzet",
      studentID: currentUserData.id,
      studentIme: currentUserData.ime,
      tipTermina: kojiTermin.tipTermina,
      vremeKraja: kojiTermin.vremeKraja,
      vremePocetka: kojiTermin.vremePocetka,
    };
    console.log("TERMINDATA", TerminData);

    if (kojiTermin.tipTermina === "Jednocas") {
      if (currentUserData.krediti === undefined) return;
      console.log("MAROLI", kojiTermin, currentUserData);
      updateDoc(doc(korisniciRef, currentUserData.id), {
        krediti: currentUserData.krediti - 1,
        nizZakazanihTermina: arrayUnion(TerminData),
      });
    }
    if (
      kojiTermin.tipTermina === "Dvocas" ||
      kojiTermin.tipTermina === "Nocna voznja"
    ) {
      if (currentUserData.krediti === undefined) return;
      console.log("MAROLI", kojiTermin, currentUserData);

      updateDoc(doc(korisniciRef, currentUserData.id), {
        krediti: currentUserData.krediti - 2,
        nizZakazanihTermina: arrayUnion(TerminData),
      });
    }

    const filtriraniNizSvihTermina = _.filter(sviTermini, function (o) {
      if (
        o.fullDateEnd !== kojiTermin.fullDateEnd &&
        o.fullDateStart !== kojiTermin.fullDateStart
      ) {
        return o;
      }
    });
    updateDoc(doc(terminiRef, instruktorID), {
      termini: [...filtriraniNizSvihTermina, TerminData],
    });

    getDoc(doc(korisniciRef, instruktorID)).then((instruktor) => {
      const instruktorData = instruktor.data() as UserData;
      const [godina, mesec, dan] = kojiTermin?.kojiDatum.split("/").map(Number);
      const [sati, minuti] = kojiTermin?.vremePocetka.split(":").map(Number);
      const terminDatum = new Date(
        kojiTermin.fullDateStart as string
      ).getTime();
      const sad = new Date().getTime();
      const razlika = (sad - terminDatum) / 3600000;
      console.log("RAZLIKA", razlika, razlika >= -24);
      console.log(kojiTermin.kojiDatum);
      if (instruktorData.subscribed === true && razlika >= -24) {
        sendUltra(
          `+381${instruktorData.telefon}`,
          `Termin dana: ${dan}/${mesec}/${godina}, s početkom u ${kojiTermin.vremePocetka} je zakazao/la ${currentUserData.ime}.\nPozdrav, Vaš VoznjaRS tim.`
        );
      }
    });
  }

  useEffect(() => {
    if (danasnjiTermini.length === 0) {
      setAdvancedTermini([]);
      return;
    }

    const indexi: Array<{ broj: number }> = [];
    danasnjiTermini.forEach((termin, index) => {
      if (termin.status === "Zauzet") {
        indexi.push({ broj: index });
      }
    });
    if (indexi.length === 0 || indexi === null || indexi === undefined) {
      setAdvancedTermini(danasnjiTermini);
      return;
    }

    const filteredObjects: Termin[] = [];

    indexi.forEach((obj: { broj: number }) => {
      if (obj.broj - 1 >= 0) {
        filteredObjects.push(danasnjiTermini[obj.broj - 1]);
      }
      filteredObjects.push(danasnjiTermini[obj.broj]);
      if (obj.broj + 1 < danasnjiTermini.length) {
        filteredObjects.push(danasnjiTermini[obj.broj + 1]);
      }
    });

    setAdvancedTermini(_.uniqBy(filteredObjects, "fullDateStart"));
  }, [danasnjiTermini]);

  function zavrsiTermin(kojiTermin: Termin) {
    if (kojiTermin === null || kojiTermin === undefined) return;
    setLoading(true);
    const gotovTermin: Termin = {
      beleskaInstruktora: kojiTermin.beleskaInstruktora,
      fullDateEnd: kojiTermin.fullDateEnd,
      fullDateStart: kojiTermin.fullDateStart,
      kojiDatum: kojiTermin.kojiDatum,
      status: "Gotov",
      studentID: kojiTermin.studentID,
      studentIme: kojiTermin.studentIme,
      tipTermina: kojiTermin.tipTermina,
      vremeKraja: kojiTermin.vremeKraja,
      vremePocetka: kojiTermin.vremePocetka,
    };

    const kandidatID: string = kojiTermin.studentID as string;
    getDoc(doc(korisniciRef, kandidatID)).then((kandidat) => {
      const kandidatInfo: UserData = kandidat.data() as UserData;
      const filtriraniNizZakazanih = _.filter(
        kandidatInfo.nizZakazanihTermina,
        function (o) {
          if (
            o.fullDateStart !== kojiTermin.fullDateStart &&
            o.fullDateEnd !== kojiTermin.fullDateEnd
          )
            return o;
        }
      );
      if (kojiTermin.tipTermina === "Jednocas") {
        updateDoc(doc(korisniciRef, kandidatID), {
          brOdvozanihCasova: kandidatInfo.brOdvozanihCasova + 1,
        });
      }
      if (kojiTermin.tipTermina === "Dvocas") {
        updateDoc(doc(korisniciRef, kandidatID), {
          brOdvozanihCasova: kandidatInfo.brOdvozanihCasova + 2,
        });
      }
      if (kojiTermin.tipTermina === "Nocna voznja") {
        updateDoc(doc(korisniciRef, kandidatID), {
          brNocnih: kandidatInfo.brNocnih + 2,
        });
      }
      updateDoc(doc(korisniciRef, kandidatID), {
        nizZakazanihTermina: filtriraniNizZakazanih,
        nizOdvozanihTermina: arrayUnion(gotovTermin),
      }).then(() => {
        const filtriraniSviTermini = _.filter(sviTermini, function (o) {
          if (
            o.fullDateEnd !== kojiTermin.fullDateEnd &&
            o.fullDateStart !== kojiTermin.fullDateStart
          )
            return o;
        });
        updateDoc(doc(terminiRef, instruktorID), {
          termini: [...filtriraniSviTermini, gotovTermin],
        }).then(() => {
          setLoading(false);
          setAlert("Uspešno završen termin");
          setIsAlert(true);
          dohvatanjeTermina();
        });
      });
    });
  }

  function otkaziTermin(kojiTermin: Termin) {
    // Prvo treba da se termin updatuje, tj izbaci iz niza termina i doda novi terminData
    // Treba kod korisnika iz niza zakazanih da se obrise i da se dodaju krediti nazad
    // Ukoliko je to 48h pred termin, salju se poruke svim studentima koji tog dana nemaju zakazan termin
    setLoading(true);

    getDoc(doc(korisniciRef, kojiTermin.studentID as string)).then(
      (kandidat) => {
        const kandidatInfo: UserData = kandidat.data() as UserData;

        const filtriraniNizSvihTermina = _.filter(sviTermini, function (o) {
          if (
            o.fullDateStart !== kojiTermin.fullDateStart &&
            o.fullDateEnd !== kojiTermin.fullDateEnd
          )
            return o;
        });

        const terminInfo: Termin = {
          beleskaInstruktora: kojiTermin.beleskaInstruktora,
          fullDateEnd: kojiTermin.fullDateEnd,
          fullDateStart: kojiTermin.fullDateStart,
          kojiDatum: kojiTermin.kojiDatum,
          status: "Slobodan",
          studentID: "",
          studentIme: "",
          tipTermina: kojiTermin.tipTermina,
          vremeKraja: kojiTermin.vremeKraja,
          vremePocetka: kojiTermin.vremePocetka,
        };

        const filtriraniNizZakazanih = _.filter(
          kandidatInfo.nizZakazanihTermina,
          function (o) {
            if (
              o.fullDateStart !== kojiTermin.fullDateStart &&
              o.fullDateEnd !== kojiTermin.fullDateEnd
            )
              return o;
          }
        );

        let dodatniKrediti = 2;

        if (
          kojiTermin.tipTermina === "Dvocas" ||
          kojiTermin.tipTermina === "Nocna voznja"
        ) {
          dodatniKrediti = 2;
        }
        if (kojiTermin.tipTermina === "Jednocas") {
          dodatniKrediti = 1;
        }

        updateDoc(doc(korisniciRef, kojiTermin.studentID as string), {
          nizZakazanihTermina: filtriraniNizZakazanih,
          krediti: kandidatInfo.krediti + dodatniKrediti,
        }).then(() => {
          updateDoc(doc(terminiRef, instruktorID), {
            termini: [...filtriraniNizSvihTermina, terminInfo],
          }).then(() => {
            const danas = new Date();
            const datumPocetka = new Date(kojiTermin.fullDateStart as string);

            if ((danas.getTime() - datumPocetka.getTime()) / 3600000 >= -48) {
              getDoc(doc(korisniciRef, instruktorID)).then((inst) => {
                const instruktorInfo: UserData = inst.data() as UserData;

                for (let i = 0; i < instruktorInfo.nizStudenata.length; i++) {
                  getDoc(
                    doc(korisniciRef, instruktorInfo.nizStudenata[i].id)
                  ).then((kor) => {
                    const korisnikInfo: UserData = kor.data() as UserData;
                    let brojZakazanihDanas = 0;

                    for (
                      let j = 0;
                      j < korisnikInfo.nizZakazanihTermina.length;
                      j++
                    ) {
                      if (
                        korisnikInfo.nizZakazanihTermina[j].kojiDatum ===
                        kojiTermin.kojiDatum
                      ) {
                        brojZakazanihDanas++;
                      }
                    }

                    const [godina, mesec, dan] = kojiTermin.kojiDatum
                      .split("/")
                      .map(Number);

                    if (brojZakazanihDanas === 0) {
                      console.log(
                        "Saljem poruku",
                        korisnikInfo,
                        korisnikInfo.telefon
                      );
                      if (korisnikInfo.subscribed === true) {
                        sendUltra(
                          `+381${korisnikInfo.telefon}`,
                          `Neko je otkazao termin u ${kojiTermin.vremePocetka}, ${dan}/${mesec}/${godina}! \n Požuri i rezerviši termin! \n\nVaš VoznjaRS tim!`
                        );
                      }
                    }
                  });
                }
              });
            }

            setLoading(false);
            setAlert("Uspešon otkazan čas");
            setIsAlert(true);
            dohvati();
            dohvatanjeTermina();
          });
        });
      }
    );
  }
  const prviDatum = new Date("2023/01/08 12:00");
  const drugiDatum = new Date("2023/01/10 14:30");
  console.log(
    "AAAAAAAAAAAAA",
    (prviDatum.getTime() - drugiDatum.getTime()) / 3600000 <= 48
  );
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {datum !== null && datum !== undefined && (
          <div className="kalendarHeader">
            <button onClick={() => juce()}>
              <IoArrowBack color="white" size={40} />
            </button>
            <h2
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              {imeDana()}, {izabraniDatum.getDate()}. {imeMeseca()} '
              {izabraniDatum.getFullYear().toString().substring(2)}
            </h2>
            <DatePicker
              label="Izaberite datum"
              className="izborDatumaMUI"
              open={isOpen}
              format="DD/MM/YYYY"
              onClose={() => setIsOpen(false)}
              closeOnSelect
              onChange={(e: any) => {
                console.log(e.$d);
                setIzabraniDatum(new Date(`${e.$y}/${e.$M + 1}/${e.$D}`));
                setIsOpen(false);
              }}
            />
            <button onClick={() => sutra()}>
              <IoArrowForward color="white" size={40} />
            </button>
          </div>
        )}
        <div className="kalendarBody">
          {currentUserData?.id === instruktorID && date <= izabraniDatum && (
            <button onClick={() => setIsNewTermin(true)}>
              <p>Dodajte termin</p>
            </button>
          )}
          {danasnjiTermini.length !== 0 &&
            currentUserData?.uloga === "schoolinstructor" && (
              <button
                onClick={() => {
                  setIsPRNVD(true);
                }}
              >
                <p>Primeni raspored na više dana</p>
              </button>
            )}

          {(currentUserData?.prikazivanjeTermina === "Default" ||
            currentUserData?.uloga === "schoolinstructor") &&
            danasnjiTermini.map((termin) => {
              const danas = new Date();
              const datumPocetka = new Date(termin.fullDateStart as string);
              const razlika =
                (danas.getTime() - datumPocetka.getTime()) / 3600000;
              return (
                <div
                  key={`${termin.vremeKraja} ${termin.vremePocetka} ${termin.kojiDatum}`}
                  className={`termin ${
                    termin.status === "Slobodan" && "free"
                  } ${termin.status === "Gotov" && "done"} ${
                    termin.status === "Zauzet" && "closed"
                  }`}
                >
                  <div className="terminInfo">
                    <h3>
                      {termin.vremePocetka}-{termin.vremeKraja}
                    </h3>
                    <p>Tip: {termin?.tipTermina}</p>
                    <p>Status: {termin?.status}</p>

                    {termin.beleskaInstruktora !== "" && (
                      <p>Beleška: {termin?.beleskaInstruktora}</p>
                    )}
                    {termin.studentIme !== "" &&
                      termin.studentIme !== null &&
                      termin.studentIme !== undefined &&
                      currentUserData?.uloga !== "student" && (
                        <p>Kandidat: {termin.studentIme}</p>
                      )}
                    {termin.studentIme !== "" &&
                      termin.studentIme !== null &&
                      termin.studentIme !== undefined &&
                      currentUserData?.uloga === "student" &&
                      currentUserData.id === termin.studentID && (
                        <p>Kandidat: {termin.studentIme}</p>
                      )}
                    {termin.status === "Slobodan" &&
                      new Date() > new Date(termin.fullDateStart as string) && (
                        <p>Nije moguće rezervisati jer je termin prošao.</p>
                      )}
                  </div>

                  <div className="terminOptions">
                    {currentUserData?.id === instruktorID &&
                      termin.status === "Zauzet" &&
                      new Date(termin.fullDateEnd as string) < new Date() && (
                        <IconContext.Provider
                          value={{ className: "trashIkonica" }}
                        >
                          <div
                            className="ikonicaWrapper"
                            onClick={() => zavrsiTermin(termin)}
                          >
                            <div className="ikonicaKrug">
                              <GiCheckMark />
                            </div>
                            <p>Završi</p>
                          </div>
                        </IconContext.Provider>
                      )}
                    {currentUserData?.id === instruktorID &&
                      termin.status !== "Gotov" && (
                        <IconContext.Provider
                          value={{ className: "trashIkonica" }}
                        >
                          <div
                            className="ikonicaWrapper"
                            onClick={() => obrisiTermin(termin)}
                          >
                            <div className="ikonicaKrug">
                              <BsFillTrashFill />
                            </div>
                            <p>Obriši</p>
                          </div>
                        </IconContext.Provider>
                      )}
                    {currentUserData.uloga === "student" &&
                      termin.status === "Zauzet" &&
                      termin.studentID === currentUserData.id &&
                      razlika <= -1.5 && (
                        <IconContext.Provider
                          value={{ className: "trashIkonica" }}
                        >
                          <div
                            className="ikonicaWrapper"
                            onClick={() => otkaziTermin(termin)}
                          >
                            <div className="ikonicaKrug">
                              <IoCloseCircle />
                            </div>
                            <p>Otkaži</p>
                          </div>
                        </IconContext.Provider>
                      )}
                    {currentUserData?.uloga === "student" &&
                      termin.status === "Slobodan" &&
                      new Date() < new Date(termin.fullDateStart as string) && (
                        <IconContext.Provider
                          value={{ className: "trashIkonica" }}
                        >
                          <div
                            className="ikonicaWrapper"
                            onClick={() => rezervisiTermin(termin)}
                          >
                            <div className="ikonicaKrug">
                              <GiCheckMark />
                            </div>
                            <p>Rezerviši</p>
                          </div>
                        </IconContext.Provider>
                      )}
                  </div>
                </div>
              );
            })}

          {currentUserData?.prikazivanjeTermina === "Advanced" &&
            currentUserData.uloga === "student" &&
            advancedTermini.map((termin) => {
              return (
                <div
                  key={`${termin.vremeKraja} ${termin.vremePocetka} ${termin.kojiDatum}`}
                  className={`termin ${
                    termin.status === "Slobodan" && "free"
                  } ${termin.status === "Gotov" && "done"} ${
                    termin.status === "Zauzet" && "closed"
                  }`}
                >
                  <div className="terminInfo">
                    <h3>
                      {termin.vremePocetka}-{termin.vremeKraja}
                    </h3>
                    <p>Tip: {termin?.tipTermina}</p>
                    <p>Status: {termin?.status}</p>
                    {termin.beleskaInstruktora !== "" &&
                      termin.beleskaInstruktora !== undefined &&
                      termin.beleskaInstruktora !== null && (
                        <p>Beleška: {termin?.beleskaInstruktora}</p>
                      )}
                    {termin.studentIme !== "" &&
                      termin.studentIme !== null &&
                      termin.studentIme !== undefined &&
                      currentUserData?.uloga !== "student" && (
                        <p>Kandidat: {termin.studentIme}</p>
                      )}
                    {termin.studentIme !== "" &&
                      termin.studentIme !== null &&
                      termin.studentIme !== undefined &&
                      currentUserData?.uloga === "student" &&
                      currentUserData.id === termin.studentID && (
                        <p>Kandidat: {termin.studentIme}</p>
                      )}
                    {termin.status === "Slobodan" &&
                      new Date() > new Date(termin.fullDateStart as string) && (
                        <p>Nije moguće rezervisati jer je termin prošao.</p>
                      )}
                  </div>
                  <div className="terminOptions">
                    {currentUserData?.id === instruktorID &&
                      termin.status === "Zauzet" &&
                      new Date(termin.fullDateEnd as string) < new Date() && (
                        <IconContext.Provider
                          value={{ className: "trashIkonica" }}
                        >
                          <div
                            className="ikonicaWrapper"
                            onClick={() => zavrsiTermin(termin)}
                          >
                            <div className="ikonicaKrug">
                              <GiCheckMark />
                            </div>
                            <p>Završi</p>
                          </div>
                        </IconContext.Provider>
                      )}
                    {currentUserData?.id === instruktorID &&
                      termin.status !== "Gotov" && (
                        <IconContext.Provider
                          value={{ className: "trashIkonica" }}
                        >
                          <div
                            className="ikonicaWrapper"
                            onClick={() => obrisiTermin(termin)}
                          >
                            <div className="ikonicaKrug">
                              <BsFillTrashFill />
                            </div>
                            <p>Obriši</p>
                          </div>
                        </IconContext.Provider>
                      )}
                    {currentUserData.uloga === "student" &&
                      termin.status === "Zauzet" &&
                      termin.studentID === currentUserData.id && (
                        <IconContext.Provider
                          value={{ className: "trashIkonica" }}
                        >
                          <div
                            className="ikonicaWrapper"
                            onClick={() => otkaziTermin(termin)}
                          >
                            <div className="ikonicaKrug">
                              <IoCloseCircle />
                            </div>
                            <p>Otkaži</p>
                          </div>
                        </IconContext.Provider>
                      )}
                    {currentUserData?.uloga === "student" &&
                      termin.status === "Slobodan" &&
                      new Date() < new Date(termin.fullDateStart as string) && (
                        <IconContext.Provider
                          value={{ className: "trashIkonica" }}
                        >
                          <div
                            className="ikonicaWrapper"
                            onClick={() => rezervisiTermin(termin)}
                          >
                            <div className="ikonicaKrug">
                              <GiCheckMark />
                            </div>
                            <p>Rezerviši</p>
                          </div>
                        </IconContext.Provider>
                      )}
                  </div>
                </div>
              );
            })}
        </div>
        {isNewTermin && (
          <NewTermin
            setIsNewTermin={setIsNewTermin}
            kojiDatum={`${izabraniDatum.getFullYear()}/${
              izabraniDatum.getMonth() + 1
            }/${izabraniDatum.getDate()}`}
            danasnjiTermini={danasnjiTermini}
          />
        )}
        {isPRNVD && (
          <PRNVDOverlay
            instruktorID={instruktorID as string}
            nizDanasnjihTermina={danasnjiTermini}
            setIsPRNVD={setIsPRNVD}
            sviTermini={sviTermini}
          />
        )}
      </LocalizationProvider>
    </>
  );
}
