import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { imageDb, korisniciRef } from "../utils/firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import uuid from "react-uuid";
import "../styles/newUploadOverlay.scss";
import { AppContext } from "../functions/AppProvider";
import { FileObject } from "../utils/types";
import useKeyPress from "react-use-keypress";

interface Props {
  setIsNewUO: any;
  setProfilna?: any;
  tip?: string;
  setSlikuZaPitanje?: any;
}

export function NewUploadOverlay(props: Props) {
  const { setIsNewUO, setProfilna, tip, setSlikuZaPitanje } = props;
  const { currentUserData } = useContext(AppContext);
  const [nizUploads, setNizUploads] = useState<Array<FileObject>>([]);
  const [izabranaSlika, setIzabranaSlika] = useState<FileObject>();

  useKeyPress("Escape", () => {
    setIsNewUO(false);
  });
  useKeyPress("Enter", () => {
    if (izabranaSlika !== null && izabranaSlika !== undefined) {
      saveProfilePic();
    }
  });
  function handleUploadClick(file: File) {
    const imgRef = ref(imageDb, `files/${uuid()}`);
    if (file === undefined) return;
    uploadBytes(imgRef, file).then((img) => {
      console.log(img);
      const imgType = img.metadata.contentType;
      getDownloadURL(img.ref).then(async (url) => {
        console.log(url);
        const imageData: FileObject = {
          ime: "",
          type: imgType as string,
          url: url,
        };
        await updateDoc(doc(korisniciRef, currentUserData?.id), {
          uploads: arrayUnion(imageData),
        }).then(() => {
          setNizUploads([...nizUploads, imageData]);
        });
      });
    });
  }
  useEffect(() => {
    if (currentUserData === null || currentUserData === undefined) return;
    if (currentUserData.uploads === undefined) return;
    setNizUploads(currentUserData.uploads);
  }, [currentUserData, setIsNewUO]);

  async function saveProfilePic() {
    if (currentUserData === null || currentUserData === undefined) return;
    if (izabranaSlika === null || izabranaSlika === undefined) return;
    if (izabranaSlika.url === undefined) return;
    if (tip === "test") {
      // OVDE POSTAVI IZABRANU SLIKU ZA TO PITANJE U TESTU
      setSlikuZaPitanje(izabranaSlika.url);
      setIsNewUO(false);
    } else {
      await updateDoc(doc(korisniciRef, currentUserData.id), {
        profilnaURL: izabranaSlika.url,
      }).then(() => {
        setProfilna(izabranaSlika.url);
        setIsNewUO(false);
        setIsNewUO(false);
      });
    }
  }
  return (
    <div className="newUploadOverlay">
      <div className="newUploadBox">
        <div className="newUploadBoxHeader">
          <h2>Izbor slike</h2>
          {/* <button className="uploadDugme">Otpremi slike</button> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <label htmlFor="uploadDugme" className="uploadDugme">
              Otpremi slike
            </label>
            <input
              type="file"
              name="uploadDugme"
              id="uploadDugme"
              className="uploadDugme"
              style={{ display: "none" }}
              accept="image/*"
              onChange={(e) => {
                if (e.target.files !== null)
                  handleUploadClick(e.target.files[0]);
              }}
            />
            {izabranaSlika !== null && izabranaSlika !== undefined && (
              <button className="uploadDugme" onClick={() => saveProfilePic()}>
                Sačuvaj
              </button>
            )}
          </div>
        </div>
        <div className="newUploadBoxBody">
          {nizUploads.length !== 0 &&
            nizUploads.map((upload) => {
              if ((upload.type = "image"))
                return (
                  <img
                    src={upload.url}
                    alt={upload.ime}
                    className={
                      upload.url === izabranaSlika?.url ? "active" : ""
                    }
                    onClick={() => {
                      setIzabranaSlika(upload);
                    }}
                  />
                );
            })}
          {nizUploads.length === 0 && <p>Nema dostupnih slika</p>}
        </div>
      </div>
      <button
        className="x"
        onClick={() => {
          setIsNewUO(false);
        }}
      >
        X
      </button>
    </div>
  );
}
