import React, { useContext, useEffect, useState } from "react";
import "../styles/login.scss";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../functions/AppProvider";
import {
  korisniciRef,
  noviKorisniciRef,
  skoleRef,
  terminiRef,
} from "../utils/firebase";
import {
  arrayUnion,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { UserData } from "../utils/types";
import _ from "lodash";
import useKeyPress from "react-use-keypress";
import VoznjaLogo from "../voznjarslogo.png";
import Logo from "../voznjaLogo.png";

interface Props {}

export function Login(props: Props) {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { setLoading, setAlert, setIsAlert, currentUserData, currentUser } =
    useContext(AppContext);
  const navigate = useNavigate();
  const auth = getAuth();

  useKeyPress("Enter", () => {
    login();
  });

  useEffect(() => {
    if (
      currentUserData !== null &&
      currentUserData !== undefined &&
      currentUser !== null &&
      currentUser !== undefined
    ) {
      navigate("/home");
    }
  }, [currentUserData]);

  // const createAccount = () => {
  //   createUserWithEmailAndPassword(auth, email, password)
  //     .then((userCredential) => {
  //       //Signed in
  //       const user = userCredential.user;
  //       console.log("Uspesno kreiran korisnik", user);
  //       redirect("/home");
  //     })

  //     .catch((error) => {
  //       const errorCode = error.code;
  //       const errorMessage = error.message;
  //       console.log(error);
  //     });
  // };

  function login() {
    setLoading(true);
    const auth = getAuth();

    getDoc(doc(noviKorisniciRef, email)).then((noviKorisnik) => {
      const noviKorisnikData = noviKorisnik.data() as UserData;
      if (noviKorisnikData === null || noviKorisnikData === undefined) {
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            //Signed in
            const user = userCredential.user;
            console.log("Uspešno ulogovan korisnik", user);
            console.log("Auth", auth);
            navigate("/home");
            setLoading(false);
          })
          .catch((error) => {
            setAlert("Greška prilikom prijavljivanja.");
            setIsAlert(true);
            setLoading(false);
            return;
          });
      } else if (noviKorisnikData !== null && noviKorisnikData !== undefined) {
        createUserWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            console.log("Kreiran novi korisnik.");
            const user = userCredential.user;
            const userData: UserData = {
              email: email,
              id: user.uid,
              uloga: noviKorisnikData.uloga,
              profilnaURL: noviKorisnikData.profilnaURL,
              autoskolaId: noviKorisnikData.autoskolaId,
              ime: noviKorisnikData.ime,
              aktiviran: true,
              brNocnih: noviKorisnikData.brNocnih,
              brOdvozanihCasova: noviKorisnikData.brOdvozanihCasova,
              datum: noviKorisnikData.datum,
              koJeInstruktorID: noviKorisnikData.koJeInstruktorID,
              koJeInstruktorIme: noviKorisnikData.koJeInstruktorIme,
              polozioTeoriju: noviKorisnikData.polozioTeoriju,
              telefon: noviKorisnikData?.telefon,
              voziAuto: noviKorisnikData?.voziAuto,
              editovanInfoPrviPut: false,
              grupa: "",
              grupaID: "",
              uploads: [],
              krediti: 0,
              nizOdvozanihTermina: [],
              nizStudenata: [],
              nizZakazanihTermina: [],
              prikazivanjeTermina: "Default",
              nedeljnaKvota: 3,
              subscribed: false,
            };
            await setDoc(doc(korisniciRef, user.uid), userData).then(
              async () => {
                await deleteDoc(doc(noviKorisniciRef, email)).then(async () => {
                  await getDoc(doc(skoleRef, userData.autoskolaId)).then(
                    async (skola) => {
                      // Dohvatanje podataka auto skole
                      const skolaData = skola.data();
                      const nizNovihStudenata = skola?.data()?.noviStudenti;
                      const nizNovihInstruktora =
                        skola?.data()?.noviInstruktori;
                      const nizNovihPredavaca = skola?.data()?.noviPredavaci;

                      // AKO JE STUDENT ONDA UPDATUJEMO NOVISTUDENTI

                      if (userData.uloga === "student") {
                        const nizNovihStudenataBezTrenutnog = _.filter(
                          nizNovihStudenata,
                          function (s) {
                            if (s.email !== userData.email) {
                              return s;
                            }
                          }
                        );
                        await updateDoc(doc(skoleRef, userData.autoskolaId), {
                          noviStudenti: nizNovihStudenataBezTrenutnog,
                          studenti: arrayUnion(userData),
                        }).then(() => {
                          // USPEŠNO KREIRANJE I BRISANJE SVEGA
                          // ULOGOVAN JE I REDIREKTUJEMO NA POČETNU

                          navigate("/home");
                          setLoading(false);
                        });
                      }

                      //AKO JE INSTRUKTOR ONDA UPDATUJEMO NOVIINSTRUKTORI

                      if (userData.uloga === "schoolinstructor") {
                        const nizNovihInstruktoraBezTrenutnog = _.filter(
                          nizNovihInstruktora,
                          function (s) {
                            if (s.email !== userData.email) {
                              return s;
                            }
                          }
                        );
                        await updateDoc(doc(skoleRef, userData.autoskolaId), {
                          noviInstruktori: nizNovihInstruktoraBezTrenutnog,
                          instruktori: arrayUnion(userData),
                        }).then(() => {
                          // USPEŠNO KREIRANJE I BRISANJE SVEGA
                          // ULOGOVAN JE I REDIREKTUJEMO NA POČETNU
                          setDoc(doc(terminiRef, userData.id), {
                            termini: [],
                          }).then(() => {
                            navigate("/home");
                            setLoading(false);
                          });
                        });
                      }

                      // AKO JE PREDAVAČ ONDA UPDATUJEMO NOVIPREDAVAČI

                      if (userData.uloga === "schoolteacher") {
                        const nizNovihPredavacaBezTrenutnog = _.filter(
                          nizNovihPredavaca,
                          function (s) {
                            if (s.email !== userData.email) {
                              return s;
                            }
                          }
                        );
                        await updateDoc(doc(skoleRef, userData.autoskolaId), {
                          noviPredavaci: nizNovihPredavacaBezTrenutnog,
                          predavaci: arrayUnion(userData),
                        }).then(() => {
                          // USPEŠNO KREIRANJE I BRISANJE SVEGA
                          // ULOGOVAN JE I REDIREKTUJEMO NA POČETNU

                          navigate("/home");
                          setLoading(false);
                        });
                      }
                    }
                  );
                });
              }
            );
          })
          .catch((error) => {
            setAlert(
              "Greška prilikom kreiranja naloga. Lozinka mora da ima 6 karaktera"
            );
            setIsAlert(true);
            setLoading(false);
          });
      }
    });
  }

  return (
    <div className="loginPage">
      <img src={Logo} alt="Voznja.rs" />
      <div className="loginContainer">
        <h2>Prijavite se</h2>

        <input
          type="email"
          name="email"
          id="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          name="password"
          id="password"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />

        <div className="loginOptions">
          <button onClick={() => login()}>Prijavite se</button>
          {/* <button onClick={() => createAccount()}>Registrujte se</button> */}
        </div>
      </div>
      <p>Verzija 1.0</p>
      <h2
        onClick={() => {
          navigate("/zaboravljenasifra");
        }}
      >
        Zaboravili ste password?
      </h2>
      <h2
        onClick={() => {
          navigate("/newacc");
        }}
      >
        Kreirajte nalog
      </h2>
    </div>
  );
}
