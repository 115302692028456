import React, { useContext, useEffect, useState } from "react";
import "../styles/newSTOverlay.scss";
import { AppContext } from "../functions/AppProvider";
import { InteraktivniKviz } from "../utils/types";
import { interaktivniRef, skoleRef } from "../utils/firebase";
import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { CgEnter } from "react-icons/cg";
import useKeyPress from "react-use-keypress";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import _ from "lodash";

interface Props {
  setCFI: any;
}

export function CheckForInteractive(props: Props) {
  const { setCFI } = props;
  const { currentUserData, setLoading, setAlert, setIsAlert } =
    useContext(AppContext);
  const [nizInteraktivnih, setNizInteraktivnih] = useState<
    Array<InteraktivniKviz>
  >([]);
  const navigate = useNavigate();
  useKeyPress("Escape", () => {
    setCFI(false);
  });
  useEffect(() => {
    proveri();
  }, [currentUserData]);
  function proveri() {
    if (
      currentUserData === null ||
      currentUserData === undefined ||
      currentUserData.autoskolaId === "" ||
      skoleRef === null ||
      skoleRef === undefined
    )
      return;
    getDoc(doc(skoleRef, currentUserData.autoskolaId)).then((skola) => {
      const nizI: Array<InteraktivniKviz> = skola?.data()
        ?.interaktivniUToku as Array<InteraktivniKviz>;
      console.log(nizI);
      if (nizI === null || nizI === undefined || nizI.length === 0) {
        return;
      } else {
        if (nizI.length > 0) {
          const sadasnjiDatum = dayjs();
          const pre45 = sadasnjiDatum.subtract(45, "minute").toDate();

          const filtriraniNizKvizova = _.filter(nizI, function (o) {
            if (new Date(o.datum) >= pre45) return o;
          });
          console.log("VECI OD 1", filtriraniNizKvizova, nizI[nizI.length - 1]);
          updateDoc(doc(skoleRef, currentUserData.autoskolaId), {
            interaktivniUToku: filtriraniNizKvizova,
          }).then(() => {
            setNizInteraktivnih([...nizInteraktivnih, ...nizI]);
          });
        } else if (nizI.length === 1) {
          setNizInteraktivnih(nizI);
        }
      }
    });
  }
  function prikljuciKvizu(int: InteraktivniKviz) {
    if (currentUserData === null || currentUserData === undefined) return;
    updateDoc(doc(interaktivniRef, int.id), {
      nizStudenata: arrayUnion(currentUserData),
    }).then(() => {
      navigate(`/interaktivniKvizStudent/${int.test.id}/${int.id}`);
    });
  }
  return (
    <div className="newSTOverlay">
      <div className="newSTBox">
        <div className="newSTBoxHeader">
          <h2>Interaktivni kvizovi</h2>
        </div>
        <div className="newSTList">
          {nizInteraktivnih.length === 0 && (
            <h2>Trenutno nema aktivnih kvizova.</h2>
          )}
          {nizInteraktivnih.length === 0 && (
            <button
              className="osvezi"
              onClick={() => {
                proveri();
              }}
            >
              Osveži
            </button>
          )}
          {nizInteraktivnih.length !== 0 &&
            nizInteraktivnih.map((interaktivni) => {
              return (
                <div className="studentBox">
                  <div className="studentBoxInfo">
                    <div className="studentBoxInfoText">
                      <h2>{interaktivni?.id.slice(0, 5)}</h2>
                    </div>
                  </div>
                  <div className="studentBoxOptions">
                    <button
                      onClick={() => {
                        prikljuciKvizu(interaktivni);
                      }}
                    >
                      <CgEnter color="white" size={40} />
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
        <button
          className="x"
          onClick={() => {
            setCFI(false);
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}
