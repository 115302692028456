import React, { useContext, useState } from "react";
import "../styles/newSTOverlay.scss";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { UserData } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { doc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { noviKorisniciRef, sendUltra, skoleRef } from "../utils/firebase";
import useKeyPress from "react-use-keypress";
import { IconContext } from "react-icons/lib";
import { IoEye, IoEyeOff } from "react-icons/io5";

interface Props {
  setIsPasswordReset: any;
  dohvatiKorisnika: any;
}

export function PasswordReset(props: Props) {
  const { setIsPasswordReset, dohvatiKorisnika } = props;
  const [stara, setStara] = useState<string>("");
  const [sifra, setSifra] = useState<string>("");
  const [hidden, setHidden] = useState<boolean>(true);
  const auth = getAuth();
  const user = auth.currentUser;
  const { currentUserData, setLoading, setAlert, setIsAlert } =
    useContext(AppContext);

  useKeyPress("Enter", () => {
    resetSifre();
  });
  useKeyPress("Escape", () => {
    setIsPasswordReset(false);
  });

  const resetSifre = async () => {
    if (
      user === null ||
      user === undefined ||
      currentUserData === null ||
      currentUserData === undefined
    )
      return;
    if (sifra === "") return;
    if (sifra.length < 6) {
      setAlert("Minimum 6 karaktera");
      setIsAlert(true);
      return;
    }
    setLoading(true);
    const credential = EmailAuthProvider.credential(
      currentUserData.email,
      stara
    );
    reauthenticateWithCredential(user, credential)
      .then(() => {
        updatePassword(user, sifra)
          .then(() => {
            setAlert("Uspešno promenjena šifra.");
            setIsAlert(true);
            setLoading(false);
            setIsPasswordReset(false);
          })
          .catch((error) => {
            setAlert("Error");
            setIsAlert(true);
            setLoading(false);
            console.log(error.message);
          });
      })
      .catch((error) => {
        setAlert("Error: Stara sifra neispravna");
        setIsAlert(true);
        setLoading(false);
      });
  };
  return (
    <div className="newSTOverlay">
      <div className="newSTBox">
        <h2>Resetovanje šifre</h2>
        <label htmlFor="staraSifra">Stara šifra</label>
        <div className="inputBox">
          <input
            type={`${hidden === true ? "password" : "text"}`}
            name="imePredavaca"
            id="staraSifra"
            placeholder="Nova šifra"
            onChange={(e) => setStara(e.target.value)}
          />
          {hidden === false && (
            <IconContext.Provider value={{ className: "eyeIkonica" }}>
              <div onClick={() => setHidden(true)}>
                <IoEye size={30} />
              </div>
            </IconContext.Provider>
          )}
          {hidden === true && (
            <IconContext.Provider value={{ className: "eyeIkonica" }}>
              <div onClick={() => setHidden(false)}>
                <IoEyeOff size={30} />
              </div>
            </IconContext.Provider>
          )}
        </div>
        <label htmlFor="imePredavaca">Nova šifra</label>
        <div className="inputBox">
          <input
            type={`${hidden === true ? "password" : "text"}`}
            name="imePredavaca"
            id="imePredavaca"
            placeholder="Nova šifra"
            onChange={(e) => setSifra(e.target.value)}
          />
          {hidden === false && (
            <IconContext.Provider value={{ className: "eyeIkonica" }}>
              <div onClick={() => setHidden(true)}>
                <IoEye size={30} />
              </div>
            </IconContext.Provider>
          )}
          {hidden === true && (
            <IconContext.Provider value={{ className: "eyeIkonica" }}>
              <div onClick={() => setHidden(false)}>
                <IoEyeOff size={30} />
              </div>
            </IconContext.Provider>
          )}
        </div>
        <div className="buttonOptions">
          <button onClick={() => resetSifre()}>Potvrdi</button>
          <button onClick={() => setIsPasswordReset(false)}>Odustani</button>
        </div>

        <button
          className="x"
          onClick={() => {
            setIsPasswordReset(false);
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}
