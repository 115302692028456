import React, { useEffect, useState } from "react";
import "../styles/newSTOverlay.scss";
import { useNavigate } from "react-router";
import useKeyPress from "react-use-keypress";

interface Props {
  testDescription: string;
  setTestDescription: any;
  setIsDesc: any;
}

export function TestDescription(props: Props) {
  const { testDescription, setTestDescription, setIsDesc } = props;
  const [trenutniOpis, setTrenutniOpis] = useState<string>("");
  useKeyPress("Enter", () => {
    setTestDescription(trenutniOpis);
    setIsDesc(false);
  });
  useKeyPress("Escape", () => {
    setIsDesc(false);
  });

  return (
    <div className="newSTOverlay">
      <div className="newSTBox">
        <h2>Opis testa</h2>
        <textarea
          //   type="text"
          name="opisTesta"
          id="opisTesta"
          placeholder="Unesite opis"
          onChange={(e) => setTrenutniOpis(e.target.value)}
          //   value={TestDescription}
          required
          style={{
            resize: "none",
          }}
        />
        <div className="buttonOptions">
          <button
            onClick={() => {
              setTestDescription(trenutniOpis);
              setIsDesc(false);
            }}
          >
            Sačuvaj
          </button>
          <button
            onClick={() => {
              setIsDesc(false);
            }}
          >
            Odustani
          </button>
        </div>
      </div>
    </div>
  );
}
