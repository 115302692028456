import React, { useContext, useEffect, useState } from "react";
import "../styles/home.scss";
import {
  BsFillCloudDownloadFill,
  BsPersonAdd,
  BsSortNumericDownAlt,
  BsSortNumericUpAlt,
  BsTrashFill,
} from "react-icons/bs";
import {
  AiFillEye,
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { Lekcija, Prezentacija } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { imageDb, lekcijeRef, sendUltra, skoleRef } from "../utils/firebase";
import uuid from "react-uuid";
import _ from "lodash";
import { useNavigate } from "react-router";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { IconContext } from "react-icons/lib";
import { MdArrowBack } from "react-icons/md";
// import * as functions from "firebase/functions";
import { Functions, getFunctions, httpsCallable } from "firebase/functions";
// import { functions } from "firebase/app";
// import * as functions from "firebase-functions";
// import { Twilio } from "twilio";

interface Props {}

export function Prezentacije(props: Props) {
  const { currentUserData, setLoading, setAlert, setIsAlert } =
    useContext(AppContext);
  const [nizPrezentacija, setNizPrezentacija] = useState<Array<Prezentacija>>(
    []
  );
  const [sekundarniNiz, setSekundarniNiz] = useState<Array<Prezentacija>>([]);
  const [nizLekcija, setNizLekcija] = useState<Array<Lekcija>>([]);
  const date = new Date();
  const [trenutniSort, setTrenutniSort] = useState<
    "imeAZ" | "imeZA" | "datumAsc" | "datumDesc"
  >();
  const navigate = useNavigate();

  useEffect(() => {
    dohvatiPrezentacije();
  }, [currentUserData]);

  function dohvatiPrezentacije() {
    if (
      currentUserData === null ||
      currentUserData === undefined ||
      currentUserData.autoskolaId === null ||
      currentUserData.autoskolaId === undefined ||
      currentUserData.autoskolaId === "" ||
      skoleRef === null ||
      skoleRef === undefined
    )
      return;
    setLoading(true);
    getDoc(doc(skoleRef, currentUserData.autoskolaId)).then((skola) => {
      console.log("SKOLA", skola?.data()?.prezentacije);
      const nizPrez = skola?.data()?.prezentacije as Array<Prezentacija>;
      setNizPrezentacija(nizPrez);
      setSekundarniNiz(nizPrez);
      setNizLekcija(skola?.data()?.lekcije as Array<Lekcija>);
      setLoading(false);
    });
  }
  function handleUpload(file: File) {
    setLoading(true);
    const fileRef = ref(imageDb, `${uuid()}`);
    if (file === undefined) return;
    console.log(file);
    const ime = file.name;
    uploadBytes(fileRef, file).then((file) => {
      console.log(file);
      getDownloadURL(file.ref).then(async (url) => {
        console.log(url);
        const fileData: Prezentacija = {
          ime: ime,
          tip: file.metadata.contentType as string,
          datum: date.toString(),
          korisnikID: currentUserData?.id as string,
          korisnik: currentUserData?.ime as string,
          url: url,
          id: uuid(),
        };
        await updateDoc(doc(skoleRef, currentUserData?.autoskolaId), {
          prezentacije: arrayUnion(fileData),
        }).then(() => {
          dohvatiPrezentacije();
          setLoading(false);
        });
      });
    });
  }
  function search(q: string) {
    const duzina = q.length;
    const niz = nizPrezentacija;
    if (q === "") {
      setSekundarniNiz(nizPrezentacija);
      return;
    }
    const filtriranNiz = _.filter(niz, function (o) {
      const ime = o.ime;
      console.log(
        "FILTER PRVI",
        ime.toLowerCase().substring(0, duzina),
        q.toLowerCase().substring(0, duzina)
      );
      if (
        ime.toLowerCase().substring(0, duzina) ===
          q.toLowerCase().substring(0, duzina) ||
        ime.toLowerCase().split(".")[1].substring(0, duzina) ===
          q.toLowerCase().substring(0, duzina)
      )
        return o;
    });
    console.log("FILTER", filtriranNiz);
    setSekundarniNiz(filtriranNiz as Array<Prezentacija>);
  }
  function sortIme() {
    const niz = nizPrezentacija;
    const sortiraniNiz = _.orderBy(niz, ["ime"], ["asc"]);
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("imeAZ");
  }
  function sortImeDesc() {
    const niz = nizPrezentacija;
    const sortiraniNiz = _.orderBy(niz, ["ime"], ["desc"]);
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("imeZA");
  }
  function sortDateAsc() {
    const niz = nizPrezentacija;
    const sortiraniNiz = niz.sort((a, b) => {
      const dateA = new Date(a.datum);
      const dateB = new Date(b.datum);
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    });
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("datumAsc");
  }
  function sortDateDesc() {
    const niz = nizPrezentacija;
    const sortiraniNiz = [...niz].sort((a, b) => {
      const dateA = new Date(a.datum);
      const dateB = new Date(b.datum);

      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      return 0;
    });
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("datumDesc");
  }
  function download(url: string, ime: string) {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = (event) => {
      const blob = xhr.response;
      const uri = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = uri;
      a.download = ime;
      a.click();
      window.URL.revokeObjectURL(uri);
    };
    xhr.open("GET", url);
    xhr.send();
  }
  function obrisi(prezentacija: Prezentacija) {
    const filtriraniNiz = _.filter(nizPrezentacija, function (o) {
      if (o.id !== prezentacija.id) {
        return o;
      }
    });
    let noviNizLekcija: Array<Lekcija> = [];
    const filtriraniNizLekcijaKojeImajuPrezentaciju = _.filter(
      nizLekcija,
      function (o) {
        for (let j = 0; j < o.prezentacije.length; j++) {
          if (o.prezentacije[j].id === prezentacija.id) {
            return o as Lekcija;
          }
        }
      }
    );
    const filter2: Array<Lekcija> =
      filtriraniNizLekcijaKojeImajuPrezentaciju as Array<Lekcija>;
    console.log(filter2, "FILTER2");
    for (let x = 0; x < filter2.length; x++) {
      updateDoc(doc(lekcijeRef, filter2[x].id), {
        prezentacije: _.filter(filter2[x].prezentacije, function (o) {
          if (o.id !== prezentacija.id) {
            return o;
          }
        }),
      });
    }
    for (let i = 0; i < nizLekcija.length; i++) {
      const filterNizaPrezentacija = _.filter(
        nizLekcija[i].prezentacije,
        function (o) {
          if (o.id !== prezentacija.id) return o;
        }
      );
      noviNizLekcija = [
        ...noviNizLekcija,
        {
          datum: nizLekcija[i].datum,
          id: nizLekcija[i].id,
          ime: nizLekcija[i].ime,
          kreirao: nizLekcija[i].kreirao,
          kreiraoID: nizLekcija[i].kreiraoID,
          testovi: nizLekcija[i].testovi,
          prezentacije: filterNizaPrezentacija as Array<Prezentacija>,
        },
      ];
    }
    updateDoc(doc(skoleRef, currentUserData?.autoskolaId), {
      prezentacije: filtriraniNiz,
      lekcije: noviNizLekcija,
    }).then(() => {
      setAlert("Uspešno obrisana prezentacija.");
      setIsAlert(true);
      dohvatiPrezentacije();
    });
  }

  //   const [broj, setBroj] = useState<number>(0);
  //   const ipsilon = 85;

  //   function povecajBroj() {
  //     setBroj((prevBroj) => Math.min(prevBroj + 1, ipsilon));
  //   }
  //   useEffect(() => {
  //     if (broj < ipsilon) {
  //       const timer = setTimeout(povecajBroj, 1500 / ipsilon);
  //       return () => clearTimeout(timer);
  //     }
  //   }, [broj, ipsilon]);

  // const accountSid = "AC852ca847d3764d8277ddb474d6ca94d0";
  // const authToken = "e3cd401a5a80cc17b49804415281cfa7";
  // const twilioNumber = "+16235526269";

  // const client = new Twilio(accountSid, authToken);

  // async function sendWA() {
  //   try {
  //     const result = await client.messages.create({
  //       from: `whatsapp:${twilioNumber}`,
  //       body: "Cao, da li slanje poruka preko aplikacije radi?",
  //       to: "whatsapp:+381641472424",
  //     });

  //     console.log("Whatsapp message sent", result.sid);
  //   } catch (error) {
  //     console.log("Error sending wa", error);
  //   }
  // }
  const accountSid = "AC852ca847d3764d8277ddb474d6ca94d0";
  const authToken = "e3cd401a5a80cc17b49804415281cfa7";
  const twilioNumber = "+16235526269";
  const functions = getFunctions();
  const sendWhatsAppMessage = httpsCallable(functions, "sendWhatsAppMessage");
  const sendUltraMsg = httpsCallable(functions, "sendUltraMsg");
  const handleSend = async () => {
    const response = await sendWhatsAppMessage({
      to: "381640004788",
      message:
        // "Poštovani korisniče, obaveštavamo Vas da je Bogdan najjači programer i da je uspeo da poveže aplikaciju sa slanjem obaveštenja na WhatsApp koji je sto puta jeftiniji od slanja poruka. Poyyy",
        `Poštovani, Sladjana,
Dobrodošli na aplikaciju Voznja.rs! Kreiran Vam je nalog od strane Vaše auto škole, već sada se možete prijaviti na našoj aplikaciji. Prilikom prve prijave, unesite šifru koja želite da Vam ostane i koristite je u budućnosti.

Molimo Vas da na ovu poruku odgovorite sa "Pristajem" kako biste dobijali notifikacije od auto škole poput slobodnih termina, podsetnika za zakazane termine, i ostalih podsetnika...

Voznja.rs tim!`,
    });
    console.log(response.data);
  };

  return (
    <>
      <div className="welcome">
        <div className="welcomeTitle">
          <IconContext.Provider value={{ className: "backIkonica" }}>
            <div className="ikonicaOmotac" onClick={() => navigate("/home")}>
              <MdArrowBack />
            </div>
          </IconContext.Provider>
          <h2>Prezentacije ({nizPrezentacija.length})</h2>
        </div>

        <input
          type="text"
          name="pretragaStudenata"
          id="pretragaStudenata"
          placeholder="Pretražite"
          onChange={(e) => search(e.target.value)}
        />
      </div>
      <div className="optionsShortcuts">
        {currentUserData?.uloga !== "student" && (
          <label htmlFor="novaPrezentacija" className="shortcut">
            <div className="ikonicaWrap">
              <BsPersonAdd color="white" size={50} />
            </div>
            <h3>Nova prezentacija</h3>
          </label>
        )}
        {currentUserData?.uloga !== "student" && (
          <input
            type="file"
            name="novaPrezentacija"
            id="novaPrezentacija"
            style={{ display: "none" }}
            accept="application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-powerpoint, .ppt, .pptx"
            onChange={(e) => {
              if (e.target.files !== null) {
                handleUpload(e.target.files[0]);
              }
            }}
          />
        )}
        <div className="shortcut" onClick={() => sortIme()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "imeAZ" ? "correct" : ""
            }`}
          >
            <AiOutlineSortAscending color="white" size={50} />
          </div>
          <h3>Ime A-Z</h3>
        </div>
        <div className="shortcut" onClick={() => sortImeDesc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "imeZA" ? "correct" : ""
            }`}
          >
            <AiOutlineSortDescending color="white" size={50} />
          </div>
          <h3>Ime Z-A</h3>
        </div>
        <div className="shortcut" onClick={() => sortDateDesc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "datumDesc" ? "correct" : ""
            }`}
          >
            <BsSortNumericDownAlt color="white" size={50} />
          </div>
          <h3>Najnovija - Najstarija</h3>
        </div>
        <div className="shortcut" onClick={() => sortDateAsc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "datumAsc" ? "correct" : ""
            }`}
          >
            <BsSortNumericUpAlt color="white" size={50} />
          </div>
          <h3>Najstarija - Najnovija</h3>
        </div>
      </div>
      <div className="list">
        {sekundarniNiz.map((prezentacija) => {
          return (
            <div className="listPiece">
              <div className="pieceInfo">
                <div className="pieceInfoText">
                  <h3>{prezentacija.ime}</h3>
                  <p>{`Datum otpremanja: ${new Date(
                    prezentacija.datum
                  ).getDate()}.${
                    new Date(prezentacija.datum).getMonth() + 1
                  }.${new Date(prezentacija.datum).getFullYear()}`}</p>
                  <p>{`Korisnik: ${prezentacija.korisnik}`}</p>
                </div>
              </div>
              <div className="pieceOptions">
                <div
                  className="pieceOption"
                  onClick={() => download(prezentacija.url, prezentacija.ime)}
                >
                  <div className="pieceOptionIcon">
                    <BsFillCloudDownloadFill color="white" size={40} />
                  </div>
                  <p>Preuzmi</p>
                </div>
                <div
                  className="pieceOption"
                  onClick={() => navigate(`/prezentacija/${prezentacija.id}`)}
                >
                  <div className="pieceOptionIcon">
                    <AiFillEye color="white" size={50} />
                  </div>
                  <p>Pogledaj</p>
                </div>
                {currentUserData?.uloga !== "student" && (
                  <div
                    className="pieceOption"
                    onClick={() => {
                      obrisi(prezentacija);
                    }}
                  >
                    <div className="pieceOptionIcon">
                      <BsTrashFill color="white" size={50} />
                    </div>
                    <p>Obriši</p>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {/* {broj}
      <button onClick={povecajBroj} disabled={broj >= ipsilon}>
        Povecaj borj
      </button> */}
      {/* <DocumentViewer
        style={{
          height: "calc(100vh - 80px)",
          width: "100%",
          backgroundColor: "red",
        }}
        className="pregledac"
        url="https://firebasestorage.googleapis.com/v0/b/pas1-64f21.appspot.com/o/files%2FMerkaPPT.pptx?alt=media&token=5721e52b-210a-4db0-ac52-9c67edf56da6"
        viewer="google"
      /> */}
    </>
  );
}
