import React, { useContext, useState } from "react";
import "../styles/newSTOverlay.scss";
import { getAuth } from "firebase/auth";
import { UserData } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { doc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { noviKorisniciRef, sendUltra, skoleRef } from "../utils/firebase";
import useKeyPress from "react-use-keypress";

interface Props {
  setIsNewST: any;
  dohvatiPredavace: any;
}

export function NewSTOverlay(props: Props) {
  const { setIsNewST, dohvatiPredavace } = props;
  const [email, setEmail] = useState<string>("");
  const [ime, setIme] = useState<string>("");
  const [brojTelefona, setBrojTelefona] = useState<string>("");
  const auth = getAuth();
  const { currentUserData, setLoading, setAlert, setIsAlert } =
    useContext(AppContext);

  useKeyPress("Enter", () => {
    dodajNovogPredavaca();
  });
  useKeyPress("Escape", () => {
    setIsNewST(false);
  });

  const dodajNovogPredavaca = async () => {
    if (currentUserData === null || currentUserData === undefined) return;
    setLoading(true);

    if (ime === "" || email === "" || brojTelefona === "") {
      setAlert("Sva polja moraju biti popunjena!");
      setIsAlert(true);
      setLoading(false);
      return;
    }

    const datum = new Date();

    const userData: UserData = {
      autoskolaId: currentUserData.autoskolaId,
      email: email,
      id: "",
      ime: ime,
      profilnaURL:
        "https://firebasestorage.googleapis.com/v0/b/pas1-64f21.appspot.com/o/files%2FDarkProfile.png?alt=media&token=2671c205-04a5-435c-8875-d3624c62da81",
      uloga: "schoolteacher",
      datum: datum.toString(),
      telefon: brojTelefona,
      aktiviran: false,
      brNocnih: 0,
      brOdvozanihCasova: 0,
      koJeInstruktorID: "",
      koJeInstruktorIme: "",
      polozioTeoriju: false,
      voziAuto: "",
      editovanInfoPrviPut: false,
      grupa: "",
      grupaID: "",
      krediti: 0,
      nizOdvozanihTermina: [],
      nizStudenata: [],
      nizZakazanihTermina: [],
      uploads: [],
      prikazivanjeTermina: "Default",
      nedeljnaKvota: 3,
      subscribed: false,
    };

    await setDoc(doc(noviKorisniciRef, email), userData).then(async () => {
      setIsNewST(false);
      await updateDoc(doc(skoleRef, currentUserData.autoskolaId), {
        noviPredavaci: arrayUnion(userData),
      }).then(() => {
        // sendUltra(
        //   `+381${brojTelefona}`,
        //   `Poštovani, ${ime}, \nKreiran Vam je nalog na Voznja-rs od strane Vaše auto škole ${currentUserData.autoskolaId}.\n\n Vaš email za prijavu je ${email}\nPrilikom prve prijave unosite šifru koju želite, nakon toga uvek koristite nju.\n\nVaš VoznjaRS tim!`
        // );
        dohvatiPredavace();
        setAlert("Uspešno kreiran predavač");
        setIsAlert(true);
        setLoading(false);
      });
    });
  };
  return (
    <div className="newSTOverlay">
      <div className="newSTBox">
        <h2>Dodajte novog predavača</h2>
        <label htmlFor="imePredavaca">Ime prezime</label>
        <input
          type="text"
          name="imePredavaca"
          id="imePredavaca"
          placeholder="Ime prezime"
          onChange={(e) => setIme(e.target.value)}
        />
        <label htmlFor="emailPredavača">Email</label>
        <input
          type="email"
          name="emailPredavača"
          id="emailPredavača"
          placeholder="Email predavača"
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="brojTel">Broj telefona</label>
        <input
          type="text"
          name="brojTel"
          id="brojTel"
          placeholder="Broj telefona"
          onChange={(e) => setBrojTelefona(e.target.value)}
        />
        <div className="buttonOptions">
          <button onClick={() => dodajNovogPredavaca()}>Potvrdi</button>
          <button onClick={() => setIsNewST(false)}>Odustani</button>
        </div>

        <button
          className="x"
          onClick={() => {
            setIsNewST(false);
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}
