import React, { useContext, useEffect, useState } from "react";
import "../styles/home.scss";
import {
  BsClockHistory,
  BsFillTrashFill,
  BsPersonAdd,
  BsSortNumericDownAlt,
  BsSortNumericUpAlt,
} from "react-icons/bs";
import {
  AiFillEdit,
  AiFillPlayCircle,
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { Lekcija, Test } from "../utils/types";
import { AppContext } from "../functions/AppProvider";
import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { testoviRef, skoleRef, lekcijeRef } from "../utils/firebase";
import _ from "lodash";
import { useNavigate } from "react-router";
import { NewTestOverlay } from "../components/NewTestOverlay";
import { IconContext } from "react-icons/lib";
import { MdArrowBack } from "react-icons/md";

interface Props {}

export function Testovi(props: Props) {
  const { currentUserData, setLoading, setAlert, setIsAlert } =
    useContext(AppContext);
  const [nizTestova, setNizTestova] = useState<Array<Test>>([]);
  const [nizLekcija, setNizLekcija] = useState<Array<Lekcija>>([]);
  const [sekundarniNiz, setSekundarniNiz] = useState<Array<Test>>([]);
  const [isNQ, setIsNQ] = useState<boolean>(false);
  const [trenutniSort, setTrenutniSort] = useState<
    "imeAZ" | "imeZA" | "datumAsc" | "datumDesc"
  >();
  const date = new Date();
  const navigate = useNavigate();

  useEffect(() => {
    dohvatiTestove();
  }, [currentUserData]);

  function dohvatiTestove() {
    if (
      currentUserData === null ||
      currentUserData === undefined ||
      currentUserData.autoskolaId === null ||
      currentUserData.autoskolaId === undefined ||
      currentUserData.autoskolaId === "" ||
      skoleRef === null ||
      skoleRef === undefined
    )
      return;
    setLoading(true);
    getDoc(doc(skoleRef, currentUserData.autoskolaId)).then((skola) => {
      console.log("SKOLA", skola?.data()?.testovi);
      const nizTestova = skola?.data()?.testovi as Array<Test>;
      const nizLekcija = skola?.data()?.lekcije as Array<Lekcija>;
      setNizTestova(nizTestova);
      setNizLekcija(nizLekcija);
      setSekundarniNiz(nizTestova);
      setLoading(false);
    });
  }

  function search(q: string) {
    const duzina = q.length;
    const niz = nizTestova;
    if (q === "") {
      setSekundarniNiz(nizTestova);
      return;
    }
    const filtriranNiz = _.filter(niz, function (o) {
      const ime = o.ime;
      console.log(
        "FILTER PRVI",
        ime.toLowerCase().substring(0, duzina),
        q.toLowerCase().substring(0, duzina)
      );
      if (
        ime.toLowerCase().substring(0, duzina) ===
          q.toLowerCase().substring(0, duzina) ||
        (ime.toLowerCase().split(" ")[1] !== null &&
          ime.toLowerCase().split(" ")[1] !== undefined &&
          ime.toLowerCase().split(" ")[1].substring(0, duzina) ===
            q.toLowerCase().substring(0, duzina))
      )
        return o;
    });
    console.log("FILTER", filtriranNiz);
    setSekundarniNiz(filtriranNiz as Array<Test>);
  }
  async function deleteTest(kojiTest: string) {
    setLoading(true);
    const filtriraniNiz = _.filter(nizTestova, function (o) {
      if (o.id !== kojiTest) return o;
    });
    let noviNizLekcija: Array<Lekcija> = [];
    const filtriraniNizLekcijaKojeImajuTest = _.filter(
      nizLekcija,
      function (o) {
        for (let j = 0; j < o.testovi.length; j++) {
          if (o.testovi[j].id === kojiTest) {
            return o as Lekcija;
          }
        }
      }
    );

    const filter2: Array<Lekcija> =
      filtriraniNizLekcijaKojeImajuTest as Array<Lekcija>;

    for (let x = 0; x < filter2.length; x++) {
      updateDoc(doc(lekcijeRef, filter2[x].id), {
        testovi: _.filter(filter2[x].testovi, function (o) {
          if (o.id !== kojiTest) {
            return o;
          }
        }),
      });
    }

    for (let i = 0; i < nizLekcija.length; i++) {
      const filterNizaTestova = _.filter(nizLekcija[i].testovi, function (o) {
        if (o.id !== kojiTest) return o;
      });
      noviNizLekcija = [
        ...noviNizLekcija,
        {
          datum: nizLekcija[i].datum,
          id: nizLekcija[i].id,
          ime: nizLekcija[i].ime,
          kreirao: nizLekcija[i].kreirao,
          kreiraoID: nizLekcija[i].kreiraoID,
          prezentacije: nizLekcija[i].prezentacije,
          testovi: filterNizaTestova as Array<Test>,
        },
      ];
    }

    await updateDoc(doc(skoleRef, currentUserData?.autoskolaId), {
      testovi: filtriraniNiz,
      lekcije: noviNizLekcija,
    }).then(() => {
      deleteDoc(doc(testoviRef, kojiTest)).then(() => {
        setAlert("Uspešno obrisan test");
        setIsAlert(true);
        dohvatiTestove();
        setLoading(false);
      });
    });
  }
  function sortIme() {
    const niz = nizTestova;
    const sortiraniNiz = _.orderBy(niz, ["ime"], ["asc"]);
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("imeAZ");
  }
  function sortImeDesc() {
    const niz = nizTestova;
    const sortiraniNiz = _.orderBy(niz, ["ime"], ["desc"]);
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("imeZA");
  }
  function sortDateAsc() {
    const niz = nizTestova;
    const sortiraniNiz = niz.sort((a, b) => {
      const dateA = new Date(a.datum);
      const dateB = new Date(b.datum);
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    });
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("datumAsc");
  }
  function sortDateDesc() {
    const niz = nizTestova;
    const sortiraniNiz = [...niz].sort((a, b) => {
      const dateA = new Date(a.datum);
      const dateB = new Date(b.datum);

      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      return 0;
    });
    setSekundarniNiz(sortiraniNiz);
    setTrenutniSort("datumDesc");
  }
  return (
    <>
      <div className="welcome">
        <div className="welcomeTitle">
          <IconContext.Provider value={{ className: "backIkonica" }}>
            <div className="ikonicaOmotac" onClick={() => navigate("/home")}>
              <MdArrowBack />
            </div>
          </IconContext.Provider>
          <h2>
            Testovi
            {nizTestova !== null &&
              nizTestova !== undefined &&
              `(${nizTestova.length})`}
          </h2>
        </div>

        <input
          type="text"
          name="pretragaStudenata"
          id="pretragaStudenata"
          placeholder="Pretražite"
          onChange={(e) => search(e.target.value)}
        />
      </div>
      <div className="optionsShortcuts">
        {currentUserData?.uloga !== "student" && (
          <div className="shortcut" onClick={() => setIsNQ(true)}>
            <div className="ikonicaWrap">
              <BsPersonAdd color="white" size={50} />
            </div>
            <h3>Novi test</h3>
          </div>
        )}
        <div className="shortcut" onClick={() => sortIme()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "imeAZ" ? "correct" : ""
            }`}
          >
            <AiOutlineSortAscending color="white" size={50} />
          </div>
          <h3>Ime A-Z</h3>
        </div>
        <div className="shortcut" onClick={() => sortImeDesc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "imeZA" ? "correct" : ""
            }`}
          >
            <AiOutlineSortDescending color="white" size={50} />
          </div>
          <h3>Ime Z-A</h3>
        </div>
        <div className="shortcut" onClick={() => sortDateDesc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "datumDesc" ? "correct" : ""
            }`}
          >
            <BsSortNumericDownAlt color="white" size={50} />
          </div>
          <h3>Najnoviji - Najstariji</h3>
        </div>
        <div className="shortcut" onClick={() => sortDateAsc()}>
          <div
            className={`ikonicaWrap ${
              trenutniSort === "datumAsc" ? "correct" : ""
            }`}
          >
            <BsSortNumericUpAlt color="white" size={50} />
          </div>
          <h3>Najstariji - Najnoviji</h3>
        </div>
      </div>
      <div className="list">
        {sekundarniNiz !== null &&
          sekundarniNiz !== undefined &&
          sekundarniNiz.map((Test) => {
            if (currentUserData?.uloga === "student" && Test.pp === "Privatno")
              return;
            else
              return (
                <div className="listPiece">
                  <div className="pieceInfo">
                    <div className="pieceInfoText">
                      <h3>{Test.ime}</h3>
                      {Test.opis !== "" && <p>{`Opis: ${Test.opis}`}</p>}
                      <p>{`Datum: ${new Date(Test.datum).getDate()}.${
                        new Date(Test.datum).getMonth() + 1
                      }.${new Date(Test.datum).getFullYear()}`}</p>
                      <p>{`Autor: ${Test.kreiraoIme}`}</p>
                      {currentUserData?.uloga !== "student" && <p>{Test.pp}</p>}
                    </div>
                  </div>
                  <div className="pieceOptions">
                    {currentUserData?.uloga !== "student" && (
                      <div
                        className="pieceOption"
                        onClick={() => {
                          navigate(`/interaktivniKvizAdmin/${Test.id}`);
                        }}
                      >
                        <div className="pieceOptionIcon">
                          <BsClockHistory color="white" size={40} />
                        </div>
                        <p>Interaktivno</p>
                      </div>
                    )}
                    <div
                      className="pieceOption"
                      onClick={() => {
                        navigate(`/test/${Test.id}`);
                      }}
                    >
                      <div className="pieceOptionIcon">
                        <AiFillPlayCircle color="white" size={40} />
                      </div>
                      <p>Započni</p>
                    </div>
                    {currentUserData?.uloga !== "student" && (
                      <div
                        className="pieceOption"
                        onClick={() => {
                          navigate(`/noviTest/${Test.ime}/true/${Test.id}`);
                        }}
                      >
                        <div className="pieceOptionIcon">
                          <AiFillEdit color="white" size={40} />
                        </div>
                        <p>Izmeni</p>
                      </div>
                    )}
                    {currentUserData?.uloga !== "student" && (
                      <div
                        className="pieceOption"
                        onClick={() => deleteTest(Test.id)}
                      >
                        <div className="pieceOptionIcon">
                          <BsFillTrashFill color="white" size={40} />
                        </div>
                        <p>Obriši</p>
                      </div>
                    )}
                  </div>
                </div>
              );
          })}
      </div>

      {isNQ && <NewTestOverlay setIsNewTest={setIsNQ} />}
    </>
  );
}
