import React, { useState } from "react";
import "../styles/newSTOverlay.scss";
import { doc, setDoc } from "firebase/firestore";
import { skoleRef } from "../utils/firebase";
import useKeyPress from "react-use-keypress";
import { autoSkola } from "../utils/types";

interface Props {
  setIsNewSchool?: any;
}

export function NewSchoolOverlay(props: Props) {
  const { setIsNewSchool } = props;
  const [imeSkole, setImeSkole] = useState<string>("");
  const [emailAdmina, setEmailAdmina] = useState<string>("bogdan@bbit.rs");
  const [adresaSkole, setAdresaSkole] = useState<string>("");
  const [brojTelefona, setBrojTelefona] = useState<string>("");
  const kreirajNovuSkolu = async () => {
    if (imeSkole === "") {
      alert("Nije moguće uneti školu.");
      return;
    }
    const skolaData: autoSkola = {
      ime: imeSkole,
      adresa: adresaSkole,
      grupe: [],
      id: "",
      instruktori: [],
      interaktivniUToku: [],
      lekcije: [],
      noviInstruktori: [],
      noviPredavaci: [],
      noviStudenti: [],
      predavaci: [],
      predavanja: [],
      prezentacije: [],
      studenti: [],
      testovi: [],
    };
    await setDoc(doc(skoleRef, imeSkole), skolaData);
    setIsNewSchool(false);
    setImeSkole("");
    setEmailAdmina("");
    setAdresaSkole("");
    setBrojTelefona("");
  };

  useKeyPress("Enter", () => {
    kreirajNovuSkolu();
  });
  useKeyPress("Escape", () => {
    setIsNewSchool(false);
  });
  return (
    <div className="newSTOverlay">
      <div className="newSTBox">
        <h2>Dodajte novu školu</h2>
        <input
          type="text"
          name="imeSkole"
          id="imeSkole"
          placeholder="Ime škole"
          onChange={(e) => setImeSkole(e.target.value)}
        />
        <input
          type="text"
          name="adresaSkole"
          id="adresaSkole"
          placeholder="Adresa škole"
          onChange={(e) => setAdresaSkole(e.target.value)}
        />
        <input
          type="text"
          name="brojTelefona"
          id="brojTelefona"
          placeholder="Broj telefona"
          onChange={(e) => setBrojTelefona(e.target.value)}
        />
        <div className="buttonOptions">
          <button onClick={() => kreirajNovuSkolu()}>Potvrdi</button>
          <button onClick={() => setIsNewSchool(false)}>Odustani</button>
        </div>
        <button
          className="x"
          onClick={() => {
            setIsNewSchool(false);
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}
